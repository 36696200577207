<template>
  <v-dialog persistent v-model="display" :width="width">
    <v-card rounded="0" class="px-12">
      <div class="d-flex align-center">
        <v-icon class="mr-2">mdi-camera</v-icon>
        <v-card-title class="word-break px-0 mx-0">
          Please crop the image below
          {{ aspectRatioText ? `to a ${aspectRatioText} aspect ratio.` : "" }}
        </v-card-title>
      </div>
      <cropper ref="cropper" :src="imageUrl" :stencil-props="stencilProps" />
      <v-alert dense outlined v-if="!imageisPNG" type="error" class="mt-4">
        To avoid display issues we suggest a PNG logo file
      </v-alert>
      <v-card-actions class="pt-4">
        <v-spacer />
        <v-btn text @click="close(false)">Cancel</v-btn>
        <v-btn text color="primary" @click="close(true)">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ImageCropper",
  components: {},
  props: {
    width: {
      type: Number,
      default: 600
    },
    aspectRatioValue: {
      type: Number
      // default: 16 / 9
    },
    aspectRatioText: {
      type: String
      // default: "16 : 9"
    },
    fileName: {
      type: String
    },
    imageUrl: {
      type: String
    },
    imageisPNG: {
      type: Boolean,
      default: true
    }
  },
  data() {
    var d = {
      display: true,
      loading: false,
      stencilProps: {
        aspectRatio: this.aspectRatioValue
      }
    };
    return d;
  },
  created() {
    console.log("Created ImageCropper");
  },

  methods: {
    close(save = false) {
      if (!save) return this.reset();

      this.loading = true;
      const cropperRef = this.$refs.cropper;
      const { canvas } = cropperRef.getResult();
      canvas.toBlob(blob => {
        // Do something with blob: upload to a server, download and etc.
        const file = new File([blob], this.fileName);
        const imageUrl = canvas.toDataURL();
        this.newCompanyPicFile = file;
        this.newCompanyPicTemp = canvas.toDataURL();
        this.$emit("success", { file, imageUrl });
        this.loading = false;
        this.display = false;
        console.log("Successfully processed picture: ", file);
        // this.reset();
      });
    },
    reset() {
      this.$emit("close");
      this.display = false;
    }
  },

  computed: {},
  watch: {
    display: function(newVal) {
      if (!newVal) this.$emit("close");
    }
  }
};
</script>

<style></style>
