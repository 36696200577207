var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isAdmin)?_c('div',[_c('chat-agent',{class:['agent-window', _vm.fab ? 'active' : ''],attrs:{"height":"614px","width":"384px"},on:{"dismissed":function($event){_vm.fab = false}}}),_c('v-speed-dial',{staticClass:"clickthrough polite",attrs:{"top":_vm.top,"bottom":_vm.bottom,"right":_vm.right,"left":_vm.left,"direction":_vm.direction,"open-on-hover":_vm.hover,"transition":_vm.transition,"fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{class:!_vm.fab
            ? [
                _vm.active ? 'active' : '',
                'blue',
                'widget',
                'align-center',
                'd-flex',
                'justify-start',
                'pa-2',
                'rounded-pill'
              ]
            : ['blue', 'active'],attrs:{"dark":"","x-large":"","tile":!_vm.fab},on:{"click":function($event){$event.stopPropagation();return _vm.open.apply(null, arguments)}}},[(!_vm.fab)?[_c('v-avatar',{staticClass:"pa-2 white mr-2",attrs:{"size":"32px"}},[_c('v-img',{attrs:{"contain":"","src":require("@/assets/robin/Curious.png"),"height":"32px"}})],1),_c('p',{staticClass:"subtitle-1 my-5"},[_vm._v("Hi! Can I help?")])]:_c('v-icon',[_vm._v("mdi-close")])],2)]},proxy:true}],null,false,2100335280)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }