import { main as axios, oauth as oauthAxios } from "../axios";

const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_TRIGGER_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace(
      "MICROSERVICE",
      "microservice-trigger"
    )
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE ? "/microservice-trigger" : "/trigger-service"
    }`;

class TriggerService {
  createActivity(body, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/activity", body)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error creating activity ", error.response);
        return error;
      });
  }
}

export default new TriggerService();
