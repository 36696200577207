<template>
  <div>
    <v-dialog
      fullscreen
      value="true"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="brandDarkCyan" rounded="0" elevation="0">
          <v-btn dark icon large @click="reset" class="ml-1">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title class="d-flex align-center font-weight-bold"
            ><v-icon v-if="!isMobile" class="mr-3">mdi-cash-fast</v-icon>
            {{ paymentTileName || "Team Rewards Budgets" }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            dark
            icon
            large
            @click="reset"
            class="ml-1 hidden"
            v-if="userProfile.clientId != 1"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-autocomplete
            v-else
            outlined
            dense
            hide-details
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client"
            style="max-width: 250px;"
            v-model="clientId"
            @change="refreshClientId"
            :disabled="slideKey > keys.review"
          ></v-autocomplete>
        </v-toolbar>
        <v-row
          class="full-height mt-6 pb-6 container-row"
          justify="center"
          align="center"
          height="100%"
          no-gutters
        >
          <v-col
            cols="11"
            sm="11"
            md="5"
            lg="4"
            xl="3"
            class="main-card rounded-lg px-5 d-flex flex-column justify-space-between"
          >
            <div>
              <div class="d-flex justify-space-between align-center mt-10">
                <v-progress-linear
                  :value="
                    slideKey <= keys.review ? slideKey * 25 : progressBarValue
                  "
                  :color="loading.submitting ? 'brandDarkCyan' : 'brandCyan'"
                  height="40"
                  class="pill-nav mr-6"
                >
                  <!-- <div style="left: 0; width: 20%; height: 40px; background: red;"></div> -->
                  <div class="d-flex justify-space-between align-center">
                    <div
                      @click="goToSlide(keys.recipient)"
                      class="pill-item"
                      :style="`width: calc(${navigationItemWidth}px / 4);`"
                      :class="{ 'active-pill': slideKey == keys.recipient }"
                    >
                      <v-icon color="white" :small="$vuetify.breakpoint.xs"
                        >mdi-account-multiple</v-icon
                      >
                    </div>
                    <div
                      @click="goToSlide(keys.payment)"
                      class="pill-item"
                      :style="`width: calc(${navigationItemWidth}px / 4);`"
                      :class="{ 'active-pill': slideKey == keys.payment }"
                    >
                      <v-icon color="white" :small="$vuetify.breakpoint.xs"
                        >mdi-credit-card-outline</v-icon
                      >
                    </div>
                    <div
                      @click="goToSlide(keys.memo)"
                      class="pill-item"
                      :style="`width: calc(${navigationItemWidth}px / 4);`"
                      :class="{ 'active-pill': slideKey == keys.memo }"
                    >
                      <v-icon color="white" :small="$vuetify.breakpoint.xs"
                        >mdi-message-text</v-icon
                      >
                    </div>
                    <div
                      @click="goToSlide(keys.review)"
                      class="pill-item"
                      :style="`width: calc(${navigationItemWidth}px / 4);`"
                      :class="{
                        'active-pill': slideKey == keys.review
                      }"
                    >
                      <v-icon color="white" :small="$vuetify.breakpoint.xs"
                        >mdi-rocket-launch</v-icon
                      >
                    </div>
                  </div>
                </v-progress-linear>
                <v-btn
                  :width="isMobile ? 90 : 110"
                  rounded
                  depressed
                  color="brandCyan"
                  class="white--text font-weight-bold"
                  @click="goToNextSlide"
                  :disabled="disableContinueButton"
                >
                  <v-icon
                    color="white"
                    class="mr-1"
                    v-if="slideKey == keys.sent"
                    >mdi-checkbox-marked-circle</v-icon
                  >
                  <v-icon
                    color="white"
                    class="mr-1"
                    v-else-if="!isMobile && slideKey != keys.review"
                    >mdi-arrow-right-circle</v-icon
                  >
                  <span v-if="slideKey !== keys.sent" style="margin-top:2px;">{{
                    slideKey == keys.sent
                      ? "Close"
                      : slideKey == keys.review
                      ? "Send"
                      : "Next"
                  }}</span>
                </v-btn>
              </div>
              <!-- <div class="d-flex justify-space-between align-center mt-10"> 
            <div class="pill-nav mr-6">
              <div class="d-flex justify-space-between align-center">
                <div
                  @click="slideKey = keys.submitting"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.budget }"
                >
                  <v-icon color="white">mdi-wallet</v-icon>
                </div>
                <div
                  @click="slideKey = keys.memo"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.memo }"
                >
                  <v-icon color="white">mdi-message-text-outline</v-icon>
                </div>
                <div
                  @click="slideKey = keys.csv"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.csv }"
                >
                  <v-icon color="white">mdi-upload</v-icon>
                </div>
                <div
                  @click="slideKey = keys.review"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{
                    'active-pill':
                      slideKey == keys.review ||
                      slideKey == keys.submittingValidation,
                  }"
                >
                  <v-progress-circular
                    color="white"
                    indeterminate
                    :size="25"
                    :width="4"
                    v-if="slideKey == keys.submittingValidation"
                  ></v-progress-circular>
                  <v-icon color="white" v-else>mdi-rocket-launch</v-icon>
                </div>
              </div>
            </div>-->

              <!-- </div> -->
              <transition :name="slideDirection" mode="out-in">
                <div
                  v-if="slideKey === keys.recipient"
                  :key="keys.recipient"
                  class="full-width text-left"
                >
                  <p class="text-h6 font-weight-bold mt-12">
                    Who is receiving these
                    {{ paymentTileName || "Team Rewards Budgets" }}?
                  </p>
                  <p class=" mt-2">
                    Each recipient of a
                    {{ paymentTileName || "Team Rewards Budgets" }} will be able
                    to send those funds to team members as rewards,
                    recognitions, or bonuses.
                  </p>
                  <div class="d-flex align-center flex-wrap mt-4">
                    <div
                      v-for="(user, index) in values.selected"
                      :key="user.userId"
                      :style="{ 'max-width': '100%' }"
                    >
                      <v-chip
                        class="ma-1 selected-audience-chip"
                        close
                        outlined
                        color="brandGreen"
                        @click:close="removeUserFromSelected(index)"
                      >
                        <span class="text-truncate black--text">{{
                          user.displayName
                        }}</span>
                      </v-chip>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between align-center mt-10">
                    <v-text-field
                      label="Start typing a name, email, or phone"
                      v-model="data.debounceSearch"
                      outlined
                      flat
                      dense
                      hide-details
                      class="search-field mr-3"
                      @focus="changeSearchTab"
                    >
                    </v-text-field>
                    <v-btn
                      fab
                      depressed
                      small
                      color="brandCyan"
                      @click="
                        if (data.activeAudienceTab == 2) {
                          dialog.createGroup = true;
                        } else {
                          dialog.createUser = true;
                        }
                      "
                      ><v-icon color="white">{{
                        data.activeAudienceTab == 2
                          ? "mdi-account-multiple-plus"
                          : "mdi-account-plus"
                      }}</v-icon></v-btn
                    >
                  </div>
                  <div
                    class="d-flex justify-space-between align-center mt-6"
                    :class="{ 'mx-14': !isMobile }"
                  >
                    <!-- <span
                      @click="changeAudienceTab(0)"
                      class="rounded-pill audience-tab cursor-pointer"
                      :class="{
                        'active-audience-tab': data.activeAudienceTab == 0
                      }"
                      >Recent</span
                    > -->
                    <span
                      @click="changeAudienceTab(1)"
                      class="rounded-pill audience-tab cursor-pointer"
                      :class="{
                        'active-audience-tab': data.activeAudienceTab == 1
                      }"
                      >People</span
                    >
                    <span
                      @click="changeAudienceTab(2)"
                      class="rounded-pill audience-tab cursor-pointer"
                      :class="{
                        'active-audience-tab': data.activeAudienceTab == 2
                      }"
                      >Groups</span
                    >
                  </div>
                  <div class="text-left mt-2 ">
                    <p class="darkGrey--text text-sm">
                      Note: Budgets don't support external users at this
                      time<span v-if="!!values.selected.find(x => x.groupId)"
                        >. If groups contain external users, they will be
                        ignored.</span
                      >
                    </p>
                    <v-divider class="mt-5" />
                    <!-- Table for people -->
                    <div v-if="data.activeAudienceTab <= 1">
                      <UserTable
                        ref="user-table"
                        :selected="selectedUsers"
                        :search="data.search"
                        :external="false"
                        sourcePage="budgetwizard"
                        @select-item="addUserToSelected($event, false)"
                      />
                    </div>
                    <div v-else>
                      <GroupTable
                        ref="group-table"
                        :selected="selectedGroups"
                        :search="data.search"
                        @select-item="addUserToSelected($event, false)"
                        @update-item="updateSelectedItem"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey === keys.payment"
                  :key="keys.payment"
                  class="full-width text-left"
                >
                  <p class="text-h6 font-weight-bold mb-5 mt-12">
                    How much do you want to put in each budget?
                  </p>

                  <div class="d-flex justify-space-between align-center mb-5">
                    <div class="d-flex flex-column text-sm-left">
                      <span class="brandCyan--text">Amount per person</span>
                      <v-text-field
                        outlined
                        dense
                        placeholder="0.00"
                        color="brandCyan"
                        :prefix="cashAward ? '$' : ''"
                        class="mt-0 pt-0 amount-text-field"
                        type="number"
                        hide-details
                        v-model="values.amount"
                        :rules="rules.amount"
                      ></v-text-field>
                    </div>

                    <div class="d-flex align-center brandCyan--text mt-4">
                      <v-progress-circular
                        indeterminate
                        :size="20"
                        :width="2"
                        color="brandCyan"
                        v-if="loading.distinctUsers"
                      ></v-progress-circular>
                      <span v-else> {{ paymentAudienceCount }}</span>
                      <v-icon color="brandCyan" class="ml-1"
                        >mdi-account-multiple</v-icon
                      >
                    </div>
                    <div class="d-flex flex-column text-sm-left">
                      <span class="">Total funded</span>
                      <v-text-field
                        outlined
                        readonly
                        dense
                        placeholder="0.00"
                        color="brandCyan"
                        :prefix="cashAward ? '$' : ''"
                        class="mt-0 pt-0 amount-text-field"
                        type="number"
                        hide-details
                        v-model="totalAmount"
                        :rules="rules.amount"
                      ></v-text-field>
                    </div>
                  </div>
                  <p v-if="amountRemainingInBudget < 0" class="mt-5">
                    Over budget by
                    <span class="font-weight-bold ml-1 error--text">
                      {{ amountRemainingInBudgetFormatted }}</span
                    >
                  </p>

                  <p class="text-caption ml-2 mb-1 mt-12">Deducted from</p>
                  <v-select
                    outlined
                    dense
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    color="brandCyan"
                    :items="visibleBudgets"
                    :loading="loadingBudgetsComputed"
                    v-model="values.budgetId"
                    item-value="budgetId"
                    item-text="displayNameWithBalance"
                    :no-data-text="
                      loadingBudgetsComputed
                        ? 'Loading your budgets'
                        : 'No budgets found'
                    "
                    :rules="[v => !!v || 'Budget is required']"
                    @blur="sendBudgetIdTrigger"
                  >
                    <template v-slot:item="{ item }">
                      <div
                        class="d-flex align-center justify-space-between full-width"
                      >
                        <span class="budget-name-container text-overflow-clip">
                          {{ item.budgetDisplayName }}
                        </span>
                        <span class="text-right word-break"
                          >{{
                            formatCurrency(
                              item.budgetBalance,
                              item.awardId == 1
                            )
                          }}
                          <span v-if="item.awardId != 1 && item.Award">{{
                            item.budgetBalance == 1
                              ? item.Award.unitSingular || "unit"
                              : item.Award.unitPlural || "units"
                          }}</span></span
                        >
                      </div>
                    </template></v-select
                  >
                  <div
                    v-if="
                      selectedBudget &&
                        selectedBudget.permissions &&
                        selectedBudget.permissions.owner
                    "
                    class="mb-4"
                  >
                    <v-btn
                      text
                      :color="
                        amountRemainingInBudget < 0 ? 'error' : 'brandCyan'
                      "
                      @click="dialog.funding = true"
                    >
                      + Add funds
                    </v-btn>
                  </div>
                </div>
                <div
                  v-else-if="slideKey === keys.memo"
                  :key="keys.memo"
                  class="full-width text-left"
                >
                  <p class="text-h6 font-weight-bold mb-5 mt-12">
                    What do you want the budgets to be called?
                  </p>
                  <v-text-field
                    label="Budget name"
                    color="brandCyan"
                    v-model="values.name"
                    outlined
                    dense
                    :rules="[
                      v => !!v || 'A budget name is required',
                      v =>
                        !!(v && v.length < 255) ||
                        'The budget name should be shorter'
                    ]"
                  ></v-text-field>
                  <p class="text-h6 font-weight-bold mb-5 mt-5">
                    Add a note?
                    <span class="font-weight-regular"
                      >It's optional, but recommended.</span
                    >
                  </p>
                  <v-textarea
                    label="Memo"
                    outlined
                    v-model="values.memo"
                  ></v-textarea>
                </div>

                <div
                  v-else-if="slideKey === keys.review"
                  :key="keys.review"
                  class="full-width text-left"
                >
                  <div>
                    <p class="text-h6 font-weight-bold mb-5 mt-12">
                      Does this look right?
                    </p>
                    <div class="d-flex justify-start align-center">
                      <div
                        :style="
                          `background: ${(values.preset &&
                            values.preset.backgroundColor) ||
                            '#349E03'}`
                        "
                        class="icon-img rounded-lg d-flex justify-center align-center pa-1 mr-3"
                      >
                        <v-img
                          v-if="
                            values.preset &&
                              values.preset.Icon &&
                              values.preset.Icon.imageUrl
                          "
                          :src="values.preset.Icon.imageUrl"
                          width="45"
                          height="45"
                          contain
                        />
                        <v-icon v-else color="white" large>mdi-wallet</v-icon>
                      </div>
                      <div class="d-flex flex-column">
                        <p class="mb-0">
                          for
                          <v-progress-circular
                            indeterminate
                            :size="20"
                            :width="2"
                            color="brandCyan"
                            class="mx-1"
                            v-if="loading.distinctUsers"
                          ></v-progress-circular>
                          <span
                            v-else
                            @click="goToSlide(keys.recipient)"
                            class="brandCyan--text font-weight-bold cursor-pointer"
                            >{{ paymentAudienceCount }}</span
                          >
                          {{ paymentAudienceCount == 1 ? "person" : "people" }}
                        </p>
                        <p class="mb-1">
                          Total funded:
                          <span class="font-weight-bold ml-1">
                            {{ formattedTotalPayment }}</span
                          >
                        </p>
                      </div>
                    </div>
                    <p v-if="amountRemainingInBudget < 0" class="mt-5">
                      Over budget by
                      <span class="font-weight-bold ml-1 error--text">
                        {{ amountRemainingInBudgetFormatted }}</span
                      >
                    </p>

                    <p class="text-caption mb-1 grey--text mt-3">
                      Memo
                    </p>
                    <v-textarea outlined v-model="values.memo"></v-textarea>
                    <p class="text-caption mb-0 grey--text mt-3 mb-2">
                      Payment details
                    </p>
                    <div
                      class="d-flex justify-start align-center full-width mb-2"
                      v-if="selectedBudget"
                    >
                      <p
                        class="review-page-text mt-0 mb-0"
                        :class="{ 'text-xs': $vuetify.breakpoint.xs }"
                      >
                        Payment source:
                      </p>
                      <v-select
                        outlined
                        dense
                        attach
                        :menu-props="{ bottom: true, offsetY: true }"
                        color="brandCyan"
                        :items="visibleBudgets"
                        :loading="loadingBudgetsComputed"
                        v-model="values.budgetId"
                        item-value="budgetId"
                        item-text="displayNameWithBalance"
                        :no-data-text="
                          loadingBudgetsComputed
                            ? 'Loading your budgets'
                            : 'No budgets found'
                        "
                        :rules="[v => !!v || 'Budget is required']"
                        @blur="sendBudgetIdTrigger"
                        class="review-page-field"
                        hide-details
                      >
                        <template v-slot:item="{ item }">
                          <div
                            class="d-flex align-center justify-space-between full-width"
                          >
                            <span
                              class="budget-name-container text-overflow-clip"
                            >
                              {{ item.budgetDisplayName }}
                            </span>
                            <span class="text-right word-break"
                              >{{
                                formatCurrency(
                                  item.budgetBalance,
                                  item.awardId == 1
                                )
                              }}
                              <span v-if="item.awardId != 1 && item.Award">{{
                                item.budgetBalance == 1 &&
                                item.Award.unitSingular
                                  ? item.Award.unitSingular
                                  : item.budgetBalance != 1 &&
                                    item.Award.unitPlural
                                  ? item.Award.unitPlural
                                  : ""
                              }}</span></span
                            >
                          </div>
                        </template></v-select
                      >
                    </div>

                    <!-- <div class="d-flex justify-start align-start full-width mb-2">
                  <p class="review-page-text mb-0 mt-2">Funds expire:</p>
                  <div class="d-flex flex-column review-page-field">
                    <v-select
                      outlined
                      dense
                      attach
                      :menu-props="{ top: true, offsetY: true }"
                      color="brandCyan"
                      :items="data.expirationOptions"
                      v-model="values.expiration"
                      item-value="id"
                      item-text="label"
                      class=""
                      hide-details
                    >
                    </v-select>
                    <input
                      v-if="values.expiration == 7"
                      class="timestamp-input mt-2"
                      type="datetime-local"
                      v-model="values.expirationCustom"
                    />
                  </div>
                </div>
                <div class="d-flex justify-start align-center full-width mb-2">
                  <p class="review-page-text mb-0">Recurring:</p>
                  <v-select
                    outlined
                    dense
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    color="brandCyan"
                    :items="data.recurringOptions"
                    :rules="rules.recurring"
                    v-model="values.recurring"
                    item-value="id"
                    item-text="label"
                    class="review-page-field"
                    hide-details
                  >
                  </v-select>
                </div>
                <div
                  class="d-flex justify-start align-center full-width mb-2"
                  v-if="values.recurring != null && values.recurring != 1"
                >
                  <p class="review-page-text mb-0"># of payments:</p>
                  <v-text-field
                    outlined
                    dense
                    color="brandCyan"
                    class="mt-0 pt-0 review-page-field"
                    type="number"
                    hide-details
                    v-model="values.recurringNum"
                    :rules="rules.amount"
                  ></v-text-field>
                </div> -->
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.submitting"
                  :key="keys.submitting"
                  class="full-width text-left"
                >
                  <div>
                    <p class="text-h6 font-weight-bold mb-5 mt-12">
                      Processing...
                    </p>
                    <div class="d-flex flex-column align-center"></div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.sent"
                  :key="keys.sent"
                  class="full-width text-left pt-12"
                >
                  <p class="text-h6 word-break font-weight-bold mb-3">
                    You just made someone's day better!
                  </p>
                  <!-- <p class="full-width">
                Your payments are being processed. You will be notified once
                completed.
              </p> -->
                  <div class="d-flex justify-center mt-6">
                    <Robin
                      :height="120"
                      :width="140"
                      class=""
                      :loop="false"
                      animation="backflip"
                    />
                  </div>
                  <div class="d-flex justify-center mt-12 mb-5">
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text"
                      @click="reset"
                      width="95"
                      ><span>Close</span></v-btn
                    >
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.error"
                  :key="keys.error"
                  class="full-width text-left pt-12"
                >
                  <p class="text-h6 word-break font-weight-bold mb-3">
                    We had some trouble sending your payment. <br />Please try
                    again later.
                  </p>
                  <p class="full-width">
                    If this problem continues, feel free to reach out to
                    customer service via one of the following methods...
                  </p>
                  <p class="pl-4 full-width">
                    Email -
                    <b
                      ><a href="mailto:help@wewhistle.com"
                        >help@wewhistle.com</a
                      ></b
                    >
                  </p>
                  <p class="pl-4 full-width">
                    Phone (Toll Free) - <b>(855) 264-3329</b>
                  </p>

                  <div class="d-flex justify-center my-5">
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text mt-4"
                      @click="(slideKey = keys.review), (progressBarValue = 0)"
                      ><v-icon class="mr-1">mdi-chevron-left</v-icon
                      ><span style="margin-top:1px;">Go back</span></v-btn
                    >
                  </div>
                </div>
              </transition>
            </div>

            <div
              v-if="slideKey == keys.review || slideKey == keys.submitting"
              class="mt-2 mb-5 d-flex flex-column align-center"
            >
              <v-btn
                rounded
                depressed
                color="brandCyan"
                class="white--text"
                @click="submitPayment"
                :disabled="disableContinueButton"
              >
                {{
                  slideKey == keys.review ? "Create budgets" : "Processing"
                }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-overlay
          v-if="
            dialog.createGroup ||
              dialog.createUser ||
              dialog.categoryDrawer ||
              dialog.funding
          "
        />
        <!-- User creator widget -->
        <v-navigation-drawer
          v-model="dialog.createUser"
          temporary
          fixed
          right
          width="500"
        >
          <UserCreatorWidgetSimple
            v-if="dialog.createUser"
            @close="dialog.createUser = false"
            @get-users="getUsers"
            :clientId="clientId"
            :external="false"
            source="BUDGET_WIZARD"
          ></UserCreatorWidgetSimple>
        </v-navigation-drawer>
        <!-- Group creator widget -->
        <v-navigation-drawer
          v-model="dialog.createGroup"
          temporary
          fixed
          right
          width="500"
        >
          <GroupCreatorWidget
            v-if="dialog.createGroup"
            @get-groups="getGroups($event, true)"
            @close="dialog.createGroup = false"
            :clientId="clientId"
            :version="3"
            source="BUDGET_WIZARD"
          />
        </v-navigation-drawer>
        <!-- Budget funding -->
        <v-navigation-drawer
          v-model="dialog.funding"
          temporary
          fixed
          right
          width="500"
          disable-route-watcher
        >
          <StripePaymentMethodsManager
            v-if="dialog.funding"
            @reset="dialog.funding = false"
            @get-budgets="getBudgets"
            :destinationBudget="selectedBudget"
            :budgets="data.budgets"
            :clientId="clientId"
            source="BUDGETS"
            :displayBudgetTransfer="false"
          />
        </v-navigation-drawer>
      </v-card>
    </v-dialog>

    <!-- Error dialog -->
    <v-dialog v-model="dialogError" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-center align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-2"
            >mdi-alert</v-icon
          >
          <v-card-title
            class="word-break text-left exit-warning-text mx-0 px-0"
          >
            There was an error creating your budgets
          </v-card-title>
        </div>

        <p>{{ errorText }}</p>

        <v-card-actions class="mx-12">
          <!-- <v-btn
            color="primary"
            @click="closeProgramBuilder"
            outlined
            width="130"
            >Don't save</v-btn
          > -->
          <v-btn
            class="mx-auto white--text"
            depressed
            rounded
            color="brandCyan"
            @click="
              (slideKey = keys.review),
                (dialogError = false),
                (progressBarValue = 0)
            "
            width="130"
            >Go Back</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BudgetService from "@/services/BudgetService";
import TriggerService from "@/services/TriggerService";
import UserService from "@/services/UserService";
import GroupService from "@/services/GroupService";

// import { truncateNumber, numberWithCommas } from "@/shared_data/functions";

import Robin from "@/components/Robin";
import UserCreatorWidgetSimple from "@/components/UserCreatorWidgetSimpleV2.vue";
import GroupCreatorWidget from "@/components/groups/GroupCreatorWidgetSimplified.vue";
import GroupTable from "@/components/data-table/Groups.vue";
import UserTable from "@/components/data-table/Users.vue";
import StripePaymentMethodsManager from "@/components/stripe/StripePaymentMethodsManager.vue";

import currency from "currency.js";
import moment from "moment";
import { mapState } from "vuex";
import { debounce } from "@/shared_data/functions";

function initialState() {
  return {
    slideKey: 1,
    dialogError: false,
    errorText: null,
    slideDirection: "topic-left",
    keys: {
      recipient: 1,
      payment: 2,
      memo: 3,
      review: 4,
      submitting: 5,
      // send: 101,
      sent: 102,
      error: 103
    },
    loading: {
      submitting: false,
      users: false,
      groups: false,
      groupAssignments: false,
      distinctUsers: false,
      recent: false,
      budgets: false
    },
    data: {
      search: null,
      debounceSearch: null,
      activeAudienceTab: 1,
      users: [],
      groups: [],
      budgets: [],
      parentPaymentRules: [],
      expirationOptions: [
        { id: 1, label: "Never" },
        { id: 2, label: "1 Week" },
        { id: 3, label: "1 Month" },
        { id: 4, label: "3 Months" },
        { id: 5, label: "6 Months" },
        { id: 6, label: "1 Year" },
        { id: 7, label: "Other" }
      ],
      recurringOptions: [
        { id: 1, label: "Nope, just one time" },
        { id: 2, label: "Weekly" },
        { id: 3, label: "Monthly" },
        { id: 4, label: "Quarterly" },
        { id: 5, label: "Annually" }
      ]
    },
    dialog: {
      previewAudience: false,
      selectedAudience: {},
      createGroup: false,
      createUser: false,
      categoryDrawer: false,
      funding: false
    },
    values: {
      budgetId: null,
      selected: [],
      distinctUsers: 0,
      paymentPresetId: null,
      preset: null,
      amount: null,
      totalAmount: null,
      classification: null,
      name: null,
      memo:
        "This budget is for you! It can be used for rewarding your team members!",
      sendDate: moment().format("YYYY-MM-DDTHH:mm"),
      expiration: 1,
      expirationCustom: null,
      recurring: 1,
      recurringNum: "1",
      categoryIndex: 0
    },
    rules: {
      amount: [
        v =>
          !!(v && parseFloat(v) > 0) || "Payment amount must be greater than 0",
        v =>
          !!(
            v &&
            (!v.toString().includes(".") ||
              (v.toString().includes(".") &&
                v.toString().substring(v.toString().indexOf(".") + 1).length <
                  3))
          ) || `Payment amount shouldn't be longer than 2 decimals.`
      ],
      recurring: [
        v =>
          !!(v && parseFloat(v) > 0) || "# of payments must be greater than 0",
        v =>
          !!(v && !v.toString().includes(".")) ||
          "# of payments must be a whole number"
      ]
    },
    progressBarValue: 0,
    budgetPlatformActivityCounter: 0,
    categoryDescriptionPreview: null,
    navigationItemWidth: 200,
    userTable: {
      itemsPerPage: 10,
      page: 1,
      // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
      awaitingPage: 1,
      search: null,
      debounceSearch: null,
      loading: false,
      users: [],
      total: 0,
      selected: [],
      options: {},
      footerProps: {
        "items-per-page-options": [10, 25, 50, 100]
      },
      latestFilter: null,
      latestSort: null,
      columns: [
        { text: "Name", value: "displayName", width: "40%" },
        { text: "Email", value: "businessEmail", width: "50%" },
        { text: "", value: "add" }
      ]
    }
  };
}

export default {
  name: "BudgetWizard",
  components: {
    Robin,
    UserCreatorWidgetSimple,
    GroupCreatorWidget,
    GroupTable,
    UserTable,
    StripePaymentMethodsManager
  },
  props: {
    budgets: Array,
    loadingBudgets: Boolean,
    previousRoute: {
      type: String,
      default: "paymentadmin"
    },
    previousRouteProps: {
      type: Object,
      default: undefined
    },
    paymentTileName: {
      type: String,
      default: "Team Rewards Budgets"
    }
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created budget wizard", this.paymentTileName);
    if (this.paymentTileName) this.values.name = this.paymentTileName;
    // this.getUsers();

    // this.clientId = this.userProfile.clientId;
    // this.preloadBudget();
    // this.getRecentParentPaymentRules();
    // this.getUsersV2New(true);
    // this.getGroupsV3(0, null, true);
    // this.getPaymentPresets();

    if (!this.budgets) this.getBudgets();

    this.createPlatformActivity("OPENED_BUDGET_WIZARD");
    window.addEventListener("resize", this.refreshScreenSize);
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
    this.refreshScreenSize();
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }

    window.removeEventListener("resize", this.refreshScreenSize);
  },
  beforeDestroy() {},
  methods: {
    formatCurrency(amount, cash = true) {
      return currency(amount).format({ symbol: cash ? "$" : "" });
    },
    resetAllData() {
      // Used to reset the state when the user clicks out of the payments console.
      Object.assign(this.$data, initialState());
    },
    reset() {
      if (this.previousRoute)
        this.$router.push({
          name: this.previousRoute,
          params: this.previousRouteProps || {}
        });
      else {
        this.resetAllData();
        this.$router.push("/paymentadmin");

        // this.$emit("reset");
      }
    },
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },
    refreshScreenSize() {
      setTimeout(() => {
        if (document.getElementsByClassName("pill-nav").length > 0) {
          this.navigationItemWidth = document.getElementsByClassName(
            "pill-nav"
          )[0].offsetWidth;
        } else {
          this.navigationItemWidth = 200;
        }
        console.log("Nav width ", this.navigationItemWidth);
      }, 30);
    },
    goToSlide(index) {
      // Figures out what step we're going into so we can insert the proper platform activity
      const oldIndex = this.slideKey;
      var platformActivityEvent = null;
      if (this.slideKey == this.keys.recipient)
        platformActivityEvent = "SELECTED_BUDGET_RECIPIENTS";
      else if (this.slideKey == this.keys.memo) {
        platformActivityEvent = "ADDED_BUDGET_MEMO";
      } else if (this.slideKey == this.keys.payment)
        platformActivityEvent = "SELECTED_BUDGET_AMOUNT";
      // else if (this.slideKey == this.keys.expiration)
      //   platformActivityEvent = "SELECTED_PAYMENT_EXPIRATION_DATE";
      // this.disableContinueButton
      // We have to block if the user has already sent payment
      if (this.slideKey >= 1 && this.slideKey < this.keys.submitting) {
        if (index < this.slideKey) {
          this.slideDirection = "topic-right";
          this.scrollToTop();
          return (this.slideKey = index);
        } else {
          this.slideDirection = "topic-left";
        }

        // We route depending on true's and false's in the computed array
        var foundFalse = false;
        // Index is relative to all. We have to equate it to the index in our current route;
        var routeIndex = this.route.findIndex(x => x == index);

        // When using goToSlide to jump around, we have to make sure the user has completed all steps prior to the step that they're trying to route to.
        for (var i = 0; i < routeIndex; i++) {
          if (!this.routeValidation[i]) {
            foundFalse = true;
          }
        }

        // If we only found true values in the array, then we didn't find a false and therefore can continue
        if (!foundFalse) {
          this.scrollToTop();
          this.slideKey = index;

          if (oldIndex == this.keys.recipient)
            this.getDistinctUsersFromGroups();
          if (platformActivityEvent)
            this.createPlatformActivity(platformActivityEvent);
        }
      }
    },
    goToNextSlide() {
      this.slideDirection = "topic-left";
      this.scrollToTop();
      const oldIndex = this.slideKey;

      if (this.slideKey === this.keys.review) {
        //We're on the final slide so show confirmation
        //Or we are editing and the message has already sent, so they can only update the message body
        this.submitPayment();
      } else if (this.slideKey == this.keys.sent) {
        this.reset();
      } else {
        // Figures out what step we're going into so we can insert the proper platform activity
        var platformActivityEvent = null;
        if (this.slideKey == this.keys.recipient)
          platformActivityEvent = "SELECTED_BUDGET_RECIPIENTS";
        else if (this.slideKey == this.keys.memo) {
          platformActivityEvent = "ADDED_BUDGET_MEMO";
        } else if (this.slideKey == this.keys.payment)
          platformActivityEvent = "SELECTED_BUDGET_AMOUNT";

        // The route has been established. Now we have to check if they can proceed
        var routeIndex = this.route.findIndex(x => x == this.slideKey);

        // if (
        //   this.route[routeIndex + 1] == this.keys.mccCustom &&
        //   this.slideProvisional.selectedPresets.length > 0
        // ) {
        //   // We can skip custom MCC page
        //   routeIndex++;
        // }
        this.slideKey = this.route[routeIndex + 1];

        if (oldIndex == this.keys.recipient) this.getDistinctUsersFromGroups();

        if (platformActivityEvent)
          this.createPlatformActivity(platformActivityEvent);
      }
    },
    goToPreviousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();

      // The route has been established. Now we have to check if they can proceed
      var routeIndex = this.route.findIndex(x => x == this.slideKey);
      var counter = 1;

      this.slideKey = this.route[routeIndex - counter];
    },

    async submitPayment() {
      // Show loading bar
      console.log("Submitting payment! ", this.keys.submitting);
      this.slideKey = this.keys.submitting;
      this.loading.submitting = true;
      const progressIncrement = 1.5;
      const sleepDur = 150;
      this.loadBarSleeper(progressIncrement, sleepDur, false);

      try {
        var object = {
          clientId: this.clientId,
          budgetId: this.values.budgetId,
          name: this.values.name,
          description: this.computedNote,
          amount: currency(this.values.amount),
          audience: {
            userId: this.values.selected
              .filter(x => x.userId)
              .map(x => x.userId),
            groupId: this.values.selected
              .filter(x => x.groupId)
              .map(x => x.groupId)
          },
          source: "BUDGET_WIZARD"
        };

        console.log(object);
        // Display loading screen
        // var response;
        var response = await BudgetService.createManagerBudgets(object);
        this.loading.submitting = false;
        this.loadBarSleeper(progressIncrement, sleepDur, true);
        console.log(response);

        // Display success screen
        this.slideKey = this.keys.sent;
        // })
      } catch (error) {
        console.log("Error creating budgets! ", error);
        if (error && error.error_code && typeof error.error_code == "string") {
          if (error.error_code == "1060100") {
            this.dialogError = true;
            this.errorText =
              "The total amount is more than the selected budget's balance.";
            return;
          }
        } else if (error && error.error) {
          this.slideKey = this.keys.error;
          return;
        }
        this.dialogError = true;
        this.errorText =
          "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
      }
    },
    loadBarSleeper(incr, dur, override = false) {
      setTimeout(() => {
        console.log("Incrementing progress", this.progressBarValue);
        if (!this.loading.submitting || override) {
          // We just want to skip to the end
          this.progressBarValue = 100;
        } else {
          this.progressBarValue += incr;
          // We loop IF no error has occurred
          if (
            this.progressBarValue < 90 &&
            this.slideKey != this.keys.error &&
            !this.dialogError
          )
            this.loadBarSleeper(incr, dur);
        }
      }, dur);
    },
    refreshClientId(clientId) {
      this.clientId = clientId;

      this.slideKey = this.keys.recipient;
      this.values.budgetId = null;
      this.values.selected = [];

      // We emit this so the parent (Payment Admin will refresh budgets)
      this.$emit("update-client", clientId);
      this.getBudgets();
      // this.getRecentParentPaymentRules();
      // this.getGroupsV3(0, null, true);
      // this.getUsersV2New(true);
      // this.getPaymentPresets();
    },
    preloadBudget() {
      // Preload the master budget
      if (this.values.budgetId) return;
      const clientBudgets = (this.budgets || this.data.budgets).filter(
        x =>
          x.clientId == this.clientId &&
          // x.awardId == 1 &&
          x.status == "Active" &&
          Number(x.budgetBalance) > 0
      );

      const acctBalance = clientBudgets.find(x => x.masterBudget);
      const cashBudget = clientBudgets.find(x => x.awardId == 1);
      if (acctBalance) this.values.budgetId = acctBalance.budgetId;
      else if (cashBudget) this.values.budgetId = cashBudget.budgetId;
      else if (clientBudgets.length)
        this.values.budgetId = clientBudgets[0].budgetId;
    },
    createPlatformActivity(event) {
      var obj = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        event: event,
        source: "BUDGET_WIZARD",
        category: "PAYMENTS",
        date: new Date()
      };
      TriggerService.createActivity(obj, this.magicLinkToken)
        .then(response => {
          console.log("Response from inserting activity ", response);
        })
        .catch(error => {
          console.log("There was an error inserting activity ", error);
        });
    },
    sendBudgetIdTrigger() {
      // We want to fire off a platform activity for when they update the payment memo, but this can be done on any screen.
      // So this gets called when the payment memo field loses focus
      // We also want to make sure this only gets sent once.
      if (this.values.budgetId && this.budgetPlatformActivityCounter == 0) {
        this.createPlatformActivity("SELECTED_BUDGET_WIZARD_BUDGET");
        this.budgetPlatformActivityCounter++;
      }
    },
    changeAudienceTab(newIndex) {
      if (newIndex == this.data.activeAudienceTab) return;
      const oldTab = this.data.activeAudienceTab;
      const oldSearchValue = this.data.search;
      this.$set(this.data, "activeAudienceTab", newIndex);
      // this.data.activeAudienceTab = newIndex;

      this.data.debounceSearch = null;
      this.data.search = null;
      // for some reason, if we change the tab, the user table's options don't initialize until you interact with the table once. But if you set the page to 2 then it forces it to init????
      if (this.userTable.options && !this.userTable.options.itemsPerPage) {
        this.userTable.options.page = 2;
        console.log("Setting new page val");
      }

      // If we're leaving users and there was a search value, we refetch users
      // if (oldTab == 1 && oldSearchValue) this.getUsersV2New(true);
    },
    changeSearchTab() {
      console.log("Focused on search bar");
      if (this.data.activeAudienceTab == 0) this.data.activeAudienceTab = 1;
      // for some reason, if we change the tab, the user table's options don't initialize until you interact with the table once. But if you set the page to 2 then it forces it to init????
      if (this.userTable.options && !this.userTable.options.itemsPerPage)
        this.userTable.options.page = 2;
    },
    async getBudgets() {
      try {
        this.loading.budgets = true;
        let response = await BudgetService.getBudgets(
          {
            lite: 1,
            clientId: this.clientId
          },
          // this.magicLinkToken,
          2
        );
        console.log("Get budgets ", response);
        this.data.budgets = response.map(x => {
          x.displayNameWithBalance = `${x.budgetDisplayName ||
            x.budgetName} - ${currency(x.budgetBalance).format({
            symbol: x.awardId == 1 ? "$" : ""
          })}`;
          return x;
        });
        this.loading.budgets = false;
        this.preloadBudget();
      } catch (e) {
        console.log("Error getting budgets ", e);
        this.loading.budgets = false;
      }
    },
    getGroups(groupId, wipeSearch = false) {
      // Uses the group table widget
      console.log("getGroups", { groupId, wipeSearch });
      if (wipeSearch) {
        this.data.search = undefined;
        this.data.debounceSearch = undefined;
      }
      this.$nextTick(() => {
        if (this.$refs["group-table"])
          this.$refs["group-table"].getGroups(
            true,
            "getGroups - budget wizard",
            groupId
          );
      });
    },
    getUsers(userId) {
      // Uses the group table widget
      if (this.$refs["user-table"])
        this.$refs["user-table"].getUsers(
          true,
          "getUsers - budget wizard",
          userId
        );
    },
    updateSelectedItem(e) {
      if (!e.groupId) return console.log("no group id");

      this.values.selected.forEach(o => {
        if (o.groupId == e.groupId) {
          console.log("UPDATING SELECTED NUM USERS ", e.numUsers);
          o.numUsers = e.numUsers || 0;
          // this.getDistinctUsersFromGroups();
        }
      });
    },
    async getDistinctUsersFromGroups() {
      console.log("getDistinctUsersFromGroups()");
      // assign to slidePeople.selectedItem.GroupAssignments
      const groups = this.values.selected
        .filter(x => x.groupId)
        .map(x => x.groupId);
      if (!groups.length) return;
      const users = this.values.selected
        .filter(x => x.userId)
        .map(x => x.userId);
      this.loading.distinctUsers = true;
      try {
        const response = await GroupService.getDistinctUsersFromGroupsV2(
          groups,
          users,
          this.magicLinkToken
        );

        this.values.distinctUsers = response;
        console.log("New distinct user count", this.values.distinctUsers);
      } catch (error) {
        console.log("Error getting distinct number of users ", error);
        this.values.distinctUsers = 0;
      } finally {
        this.loading.distinctUsers = false;
      }
    },
    async addUserToSelected(user, dontRemove = false) {
      console.log("Adding user/group to selected array", user);
      // Added because we can only pass one param in from the groups table
      if (user.dontRemove) dontRemove = true;
      this.dialog.previewAudience = false;
      if (user.userId) {
        let idx = this.values.selected.findIndex(x => x.userId == user.userId);
        if (idx == -1) this.values.selected.push(user);
        else if (!dontRemove) this.removeUserFromSelected(idx);
      } else {
        let idx = this.values.selected.findIndex(
          x => x.groupId == user.groupId
        );
        if (idx == -1) this.values.selected.push(user);
        else if (!dontRemove) this.removeUserFromSelected(idx);

        // Wipe distinct user count so we have to manually calculate it again rather than using the cached API value
        this.values.distinctUsers = 0;
      }
    },
    removeUserFromSelected(index) {
      // var user = this.values.selected[index];
      this.values.selected.splice(index, 1);

      // Wipe distinct user count so we have to manually calculate it again rather than using the cached API value
      this.values.distinctUsers = 0;
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "clients",
      "permissions",
      "globalClientId",
      "magicLinkToken"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    disableContinueButton: {
      cache: false,
      get: function() {
        // Compare to route validation
        // We check the index within the route that we're in and use routeValidation to see if we've satisfied the requirements
        var routeIndex = this.route.findIndex(x => x == this.slideKey);
        return !this.routeValidation[routeIndex];
      }
    },
    visibleBudgets() {
      // var awardId = null;
      const budgetArr = (this.budgets || this.data.budgets).filter(x => {
        return (
          x.status == "Active" &&
          x.clientId == this.clientId &&
          (Number(x.allocatedBalance) > 0 || Number(x.budgetBalance) > 0)
        );
      });
      return budgetArr;
    },
    selectedBudget: {
      cache: false,
      get: function() {
        if (this.values.budgetId !== null) {
          var budget = (this.budgets || this.data.budgets).find(
            x => x.budgetId == this.values.budgetId
          );

          return budget;
        }
        return null;
      }
    },
    cashAward() {
      return !(this.selectedBudget && this.selectedBudget.awardId != 1);
    },
    singularAwardUnit() {
      return this.selectedBudget &&
        this.selectedBudget.awardId != 1 &&
        this.selectedBudget.Award
        ? this.selectedBudget.Award.unitSingular || "unit"
        : null;
    },
    pluralAwardUnit() {
      return this.selectedBudget &&
        this.selectedBudget.awardId != 1 &&
        this.selectedBudget.Award
        ? this.selectedBudget.Award.unitPlural || "units"
        : null;
    },
    amountRemainingInBudget() {
      if (this.selectedBudget && this.selectedBudget.budgetBalance) {
        return currency(this.selectedBudget.budgetBalance).subtract(
          this.totalAmount
        );
      } else {
        return 0;
      }
    },
    amountRemainingInBudgetFormatted() {
      return `${currency(this.amountRemainingInBudget).format({
        symbol: this.cashAward ? "$" : ""
      })} ${
        this.cashAward
          ? ""
          : this.amountRemainingInBudget == 1
          ? this.singularAwardUnit
          : this.pluralAwardUnit
      }`;
    },
    route: {
      cache: false,
      get: function() {
        // We establish the route depending on what payment type
        // This dynamic array of keys will be used by routeValidation, disableContinuButton, etc
        return [
          this.keys.recipient,
          this.keys.payment,
          this.keys.memo,
          this.keys.review,
          this.keys.submitting,
          this.keys.sent
        ];
      }
    },
    routeValidation: {
      cache: false,
      get: function() {
        var array = [];

        // This uses this.route and compiles an array of true/false values for their entire route to tell us if they've completed everything that they need to
        // Used by disableContinueButton and goToSlide
        this.route.forEach(page => {
          // Page 1
          // console.log(page);
          if (page == this.keys.recipient && this.paymentAudienceCount > 0) {
            array.push(true);
          } else if (
            page == this.keys.payment &&
            this.values.budgetId &&
            this.amountRemainingInBudget >= 0 &&
            this.totalAmount > 0 &&
            this.values.categoryIndex != null
          ) {
            array.push(true);
          } else if (
            page == this.keys.memo &&
            this.values.name &&
            this.values.name.length < 255
          ) {
            array.push(true);
          } else if (
            page == this.keys.review &&
            this.amountRemainingInBudget >= 0 &&
            !this.loading.distinctUsers
          ) {
            array.push(true);
          } else if (page == this.keys.submitting) {
            array.push(false);
          } else if (page == this.keys.sent) {
            // console.log("sent");
            array.push(true);
          } else {
            // console.log("ELSE");
            array.push(false);
          }
          // console.log(array[array.length - 1]);
        });

        // console.log(array);
        return array;
      }
    },
    loadingBudgetsComputed() {
      if (this.budgets) return this.loadingBudgets;
      return this.loading.budgets;
    },
    computedNote() {
      if (!this.values.memo) return null;
      // let arr = [];
      // if (this.values.memo) {
      //   this.values.memo
      //     .split("\n")
      //     .forEach(item => arr.push(`${item.trim()}<br />`));
      // }
      // return arr.join("");
      return this.values.memo;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    paymentAudienceCount() {
      if (
        this.loading.distinctUsers &&
        this.values.selected.find(x => x.groupId)
      )
        return 0;
      if (
        this.values.distinctUsers &&
        this.values.selected.find(x => x.groupId)
      ) {
        console.log("Returning distinct users ", this.values.distinctUsers);
        return this.values.distinctUsers;
      }
      let count = 0;
      var arr = [];

      this.values.selected.forEach(item => {
        if (item.groupId && item.numUsers) count += item.numUsers;
        else if (item.groupId && item.GroupAssignments)
          arr = arr.concat(item.GroupAssignments.map(x => x.userId));
        else if (item.userId) arr.push(item.userId);
      });

      if (count != 0) return count;
      return [...new Set(arr)].length;
    },
    formattedAmount() {
      return currency(this.values.amount).value;
    },
    totalAmount() {
      return currency(this.values.amount).multiply(this.paymentAudienceCount)
        .value;
    },
    formattedTotalPayment() {
      let v = this.totalAmount;
      return `${currency(v).format({ symbol: this.cashAward ? "$" : "" })} ${
        this.cashAward
          ? ""
          : v == 1
          ? this.singularAwardUnit
          : this.pluralAwardUnit
      }`;
    },
    selectedGroups: {
      get: function() {
        return this.values.selected.filter(x => x.groupId);
      },
      set: function() {
        // console.log("Adding el ", o);
      }
    },
    selectedUsers: {
      get: function() {
        return this.values.selected.filter(x => x.userId);
      },
      set: function() {
        // console.log("Adding el ", o);
      }
    }
  },
  watch: {
    budgets: function(newVal, oldVal) {
      if (oldVal.length == 0) {
        console.log("Got budget watcher", oldVal);

        this.preloadBudget();
      }
    },
    // "data.budgets": function(newVal, oldVal) {
    //   if (oldVal.length == 0) {
    //     console.log("Got budget watcher", oldVal);

    //     this.preloadBudget();
    //   }
    // },
    "data.debounceSearch": debounce(function(newVal) {
      this.data.search = newVal;
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.error-background {
  background-color: var(--v-error-base);
}

.header-text {
  font-size: x-large;
}

.light-grey-background {
  background-color: var(--v-lightGrey-base);
}

.people-group-button {
  padding: 5px 5px;
  border-radius: 7px;
  width: 200px;
  background-color: var(--v-lightGrey-base);
}
.audience-button {
  background-color: white;
  background: white;
  border: 1px solid;
  border-color: var(--v-grey-base);
}
.profile-picture {
  border: 2px solid;
  border-color: var(--v-grey-base);
}
.icon-image {
  height: 105px;
  width: 105px;
}

.text-overflow-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.amount-table {
  padding-top: 1px;
  border: solid 1px;
  border-color: lightgray;
  border-radius: 4px;
}

.amount-table >>> .v-data-table-header {
  background-color: white;
}
.side-nav-bold-text {
  color: var(--v-brand-base);
  font-weight: bold;
}
.side-nav-text {
  color: var(--v-brand-base);
}
.side-nav-icons {
  border-radius: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
}
.side-nav-box {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 35px;
  padding-top: 7px;
}
.side-nav-panel {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-top: 7px;
}
.budget-select >>> .v-list-item {
  font-size: 0.8125rem;
}
.category-div {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
}
.selected-category {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  height: 45px;
  cursor: pointer;
}
.category-container {
  cursor: pointer;
}
.category-container:hover .category-div {
  background-color: #e8e8ec;
}
.selected-category-background {
  background-color: #e8e8ec;
}
.timestamp-input {
  margin-top: 0px;
  margin-bottom: 0px;
}
.rounded-border {
  border: 1px solid lightgray;
  border-radius: 5px;
}
.selected-people-content >>> .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
.input-error {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
  border: 2px solid var(--v-error-base) !important;
}

#note {
  background: var(--v-lightGrey-base);
  padding: 10px 10px;
  margin-left: 24px;
  width: calc(100% - 24px);
  border-radius: 6px;
}

/* outline used for the main content card */
.main-card {
  border: solid 1px;
  border-color: lightgray;
  min-height: calc(100vh - 120px);
}

/* Used for the pill nav at the top of the main card */
.pill-nav {
  /* background: var(--v-grey-base); */
  /* background: var(--v-brandDarkGreen-base); */
  /* opacity: 0.3; */
  width: calc(100% - 20px);
  min-height: 40px;
  max-height: 40px;
  border-radius: 999px;
  /* padding: 0 20px 0 20px; */
}
.pill-nav >>> .pill-item {
  border-radius: 999px;
  /* min-width: 60px; */
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1 !important;
}
.pill-nav >>> .active-pill {
  /* border-radius: 999px; */
  /* background: var(--v-brandGreen-base); */
}

.budget-name-container {
  width: calc(100% - 120px);
}

.progress-bar {
  width: 70%;
  min-width: 220px;
  max-width: 600px;
}

/* progress bar text */
.progress-bar >>> .v-progress-linear__content {
  justify-content: flex-start;
  margin-left: 15px;
}

.progress-bar >>> .v-progress-linear__determinate {
  border-radius: 0 9999px 9999px 0;
}

.progress-bar >>> .v-progress-linear__background {
  left: 0 !important;
  width: 100% !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Hides extra padding that for some reason exists in v-row */
.container-row {
  overflow-x: hidden;
}

/* Provides spacing for each audience tab */
.audience-tab {
  padding: 8px 20px 8px 20px;
}

/* Bkgnd color for selected audience tab */
.active-audience-tab {
  background-color: #e8e8e8 !important;
}

/* Provides feedback when you hover over an audience tab */
.audience-tab:hover {
  background: lightgray;
}

/* Changes the close icon's color for selected audience chips */
.selected-audience-chip >>> .v-chip__close {
  color: lightgray;
}

.preset-loading {
  height: 70px;
}
/* Outer container for row of preset carousel */
.preset-carousel {
  overflow-x: scroll;
}
/* Card for each preset */
.preset-card {
  border: 1px solid lightgray;
  width: 75px;
  min-width: 75px;
  /* background: white !important; */
}

.icon-img {
  width: 70px;
  height: 70px;
}
.preset-card >>> .icon-img {
  width: 50px;
  height: 50px;
}
.preset-card >>> .text {
  font-size: small;
}

/* Sets width for amt field */
.amount-text-field >>> .v-input__slot {
  max-width: 150px;
  width: 150px;
  min-width: 150px;
}

.review-page-field {
  width: 65% !important;
  min-width: 170px !important;
  max-width: 400px !important;
  margin-left: 12px;
}
.review-page-text {
  width: 35% !important;
}
</style>
