<template>
  <div class="full-height">
    <v-card width="100%" rounded="0" class="full-height">
      <div id="header" class="d-flex justify-start" width="100%">
        <v-icon large dark class="pa-2 ml-3 my-2 brand-background border-radius"
          >mdi-email</v-icon
        >
        <v-card-title> Communications </v-card-title>
      </div>
      <v-divider class="mx-4" />
      <v-row class="pl-3">
        <v-col sm="12" md="3">
          <!-- <h3 class="align-text-left mb-3">
            Share the right information at the right time with the right people
          </h3>
          <v-divider></v-divider> -->
          <div
            class="my-4 align-text-right d-flex flex-column align-end justify-center"
          >
            <h4 class="mr-3 mb-2">Need help getting started?</h4>
            <!-- <span small text class="primary--text mb-2 align-text-right mr-2"
              ><a
                class="text-decoration-none"
                href="https://www.success.com/how-to-speak-well-and-listen-better/"
                >View communication guide</a
              >
            </span> -->
            <span small text class="primary--text mb-2 align-text-right mr-2"
              ><a class="text-decoration-none" @click="openFAQ"
                >View the FAQs</a
              >
            </span>
          </div>
          <v-divider></v-divider>
          <!-- <v-card rounded="0" elevation="4" class="mt-4 align-center">
            <v-card-title class="justify-center word-break">
              Need professional design help?
            </v-card-title>
            <v-card-text>
              <span
                >Whistle maintains partnerships with design professionals who
                have experience with program communication design.
              </span>
              <br />
              <br />
              <span small text class="primary--text"
                ><a
                  class="text-decoration-none"
                  href="mailto:help@wewhistle.com"
                  >Contact a communication expert</a
                >
              </span>
            </v-card-text>
          </v-card> -->
        </v-col>
        <v-col sm="12" md="9">
          <v-container
            class="d-flex flex-wrap"
            v-if="
              !dialogNewsCreator &&
                !showEditNewsArticleScreen &&
                !showProgramCommScreen
            "
          >
            <v-card
              rounded="0"
              elevation="4"
              class="mr-4 mt-4"
              width="25%"
              min-width="275"
              @click="loadProgramCommScreen"
            >
              <!-- Empty div because V-cards override border radius for the first element????? -->
              <div></div>
              <div class="image-container mt-2">
                <v-icon
                  x-large
                  dark
                  class="pa-4 mt-3 dark-blue-background border-radius"
                  >mdi-chart-box</v-icon
                >
                <v-icon
                  class="top-right-icon brand-cyan-background"
                  :class="{
                    hidden: dialogNewsCreator
                  }"
                  dark
                  >mdi-plus</v-icon
                >
              </div>

              <v-card-title
                class="word-break justify-center card-title d-flex align-start justify-center"
              >
                Create a new program communication
              </v-card-title>
              <v-card-text>
                Create a new program communication element that can be
                automatically triggered by an action in a program.
              </v-card-text>
            </v-card>
            <v-card
              rounded="0"
              elevation="4"
              class="mr-4 mt-4 "
              width="25%"
              min-width="275"
              @click="showUnderConstruction = true"
            >
              <!-- Empty div because V-cards override border radius for the first element????? -->

              <div></div>
              <div class="image-container mt-2">
                <v-icon
                  x-large
                  dark
                  class="pa-4 mt-3 dark-blue-background border-radius"
                  >mdi-chart-box</v-icon
                >
                <v-icon
                  class="top-right-icon brand-cyan-background"
                  :class="{
                    hidden: dialogNewsCreator
                  }"
                  dark
                  >mdi-pencil</v-icon
                >
              </div>

              <v-card-title
                class="word-break justify-center card-title d-flex align-start justify-center"
              >
                Edit an existing communication element
              </v-card-title>
              <v-card-text>
                Find and edit an existing communication element.
              </v-card-text>
            </v-card>
            <!-- <v-card
              rounded="0"
              elevation="4"
              class="mr-4 mt-4"
              width="25%"
              min-width="275"
              @click="showUnderConstruction = true"
            >
              Empty div because V-cards override border radius for the first element?????
              <div></div>

              <div class="image-container mt-2">
                <v-icon
                  x-large
                  dark
                  class="pa-4 mt-3 dark-blue-background border-radius"
                  >mdi-chart-box</v-icon
                >
                <v-icon
                  class="top-right-icon accent-background"
                  :class="{
                    hidden: dialogNewsCreator,
                  }"
                  dark
                  >mdi-file-find</v-icon
                >
              </div>

              <v-card-title
                class="word-break justify-center card-title d-flex align-start justify-center"
              >
                Review communication templates
              </v-card-title>
              <v-card-text>
                Review existing communication templates in Whistle to help you
                get the most from your programs.
              </v-card-text>
            </v-card> -->
            <v-card
              rounded="0"
              elevation="4"
              class="mr-4 mt-4"
              width="25%"
              min-width="275"
              @click="loadNewsCreator(true)"
            >
              <!-- Empty div because V-cards override border radius for the first element????? -->
              <div></div>

              <div class="image-container mt-2">
                <v-icon
                  x-large
                  dark
                  class="pa-4 mt-3 dark-blue-background border-radius"
                  >mdi-alert</v-icon
                >
                <v-icon
                  class="top-right-icon brand-cyan-background"
                  :class="{
                    hidden: dialogNewsCreator
                  }"
                  dark
                  >mdi-pencil</v-icon
                >
              </div>

              <v-card-title
                class="word-break justify-center card-title d-flex align-start justify-center"
              >
                Send System Wide Message
              </v-card-title>
              <v-card-text>
                Send a message to your entire organization.
              </v-card-text>
            </v-card>
            <v-card
              rounded="0"
              elevation="4"
              class="mr-4 mt-4"
              width="25%"
              min-width="275"
              @click="loadNewsCreator(false)"
            >
              <!-- Empty div because V-cards override border radius for the first element????? -->
              <div></div>
              <div class="image-container mt-2">
                <v-icon
                  x-large
                  dark
                  class="pa-4 mt-3 brand-yellow-background border-radius"
                  >mdi-newspaper-variant-outline</v-icon
                >
                <v-icon
                  class="top-right-icon brand-cyan-background"
                  :class="{
                    hidden: dialogNewsCreator
                  }"
                  dark
                  >mdi-plus</v-icon
                >
              </div>
              <v-card-title
                class="word-break justify-center card-title d-flex align-start justify-center"
              >
                Create a news article
              </v-card-title>
              <v-card-text>
                Create a new news article to share with specific groups.
              </v-card-text>
            </v-card>
            <v-card
              rounded="0"
              elevation="4"
              class="mr-4 mt-4"
              width="25%"
              min-width="275"
              @click="loadEditNewsArticleScreen"
            >
              <!-- Empty div because V-cards override border radius for the first element????? -->
              <div></div>
              <div class="image-container mt-2">
                <v-icon
                  x-large
                  dark
                  class="pa-4 mt-3 brand-yellow-background border-radius"
                  >mdi-newspaper-variant-outline</v-icon
                >
                <v-icon
                  class="top-right-icon brand-cyan-background"
                  :class="{
                    hidden: dialogNewsCreator
                  }"
                  dark
                  >mdi-pencil</v-icon
                >
              </div>
              <v-card-title
                class="word-break justify-center card-title d-flex align-start justify-center"
              >
                Edit an existing news article
              </v-card-title>
              <v-card-text>
                Find an existing news article to review or make edits.
              </v-card-text>
            </v-card>
            <v-card
              rounded="0"
              elevation="4"
              class="mr-4 mt-4"
              width="25%"
              min-width="275"
              @click="loadMessageCreator"
            >
              <!-- Empty div because V-cards override border radius for the first element????? -->
              <div></div>
              <div class="image-container mt-2">
                <v-icon
                  x-large
                  dark
                  class="pa-4 mt-3 orange-background border-radius"
                  >mdi-message</v-icon
                >
                <v-icon
                  class="top-right-icon brand-cyan-background"
                  :class="{
                    hidden: dialogNewsCreator
                  }"
                  dark
                  >mdi-plus</v-icon
                >
              </div>
              <v-card-title
                class="word-break justify-center card-title d-flex align-start justify-center"
              >
                Send a message to a group
              </v-card-title>
              <v-card-text>
                Create a message to distribute to selected groups.
              </v-card-text>
            </v-card>
            <!-- <v-card
              rounded="0"
              elevation="4"
              class="mr-4 mt-4"
              width="25%"
              min-width="275"
            >
              <div></div>
              <div class="image-container mt-2">
                <v-icon
                  x-large
                  dark
                  class="pa-4 mt-3 orange-background border-radius"
                  >mdi-api</v-icon
                >
              </div>
              <v-card-title
                class="word-break align-text-center card-title d-flex align-start justify-center"
              >
                Manage communications through your CRM
              </v-card-title>
              <v-card-text>
                Integrate with Salesforce, Hubspot, and Freshworks to send
                communications when triggered by Whistle.
              </v-card-text>
            </v-card> -->
          </v-container>
          <!-- This container will appear if the user wants to view a list of published news articles that they can edit -->
          <v-container
            class="d-flex flex-column align-start flex-wrap"
            v-else-if="showEditNewsArticleScreen && !dialogNewsCreator"
          >
            <v-btn
              text
              color="primary"
              @click="closeEditNewsArticle"
              class="mb-3"
              ><v-icon>mdi-chevron-left</v-icon>Back to communications</v-btn
            >
            <!-- <div class="d-flex flex-wrap"> -->
            <v-data-table
              :key="0"
              :headers="editNews.columns"
              :items="visibleMessages"
              :search="table.search"
              item-key="messageId"
              :items-per-page="table.itemsPerPage"
              :footer-props="table.footerProps"
              :options.sync="table.options"
              :server-items-length="table.total"
              class="elevation-0 full-width message-table"
              :loading="editNews.loading"
              loading-text="Loading messages... Please wait"
              no-data-text="No messages found"
              no-results-text="No messages found in search"
            >
              <template v-slot:[`item.icon`]="{ item }">
                <!-- {{ item }} -->
                <div class="d-flex">
                  <v-btn
                    icon
                    @click="preloadNewsCreator(item)"
                    :class="{ hidden: item.selectedGroups === null }"
                  >
                    <v-icon color="primary">mdi-pencil </v-icon></v-btn
                  >
                  <v-btn icon class="ml-1" @click="duplicateArticle(item)">
                    <v-icon color="primary">mdi-content-copy </v-icon></v-btn
                  >

                  <v-btn
                    icon
                    @click="deleteNewsArticle(item)"
                    class="ml-1"
                    :loading="editNews.showLoading === item.messageId"
                    ><v-icon color="red">mdi-delete </v-icon></v-btn
                  >
                </div>
              </template>

              <template v-slot:[`item.messageSubject`]="{ item }">
                <span
                  v-if="item.selectedGroups !== null"
                  class="cursor-pointer selectable-blue-text"
                  @click="preloadNewsCreator(item)"
                  >{{ item.messageSubject }}</span
                >
                <span v-else>{{ item.messageSubject }}</span>
                <!-- <br /> -->
                <!-- <span
                  v-if="item.selectedGroups !== null"
                  class="cursor-pointer grey--text"
                  @click="preloadNewsCreator(item)"
                  >{{ item.formattedSendDate }}</span
                >
                <span class="grey--text" v-else>{{
                  item.formattedSendDate
                }}</span> -->
              </template>
              <template v-slot:[`item.displayOnPlatform`]="{ item }">
                <!-- {{ item }} -->
                <v-icon
                  v-if="item.displayOnPlatform"
                  class="d-flex justify-center"
                  >mdi-check</v-icon
                >
                <v-icon v-else class="d-flex justify-center">mdi-close</v-icon>
              </template>
              <template v-slot:[`item.sendEmail`]="{ item }">
                <!-- {{ item }} -->
                <v-icon v-if="item.sendEmail" class="d-flex justify-center"
                  >mdi-check</v-icon
                >
                <v-icon v-else class="d-flex justify-center">mdi-close</v-icon>
              </template>
              <template v-slot:[`item.sendSMS`]="{ item }">
                <!-- {{ item }} -->
                <v-icon v-if="item.sendSMS" class="d-flex justify-center"
                  >mdi-check</v-icon
                >
                <v-icon v-else class="d-flex justify-center">mdi-close</v-icon>
              </template>
              <template v-slot:[`item.sendPush`]="{ item }">
                <!-- {{ item }} -->
                <v-icon v-if="item.sendPush" class="d-flex justify-center"
                  >mdi-check</v-icon
                >
                <v-icon v-else class="d-flex justify-center">mdi-close</v-icon>
              </template>
              <template v-slot:loading>
                <!-- {{ item }} -->
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="my-8"
                  :size="50"
                  :width="4"
                ></v-progress-circular>
              </template>
            </v-data-table>
            <!-- </div> -->
          </v-container>
          <!-- This container will appear if the user wants to view a list of published news articles that they can edit -->
          <v-container
            class="d-flex flex-column align-start flex-wrap"
            v-else-if="showProgramCommScreen && !dialogMessageCreator"
          >
            <div class="d-flex justify-space-between full-width">
              <v-btn
                text
                color="primary"
                @click="closeProgramCommScreen"
                class="mb-3"
                ><v-icon>mdi-chevron-left</v-icon>Back to communications</v-btn
              >
              <v-text-field
                outlined
                placeholder="Search"
                single-line
                dense
                hide-details
                v-model="programTable.debounceSearch"
                class="shrink"
              ></v-text-field>
            </div>
            <!-- <div class="d-flex flex-wrap"> -->
            <v-data-table
              :key="1"
              :headers="programComms.columns"
              :items="visiblePrograms"
              :search="programTable.search"
              item-key="programId"
              :items-per-page="programTable.itemsPerPage"
              :footer-props="programTable.footerProps"
              :options.sync="programTable.options"
              :server-items-length="programTable.total"
              class="elevation-0 full-width message-table"
              :loading="programTable.loading"
              loading-text="Loading programs... Please wait"
              no-data-text="No programs found"
              no-results-text="No programs found in search"
              @click:row="loadProgramMessageCreator"
            >
              <template v-slot:[`item.icon`]="{ item }">
                <!-- {{ item }} -->
                <div class="d-flex">
                  <v-btn icon @click="loadProgramMessageCreator(item)">
                    <v-icon color="primary">mdi-plus-box </v-icon></v-btn
                  >
                </div>
              </template>
              <template v-slot:[`item.titleDate`]="{ item }">
                <span
                  class="cursor-pointer selectable-blue-text"
                  @click="loadProgramMessageCreator(item)"
                  >{{ item.displayName }}</span
                >
                <br />
                <span
                  class="cursor-pointer grey--text"
                  @click="loadProgramMessageCreator(item)"
                  >{{ item.combinedDates }}</span
                >
              </template>
              <template v-slot:loading>
                <!-- {{ item }} -->
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="my-8"
                  :size="50"
                  :width="4"
                ></v-progress-circular>
              </template>
            </v-data-table>
          </v-container>
        </v-col>
      </v-row>
      <!-- DIALOGS SECTION -->
      <!-- Dialog for creating news articles -->
      <v-dialog
        fullscreen
        persistent
        v-model="dialogNewsCreator"
        class="dialog"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <NewsCreator
          v-if="dialogNewsCreator"
          @close-dialog="closeNewsCreator"
          :trigger="newsCreatorTrigger"
          :systemWide="newsCreatorSystemWide"
          :editArticle="editNews.articleToEdit"
          :duplicateArticle="editNews.duplicateFlag"
        />
      </v-dialog>
      <!-- Dialog for sending messages -->
      <v-dialog
        fullscreen
        persistent
        v-model="dialogMessageCreator"
        class="dialog"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <MessageCreator
          @close-dialog="closeMessageCreator"
          v-if="dialogMessageCreator"
          :trigger="messageCreatorTrigger"
          :peopleOrGroups="
            programComms.programToLoad != null ? 'people' : 'groups'
          "
          :largeVersion="true"
          :programMessage="programComms.programToLoad"
          :preSelectedAudience="null"
        />
      </v-dialog>
      <!-- Dialog used to show under construction message -->
      <v-dialog v-model="showUnderConstruction" width="400">
        <v-img src="@/assets/Under_Construction.png" max-width="400" />
      </v-dialog>
      <!-- Dialog used for displaying loading message. -->
      <LoadingDialog
        :showDialog="showLoadingDialog"
        :header="loadingHeaderMessage"
        :line1="loadingMessageLine1"
        :line2="loadingMessageLine2"
      ></LoadingDialog>
    </v-card>
  </div>
</template>

<script>
import LoadingDialog from "@/components/loadingDialog.vue";
import CommunicationService from "../services/CommunicationService";
import ProgramService from "../services/ProgramService";
import NewsCreator from "@/components/NewsCreator.vue";
import MessageCreator from "@/components/MessageCreator.vue";

import moment from "moment";
import { debounce } from "@/shared_data/functions";

import { mapState } from "vuex";

export default {
  name: "Communications",
  title: "Whistle | Communications",
  props: {
    messageCreatorOnLoad: Boolean,
    programToLoad: Object
  },
  components: {
    LoadingDialog,
    NewsCreator,
    MessageCreator
    // ActivityFeedCard,
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      showUnderConstruction: false,
      dialogNewsCreator: false,
      dialogMessageCreator: false,
      dialogProgramAlertCreator: false,
      newsCreatorTrigger: false,
      newsCreatorSystemWide: false,
      messageCreatorTrigger: false,
      showEditNewsArticleScreen: false,
      editNews: {
        articleToEdit: null,
        duplicateFlag: false,
        showLoading: -1,
        columns: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "icon",
            width: "10%"
          },
          { text: "Title", value: "messageSubject", width: "25%" },
          { text: "Date", value: "formattedSendDate", width: "20%" },
          {
            text: "Visible To",
            value: "numRecipients",
            width: "20%",
            sortable: false
          },
          {
            text: "Platform",
            value: "displayOnPlatform",
            width: "5%",
            sortable: false
          },
          {
            text: "Email",
            value: "sendEmail",
            width: "5%",
            sortable: false
          },
          {
            text: "SMS",
            value: "sendSMS",
            width: "5%",
            sortable: false
          },
          {
            text: "App",
            value: "sendPush",
            width: "5%",
            sortable: false
          }
        ],
        loading: false,
        news: [],
        search: null
      },
      showProgramCommScreen: false,
      programComms: {
        programToLoad: null,
        duplicateFlag: false,
        columns: [
          {
            text: "",
            align: "center",
            sortable: true,
            value: "icon",
            width: "5%"
          },
          { text: "Name / Date", value: "titleDate", width: "40%" },
          { text: "Status", value: "status", width: "20%" },
          {
            text: "Visible To",
            value: "ProgramCaches.length",
            width: "20%",
            sortable: false
          }
        ],
        loading: false,
        programs: [],
        search: null
      },
      showTemplateScreen: false,
      table: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        messages: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null
      },
      programTable: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        programs: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null
      }
    };
  },
  created() {
    //check props and do things
    if (this.messageCreatorOnLoad) {
      this.programComms.programToLoad = this.programToLoad;
      this.loadMessageCreator();
    }
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  beforeDestroy() {},
  methods: {
    loadNewsCreator(systemWide) {
      if (systemWide) {
        this.newsCreatorSystemWide = true;
      } else {
        this.newsCreatorSystemWide = false;
      }

      //We just toggle the trigger because the NewsCreator component is watching for it to change
      this.newsCreatorTrigger = !this.newsCreatorTrigger;
      this.dialogNewsCreator = true;
    },
    closeNewsCreator() {
      // this.getNewsArticles();
      this.editNews.articleToEdit = null;
      this.editNews.duplicateFlag = false;
      this.dialogNewsCreator = false;
      this.showEditNewsArticleScreen = false;
    },
    loadEditNewsArticleScreen() {
      this.showEditNewsArticleScreen = true;
    },
    closeEditNewsArticle() {
      this.showEditNewsArticleScreen = false;
    },
    getNewsArticles() {
      CommunicationService.getMessagesForAdmin()
        .then(news => {
          console.log("News articles: ", news);
          var returnArray = news
            .filter(x => x.context === "news" || x.context === "alerts")
            .map(x => {
              var obj = x;

              obj.formattedSendDate = new moment(obj.sendDate)
                .local()
                .format("YYYY-MM-DD h:mm a");
              // console.log("Send date ", obj.sendDate);
              // console.log("Moment Local ", localDate);
              if (obj.selectedGroups === null) {
                if (obj.MessageLogs.length === 1) {
                  obj.numRecipients = obj.MessageLogs.length + " person";
                } else {
                  obj.numRecipients = obj.MessageLogs.length + " people";
                }
              } else {
                if (obj.MessageLogs.length === 1) {
                  obj.numRecipients =
                    obj.MessageLogs.length +
                    " person in " +
                    obj.selectedGroups.split(",").length +
                    " group(s)";
                } else {
                  obj.numRecipients =
                    obj.MessageLogs.length +
                    " people in " +
                    obj.selectedGroups.split(",").length +
                    " group(s)";
                }
              }

              return obj;
            });

          returnArray.sort(function(a, b) {
            return new Date(a.sendDate) - new Date(b.sendDate);
          });
          this.editNews.news = returnArray;
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    async getMessageDefinitions(reset = false, source) {
      try {
        console.log("Getting messages from ", source);
        this.editNews.loading = true;
        if (reset) this.table.options.page = 1;
        let options = this.table.options;
        let page = this.table.options.page || 1;
        let filter = `status != 'Deleted' && status != 'Archived' && (context = 'news' || context = 'alerts')`;
        let limit = options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        if (this.table.options.sortBy && options.sortBy.length > 0) {
          let column = options.sortBy;
          let type =
            options.sortDesc &&
            options.sortDesc.length > 0 &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";

          if (column == "formattedSendDate") sort = `sendDate ${type}`;
          else var sort = column + " " + type;
        } else sort = "messageId DESC";
        this.table.latestSort = sort;
        this.table.latestFilter = filter;
        let response = await CommunicationService.getMessageDefinitionsV2({
          filter,
          limit,
          offset,
          sort,
          expand: "MessageLog",
          leftJoin: "MessageLog"
        });

        // we have to compare states to see if we ignore results (only if them spam a filter)
        if (
          filter != this.table.latestFilter ||
          sort != this.table.latestSort ||
          page != this.table.options.page
        ) {
          console.log("Ignoring response");
          return;
        }

        console.log("Got messages ", response);
        this.table.total = response.result.count;
        // if (reset || wipeArray)
        //   this.table.messages = new Array(this.table.total).fill(undefined);
        let rows = response.result.rows.map(r => {
          r.formattedSendDate = new moment(r.sendDate)
            .local()
            .format("YYYY-MM-DD h:mm a");

          if (r.MessageLogs && r.MessageLogs.length === 1) {
            r.numRecipients = r.MessageLogs.length + " person";
          } else {
            r.numRecipients = r.MessageLogs.length + " people";
          }
          return r;
        });
        // If at the beginning we just grab the first results and then append the rest of the empty array
        // If not the beginning, we append the existing fetched array, then the new results, and anything after
        // this.table.users = rows;
        // if (offset == 0)
        this.table.messages = rows;
        //    rows.concat(
        //     this.table.messages.slice(rows.length)
        //   );
        // else
        //   this.table.messages = this.table.messages
        //     .slice(0, offset)
        //     .concat(rows, this.table.messages.slice(offset + rows.length));
        this.editNews.loading = false;
      } catch (err) {
        console.log("Error getting message definitions ", err);
        this.editNews.loading = false;
      }
    },
    async getProgramsV2(reset = false, source) {
      try {
        console.log("Getting programs from ", source);
        this.programTable.loading = true;
        if (reset) this.programTable.options.page = 1;
        let options = this.programTable.options;
        let page = this.programTable.options.page || 1;
        let search = this.programTable.search
          ? this.programTable.search.replace(/'/g, "\\'")
          : null;
        let filter = `status != 'Deleted' && status != 'Incomplete' && status != 'Preview' && (type == null || type != 'procore')`;
        if (search)
          filter += ` && (displayName.like('%${search}%') || status.like('%${search}%') || programId.like('%${search}%'))`;
        let limit = options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        if (this.programTable.options.sortBy && options.sortBy.length > 0) {
          let column = options.sortBy;
          let type =
            options.sortDesc &&
            options.sortDesc.length > 0 &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";

          if (column == "titleDate") sort = `displayName ${type}`;
          else var sort = column + " " + type;
        } else sort = "programId DESC";
        this.programTable.latestSort = sort;
        this.programTable.latestFilter = filter;
        let response = await ProgramService.getProgramsV2({
          filter,
          limit,
          offset,
          sort,
          expand: "ProgramCache,ProgramGroup",
          leftJoin: "ProgramCache,ProgramGroup"
        });

        // we have to compare states to see if we ignore results (only if them spam a filter)
        if (
          filter != this.programTable.latestFilter ||
          sort != this.programTable.latestSort ||
          page != this.programTable.options.page
        ) {
          console.log("Ignoring program response");
          return;
        }

        console.log("Got programs ", response);
        this.programTable.total = response.result.count;
        // if (reset || wipeArray)
        //   this.table.messages = new Array(this.table.total).fill(undefined);
        let rows = response.result.rows.map(r => {
          r.formattedSendDate = new moment(r.sendDate)
            .local()
            .format("YYYY-MM-DD h:mm a");

          if (r.startDate !== null) {
            var formattedStartDate = moment(
              r.startDate,
              "YYYY-MM-DD hh:mm:ss"
            ).format("MMM Do, YYYY");
          } else {
            formattedStartDate = null;
          }
          if (r.endDate !== null) {
            var formattedEndDate = moment(
              r.endDate,
              "YYYY-MM-DD hh:mm:ss"
            ).format("MMM Do, YYYY");
          } else {
            formattedEndDate = null;
          }

          if (r.rollingProgramLength != null) {
            r.combinedDates =
              "Rolling program - " + r.rollingProgramLength + " days";
          } else if (r.endDate) {
            r.combinedDates = formattedStartDate + " - " + formattedEndDate;
          } else r.combinedDates = formattedStartDate;

          // if (r.MessageLogs && r.MessageLogs.length === 1) {
          //   r.numRecipients = r.MessageLogs.length + " person";
          // } else {
          //   r.numRecipients = r.MessageLogs.length + " people";
          // }
          return r;
        });
        // If at the beginning we just grab the first results and then append the rest of the empty array
        // If not the beginning, we append the existing fetched array, then the new results, and anything after
        // this.table.users = rows;
        // if (offset == 0)
        this.programTable.programs = rows;
        //    rows.concat(
        //     this.table.messages.slice(rows.length)
        //   );
        // else
        //   this.table.messages = this.table.messages
        //     .slice(0, offset)
        //     .concat(rows, this.table.messages.slice(offset + rows.length));
        this.programTable.loading = false;
      } catch (err) {
        console.log("Error getting programs ", err);
        this.programTable.loading = false;
      }
    },
    // getPrograms() {
    //   this.programComms.loading = true;
    //   ProgramService.getPrograms()
    //     .then(response => {
    //       //Prune the returned programs for the data we need for the table
    //       var allPrograms = response.filter(
    //         y =>
    //           y.status !== "Deleted" &&
    //           y.status !== "Incomplete" &&
    //           // y.audience !== null &&
    //           y.clientId != 0
    //       );
    //       console.log(allPrograms);
    //       allPrograms.map(x => {
    //         var program = x;
    //         //format dates with Moment (maybe move this to the card component?)
    //         if (program.startDate !== null) {
    //           var formattedStartDate = moment(
    //             program.startDate,
    //             "YYYY-MM-DD hh:mm:ss"
    //           ).format("MMM Do, YYYY");
    //         } else {
    //           formattedStartDate = null;
    //         }
    //         if (program.endDate !== null) {
    //           var formattedEndDate = moment(
    //             program.endDate,
    //             "YYYY-MM-DD hh:mm:ss"
    //           ).format("MMM Do, YYYY");
    //         } else {
    //           formattedEndDate = null;
    //         }

    //         if (program.rollingProgramLength != null) {
    //           program.combinedDates =
    //             "Rolling program - " + program.rollingProgramLength + " days";
    //         } else if (program.endDate) {
    //           program.combinedDates =
    //             formattedStartDate + " - " + formattedEndDate;
    //         } else program.combinedDates = formattedStartDate;

    //         program.displayName =
    //           program.displayName === null ? program.name : program.displayName;
    //         program.formattedStartDate = formattedStartDate;
    //         program.formattedEndDate = formattedEndDate;
    //         program.ProgramCache = program.ProgramCaches || [];

    //         // We have to update the status
    //         if (program.startDate) {
    //           // console.log(moment(program.startDate));
    //           // console.log(moment());
    //           // console.log(moment(program.startDate) > moment());
    //           if (moment(program.startDate) > moment()) {
    //             // Upcoming if it hasn't started yet
    //             program.status = "Upcoming";
    //           } else if (program.rollingProgramLength) {
    //             // Active if it's a rolling program and hasn't started
    //             program.status = "Active";
    //           } else if (
    //             program.endDate &&
    //             moment(program.endDate).local() < moment().local()
    //           ) {
    //             // End date has passed
    //             program.status = "Archived";
    //           } else if (
    //             program.endDate &&
    //             moment(program.endDate).local() > moment().local()
    //           ) {
    //             // End date has passed
    //             program.status = "Active";
    //           }
    //         }

    //         if (program.ProgramCache.length === 1) {
    //           program.numRecipients = program.ProgramCache.length + " person";
    //         } else {
    //           program.numRecipients = program.ProgramCache.length + " people";
    //         }
    //       });
    //       console.log("Programs", allPrograms);
    //       allPrograms.sort(function(a, b) {
    //         return new Date(b.createdAt) - new Date(a.createdAt);
    //       });
    //       this.programComms.programs = allPrograms;
    //       this.programComms.loading = false;
    //     })
    //     .catch(error => {
    //       console.log("Error!" + error);
    //     });
    // },
    deleteNewsArticle(message) {
      console.log("Deleting message ", message.messageId);
      this.editNews.showLoading = message.messageId;
      CommunicationService.deleteMessage(message.messageId)
        .then(msg => {
          this.editNews.showLoading = -1;
          console.log(msg);
          this.getMessageDefinitions(false, "deleteNewsArticle");
        })
        .catch(error => {
          console.log("Error - ", error);
        });
    },
    preloadNewsCreator(message) {
      this.editNews.articleToEdit = message;
      this.loadNewsCreator();
    },
    duplicateArticle(message) {
      this.editNews.duplicateFlag = true;
      this.preloadNewsCreator(message);
    },
    loadTemplateScreen() {
      this.showTemplateScreen = true;
    },
    closeTemplateScreen() {
      this.showTemplateScreen = false;
    },
    loadMessageCreator() {
      //We just toggle the trigger because the NewsCreator component is watching for it to change
      this.messageCreatorTrigger = !this.messageCreatorTrigger;
      this.dialogMessageCreator = true;
    },
    closeMessageCreator() {
      // this.getNewsArticles();
      this.dialogMessageCreator = false;
      this.programComms.programToLoad = null;
    },
    loadProgramMessageCreator(program) {
      //We just toggle the trigger because the NewsCreator component is watching for it to change
      this.messageCreatorTrigger = !this.messageCreatorTrigger;
      this.dialogMessageCreator = true;
      this.programComms.programToLoad = program;
    },
    loadProgramCommScreen() {
      this.showProgramCommScreen = true;
    },
    closeProgramCommScreen() {
      this.showProgramCommScreen = false;
      this.programTable.debounceSearch = null;
    },
    openFAQ() {
      // Opens up FreskDesk in a new tab
      window.open("https://whistle.freshdesk.com/support/home", "_blank");
    }
  },
  computed: {
    ...mapState(["userProfile", "clients"]),
    visiblePrograms() {
      if (this.programTable.loading) return [];
      return this.programTable.programs;
    },
    visibleMessages() {
      // Return chunk of users in array based on page
      // var startingIndex = this.table.options.page - 1 || 0;
      // let itemsPerPage =
      //   (this.table.options && this.table.options.itemsPerPage) ||
      //   this.table.itemsPerPage;
      if (this.editNews.loading) return [];
      return this.table.messages;
      // .slice()
      // .slice(
      //   startingIndex * itemsPerPage,
      //   startingIndex * itemsPerPage + itemsPerPage
      // )
      // .filter(Boolean);
    }
  },
  watch: {
    // This watches any options configured for the data table so we know if the user interacts and changes the sorting
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }
        // This means that they just hit the next button. We have to check what the max page we've loaded in is
        // We can optimize and load in existing data
        // if (
        //   !reset &&
        //   oldVal.page !== newVal.page &&
        //   this.table.messages.filter(x => x !== undefined).length >
        //     (newVal.page - 1) * newVal.itemsPerPage
        // ) {
        //   console.log("We're not going to make the API call");
        //   apiCall = false;
        // }
        if (apiCall) this.getMessageDefinitions(reset, "watcher");
      },
      deep: true
    },
    "programTable.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }
        // This means that they just hit the next button. We have to check what the max page we've loaded in is
        // We can optimize and load in existing data
        // if (
        //   !reset &&
        //   oldVal.page !== newVal.page &&
        //   this.table.messages.filter(x => x !== undefined).length >
        //     (newVal.page - 1) * newVal.itemsPerPage
        // ) {
        //   console.log("We're not going to make the API call");
        //   apiCall = false;
        // }
        if (apiCall) this.getProgramsV2(reset, "watcher");
      },
      deep: true
    },
    "programTable.debounceSearch": debounce(function(newVal) {
      this.programTable.search = newVal;
      if (this.showProgramCommScreen) this.getProgramsV2(true);
    }, 500),
    "table.debounceSearch": debounce(function(newVal) {
      this.table.search = newVal;
      this.getMessageDefinitions(true);
    }, 500)
  }
};
</script>

<style scoped>
.dark-blue-background {
  background-color: var(--v-brand-base);
}
.grey-background {
  background-color: var(--v-grey-base);
}
.brand-background {
  background-color: var(--v-brand-base);
}
.brand-cyan-background {
  background-color: var(--v-brandCyan-base);
}

.brand-yellow-background {
  background-color: var(--v-brandYellow-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
.orange-background {
  background-color: #ffab2e;
}
.border-radius {
  border-radius: 5px;
}
.card-title {
  min-height: 100px;
}
.image-container {
  position: relative;
  display: flex;
  justify-content: center;
}
.top-right-icon {
  position: absolute;
  z-index: 1;
  right: 33%;
  top: 0px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 100%;
  transition: 0.2s opacity;
}
.dialog {
  z-index: 999;
}
.edit-news-card {
  width: 300px;
}

.message-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.message-table >>> .v-data-table-header {
  background-color: white;
}
</style>
