var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{staticClass:"top-nav full-screen-width",attrs:{"app":"","clipped-left":"","flat":"","color":"white"}},[_c('div',{staticClass:"d-flex align-center justify-space-between full-screen-width"},[_c('v-btn',{staticClass:"d-sm-flex d-md-none mr-2",attrs:{"icon":"","small":""},on:{"click":_vm.openNav}},[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('div',{staticClass:"d-flex align-center ml-3"},[(
          _vm.userProfile &&
            _vm.userProfile.Client &&
            (_vm.userProfile.Client.whitelabel == false ||
              _vm.userProfile.Client.companyPicUrl == null ||
              (!_vm.userProfile.Client.companyPicUrl.startsWith('https') &&
                !_vm.userProfile.Client.companyPicUrl.startsWith('data:image')))
        )?_c('v-img',{staticClass:"cursor-pointer",class:{
          'hidden-sm-and-down': _vm.userProfile.Client.whitelabel == true
        },attrs:{"src":require("@/assets/Logo/whistle-logo-gradient-clean-v2.png"),"height":_vm.imageHeight,"width":_vm.imageWidth,"contain":""},on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}}):_vm._e(),(
          _vm.userProfile &&
            _vm.userProfile.Client &&
            _vm.userProfile.Client.companyPicUrl !== null
        )?_c('v-img',{staticClass:"ml-5",class:{
          'hidden-sm-and-down': _vm.userProfile.Client.whitelabel == false
        },attrs:{"src":_vm.userProfile.Client.companyPicUrl,"height":_vm.imageHeight,"width":_vm.imageWidth,"contain":""}}):_vm._e()],1),_c('div',{staticClass:"d-flex align-center"},[(
          !_vm.magicLinkToken && this.permissions.includes('vue:can:impersonate')
        )?_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"text":""},on:{"click":function($event){return _vm.toggleImpersonate()}}},[_c('span',[_vm._v(" Switch to "+_vm._s(_vm.impersonateState)+" View")]),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-account")])],1):_vm._e(),(_vm.displayWallet)?_c('v-btn',{staticClass:"mr-1",attrs:{"color":"brandCyan","text":"","x-small":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.toggleWallet}},[_c('span',{staticClass:"hidden-sm-and-down mr-2"},[_vm._v("Wallet")]),_c('v-icon',[_vm._v("mdi-wallet")])],1):_vm._e(),(_vm.magicLinkToken)?_c('v-btn',{attrs:{"text":"","x-small":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.refreshPage}},[_vm._v(" Continue to Whistle "),(!_vm.$vuetify.breakpoint.xs)?_c('v-icon',{staticClass:"ml-1 mb-1"},[_vm._v("mdi-arrow-right")]):_vm._e()],1):_c('v-btn',{attrs:{"text":"","x-small":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.toggleProfile}},[_c('span',{staticClass:"hidden-sm-and-down mr-2"},[_vm._v("My Account")]),(_vm.userProfile && _vm.userProfile.profilePicUrl !== null)?_c('v-img',{staticClass:"circle-border-radius profile-picture",attrs:{"src":_vm.userProfile.profilePicUrl,"aspect-ratio":1 / 1,"width":"35","height":"35"}}):_c('v-icon',{staticClass:"profile-picture circle-border-radius icon-image"},[_vm._v("mdi-account")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }