<template>
  <v-autocomplete
    outlined
    dense
    class="full-width"
    hide-details
    :items="clients"
    item-text="formattedName"
    item-value="clientId"
    label="Client"
    v-model="clientId"
    @change="updateClient"
    v-if="userProfile.clientId === 1 && clients.length"
  ></v-autocomplete>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ClientDropdown",
  props: {},
  components: {},

  data: () => ({}),

  methods: {
    updateClient(e) {
      this.$emit("change", e);
    }
  },
  computed: {
    ...mapState(["clients", "userProfile", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    }
  }
};
</script>
