const moment = require("moment");
import { main as axios, oauth as oauthAxios } from "../axios";

// axios.interceptors.request.use(
//   async (config) => {
//     let token = await store.dispatch("isLoggedIn");
//     if (token) {
//       config.headers.common["Authorization"] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_USER_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace("MICROSERVICE", "microservice-user")
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE ? "/microservice-user" : "/user-service"
    }`;

class UserService {
  // 3/20/23 DEPRECATED
  // getUsers(queryString = "") {
  //   return axios
  //     .get(baseUrl + "/users" + queryString)
  //     .then(response => {
  //       //Prune the returned users for the data we need for the table
  //       return response.data;
  //     })
  //     .catch(error => {
  //       console.log("Error! ", error.response);
  //       return error;
  //     });
  // }
  getRoles() {
    return axios
      .get(baseUrl + "/users/roles")
      .then(response => {
        // Filter out any roles that start with 9
        // var roles = response.data.filter((x) => !x.name.startsWith("9"));
        // roles.forEach((role) => {
        //   var newName = role.name.charAt(0);
        //   for (var i = 1; i < role.name.length; i++) {
        //     if (
        //       role.name.charAt(i) == role.name.charAt(i).toUpperCase() &&
        //       !(role.name.charAt(i) == role.name.charAt(i).toLowerCase()) &&
        //       i > 1
        //     ) {
        //       // We have an uppercase letter so we add a space
        //       newName = newName + " ";
        //     }
        //     newName = newName.concat(role.name.charAt(i));
        //   }
        //   role.name = newName;
        // });

        return response.data;
      })
      .catch(error => {
        console.log("Error! ", error.response);
        throw error;
      });
  }
  getRolesByUser(userId) {
    console.log("Getting role for user ", userId);
    return axios
      .get(baseUrl + "/users/roles/assignments/byUser/" + userId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error! ", error.response);
        throw error;
        // return error;
      });
  }
  getClients() {
    return axios
      .get(baseUrl + "/clients")
      .then(response => {
        console.log("Got clients");
        return response.data.slice(0, 300);
      })
      .catch(error => {
        console.log("Error! ", error.response);
        return error;
      });
  }
  getClientsByClient(clientId) {
    return axios
      .get(baseUrl + "/clients/ofClient/" + clientId)
      .then(response => {
        console.log(response.data);
        //Prune the returned users for the data we need for the table
        return response.data;
      })
      .catch(error => {
        console.log("Error! ", error.response);
        throw error.response || error;
      });
  }
  getClientFromOnboardingLink(onboardingToken) {
    return axios
      .get(baseUrl + "/v2/client/onboarding-token/" + onboardingToken)
      .then(response => {
        console.log(response.data);
        //Prune the returned users for the data we need for the table
        return response.data;
      })
      .catch(error => {
        console.log(
          "Error getting clients from onboarding link ",
          error.response
        );
        throw error;
      });
  }
  createClient(client) {
    return axios
      .post(baseUrl + "/clients", client)
      .then(response => {
        //Prune the returned users for the data we need for the table
        return response.data;
      })
      .catch(error => {
        console.log("Error! ", error.response);
        return error;
      });
  }
  updateClient(clientId, client, file = null) {
    let formData = new FormData();

    formData.append("clientId", clientId);
    Object.keys(client).forEach(x => {
      formData.append([x], client[x]);
    });
    // if (client.clientName) formData.append("clientName", client.clientName);
    // if (client.displayWhistleLogo)
    //   formData.append("displayWhistleLogo", client.displayWhistleLogo);
    // if (client.emailDomain) formData.append("emailDomain", client.emailDomain);
    // if (client.initialRouteOnLoad)
    //   formData.append("initialRouteOnLoad", client.initialRouteOnLoad);
    // if (client.parentClientId)
    //   formData.append("parentClientId", client.parentClientId);
    // if (client.physicalCardFee)
    //   formData.append("physicalCardFee", client.physicalCardFee);
    // if (client.companyPicUrl)
    //   formData.append("companyPicUrl", client.companyPicUrl);
    // if (client.onboardingStatus)
    //   formData.append("onboardingStatus", client.onboardingStatus);
    formData.append("image", file);

    return axios
      .patch(baseUrl + "/clients/" + clientId, formData)
      .then(response => {
        //Prune the returned users for the data we need for the table
        return response.data;
      })
      .catch(error => {
        console.log("Error updating client ", error.response);
        throw error && error.response
          ? error.response.data || error.response
          : error;
      });
  }
  // 3/20/23 DEPRECATED
  // updateUserAdmin(userId, body) {
  //   return axios
  //     .patch(baseUrl + "/users/" + userId, body)
  //     .then(response => {
  //       //Prune the returned users for the data we need for the table
  //       return response;
  //     })
  //     .catch(error => {
  //       console.log("Error! ", error.response);
  //       return error;
  //     });
  // }
  updateUser(userId, body, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .patch(baseUrl + "/users/profile/" + userId, body)
      .then(response => {
        console.log(response.data);
        //Prune the returned users for the data we need for the table
        return response.data;
      })
      .catch(error => {
        console.log("Error! ", error.response);
        return error;
      });
  }
  // 3/20/23 DEPRECATED
  // updateAuth0Email(email, userId = null, clientId = null) {
  //   var body = { email: email };
  //   if (userId) {
  //     body.userId = userId;
  //   }
  //   if (clientId) {
  //     body.clientId = clientId;
  //   }
  //   return axios
  //     .patch(baseUrl + "/users/profile/email", body)
  //     .then(response => {
  //       console.log(response);
  //       //Prune the returned users for the data we need for the table
  //       return response;
  //     })
  //     .catch(error => {
  //       console.log("Error! ", error.response);
  //       return error;
  //     });
  // }
  deleteUser(userId, params = {}) {
    console.log(`In UserService deleting ${userId}`);
    return axios
      .delete(baseUrl + "/users/" + userId, { params })
      .then(response => {
        console.log(response);
        //Prune the returned users for the data we need for the table
        return response;
      })
      .catch(error => {
        console.log("Error! ", error.response);
        return error;
      });
  }
  deleteUsers(userIds, params = {}) {
    console.log("In UserService deleting users bulk");
    return axios
      .delete(baseUrl + "/users/bulk", {
        data: { userIds: userIds },
        params
      })
      .then(response => {
        console.log(response);
        //Prune the returned users for the data we need for the table
        return response;
      })
      .catch(error => {
        console.log("Error! ", error.response);
        return error;
      });
  }
  // 3/20/23 DEPRECATED
  // async userProfile() {
  //   try {
  //     var response = await axios.get(baseUrl + "/users/profile");
  //     // .then((response) => {
  //     if (response.data.user.length > 0) return response.data.user[0];
  //     else throw response;
  //     // })
  //   } catch (error) {
  //     console.log("Error fetching user! ", error);
  //     throw error;
  //   }
  // }
  async userProfileByEmail(magicLinkToken = null) {
    try {
      var response = await (magicLinkToken ? oauthAxios : axios).get(
        baseUrl + "/users/profile/email"
      );
      // .then((response) => {
      if (response.data.user.length > 0) return response.data.user[0];
      else throw response;
      // })
    } catch (error) {
      console.log("Error fetching user! ", error);
      throw error;
    }
  }
  // 3/20/23 DEPRECATED
  // getUser(userId) {
  //   console.log(userId);
  //   return axios
  //     .get(baseUrl + "/users/" + userId)
  //     .then(response => {
  //       return response.data.users[0];
  //     })
  //     .catch(error => {
  //       console.log("Error fetching user! ", error.response);
  //       return error;
  //     });
  // }
  // 3/20/23 DEPRECATED
  // getUserByEmail(email) {
  //   console.log(email);
  //   return axios
  //     .get(baseUrl + "/users/profile/email/" + email)
  //     .then(response => {
  //       return response.data.users;
  //     })
  //     .catch(error => {
  //       console.log("Error fetching users by email! ", error.response);
  //       return error;
  //     });
  // }

  uploadProfilePicture(userId, file, clientId) {
    let formData = new FormData();
    formData.append("clientId", clientId);
    formData.append("image", file);
    return axios
      .post(baseUrl + "/users/profilePicture/" + userId, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error fetching user! ", error.response);
        return error;
      });
  }
  deleteProfilePicture(userId, clientId) {
    return axios
      .delete(baseUrl + "/users/profilePicture/" + userId, {
        clientId: clientId
      })
      .then(response => {
        return response.data.success;
      })
      .catch(error => {
        console.log("Error fetching user! ", error.response);
        return error;
      });
  }
  downloadInvestorFile(fileName) {
    return axios
      .get(baseUrl + "/users/investors/download/" + fileName, {
        responseType: "blob"
      })
      .then(response => {
        console.log("Downloading investor file " + fileName);
        var arr = fileName.split(".");
        var extension = arr[arr.length - 1];
        console.log(extension);
        if (
          extension === "pptx" ||
          extension === "DOC" ||
          extension === "pdf"
        ) {
          var blob = new Blob([response.data], {
            type: "application/octet-stream"
          });
        } else if (extension === "xlsx") {
          blob = new Blob([response.data], {
            type: "application/vnd.ms-excel"
          });
        }
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.log("Error downloading file ", error);
      });
  }
  downloadCardProductFile(fileName, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/users/cardproduct/download/" + fileName, {
        responseType: "blob"
      })
      .then(response => {
        console.log("Downloading cardproduct file " + fileName);
        var arr = fileName.split(".");
        var extension = arr[arr.length - 1];
        console.log(extension);
        if (
          extension === "pptx" ||
          extension === "DOC" ||
          extension === "pdf"
        ) {
          var blob = new Blob([response.data], {
            type: "application/octet-stream"
          });
        } else if (extension === "xlsx") {
          blob = new Blob([response.data], {
            type: "application/vnd.ms-excel"
          });
        }
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.log("Error downloading file ", error);
      });
  }
  // downloadPeople(selectedUsers) {
  //   if (selectedUsers.length === 0) {
  //     var query_string = "id=select_all";
  //   } else {
  //     var pairs = selectedUsers.map(function(value) {
  //       return "id=" + encodeURIComponent(value.userId);
  //     });
  //     query_string = pairs.join("&");
  //   }
  //   return axios
  //     .get(baseUrl + "/users/download/" + query_string, {
  //       responseType: "blob",
  //     })
  //     .then((response) => {
  //       console.log("Downloading people CSV");

  //       var blob = new Blob([response.data], {
  //         type: "application/octet-stream",
  //       });

  //       let link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob);
  //       link.setAttribute("download", "people.csv");
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((error) => {
  //       console.log("Error downloading file ", error);
  //     });
  // }
  getUserSupplemental(userId) {
    return axios
      .get(baseUrl + "/users/supplemental/user/" + userId)
      .then(response => {
        return response.data.userSupplemental;
      })
      .catch(error => {
        console.log("Error fetching user supplemental! ", error.response);
        return error;
      });
  }
  bulkUpdateUserSupplemental(array, magicLinkToken = null) {
    const headers = {
      "Content-Type": "application/json"
    };

    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/users/supplemental/bulk", array, {
        headers
      })
      .then(response => {
        console.log(response);
        return response.data;
      });
  }
  createDebugUserSupplemental(data) {
    const headers = {
      "Content-Type": "application/json"
    };
    return axios
      .post(baseUrl + "/users/supplemental/debug", data, {
        headers
      })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  }

  bulkUpdateUserCommunicationPreferences(array, magicLinkToken = null) {
    const headers = {
      "Content-Type": "application/json"
    };

    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/users/communicationPreferences/bulk", array, {
        headers
      })
      .then(response => {
        console.log(response.data);
        return response.data.preference;
      });
  }

  unsubscribeFromCommPreference(context, contextResourceId) {
    const headers = {
      "Content-Type": "application/json"
    };

    return axios
      .post(
        baseUrl +
          "/users/communicationPreferences/unsubscribe/" +
          context +
          "/" +
          contextResourceId,
        {
          headers
        }
      )
      .then(response => {
        console.log(response.data.preference);
        return response.data.preference;
      })
      .catch(error => {
        console.log("Error! ", error.response.data);
        return error.response.data;
      });
  }

  bulkUpdateUser(attributes, users) {
    const headers = {
      "Content-Type": "application/json"
    };
    var body = {
      attributes: attributes,
      users: users
    };
    return axios
      .post(baseUrl + "/users/bulk", body, {
        headers
      })
      .then(response => {
        console.log(response);
        return response.data;
      });
  }

  async deleteUserBulkCSV(file, params = {}) {
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("marco", "polo");

      for (const [key, value] of formData) {
        console.log(`${key}: ${value}`);
      }

      // Object.keys(fields).forEach(key => {
      //   formData.append(key, fields[key]);
      // });
      let response = await axios.post(
        baseUrl + "/users/delete/bulk/csv",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          params
        }
      );

      console.log("deletion results ", response.data);
      return response.data;
    } catch (error) {
      console.log("Error creating password user ", error.response.data);
      throw error.response.data || error.response || error;
    }
  } //end deleteUserBulkCSV

  getValuesForUserColumn(column, clientId, includeSubClients) {
    return axios
      .get(
        baseUrl +
          "/users/column/values/" +
          includeSubClients +
          "/" +
          clientId +
          "/" +
          column
      )
      .then(response => {
        return response.data.values;
      })
      .catch(error => {
        console.log("Error fetching user columns! ", error.response);
        return error;
      });
  }
  resetPassword(email) {
    return axios
      .get(baseUrl + "/users/resetPassword/" + email)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error resetting password! ", error.response);
        return error;
      });
  }
  getUserIpAddress() {
    return axios
      .get("https://jsonip.com", {
        transformRequest: (data, headers) => {
          delete headers.common["Authorization"];
          delete headers["Authorization"];
          return data;
        }
      })
      .then(x => {
        console.log("IP: ", x);
        return x.data;
      });
  }
  async checkUserAuthByEmail(email, query = null) {
    try {
      var response = await axios.get(
        `${baseUrl}/users/auth/${email}${query ? "?" + query : ""}`
      );
      // .then((response) => {
      if (
        response.data.result &&
        response.data.result.rows &&
        response.data.result.rows.length
      )
        return response.data.result.rows[0];
      else throw response;
      // })
    } catch (error) {
      console.log("Error fetching user! ", error.response || error);
      throw error.response || error;
    }
  }

  // V2 calls
  getUsersV2(params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/user", { params })
      .then(response => {
        //Prune the returned users for the data we need for the table
        return response.data;
      })
      .catch(error => {
        console.log("Error getting users ", error.response);
        throw error.response;
      });
  } //getUsersV2
  getClientsV2(params) {
    return axios
      .get(baseUrl + "/v2/client", { params })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error getting clients ", error.response);
        throw error;
      });
  } //getClientsV2
  getMagicLinkTokenV2(magicLinkId, magicLink) {
    return axios
      .get(`${baseUrl}/v2/magic-link/verify/${magicLinkId}/${magicLink}`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error getting magic link data ", error.response);
        throw error.response ? error.response.data || error.response : error;
      });
  } //getMagicLinkTokenV2
  updateUserV2(userId, body, params = {}) {
    return axios
      .patch(`${baseUrl}/v2/user/${userId}`, body, { params })
      .then(response => {
        //Prune the returned users for the data we need for the table
        return response.data;
      })
      .catch(error => {
        console.log("Error updating user ", error.response);
        throw error && error.response
          ? error.response.data || error.response
          : error;
      });
  } //updateUserV2
  transferUserToClientV2(clientId, userId, params = {}) {
    return axios
      .patch(`${baseUrl}/v2/user/client/${clientId}/${userId}`, undefined, {
        params: {
          ...params,
          source: "web"
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error transferring to new client ", error.response);
        throw error && error.response
          ? error.response.data || error.response
          : error;
      });
  } //transferUserToClientV2
  createClientV2(data = {}, file = null) {
    let formData = new FormData();
    // formData.append("clientId", clientId);
    Object.keys(data).forEach(x => {
      console.log("Key ", x);
      formData.append([x], data[x]);
    });
    if (file) formData.append("image", file);
    return axios
      .post(`${baseUrl}/v2/client`, formData)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error creating client ", error.response);
        throw error && error.response
          ? error.response.data || error.response
          : error;
      });
  } // createClientV2

  updateClientV2(clientId, data = {}, file = null) {
    let formData = new FormData();

    // formData.append("clientId", clientId);
    Object.keys(data).forEach(x => {
      formData.append([x], data[x]);
    });
    if (file) formData.append("image", file);
    console.log("Form data ", formData);
    return axios
      .patch(`${baseUrl}/v2/client/${clientId}`, formData)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error updating client ", error.response);
        throw error && error.response
          ? error.response.data || error.response
          : error;
      });
  } // createClientV2

  getExportColumns(table) {
    return axios
      .get(`${baseUrl}/v2/export/columns/${table}`)
      .then(response => {
        return response.data.result.rows;
      })
      .catch(error => {
        console.log("Error getting CSV columns ", error.response || error);
        throw error.response ? error.response.data || error.response : error;
      });
  } //getExportColumns

  exportDataV2(table, params) {
    return axios
      .get(`${baseUrl}/v2/export/csv/${table}`, {
        responseType: "blob",
        params
      })
      .then(response => {
        console.log("Downloading CSV");

        var blob = new Blob([response.data], {
          type: "application/octet-stream"
        });

        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        const filename = `${moment().format("YYYY-DD-MM")}_${table}_Data.csv`;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.log("Error downloading file ", error);
        throw error;
      });
  } // exportDataV2
}

export default new UserService();
