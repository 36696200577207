<template>
  <v-card
    ><v-toolbar dark color="primary" rounded="0">
      <v-toolbar-title v-if="editArticle === null || duplicateArticle"
        >Create a {{ computedMessageType }}
      </v-toolbar-title>
      <v-toolbar-title v-else>Edit a {{ computedMessageType }}</v-toolbar-title>
      <v-autocomplete
        outlined
        dense
        class=" ml-2 mt-5"
        style="max-width: 300px;"
        :items="clients"
        item-text="formattedName"
        item-value="clientId"
        label="Client"
        v-model="clientId"
        v-if="userProfile.clientId === 1 && !editArticle"
        @change="updateClientId"
      >
      </v-autocomplete>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="closeNewsCreator">
          Cancel
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div
      class="d-flex justify-center full-height"
      justify="center"
      height="100%"
    >
      <v-col md="3" lg="3" class="d-flex flex-column align-start full-height">
        <v-btn
          text
          color="primary"
          v-if="slideKey > 1"
          @click="goToPreviousSlide"
          ><v-icon>mdi-chevron-left</v-icon>Back</v-btn
        >
        <p class="grey--text mt-8">Activity card preview</p>
        <ActivityFeedCard
          class="full-width"
          :icon="
            slide3.flagAsAlert ? 'mdi-alert' : 'mdi-newspaper-variant-outline'
          "
          :iconColor="computedIconColor"
          title="News
        update"
          :date="computedActivityCardDate"
          :messageTitle="slide1.title"
          :messageSubtitle="slide1.subtitle"
          :image="slide1.imageURL"
          :imageFlag="slide1.includeImageInCard"
          :messageTitleFlag="true"
          :messageSubtitleFlag="true"
          linkText="Read the news update"
          :linkButtonFlag="true"
          :actionFlag="false"
          :previewFlag="true"
          :disabled="true"
        />
        <v-checkbox
          v-if="slide1.imageURL !== null && slide1.imageURL.length > 0"
          v-model="slide1.includeImageInCard"
          label="Include image in activity card"
        ></v-checkbox>
        <span v-if="slide2.selectedGroups.length === 1" class="mt-2"
          >Visible to:
          <strong
            >{{ peopleInSelectedGroups }} people in
            {{ slide2.selectedGroups.length }} group</strong
          ></span
        >
        <span v-else-if="slide2.selectedGroups.length > 1" class="mt-2"
          >Visible to:
          <strong
            >{{ peopleInSelectedGroups }} people in
            {{ slide2.selectedGroups.length }} groups</strong
          ></span
        >
      </v-col>
      <v-col md="6" kg="6" class=" ml-6 d-flex flex-column align-start">
        <v-container class="mb-2 d-flex align-center justify-space-between">
          <div class="d-flex">
            <div
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              :class="{
                'accent-background': slideKey >= 1,
                'grey-background': 1 > slideKey
              }"
              @click="goToSlide(1)"
            ></div>
            <div
              v-if="editArticle === null || duplicateArticle"
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              @click="goToSlide(2)"
              :class="{
                'accent-background': slideKey >= 2,
                'grey-background': 2 > slideKey
              }"
            ></div>
            <div
              v-if="canEditDate"
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              @click="goToSlide(3)"
              :class="{
                'accent-background': slideKey >= 3,
                'grey-background': 3 > slideKey
              }"
            ></div>
          </div>
          <v-btn
            color="primary"
            @click="goToNextSlide"
            :disabled="disableContinueButton"
            >Continue</v-btn
          >
        </v-container>

        <div v-if="slideKey === 1">
          <div class="align-text-left mb-5">
            <span class="header-text"
              >Let's build a {{ computedMessageType }} to share</span
            >
          </div>
          <v-card
            class="d-flex flex-column align-start full-width pa-5"
            rounded="0"
            elevation="4"
          >
            <v-form ref="slide1Form" v-model="slide1.valid" class="full-width">
              <div class="d-flex align-center">
                <v-text-field
                  ref="titleInput"
                  v-model="slide1.title"
                  class="mt-5 mb-2"
                  outlined
                  label="Title"
                  :required="true"
                  :rules="[
                    v => !!v || 'A title is required!',
                    v =>
                      !!(v && 100 > v.length) ||
                      'Try and make your title shorter!'
                  ]"
                ></v-text-field>

                <v-icon
                  dark
                  tabindex="-1"
                  @click="focusOnTitle"
                  class="border-radius pa-2 ml-4 mb-5 cursor-pointer"
                  :class="{
                    'accent-background':
                      slide1.title !== null && slide1.title.length > 0,
                    'grey-background':
                      slide1.title === null ||
                      (slide1.title !== null && slide1.title.length === 0)
                  }"
                  >mdi-pencil</v-icon
                >
              </div>
              <div class="d-flex align-center mb-4">
                <v-text-field
                  ref="subtitleInput"
                  v-model="slide1.subtitle"
                  outlined
                  hide-details
                  label="Subtitle (optional)"
                  :rules="[
                    v =>
                      !!(!v || v.length < 255) ||
                      'Your subtitle should be shorter'
                  ]"
                ></v-text-field>
                <v-icon
                  dark
                  tabindex="-1"
                  @click="focusOnSubtitle"
                  class="border-radius pa-2 ml-4 cursor-pointer"
                  :class="{
                    'accent-background':
                      slide1.subtitle !== null && slide1.subtitle.length > 0,
                    'grey-background':
                      slide1.subtitle === null ||
                      (slide1.subtitle !== null && slide1.subtitle.length === 0)
                  }"
                  >mdi-pencil</v-icon
                >
              </div>
              <v-menu max-width="260" offset-x offset-y absolute>
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center image-container mt-5">
                    <v-img
                      :lazy-src="slide1.imageURL"
                      :src="slide1.imageURL"
                      :key="slide1.imageURL"
                      :aspect-ratio="16 / 9"
                      v-bind="attrs"
                      v-on="on"
                      :width="calculatedImageWidth"
                      class="cursor-pointer"
                      @drop.prevent="dragAndDropImageFileChanged"
                      @dragover.prevent
                    >
                      <template v-slot:placeholder>
                        <div
                          class="light-grey-background full-height full-width"
                        >
                          <h3 class=" pt-5 primary--text">
                            Add a banner photo?
                          </h3>

                          <p class="mt-10">
                            We suggest an image size of 740 x 416 pixels (Or any
                            image with a 16 : 9 aspect ratio).
                          </p>
                          <p>
                            JPG, PNG files supported
                          </p>
                        </div>
                      </template>
                    </v-img>
                    <v-icon
                      dark
                      tabindex="-1"
                      v-bind="attrs"
                      v-on="on"
                      class="border-radius pa-2 ml-4 mb-5 cursor-pointer"
                      :class="{
                        'accent-background': slide1.imageURL !== null,
                        'grey-background': slide1.imageURL === null
                      }"
                      >mdi-pencil</v-icon
                    >
                  </div>
                </template>
                <v-list>
                  <input
                    id="uploader"
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/*"
                    @change="imageFileChanged"
                    @blur="imageFileChanged"
                  />
                  <v-list-item @click="uploadImage">
                    <v-list-item-title class="primary--text align-text-left"
                      ><v-icon class="mr-2" color="black">mdi-upload</v-icon
                      >Upload from computer</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="slide1.dialogImageUrl = true">
                    <v-list-item-title class="primary--text align-text-left"
                      ><v-icon class="mr-2" color="black">mdi-link</v-icon>By
                      URL</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="removeImage">
                    <v-list-item-title class="primary--text align-text-left"
                      ><v-icon class="mr-2" color="black">mdi-delete</v-icon
                      >Remove image</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>

              <div class="d-flex align-start mt-8">
                <vue-editor
                  class="editor full-width"
                  v-model="slide1.content"
                  :editorToolbar="slide1.customToolbar"
                  :editorOptions="slide1.quillOptions"
                ></vue-editor>
                <v-icon
                  dark
                  tabindex="-1"
                  class="border-radius pa-2 ml-4 cursor-pointer"
                  :class="{
                    'accent-background':
                      slide1.content !== null && slide1.content.length > 0,
                    'grey-background':
                      slide1.content === null ||
                      (slide1.content !== null && slide1.content.length === 0)
                  }"
                  >mdi-pencil</v-icon
                >
              </div>
            </v-form>
          </v-card>
        </div>
        <v-container
          v-else-if="slideKey === 2 && !systemWide"
          class="d-flex flex-column align-start"
        >
          <div class="d-flex justify-space-between full-width">
            <span class="header-text mb-10">Who can read this article?</span>
            <v-text-field
              outlined
              placeholder="Search"
              single-line
              dense
              hide-details
              v-model="slide2.groupTableDebounceSearch"
              class="shrink"
            ></v-text-field>
          </div>
          <GroupTable
            ref="group-table"
            class="full-width"
            :selected="slide2.selectedGroups"
            :search="slide2.groupTableSearch"
            @select-item="addGroupToSelected"
            @update-item="updateSelectedItem"
          />
          <!-- <v-data-table
            v-model="slide2.selectedGroups"
            :headers="slide2.groupColumns"
            :items="slide2.groups"
            :search="slide2.groupTableSearch"
            item-key="groupId"
            show-select
            :items-per-page="10"
            class="elevation-1 full-width"
            :loading="slide2.loadingGroups"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.numInGroup`]="{ item }">
              <span v-if="item.GroupAssignments !== undefined"
                >{{ item.GroupAssignments.length }}
              </span>
              <span v-else>0</span>
            </template>
          </v-data-table> -->
        </v-container>
        <v-container
          v-else-if="slideKey === 2 && systemWide"
          class="d-flex flex-column align-start"
        >
          <div class="d-flex justify-space-between full-width">
            <span class="header-text mb-10">Who can read this message?</span>
            <v-text-field
              outlined
              placeholder="Search"
              single-line
              dense
              hide-details
              v-model="slide2.clientTableSearch"
              class="shrink"
            ></v-text-field>
          </div>
          <v-data-table
            v-model="slide2.selectedClients"
            :headers="slide2.clientColumns"
            :items="computedClients"
            :search="slide2.clientTableSearch"
            item-key="clientId"
            show-select
            :items-per-page="10"
            class="elevation-1 full-width"
            :loading="slide2.loadingClients"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
        </v-container>
        <v-container
          v-else-if="slideKey === 3"
          class="d-flex flex-column align-start"
        >
          <span class="header-text mb-10"
            >When can people read this news article?</span
          >
          <v-row justify="start">
            <v-col sm="8" md="6" lg="6" class="align-text-left">
              <span>Select publication start time</span>
              <v-menu
                v-model="slide3.menuStartDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                attach
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="slide3.startDate"
                    label="Start Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="mt-4"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="slide3.startDate"
                  @input="slide3.menuStartDate = false"
                ></v-date-picker>
              </v-menu>

              <div class="d-flex mt-2">
                <v-combobox
                  label="Hour"
                  v-model="slide3.startTime.hours"
                  :items="slide3.hours"
                  outlined
                  attach
                  :menu-props="{ top: true, offsetY: true }"
                  class="hour-picker mr-2"
                ></v-combobox>
                <v-combobox
                  label="Minutes"
                  v-model="slide3.startTime.minutes"
                  :items="slide3.minutes"
                  outlined
                  attach
                  :menu-props="{ top: true, offsetY: true }"
                  class="minutes-picker mr-2"
                ></v-combobox>
                <v-combobox
                  label="AM/PM"
                  v-model="slide3.startTime.amPm"
                  :items="slide3.amPm"
                  outlined
                  attach
                  :menu-props="{ top: true, offsetY: true }"
                  class="am-pm-picker"
                ></v-combobox>
              </div>
              <v-combobox
                label="Timezone"
                v-model="slide3.startTime.timezone"
                :items="calculatedTimezones"
                item-value="value"
                item-text="value"
                outlined
                attach
                :menu-props="{ top: true, offsetY: true }"
                class="full-width"
              ></v-combobox>
            </v-col>
            <v-col sm="8" md="6" lg="6" class="align-text-left">
              <span>Select publication end time (optional)</span>
              <v-menu
                v-model="slide3.menuExpirationDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                attach
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="slide3.expirationDate"
                    label="End Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="mt-4"
                    :rules="expirationDateRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="slide3.expirationDate"
                  @input="slide3.menuExpirationDate = false"
                ></v-date-picker>
              </v-menu>

              <div class="d-flex mt-2">
                <v-combobox
                  label="Hour"
                  v-model="slide3.expirationTime.hours"
                  :items="slide3.hours"
                  outlined
                  class="hour-picker mr-2"
                  attach
                  :menu-props="{ top: true, offsetY: true }"
                ></v-combobox>
                <v-combobox
                  label="Minutes"
                  v-model="slide3.expirationTime.minutes"
                  :items="slide3.minutes"
                  outlined
                  class="minutes-picker mr-2"
                  attach
                  :menu-props="{ top: true, offsetY: true }"
                ></v-combobox>
                <v-combobox
                  label="AM/PM"
                  v-model="slide3.expirationTime.amPm"
                  :items="slide3.amPm"
                  outlined
                  class="am-pm-picker"
                  attach
                  :menu-props="{ top: true, offsetY: true }"
                ></v-combobox>
              </div>

              <div class="d-flex align-center">
                <v-icon color="error" class="mr-2">mdi-alert</v-icon>
                <span class="small-text"
                  >News articles are automatically archived after 1 year unless
                  specified.</span
                >
              </div>
            </v-col>
            <span
              class="header-text"
              v-if="editArticle === null || duplicateArticle"
              >How do you want this news article distributed?</span
            >
            <span class="header-text" v-else
              >How do you want to redistribute this edited article?</span
            >
          </v-row>
          <div class="pt-5 d-flex align-center">
            <v-checkbox
              v-model="slide3.displayOnPlatform"
              :hide-details="true"
              class="pa-0 ma-0"
            ></v-checkbox>
            <span>
              Show in activity feed and logs
            </span>
          </div>
          <span
            v-if="systemWide && slide2.selectedClients.length > 1"
            class="text-left mt-1"
            ><strong>Note:</strong> By selecting multiple clients, all users in
            these clients can react & comment on the same message in the
            activity feed</span
          >
          <!-- <div class="pt-5 d-flex align-center">
            <v-checkbox
              v-model="slide3.showOnDashboard"
              :hide-details="true"
              class="pa-0 ma-0"
            ></v-checkbox>
            <span>
              Post on a participant's personalized dashboard
            </span>
          </div> -->
          <div
            class="pt-5 d-flex align-center"
            v-if="editArticle === null || duplicateArticle"
          >
            <v-checkbox
              v-model="slide3.sendEmail"
              :hide-details="true"
              class="pa-0 ma-0"
            ></v-checkbox>
            <span>
              Send an email with the full article
            </span>
          </div>
          <div class="pt-5 d-flex align-center" v-else>
            <v-checkbox
              v-model="slide3.sendEmail"
              :hide-details="true"
              class="pa-0 ma-0"
            ></v-checkbox>
            <span>
              Send an email with the full, updated article
            </span>
          </div>
          <div class="pt-5 d-flex align-center">
            <v-checkbox
              v-model="slide3.sendText"
              :hide-details="true"
              class="pa-0 ma-0"
            ></v-checkbox>
            <span>
              Send a text message with link to the full article
            </span>
          </div>
          <div class="pt-5 d-flex align-center">
            <v-checkbox
              v-model="slide3.sendPush"
              :hide-details="true"
              class="pa-0 ma-0"
            ></v-checkbox>
            <span>
              Send a push notification through our mobile app
            </span>
          </div>
          <div class="pt-8 d-flex align-center">
            <v-checkbox
              v-model="slide3.flagAsAlert"
              :hide-details="true"
              class="pa-0 ma-0"
            ></v-checkbox>
            <v-icon color="error" class="mr-2">mdi-alert</v-icon>
            <span class="align-text-left">
              Prioritize as an alert (This will publish on the feed with an
              alert icon attached)
            </span>
          </div>
        </v-container>
      </v-col>
    </div>
    <!-- Image URL dialog -->
    <v-dialog persistent v-model="slide1.dialogImageUrl" width="400">
      <v-card rounded="0">
        <v-card-title class="word-break">
          Please enter the URL of the image
        </v-card-title>
        <v-text-field
          v-model="slide1.imageURLTemp"
          class="my-4 mx-6"
          outlined
          dense
          label="Image URL"
        ></v-text-field>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeImageUrlDialog(false)">Cancel</v-btn>
          <v-btn text color="primary" @click="closeImageUrlDialog(true)"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Image Cropping dialog -->
    <v-dialog persistent v-model="slide1.dialogImageCropper" width="600">
      <v-card rounded="0" class="px-12">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-camera</v-icon>
          <v-card-title class="word-break px-0 mx-0">
            Please crop the image below to a 16 : 9 aspect ratio.
          </v-card-title>
        </div>
        <cropper
          ref="cropper"
          :src="slide1.imageURLToCrop"
          :stencil-props="{
            aspectRatio: 16 / 9
          }"
        />
        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn text @click="closeImageCropDialog(false)">Cancel</v-btn>
          <v-btn text color="primary" @click="closeImageCropDialog(true)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Confirmation dialog -->
    <v-dialog persistent v-model="dialogConfirmation" width="400">
      <v-card rounded="0" class="d-flex justify-center flex-column">
        <div></div>
        <v-card-title
          class="word-break mx-auto"
          v-if="
            !slide3.showSuccess && (editArticle === null || duplicateArticle)
          "
        >
          Please confirm you are ready to publish
        </v-card-title>
        <v-card-title
          class="word-break mx-auto"
          v-else-if="
            !slide3.showSuccess && editArticle !== null && !duplicateArticle
          "
        >
          Please confirm you are ready to re-publish
        </v-card-title>
        <v-card-title class="word-break mx-auto" v-else>
          <v-icon color="success" large class="mr-2">mdi-check-circle</v-icon>
          All done. Congratulations!
        </v-card-title>
        <p class="grey--text mt-8 align-text-left ml-4">
          Activity card preview
        </p>
        <ActivityFeedCard
          class="confirmation-page-card mx-auto mb-4"
          :icon="
            slide3.flagAsAlert ? 'mdi-alert' : 'mdi-newspaper-variant-outline'
          "
          :iconColor="computedIconColor"
          title="News update"
          :date="computedActivityCardDate"
          :messageTitle="slide1.title"
          :messageSubtitle="slide1.subtitle"
          :image="slide1.imageURL"
          :imageFlag="slide1.includeImageInCard"
          :messageTitleFlag="true"
          :messageSubtitleFlag="true"
          linkText="Read the news update"
          :linkButtonFlag="true"
          :actionFlag="false"
          :previewFlag="true"
          :disabled="true"
        />
        <p v-if="messageBatchWarning" class="mb-8">
          {{ messageBatchWarning }}
        </p>
        <v-card-actions v-if="!slide3.showSuccess">
          <v-btn
            text
            color="primary"
            @click="dialogConfirmation = false"
            :disabled="slide3.submitting"
            ><v-icon>mdi-chevron-left</v-icon>Back</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            @click="createNewsArticle"
            :loading="slide3.submitting"
            >Publish</v-btn
          >
        </v-card-actions>
        <v-card-actions class="mt-10" v-else>
          <v-btn color="primary" @click="closeNewsCreator" class="mx-auto"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ActivityFeedCard from "@/components/ActivityFeedCard.vue";
import GroupTable from "@/components/data-table/Groups.vue";

import UserService from "../services/UserService";
import CommunicationService from "../services/CommunicationService";

import momentTz from "moment-timezone";
import { mapState } from "vuex";
import { debounce } from "@/shared_data/functions";

import { VueEditor, Quill } from "vue2-editor";
Quill.register(Quill.import("attributors/style/direction"), true);
Quill.register(Quill.import("attributors/style/align"), true);

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

import {
  hours,
  amPm,
  minutes,
  editorToolbar,
  editorOptions
} from "@/shared_data/data.js";

export default {
  name: "NewsCreator",
  components: {
    ActivityFeedCard,
    VueEditor,
    GroupTable,
    Cropper
  },
  props: {
    // trigger: Boolean,
    systemWide: Boolean,
    editArticle: Object,
    duplicateArticle: Boolean
  },
  data() {
    return {
      slideKey: 1,
      dialogConfirmation: false,
      canEditDate: true,
      messageType: "Newsss Article",
      slide1: {
        title: null,
        subtitle: null,
        valid: false,
        dialogImageUrl: false,
        dialogImageCropper: false,
        imageURLTemp: null,
        image: null,
        imageURL: null,
        includeImageInCard: false,
        selectingImageFlag: false,
        content: null,
        customToolbar: editorToolbar,
        quillOptions: editorOptions
      },
      slide2: {
        groupColumns: [
          {
            text: "Group ID",
            align: "start",
            sortable: true,
            value: "groupId",
            width: "10%"
          },
          { text: "Group", value: "groupName", width: "40%" },
          { text: "Group Type", value: "groupType", width: "20%" },
          {
            text: "Participants",
            value: "numInGroup",
            width: "20%"
          }
        ],
        selectedGroups: [],
        loadingGroups: false,
        groups: [],
        groupTableSearch: null,
        groupTableDebounceSearch: null,
        clientColumns: [
          {
            text: "Client ID",
            align: "start",
            sortable: true,
            value: "clientId",
            width: "10%"
          },
          { text: "Client", value: "clientName", width: "90%" }
        ],
        selectedClients: [],
        loadingClients: false,
        clients: [],
        clientTableSearch: null
      },
      slide3: {
        submitting: false,
        showSuccess: false,
        displayOnPlatform: true,
        showOnDashboard: true,
        flagAsAlert: false,
        sendEmail: true,
        sendText: false,
        sendPush: false,
        startDate: null,
        menuStartDate: false,
        startTime: {
          hours: null,
          minutes: null,
          timezone: null,
          amPm: null
        },
        expirationDate: null,
        menuExpirationDate: false,
        expirationTime: {
          hours: null,
          minutes: null,
          amPm: null
        },
        hours: hours,
        minutes: minutes,
        amPm: amPm
      }
    };
  },
  created() {
    console.log("Created");
    // this.getGroups();
    this.getClients();

    if (this.editArticle !== null) {
      this.preloadArticle();
    } else {
      this.canEditDate = true;
      //Preload current date/time and expiration date 1 year from now
      this.slide3.startDate = momentTz()
        .local()
        .format("YYYY-MM-DD");

      var hours = momentTz()
        .local()
        .format("HH");
      console.log(hours);
      if (parseInt(hours) >= 12) {
        var amPm = "PM";
      } else {
        amPm = "AM";
      }

      this.slide3.startTime.hours = momentTz()
        .local()
        .format("h");

      this.slide3.startTime.amPm = amPm;
      this.slide3.startTime.minutes = momentTz()
        .local()
        .format("mm");
      this.slide3.startTime.timezone = { value: momentTz.tz.guess() };
      this.slide3.expirationDate = momentTz()
        .local()
        .add(1, "Y")
        .format("YYYY-MM-DD");
      this.slide3.expirationTime.hours = momentTz()
        .local()
        .format("h");
      this.slide3.expirationTime.amPm = amPm;
      this.slide3.expirationTime.minutes = momentTz()
        .local()
        .format("mm");
    }
  },
  beforeDestroy() {},
  methods: {
    closeNewsCreator() {
      if (this.slideKey === 1) {
        this.$refs.slide1Form.reset();
        this.slide1 = {
          title: null,
          subtitle: null,
          dialogImageUrl: false,
          imageURLTemp: null,
          image: null,
          imageURL: null,
          imageURLToCrop: null,
          imageURLFileName: null,
          includeImageInCard: false,
          selectingImageFlag: false,
          content: null
        };
      } else {
        this.slideKey = 1;
        this.slide1 = {
          title: null,
          subtitle: null,
          dialogImageUrl: false,
          imageURLTemp: null,
          image: null,
          imageURL: null,
          imageURLToCrop: null,
          imageURLFileName: null,
          includeImageInCard: false,
          selectingImageFlag: false,
          content: null
        };
      }
      this.slide1.imageURL = null;
      this.slide1.imageURLToCrop = null;
      this.slide1.imageURLFileName = null;

      this.slide2.selectedGroups = [];
      this.slide2.loadingGroups = false;
      this.slide2.groupTableSearch = null;
      this.slide2.groupTableDebounceSearch = null;

      this.slide3.submitting = false;
      this.slide3.showSuccess = false;
      this.slide3.showOnDashboard = true;
      this.slide3.displayOnPlatform = true;
      this.slide3.sendEmail = false;
      this.slide3.sendText = false;
      this.slide3.sendPush = false;
      this.slide3.startDate = null;
      this.slide3.menuStartDate = false;
      this.slide3.startTime = {
        hours: null,
        minutes: null,
        timezone: null,
        amPm: null
      };
      this.slide3.expirationDate = null;
      this.slide3.menuExpirationDate = false;
      this.slide3.expirationTime = {
        hours: null,
        minutes: null,
        timezone: null,
        amPm: null
      };

      this.dialogConfirmation = false;
      this.canEditDate = true;
      this.$emit("close-dialog");
    },
    goToSlide(index) {
      if (index === 1) {
        this.slideKey = 1;
      } else if (
        index === 2 &&
        this.slide1.valid &&
        this.slide1.content !== null &&
        this.slide1.content.length > 0
      ) {
        this.slideKey = 2;
      } else if (
        index === 3 &&
        this.slide1.valid &&
        this.slide1.content !== null &&
        this.slide1.content.length > 0 &&
        (((this.editArticle === null || this.duplicateArticle) &&
          this.slide2.selectedGroups.length > 0) ||
          this.editArticle !== null)
      ) {
        //We have to check if we're editing. If so, we're hiding the second slide, so we don't have to check if they selected a group
        this.slideKey = 3;
      }
    },
    goToNextSlide() {
      if (
        this.slideKey === 3 ||
        (this.slideKey === 1 &&
          this.editArticle !== null &&
          !this.canEditDate &&
          !this.duplicateArticle)
      ) {
        //We're on the final slide so show confirmation
        //Or we are editing and the message has already sent, so they can only update the message body
        this.dialogConfirmation = true;
      } else if (
        this.slideKey === 1 &&
        this.editArticle !== null &&
        this.canEditDate &&
        !this.duplicateArticle
      ) {
        this.slideKey = 3;
      } else {
        var i = this.slideKey + 1;
        this.slideKey = i;
      }
    },
    goToPreviousSlide() {
      var i = this.slideKey - 1;
      this.slideKey = i;
    },
    focusOnTitle() {
      this.$refs.titleInput.focus();
    },
    focusOnSubtitle() {
      this.$refs.subtitleInput.focus();
    },
    closeImageUrlDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      var url = this.slide1.imageURLTemp;
      if (submitPressed) {
        if (url == "" || url == null || url.replace(/\s+/g, "").length == 0) {
          this.slide1.imageURLTemp = null;
        } else {
          this.slide1.imageURL = url;
          this.slide1.image = null;
        }
      }
      //Regardless, we close dialog
      this.slide1.dialogImageUrl = false;
      this.slide1.imageURLTemp = null;
    },
    closeImageCropDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      if (submitPressed) {
        const { canvas } = this.$refs.cropper.getResult();
        this.slide1.imageURL = canvas.toDataURL();
        canvas.toBlob(blob => {
          // Do something with blob: upload to a server, download and etc.
          var file = new File([blob], this.slide1.imageURLFileName);
          this.slide1.image = file;

          //Regardless, we close dialog
          this.slide1.dialogImageCropper = false;
          this.slide1.imageURLToCrop = null;
          this.slide1.imageURLFileName = null;
        });
      } else {
        //Regardless, we close dialog
        this.slide1.dialogImageCropper = false;
        this.slide1.imageURLToCrop = null;
        this.slide1.imageURLFileName = null;
      }
    },
    uploadImage() {
      this.slide1.selectingImageFlag = true;
      window.addEventListener(
        "focus",
        () => {
          this.slide1.selectingImageFlag = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    imageFileChanged(e) {
      if (e.target.files.length > 0) {
        // this.slide1.image = e.target.files[0];
        this.slide1.imageURLToCrop = URL.createObjectURL(e.target.files[0]);
        this.slide1.imageURLFileName = e.target.files[0].name;
        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        document.querySelector("#uploader").value = "";
        this.slide1.dialogImageCropper = true;
      }
    },
    dragAndDropImageFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        // Load file into temp variable for cropping
        this.slide1.imageURLToCrop = URL.createObjectURL(
          e.dataTransfer.files[0]
        );
        this.slide1.imageURLFileName = e.dataTransfer.files[0].name;

        this.slide1.dialogImageCropper = true;
      }
    },
    removeImage() {
      this.slide1.image = null;
      this.slide1.imageURL = null;
    },
    getClients() {
      this.slide2.loadingClients = true;
      UserService.getClientsByClient(this.userProfile.clientId)
        .then(response => {
          console.log("Clients: ", response);
          var clientsArray = [];
          clientsArray = response.filter(group => group.status !== "Deleted");
          this.slide2.clients = clientsArray;
          this.slide2.loadingClients = false;
        })
        .catch(error => {
          console.log("Error getting subclients ", error.response || error);
          this.slide2.loadingClients = false;
        });
    },
    preloadArticle() {
      console.log("Preloading article ", this.editArticle);
      console.log("Duplicating article? ", this.duplicateArticle);
      var msg = this.editArticle;
      this.slide1.title = msg.messageSubject;
      this.slide1.subtitle = msg.messageShortBody;
      this.slide1.imageURL = msg.imageURL;
      this.slide1.includeImageInCard = msg.displayImageInCardPreview;
      //The message body contains the title, subtitle, and image. We need to manually parse the html and remove those to just include the body.
      //Title is always included
      var trimmedMsg = msg.messageBody;
      if (msg.messageBody.startsWith("<h1")) {
        trimmedMsg = msg.messageBody.substring(
          msg.messageBody.indexOf("</h1>") + 5
        );
        console.log(trimmedMsg);
      }

      //Subtitle is optional, so we have to check
      if (trimmedMsg.startsWith('<h2 id="subtitle"')) {
        console.log("Contains subtitle");
        trimmedMsg = trimmedMsg.substring(trimmedMsg.indexOf("</h2>") + 5);
        console.log(trimmedMsg);
      }

      //Image is optional, so we have to check
      if (trimmedMsg.startsWith('<img id="img"')) {
        console.log("Contains image");
        trimmedMsg = trimmedMsg.substring(trimmedMsg.indexOf("/>") + 2);
        console.log(trimmedMsg);
      }

      this.slide1.content = trimmedMsg;

      this.slide3.flagAsAlert = msg.context == "alerts";
      this.slide3.displayOnPlatform = msg.displayOnPlatform;
      this.slide3.showOnDashboard = msg.displayOnPlatform;
      this.slide3.sendEmail = msg.sendEmail;
      this.slide3.sendText = msg.sendSMS;
      this.slide3.sendPush = msg.sendPush;

      //We are editing an article, so we need to check if it has sent. If it has sent, we hide the last page with the canEditDate flag
      if (
        new momentTz() > new momentTz(msg.sendDate) &&
        !this.duplicateArticle
      ) {
        //We can't edit date
        this.canEditDate = false;
      } else {
        this.canEditDate = true;

        //If we are duplicating, then we prefill dates with current date and 1 year from now
        if (this.duplicateArticle) {
          this.slide3.startDate = momentTz()
            .local()
            .format("YYYY-MM-DD");

          var hours = momentTz()
            .local()
            .format("HH");
          console.log(hours);
          if (parseInt(hours) >= 12) {
            var amPm = "PM";
          } else {
            amPm = "AM";
          }

          this.slide3.startTime.hours = momentTz()
            .local()
            .format("h");

          this.slide3.startTime.amPm = amPm;
          this.slide3.startTime.minutes = momentTz()
            .local()
            .format("mm");
          this.slide3.startTime.timezone = { value: momentTz.tz.guess() };
          this.slide3.expirationDate = momentTz()
            .local()
            .add(1, "Y")
            .format("YYYY-MM-DD");
          this.slide3.expirationTime.hours = momentTz()
            .local()
            .format("h");
          this.slide3.expirationTime.amPm = amPm;
          this.slide3.expirationTime.minutes = momentTz()
            .local()
            .format("mm");
        } else {
          //Preload start date so it auto fills with the old send date
          this.slide3.startDate = new momentTz(msg.sendDate)
            .local()
            .format("YYYY-MM-DD");

          hours = new momentTz(msg.sendDate).local().format("HH");
          if (parseInt(hours) >= 12) {
            amPm = "PM";
          } else {
            amPm = "AM";
          }

          this.slide3.startTime = {
            hours: new momentTz(msg.sendDate).local().format("h"),
            minutes: new momentTz(msg.sendDate).local().format("mm"),
            amPm: amPm,
            timezone: { value: momentTz.tz.guess() }
          };

          if (msg.expirationDate !== null) {
            this.slide3.expirationDate = new momentTz(msg.expirationDate)
              .local()
              .format("YYYY-MM-DD");

            hours = new momentTz(msg.expirationDate).local().format("HH");
            if (parseInt(hours) >= 12) {
              amPm = "PM";
            } else {
              amPm = "AM";
            }

            this.slide3.expirationTime = {
              hours: new momentTz(msg.expirationDate).local().format("h"),
              minutes: new momentTz(msg.expirationDate).local().format("mm"),
              amPm: amPm,
              timezone: { value: momentTz.tz.guess() }
            };
          } else {
            //Otherwise, we add 1 year to sendDate
            this.slide3.expirationDate = new momentTz(msg.sendDate)
              .local()
              .add(1, "Y")
              .format("YYYY-MM-DD");

            hours = new momentTz(msg.sendDate).local().format("HH");
            if (parseInt(hours) >= 12) {
              amPm = "PM";
            } else {
              amPm = "AM";
            }

            this.slide3.expirationTime = {
              hours: new momentTz(msg.sendDate).local().format("h"),
              minutes: new momentTz(msg.sendDate).local().format("mm"),
              amPm: amPm,
              timezone: { value: momentTz.tz.guess() }
            };
          }
        }
      }
    },
    async createNewsArticle() {
      this.slide3.submitting = true;

      var startDate = null;
      var endDate = null;
      //If we are editing an article that hasn't sent yet, then
      if (this.canEditDate) {
        //We convert the start date/time into the time zone and then UTC
        var startHours = this.slide3.startTime.hours.toString();
        if (this.slide3.startTime.amPm == "PM") {
          if (parseInt(startHours) != 12) {
            startHours = (parseInt(startHours) + 12).toString();
          }
        } else {
          if (startHours == 12) {
            //Should be 0
            startHours = "0";
          }
        }

        if (startHours.toString().length == 1) {
          startHours = "0" + startHours.toString();
        }

        console.log(startHours);
        console.log(this.slide3.startTime.minutes);
        console.log(this.slide3.startTime.timezone.value);

        var startDateString = this.slide3.startDate
          .toString()
          .concat(" ", startHours, ":", this.slide3.startTime.minutes);
        startDate = momentTz.tz(
          startDateString,
          this.slide3.startTime.timezone.value
        );

        startDate.format();

        console.log(startDate.utc().format());
        startDate = startDate.utc().format();

        //We convert the expiration date/time into the time zone and then UTC
        var endHours = this.slide3.expirationTime.hours.toString();
        if (this.slide3.expirationTime.amPm == "PM" && endHours !== null) {
          if (parseInt(endHours) != 12) {
            endHours = (parseInt(endHours) + 12).toString();
          }
        } else if (endHours !== null) {
          if (endHours == 12) {
            //Should be 0
            endHours = "0";
          }
        }

        if (endHours.toString().length == 1 && endHours !== null) {
          endHours = "0" + endHours.toString();
        }

        console.log(this.slide3.expirationDate);
        console.log(endHours);
        console.log(this.slide3.expirationTime.minutes);
        console.log(this.slide3.startTime.timezone.value);

        endDate = null;
        if (
          this.slide3.expirationDate !== null &&
          endHours !== null &&
          this.slide3.startTime.timezone.value !== null &&
          this.slide3.expirationTime.minutes !== null
        ) {
          var endDateString = this.slide3.expirationDate
            .toString()
            .concat(" ", endHours, ":", this.slide3.expirationTime.minutes);
          endDate = momentTz.tz(
            endDateString,
            this.slide3.startTime.timezone.value
          );
          endDate.format();
          console.log(endDate.utc().format());
          endDate = endDate.utc().format();
        } else {
          //Default is 1 year after sendDate
          endDateString = momentTz(this.slide3.startDate)
            .add(1, "Y")
            .format("YYYY-MM-DD")
            .toString()
            .concat(" ", startHours, ":", this.slide3.startTime.minutes);
          endDate = momentTz.tz(
            endDateString,
            this.slide3.startTime.timezone.value
          );
          endDate.format();
          console.log(endDate.utc().format());
          endDate = endDate.utc().format();
        }
      } else {
        //If the article has already sent, then we make the start/end dates the same as what was in the preloaded article
        startDate = this.editArticle.sendDate;
        endDate = this.editArticle.expirationDate;
      }

      var imageURL = this.slide1.imageURL;
      var image = this.slide1.image;

      if (imageURL !== null && imageURL.length === 0) {
        imageURL = null;
      }
      //We check if we're editing an article but not duplicating one
      if (this.editArticle !== null && !this.duplicateArticle) {
        //We only mess with this if we're editing AND the user changed the image
        if (this.editArticle.imageURL !== imageURL) {
          //We check if the image came from google storage. If so, we delete that old image
          if (
            this.editArticle.imageURL !== null &&
            this.editArticle.imageURL.startsWith(
              "https://storage.googleapis.com/whistle"
            )
          ) {
            //We have to get the unique ID in the URL
            var shortURL = this.editArticle.imageURL.substring(
              this.editArticle.imageURL.indexOf("Communications/") + 15,
              this.editArticle.imageURL.indexOf(
                "/",
                this.editArticle.imageURL.indexOf("Communications/") + 15
              )
            );
            console.log("ShortURL: ", shortURL);
            var deleteImageResp = await CommunicationService.deleteImage(
              shortURL
            );
            console.log(deleteImageResp);
          }
          //If it didn't start with google storage, we don't have to worry, we can just overwrite
          if (image !== null && imageURL !== null) {
            //User deleted old pic and we need to upload new pic
            imageURL = await CommunicationService.uploadImage(image);
          } else if (image === null && imageURL === null) {
            //User deleted old pic. No new pic.
            imageURL = null;
            this.slide1.includeImageInCard = false;
          }
        }
      } else if (this.editArticle !== null && this.duplicateArticle) {
        //We have to copy the old image to this new article since we're duplicating
        //We only duplicate if they didn't change the image
        if (this.editArticle.imageURL === imageURL) {
          //We check if the image came from google storage. If so, we delete that old image
          //We don't need to check an else because if the original image is unchanged but wasn't stored by us, then we can just copy the url
          if (
            this.editArticle.imageURL !== null &&
            this.editArticle.imageURL.startsWith(
              "https://storage.googleapis.com/whistle"
            )
          ) {
            //We have to get the unique ID in the URL
            shortURL = this.editArticle.imageURL.substring(
              this.editArticle.imageURL.indexOf("Communications/") + 15,
              this.editArticle.imageURL.indexOf(
                "/",
                this.editArticle.imageURL.indexOf("Communications/") + 15
              )
            );
            console.log("ShortURL: ", shortURL);
            imageURL = await CommunicationService.duplicateImage(shortURL);
            console.log("Rreturn from Duplicate image call: ", imageURL);
          }
        } else {
          //Otherwise, we need to do everything like normal for uploading an image
          //We have to upload the image if one was uploaded
          if (image !== null && imageURL !== null) {
            //The user uploaded an image, so we uplaod that and wait for its reponse
            imageURL = await CommunicationService.uploadImage(image);
          } else if (image === null && imageURL === null) {
            imageURL = null;
            this.slide1.includeImageInCard = false;
          }
        }
      } else {
        //We have to upload the image if one was uploaded
        if (image !== null && imageURL !== null) {
          //The user uploaded an image, so we uplaod that and wait for its reponse
          imageURL = await CommunicationService.uploadImage(image);
        } else if (image === null && imageURL === null) {
          imageURL = null;
          this.slide1.includeImageInCard = false;
        }
      }

      //Ok now that the dates have been figured out, we need to manually add the title, subtitle, and image to the html
      // var newHtml = `<h1 id="title" style="text-align:center;">${this.slide1.title}</h1>`;
      // if (this.slide1.subtitle !== null) {
      //   newHtml =
      //     newHtml +
      //     `<h2 id="subtitle" style="text-align:center;">${this.slide1.subtitle}</h2>`;
      // }
      // if (imageURL !== null) {
      //   newHtml =
      //     newHtml +
      //     `<table align='center' border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 600px'><tr><td align='center' valign='top' style='padding: 40px 10px 40px 10px'><img id="img" alt="Image specified by sender" width="600px" height="337px" style='min-width: 600px; width: 600px;max-width:600px;height:337px;max-height:337px;object-fit: cover;display:block;margin-left:auto;margin-right:auto;' src='${imageURL}' /></td></tr></table>`;
      // }

      // var finalHtml = newHtml + this.slide1.content;
      if (this.slide3.flagAsAlert) {
        var context = "alerts";
      } else {
        context = "news";
      }

      if (this.systemWide) {
        //do client stuff
        var clientIds = this.slide2.selectedClients.map(x =>
          x.clientId.toString()
        );
        var stringClientIds = clientIds.join();
      } else {
        //do group stuff
        var groupIds = this.slide2.selectedGroups.map(x =>
          x.groupId.toString()
        );
        var stringGroupIds = groupIds.join();
      }

      var message = {
        clientId: this.clientId || this.userProfile.clientId,
        audience:
          this.editArticle !== null && !this.duplicateArticle
            ? null
            : this.systemWide
            ? clientIds
            : groupIds,
        context: context,
        contextResourceId: null,
        messageSubject: this.slide1.title,
        messageShortBody: this.slide1.subtitle,
        messageBody: this.slide1.content, //finalHtml,
        displayOnPlatform: this.slide3.displayOnPlatform,
        sendEmail: this.slide3.sendEmail,
        sendSMS: this.slide3.sendText,
        sendPush: this.slide3.sendPush,
        sendDashboard: this.slide3.displayOnPlatform,
        selectedGroups:
          this.editArticle !== null && !this.duplicateArticle
            ? this.editArticle.selectedGroups
            : this.systemWide
            ? null
            : stringGroupIds,
        selectedClients:
          this.editArticle !== null && !this.duplicateArticle
            ? this.editArticle.selectedClients
            : this.systemWide
            ? stringClientIds
            : null,
        sendDate: startDate,
        expirationDate: endDate,
        imageURL: imageURL,
        displayImageInCardPreview: this.slide1.includeImageInCard,
        createdBy:
          this.editArticle !== null && !this.duplicateArticle
            ? this.editArticle.createdBy
            : this.userProfile.userId,
        updatedBy: this.userProfile.userId
      };
      console.log(message);

      //If we're not editing, then we send normally.
      // If we are editing, then we patch
      if (this.editArticle === null || this.duplicateArticle) {
        //if system wide, do by client
        if (this.systemWide) {
          //send by cllient id
          console.log("client message");
          message.clients = clientIds;
          delete message.audience;
          delete message.groups;
        } else {
          //else do by Group
          console.log("Group message");
          message.groups = message.audience;
          delete message.audience;
          delete message.clients;

          // CommunicationService.sendMessageByGroup(message)
          //   .then((x) => {
          //     console.log(x);
          //     this.slide3.submitting = false;
          //     this.slide3.showSuccess = true;
          //   })
          //   .catch((error) => {
          //     console.log("Error sending email! ", error);
          //   });
        }

        CommunicationService.sendMessageV2(message)
          .then(x => {
            console.log(x);
            this.slide3.submitting = false;
            this.slide3.showSuccess = true;
          })
          .catch(error => {
            console.log("Error sending email! ", error);
            this.slide3.submitting = false;
          });
      } else {
        console.log("Done editing article");
        CommunicationService.updateMessage(this.editArticle.messageId, message)
          .then(x => {
            console.log(x);
            this.slide3.submitting = false;
            this.slide3.showSuccess = true;
          })
          .catch(error => {
            console.log("Error sending email! ", error);
          });
      }
    },
    async addGroupToSelected(group, dontRemove = false) {
      console.log("Adding group to selected array", group);
      // Added because we can only pass one param in from the groups table
      if (group.dontRemove) dontRemove = true;
      let idx = this.slide2.selectedGroups.findIndex(
        x => x.groupId == group.groupId
      );
      if (idx == -1) this.slide2.selectedGroups.push(group);
      else if (!dontRemove) this.removeGroupFromSelected(idx);
    },
    removeGroupFromSelected(index) {
      this.slide2.selectedGroups.splice(index, 1);
    },
    updateSelectedItem(e) {
      if (!e.groupId) return console.log("no group id");

      this.slide2.selectedGroups.forEach(o => {
        if (o.groupId == e.groupId) {
          console.log("UPDATING SELECTED NUM USERS ", e.numUsers);
          o.numUsers = e.numUsers || 0;
          // this.getDistinctUsersFromGroups();
        }
      });
    },
    updateClientId() {
      this.slide2.selectedGroups = [];
      // this.getGroupsV2(true);
    }
  },
  computed: {
    computedMessageType() {
      var messageTypeString;
      if (this.systemWide) {
        messageTypeString = "system wide message";
      } else {
        messageTypeString = "news article";
      }
      return messageTypeString;
    },
    computedClients() {
      let arr = [];
      // We add the user's client
      if (this.userProfile && this.userProfile.Client) {
        arr.push({
          clientId: this.userProfile.clientId,
          clientName: this.userProfile.Client.clientName
        });
      }

      // If clientId 1, then we show subclients
      // TO DO: we want to eventually allow non-Whistle users to send to subclients.
      if (this.userProfile && this.userProfile.clientId !== 1)
        arr = arr.concat(this.slide2.clients);
      else if (
        (this.permissions || []).includes("messages:create:adminalert") &&
        (this.clients || []).length
      )
        arr = this.clients;

      return arr;
    },
    ...mapState(["userProfile", "globalClientId", "clients", "permissions"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    computedActivityCardDate() {
      //We check if we're editing or creating new
      if (this.editArticle === null) {
        if (
          this.slide3.startDate === null ||
          this.slide3.startTime.hours === null ||
          this.slide3.startTime.minutes === null ||
          this.slide3.startTime.amPm === null
        ) {
          return null;
        } else {
          return this.slide3.startDate
            .toString()
            .concat(
              " ",
              this.slide3.startTime.hours,
              ":",
              this.slide3.startTime.minutes,
              " ",
              this.slide3.startTime.amPm.toLowerCase()
            );
        }
      } else {
        //If we're editing, then we use the sendDate from editArticle
        return new momentTz(this.editArticle.sendDate).format(
          "YYYY-MM-DD h:mm a"
        );
      }
    },
    disableContinueButton() {
      var formattedStartDate = new momentTz(this.slide3.startDate)
        .local()
        .format("YYYY-MM-DD");
      var formattedEndDate = new momentTz(this.slide3.expirationDate)
        .local()
        .format("YYYY-MM-DD");
      if (
        this.slideKey === 1 &&
        this.slide1.valid &&
        this.slide1.content !== null &&
        this.slide1.content.length > 0
      ) {
        return false;
      } else if (
        this.slideKey === 2 &&
        (this.slide2.selectedGroups.length > 0 ||
          this.slide2.selectedClients.length > 0)
      ) {
        return false;
      } else if (
        this.slideKey === 3 &&
        (this.slide3.displayOnPlatform ||
          this.slide3.showOnDashboard ||
          this.slide3.sendEmail ||
          this.slide3.sendText ||
          this.slide3.sendPush ||
          this.slide3.flagAsAlert) &&
        this.slide3.startDate !== null &&
        this.slide3.startTime.hours !== null &&
        this.slide3.startTime.minutes !== null &&
        this.slide3.startTime.timezone !== null &&
        (this.slide3.expirationDate === null ||
          formattedStartDate < formattedEndDate)
      ) {
        return false;
      } else {
        return true;
      }
    },
    peopleInSelectedGroups() {
      var sum = 0;
      for (var i = 0; i < this.slide2.selectedGroups.length; i++) {
        // sum += this.slide2.selectedGroups[i].GroupAssignments.length;
        sum += this.slide2.selectedGroups[i].numUsers || 0;
      }
      return sum;
    },
    calculatedTimezones() {
      return momentTz.tz.zonesForCountry("US").map(x => {
        return {
          value: x
        };
      });
    },
    expirationDateRules() {
      var formattedStartDate = new momentTz(this.slide3.startDate)
        .local()
        .format("YYYY-MM-DD");
      var formattedEndDate = new momentTz(this.slide3.expirationDate)
        .local()
        .format("YYYY-MM-DD");
      return [
        () =>
          !!(formattedStartDate < formattedEndDate) ||
          "End date must be later than the start date"
      ];
    },
    computedIconColor() {
      if (this.slide3.flagAsAlert) {
        return "error";
      } else {
        return "brand-yellow";
      }
    },
    calculatedImageWidth() {
      // This is used for the image width because some images want to expand outside of the container, so I get that width and subtract the width of the edit icon
      // if (!this.mounted) {
      //   console.log("not mounted");
      //   return;
      // }
      if (document.getElementsByClassName("image-container").length > 0) {
        return (
          document.getElementsByClassName("image-container")[0].offsetWidth - 48
        );
      } else {
        return 600;
      }
    },
    messageBatchWarning() {
      const limit = Number(process.env.VUE_APP_MESSAGE_BATCH_LIMIT || 500);
      if (this.peopleInSelectedGroups < limit) return null;
      return "Due to the size of the recipient list, this message may take some extra time to be delivered.";
    }
  },
  watch: {
    "slide2.groupTableDebounceSearch": debounce(function(newVal) {
      this.slide2.groupTableSearch = newVal;
    }, 500)
    // trigger: function(newVal) {
    //   if (newVal !== null) {
    //     //We are loading the news creator again, so we reload
    //     this.getGroups();
    //     console.log("Calling getGroups from watch");
    //     if (this.editArticle !== null) {
    //       this.preloadArticle();
    //     } else {
    //       this.slide3.startDate = momentTz()
    //         .local()
    //         .format("YYYY-MM-DD");
    //       var hours = momentTz()
    //         .local()
    //         .format("HH");
    //       console.log(hours);
    //       if (parseInt(hours) >= 12) {
    //         var amPm = "PM";
    //       } else {
    //         amPm = "AM";
    //       }
    //       this.slide3.startTime.hours = momentTz()
    //         .local()
    //         .format("h");
    //       this.slide3.startTime.amPm = amPm;
    //       this.slide3.startTime.minutes = momentTz()
    //         .local()
    //         .format("mm");
    //       this.slide3.startTime.timezone = { value: momentTz.tz.guess() };
    //       this.slide3.expirationDate = momentTz()
    //         .local()
    //         .add(1, "Y")
    //         .format("YYYY-MM-DD");
    //       this.slide3.expirationTime.hours = momentTz()
    //         .local()
    //         .format("h");
    //       this.slide3.expirationTime.amPm = amPm;
    //       this.slide3.expirationTime.minutes = momentTz()
    //         .local()
    //         .format("mm");
    //     }
    //   }
    // }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.quillWrapper.editor {
  max-height: 100%;
}
.dark-blue-background {
  background-color: var(--v-brand-base);
}
.grey-background {
  background-color: var(--v-grey-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
.error-background {
  background-color: var(--v-error-base);
}
.primary-text {
  color: var(--v-primary-base);
}
.border-radius {
  border-radius: 100%;
}
.header-text {
  font-size: x-large;
}
.small-text {
  font-size: small;
}
.text-field-width {
  width: 400px;
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.editor {
  max-height: 40vh;
}
.msg-body {
  width: 90%;
}
.hour-picker {
  width: 30%;
}
.minutes-picker {
  width: 30%;
}
.am-pm-picker {
  width: 30%;
}
.confirmation-page-card {
  width: 90%;
}
</style>
