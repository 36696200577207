import { render, staticRenderFns } from "./PaymentsWizardV3.vue?vue&type=template&id=6e8649b4&scoped=true"
import script from "./PaymentsWizardV3.vue?vue&type=script&lang=js"
export * from "./PaymentsWizardV3.vue?vue&type=script&lang=js"
import style0 from "./PaymentsWizardV3.vue?vue&type=style&index=0&id=6e8649b4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e8649b4",
  null
  
)

export default component.exports