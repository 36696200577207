<template>
  <div class="full-width d-flex flex-column align-start">
    <!-- <h4 class="mb-1 ml-1" v-if="transactions.length">
        Activity
        <v-btn
          icon
          class="ml-3"
          @click="display.transactionSearch = !display.transactionSearch"
          ><v-icon>mdi-magnify</v-icon></v-btn
        >
      </h4> -->
    <!-- <v-expand-transition> -->
    <!-- <div v-if="!isModal" class="d-flex justify-end full-width">
      <v-text-field
        v-model="search.transactions"
        append-icon="mdi-magnify"
        label="Search"
        outlined
        dense
        color="brandCyan"
        style="min-width: 140px; max-width: 220px;"
      ></v-text-field>
    </div> -->
    <p class="ml-2 mt-3" v-if="isModal">
      To view your full transaction activity, go to the
      <a @click="loadWallet">Wallet page</a>.
    </p>
    <!-- </v-expand-transition> -->
    <v-data-table
      :headers="columns"
      :hide-default-header="isModal && !isMobile"
      :hide-default-footer="isModal"
      :items="transactions"
      item-key="cardActivityToken"
      :items-per-page="isModal ? 5 : 10"
      class="full-width"
      sort-by="merchantName"
      :multi-sort="false"
      :custom-sort="customSort"
      :sort-desc="true"
      must-sort
      :class="{
        'elevation-2 txn-table': !isModal,
        'txn-table-mobile': isModal && isMobile
      }"
      :search="searchValue"
      loading-text="Loading... Please wait"
      no-data-text="No transactions found"
    >
      <template v-slot:[`item.amount`]="{ item }">
        <span
          :class="{
            'success--text': item.type == 'load' || item.type == 'refund'
          }"
          >{{ item.amount }}</span
        >
      </template>
      <template v-slot:[`item.merchantName`]="{ item }">
        <div class="d-flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                large
                class="mb-1 mr-2"
                style="min-width: 16px; max-width: 16px;"
                :class="item.type == 'authorization' ? '' : 'hidden'"
                color="orange"
                >mdi-circle-small</v-icon
              >
            </template>
            Pending Transaction
          </v-tooltip>
          <div class="d-flex flex-column word-break my-1">
            <span>{{ item.merchantName }}</span>
            <span class="grey--text">{{ item.formattedDate }}</span>
          </div>
        </div>
      </template>
      <template v-slot:[`item.recatagorizeItem`]="{ item }">
        <v-select
          v-if="
            item.displayRecategorizationDropdown == true &&
              item.paymentRecategorizationDropdownOptions.length > 0
          "
          @change="recatagorizeTransactionHandler(item)"
          :items="item.paymentRecategorizationDropdownOptions"
          item-text="label"
          item-value="id"
          outlined
          dense
          hide-details
          class="budget-select word-break"
          style="max-width: 250px;"
          v-model="item.recategorizationDropdownValue"
        ></v-select>
        <div
          v-else-if="item.originalBudget && item.originalBudget != null"
          class="d-flex flex-column"
        >
          <span v-for="budget in item.originalBudget" :key="budget">{{
            budget
          }}</span>
        </div>
      </template>
      <template v-slot:[`item.disputed`]="{ item }">
        <div v-if="item.displayTransactionOptions">
          <v-btn
            icon
            :loading="true"
            v-if="loading.disputeId == item.cardActivityToken"
          ></v-btn>
          <v-menu max-width="260" offset-y v-else-if="!item.disputed">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon text v-on="on" v-bind="attrs"
                ><v-icon>mdi-dots-horizontal</v-icon></v-btn
              >
            </template>
            <v-list>
              <v-list-item @click="disputeTransaction(item.cardActivityToken)">
                <v-list-item-title class="text-left"
                  ><v-icon color="black" class="mr-2">mdi-flag</v-icon>Dispute
                  transaction</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip top open-delay="300" v-else-if="item.disputed">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" class="dispute-button">
                <v-icon color="error">mdi-alert</v-icon>
              </div>
            </template>
            <span>This transaction has been disputed</span>
            <!-- <span v-else>Dispute this transaction</span> -->
          </v-tooltip>
        </div>
        <!-- <div v-else>123123</div> -->
      </template>
    </v-data-table>
    <v-dialog v-model="display.dispute" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            The dispute process has been started
          </v-card-title>
        </div>

        <v-card-title class="mb-5"
          >A Whistle representative will be in contact with you within 1
          business day.</v-card-title
        >

        <v-card-actions class="mx-12">
          <v-btn
            class="mx-auto white--text"
            color="brandCyan"
            depressed
            @click="display.dispute = false"
            width="130"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      v-model="recatagorizeTransaction.showDrawer"
      temporary
      fixed
      right
      width="500"
    >
      <RecatagorizeTransactionDrawer
        v-if="recatagorizeTransaction.showDrawer"
        @close="closeRecatagorizeTransactionDrawer"
        :transaction="recatagorizeTransaction.transaction"
      />
    </v-navigation-drawer>
    <v-dialog
      v-if="recatagorizeTransaction.transaction != null"
      v-model="recatagorizeTransaction.showModal"
      width="500"
      persistent
    >
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2">
          <v-card-title class="word-break align-text-left">
            Recategorize Transaction
          </v-card-title>
        </div>
        <!-- Merchant name - paymentRecategorizationAmountToBeMoved -->
        <v-card-subtitle class="word-break pb-1 align-text-left"
          >{{ recatagorizeTransaction.transaction.merchantName }} -
          {{ recatagorizeTransaction.transaction.amount }}</v-card-subtitle
        >
        <transition name="topic-left" mode="out-in">
          <p
            class="py-2 mx-2"
            key="0"
            v-if="
              recatagorizeTransaction.success != true &&
                recatagorizeTransaction.error != true &&
                recatagorizeTransaction.loading != true
            "
          >
            {{
              formatCurrency(
                recatagorizeTransaction.transaction
                  .paymentRecategorizationAmountToBeMoved
              )
            }}
            will be deducted from your general balance and transferred to
            <span
              v-for="(budget, i) in recatagorizeTransaction.transaction
                .originalBudget"
              :key="budget"
              >{{
                i != 0 &&
                ((i &&
                  recatagorizeTransaction.transaction.originalBudget.length -
                    1) ||
                  i ==
                    recatagorizeTransaction.transaction.originalBudget.length -
                      1) &&
                recatagorizeTransaction.transaction.originalBudget.length != 2
                  ? ", "
                  : ""
              }}
              {{
                i ==
                  recatagorizeTransaction.transaction.originalBudget.length -
                    1 && i != 0
                  ? "and "
                  : ""
              }}
              {{ budget }}</span
            >.
          </p>
          <!-- Loading Robin -->
          <div
            v-else-if="recatagorizeTransaction.loading == true"
            class="mx-2 pb-2 d-flex flex-column align-center"
            key="1"
          >
            <Robin
              animation="flying"
              :width="150"
              class="mx-auto robin-animation"
              :loop="true"
            />
            Transferring funds...
          </div>
          <!-- Success message -->
          <div
            v-else-if="recatagorizeTransaction.success == true"
            class="mx-2 pb-2 d-flex flex-column align-center"
            key="2"
          >
            <Robin
              animation="ohYeah"
              :width="150"
              class="mx-auto robin-animation"
              :loop="true"
            />
            Awesome! The funds were successfully transferred!
          </div>
          <!-- Error message -->
          <div
            v-else-if="
              recatagorizeTransaction.error == true &&
                recatagorizeTransaction.insufficientFunds != true
            "
            class="mx-2 pb-2 pt-2"
            key="3"
          >
            <p class="pl-4 full-width align-text-left">
              It looks like there was an error transferring funds.
            </p>
            <p class="pl-4 full-width align-text-left">
              If the problem continues, feel free to reach out to customer
              service via one of the following methods...
            </p>
            <p class="pl-4 align-text-left full-width">
              Email -
              <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
            </p>
            <p class="pl-4 align-text-left full-width">
              Phone (Toll Free) - <b>(855) 264-3329</b>
            </p>
          </div>
          <!-- Insufficient funds message -->
          <div
            v-else-if="
              recatagorizeTransaction.error == true &&
                recatagorizeTransaction.insufficientFunds == true
            "
            class="mx-2 pb-2 pt-2"
            key="4"
          >
            <p class="pl-4 align-text-left full-width">
              It looks like there aren't enough funds in your general balance to
              do the transaction.
            </p>
          </div>
        </transition>
        <!-- Buttons -->
        <div :class="{ hidden: recatagorizeTransaction.loading == true }">
          <v-card-actions
            class="mx-8"
            v-if="
              recatagorizeTransaction.error != true &&
                recatagorizeTransaction.success != true
            "
          >
            <v-btn
              color="brandCyan"
              @click="closeRecatagorizationTransaction"
              outlined
              width="170"
              class="mr-4"
              >Cancel</v-btn
            >
            <v-spacer />
            <v-btn
              color="brandCyan"
              depressed
              class="white--text"
              @click="submitRecatagorizeTransaction"
              width="170"
              >Submit</v-btn
            >
          </v-card-actions>
          <v-card-actions class="mx-8" v-else>
            <v-btn
              color="brandCyan"
              depressed
              @click="closeRecatagorizationTransaction"
              width="170"
              class="mx-auto white--text"
              >Done</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MarqetaService from "@/services/MarqetaService";
import WalletService from "@/services/WalletService";

import RecatagorizeTransactionDrawer from "@/components/RecatagorizeTransactionDrawer.vue";
import Robin from "@/components/Robin.vue";

import moment from "moment";
import currency from "currency.js";
import { mapState } from "vuex";

export default {
  name: "TransactionLog",
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    isolate: String,
    showVirtualCard: Boolean,
    search: String
  },
  components: { RecatagorizeTransactionDrawer, Robin },
  data() {
    return {
      loading: {
        balance: false,
        disputeId: null
      },
      display: {
        virtualCard: false,
        policies: false,
        management: false,
        transactions: false,
        authorizationSearch: false,
        transactionSearch: false,
        dispute: false
      },
      // search: {
      //   authorizations: null,
      //   transactions: null
      // },
      transactionColumns: [
        {
          text: "Description  ",
          value: "merchantName",
          width: "50%",
          sortable: true,
          align: "start",
          modal: true
        },
        // {
        //   text: "Name",
        //   value: "merchantName",
        //   width: "0%",
        //   sortable: false,
        //   align: "d-none",
        //   class: "d-none hidden",
        //   modal: false
        //   // mobile: false
        // },
        {
          text: "Budget Category",
          value: "recatagorizeItem",
          width: "20%",
          align: "start",
          sortable: false,
          modal: false
        },
        {
          text: "Amount",
          value: "amount",
          width: "15%",
          align: "end",
          sortable: false,
          modal: true
        },
        {
          text: "",
          value: "disputed",
          width: "10%",
          align: "center",
          sortable: false,
          modal: false
        }
      ],
      recatagorizeTransaction: {
        showDrawer: false,
        showModal: false,
        transaction: null,
        selectedBudget: null,
        success: false,
        error: false,
        insufficientFunds: false,
        loading: false
      }
    };
  },
  created() {},
  mounted() {
    var mostRecentTx = this.transactions.find(
      transaction => transaction.type == "load"
    );

    if (
      !this.isModal &&
      mostRecentTx &&
      this.userProfile.LoginHistories.length >= 3 &&
      mostRecentTx.date >= this.userProfile.LoginHistories[2].date &&
      mostRecentTx.date != this.confettiPlayed
    ) {
      this.$emit("trigger-confetti", mostRecentTx.date);
    }
  },
  beforeDestroy() {},
  methods: {
    formatCurrency(amount) {
      return currency(amount).format();
    },
    loadWallet() {
      this.$emit("load-wallet");
    },
    customSort(items, index, isDesc) {
      // console.log({ isDesc });
      items.sort((a, b) => {
        if (index.length && index[0] === "merchantName") {
          // console.log("Parsing date sort", b.date);
          if (isDesc.length && !isDesc[0]) {
            return moment(b.date).isBefore(a.date) ? 1 : -1;
          } else {
            return moment(a.date).isBefore(b.date) ? 1 : -1;
          }
        }
      });
      return items;
    },
    filterTransactions(value, query, item) {
      // console.log({ value, query, item });
      // We have a custom filter because we want to search merchant name, but it's combined with date in one column
      // That column's value is date so that it can naturally be sorted in descending order

      return (
        query != null &&
        ((item &&
          item.merchantName != null &&
          typeof item.merchantName === "string" &&
          item.merchantName
            .toString()
            .toLowerCase()
            .indexOf(query.toLowerCase()) !== -1) ||
          (value != null &&
            typeof value == "string" &&
            value
              .toString()
              .toLowerCase()
              .indexOf(query.toLowerCase()) !== -1))
      );
    },
    disputeTransaction(token) {
      console.log("Disputing transaction ", token);
      this.loading.disputeId = token;
      console.log(this.userProfile.CardActivities);
      var dispute = {
        clientId: this.userProfile.clientId,
        userId: this.userProfile.userId,
        transactionToken: token,
        email: this.userProfile.businessEmail,
        phone: this.userProfile.businessPhone,
        name: this.userProfile.firstName + " " + this.userProfile.lastName,
        createdBy: this.userProfile.userId,
        updatedBy: this.userProfile.userId
      };
      MarqetaService.createDispute(dispute, this.magicLinkToken).then(x => {
        console.log("Dispute response: ", x);
        // var act = {
        //   updatedBy: this.userProfile.userId,
        //   disputed: true,
        // };
        // WalletService.updateCardActivity(token, act).then((y) => {
        //   console.log("Card activity response: ", y);
        this.display.dispute = true;
        this.$store.dispatch("setUser", this.userProfile.userId);
        this.loading.disputeId = null;
        // });
      });
    },
    recatagorizeTransactionHandler(transaction) {
      console.log("recatagorizing transaction", transaction);
      console.log(transaction.recategorizationDropdownValue);
      if (transaction.recategorizationDropdownValue != 0) {
        return;
      } else {
        this.recatagorizeTransaction.showModal = true;
        this.recatagorizeTransaction.transaction = transaction;
        const selectedBudget = transaction.paymentRecategorizationDropdownOptions.find(
          x => x.id == 0
        );
        this.recatagorizeTransaction.selectedBudget = selectedBudget;
      }
    },
    submitRecatagorizeTransaction() {
      var startTime = new Date();
      this.$set(this.recatagorizeTransaction, "loading", true);
      const cardActivityToken = this.recatagorizeTransaction.transaction
        .cardActivityToken;
      WalletService.recatagorizeTransaction(
        cardActivityToken,
        this.magicLinkToken
      )
        .then(res => {
          console.log("Response from recat ", res);

          var timeDiff = moment().diff(moment(startTime));
          if (timeDiff >= 1500) {
            timeDiff = 1500;
          }
          setTimeout(() => {
            this.$set(this.recatagorizeTransaction, "loading", false);
            if (res.error == undefined) {
              this.recatagorizeTransaction.success = true;
              // Set the activity to not display the dropdown
              // find activity index
              const index = this.userProfile.CardActivities.findIndex(x => {
                return (
                  x.cardActivityToken ==
                  this.recatagorizeTransaction.transaction.cardActivityToken
                );
              });
              // Set the dropdown value back to the one that isn't general
              this.$set(
                this.userProfile.CardActivities[index],
                "displayRecategorizationDropdown",
                false
              );
              // this.refreshWallet();
              this.$store.dispatch("refreshWallet");
            } else {
              if (res.error_code === "1070100") {
                this.recatagorizeTransaction.error = true;
                this.recatagorizeTransaction.insufficientFunds = true;
              } else {
                this.recatagorizeTransaction.error = true;
              }
            }
          }, 1500 - timeDiff);
        })
        .catch(error => {
          console.log(error);
          this.$set(this.recatagorizeTransaction, "loading", false);
          this.recatagorizeTransaction.error = true;
        });
    },
    closeRecatagorizationTransaction() {
      // find activity index
      const index = this.userProfile.CardActivities.findIndex(x => {
        return (
          x.cardActivityToken ==
          this.recatagorizeTransaction.transaction.cardActivityToken
        );
      });
      // Find option that isn't general
      const value = this.userProfile.CardActivities[
        index
      ].paymentRecategorizationDropdownOptions.find(tx => tx.id != 0);
      // Set the dropdown value back to the one that isn't general
      this.$set(
        this.userProfile.CardActivities[index],
        "recategorizationDropdownValue",
        value
      );
      // console.log('setting card activity dropdown value!');
      // console.log(this.userProfile.CardActivities[index]);
      this.recatagorizeTransaction = {
        showDrawer: false,
        showModal: false,
        transaction: null,
        selectedBudget: null,
        success: false,
        error: false,
        insufficientFunds: false
      };
    },
    // Passes in an array of objects to recatagorize a transaction, we want to find the one that isn't 0
    initialDropdownOptionSelectValue(options) {
      return options.find(x => x.id != 0);
    },
    closeRecatagorizeTransactionDrawer() {
      this.recatagorizeTransaction.showDrawer = false;
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "balances",
      "cashBalance",
      "confettiPlayed",
      "magicLinkToken"
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    columns() {
      let col = this.transactionColumns;
      // if (this.isMobile) col[0].align = "end";
      // else col[0].align = "start";
      if (this.isModal) return col.filter(x => x.modal);
      // else if (this.isMobile) return col.filter(x => x.mobile !== false);
      return col;
    },
    transactions() {
      if (!this.userProfile || !this.userProfile.CardActivities) return [];
      var auth = this.userProfile.CardActivities;
      auth.forEach(a => {
        a.formattedDate = moment(a.date)
          .local()
          .format("MMM D, YYYY");
        if (a.displayRecategorizationDropdown == true) {
          a.recategorizationDropdownValue = a.paymentRecategorizationDropdownOptions.find(
            tx => {
              return tx.id != 0;
            }
          );
        }
      });
      return auth;
    },
    searchValue() {
      // if (this.search)
      return this.search;
      // return this.search.transactions;
    }
  },
  watch: {}
};
</script>

<style scoped>
.txn-table {
  /* border: solid 1px;
  border-color: lightgray; */
  border-radius: 20px;
  /* border-radius: 0px; */
}

.txn-table >>> .v-data-table__wrapper {
  border-radius: 20px 0 0 0;
  padding-top: 10px;
}

.txn-table >>> .v-data-table-header {
  background-color: white;
}

/* .txn-table
  >>> .v-data-table__wrapper
  > table
  > tbody
  > .v-data-table__mobile-table-row
  > .v-data-table__mobile-row:nth-of-type(2) {
  display: none;
} */

/* Removes header on mobile to sort columns */
.txn-table-mobile >>> .v-data-table__wrapper > table > thead {
  display: none;
}
</style>
