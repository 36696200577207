<template>
  <v-card
    ><v-toolbar dark color="primary" rounded="0">
      <v-toolbar-title
        >Send a
        <span v-if="programMessage != null">Program </span
        >Message</v-toolbar-title
      >
      <v-autocomplete
        outlined
        dense
        class=" ml-2 mt-5"
        style="max-width: 300px;"
        :items="clients"
        item-text="formattedName"
        item-value="clientId"
        label="Client"
        v-model="clientId"
        v-if="
          userProfile.clientId === 1 && !programMessage && !preSelectedAudience
        "
        @change="updateClientId"
      >
      </v-autocomplete>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="closeMessageCreator">
          Cancel
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div
      class="d-flex justify-center full-height"
      justify="center"
      height="100%"
    >
      <v-col
        md="3"
        lg="3"
        class="d-flex flex-column align-start full-height mr-6"
        v-if="largeVersion"
      >
        <v-btn
          text
          color="primary"
          v-if="slideKey > 1 || (programMessage != null && slideKey > 0)"
          @click="goToPreviousSlide"
          ><v-icon>mdi-chevron-left</v-icon>Back</v-btn
        >
        <p class="grey--text mt-8">Activity card preview</p>
        <ActivityFeedCard
          class="full-width"
          :icon="
            programMessage != null
              ? 'mdi-chart-box'
              : slide3.contextSelector == 'news'
              ? 'mdi-newspaper-variant-outline'
              : 'mdi-format-list-text'
          "
          :iconColor="
            programMessage != null
              ? 'brand'
              : slide3.contextSelector == 'news'
              ? 'brand-yellow'
              : 'brand-cyan'
          "
          :title="
            programMessage != null
              ? programMessage.displayName + ' program'
              : slide3.contextSelector == 'news'
              ? 'News update'
              : 'General activity'
          "
          :date="computedActivityCardDate"
          :messageTitle="slide1.title"
          :messageSubtitle="slide1.subtitle"
          :image="slide1.imageURL"
          :imageFlag="slide1.includeImageInCard"
          :messageTitleFlag="true"
          :messageSubtitleFlag="true"
          :linkText="
            programMessage != null ? 'Go to program' : 'Read the update'
          "
          :linkButtonFlag="true"
          :actionFlag="false"
          :disabled="true"
        />
        <v-checkbox
          v-if="slide1.imageURL !== null && slide1.imageURL.length > 0"
          v-model="slide1.includeImageInCard"
          label="Include image in activity card"
        ></v-checkbox>
        <!-- <span v-if="programMessage != null" class="mt-2"
          >Visible to: <strong>{{ programMessage.numRecipients }}</strong></span
        > -->
        <div
          v-if="!selectedFilters.length && programMessage == null"
          class="mt-2"
        >
          <span
            v-if="preSelectedAudience !== null && peopleOrGroups === 'groups'"
            class="mt-2"
            >Visible to:
            <strong
              >{{ peopleInSelectedGroups }} people in
              {{ preSelectedAudience.length }}
              {{ preSelectedAudience.length == 1 ? "group" : "groups" }}</strong
            ></span
          >
          <span
            v-else-if="
              preSelectedAudience !== null && peopleOrGroups === 'people'
            "
            class="mt-2"
            >Visible to:
            <strong
              >{{ preSelectedAudience.length }}
              {{ preSelectedAudience.length == 1 ? "person" : "people" }}
              selected</strong
            ></span
          >
          <span v-else-if="peopleOrGroups === 'groups'" class="mt-2"
            >Visible to:
            <strong
              >{{ peopleInSelectedGroups }} people in
              {{ slide2.selectedGroups.length }}
              {{
                slide2.selectedGroups.length == 1 ? "group" : "groups"
              }}</strong
            ></span
          >
          <span v-else-if="peopleOrGroups === 'people'" class="mt-2"
            >Visible to:
            <strong
              >{{ slide2.selectedPeople.length }}
              {{ slide2.selectedPeople.length == 1 ? "person" : "people" }}
              selected</strong
            ></span
          >
        </div>
        <!-- show selected filters -->
        <div v-if="slideKey > 0" class="full-width">
          <v-card
            rounded="0"
            elevation="4"
            class="mt-4"
            width="100%"
            min-height="70"
            v-for="filter in selectedFilters"
            :key="filter.key"
            @click="slideKey = 0"
          >
            <div class="d-flex justify-space-between mx-2 my-4 py-2">
              <span
                ><b class="ma-2 word-break align-text-left">
                  {{ filter.message }}
                </b></span
              >

              <div
                class="py-2 ma-2 rounded-lg text-align-center white--text card-letters "
                :class="filter.classes"
              >
                <b>{{ filter.audience.length }}</b>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col
        sm="12"
        :md="computedColumnSize"
        :lg="computedColumnSize"
        class="d-flex flex-column align-start"
      >
        <v-container class="mb-2 d-flex align-center justify-space-between">
          <!-- only show this first bubble if we are in a program message -->
          <div class="d-flex" :class="{ hidden: !programMessage }">
            <div
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer accent-background"
              @click="goToSlide(0)"
            ></div>
            <div
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              :class="{
                'accent-background': slideKey >= 1,
                'grey-background': 1 > slideKey
              }"
              @click="goToSlide(1)"
            ></div>
            <div
              v-if="preSelectedAudience === null && !programMessage"
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              @click="goToSlide(2)"
              :class="{
                'accent-background': slideKey >= 2,
                'grey-background': 2 > slideKey
              }"
            ></div>
            <div
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              @click="goToSlide(3)"
              :class="{
                'accent-background': slideKey >= 3,
                'grey-background': 3 > slideKey
              }"
            ></div>
          </div>
          <v-btn
            color="primary"
            @click="goToNextSlide"
            :disabled="disableContinueButton"
            >Continue</v-btn
          >
        </v-container>

        <div v-if="slideKey === 0">
          <div class="align-text-left mb-5">
            <span class="header-text"
              >Choose the audience for your message</span
            >
          </div>
          <!-- <v-card
            rounded="0"
            elevation="4"
            class="mt-4"
            width="100%"
            min-height="70"
          >
            <div class="d-flex justify-space-between mx-2 my-4 py-2">
              <span
                ><b class="ma-2 word-break align-text-left">
                  Everyone in Program
                </b></span
              >

              <div
                class="py-2 ma-2 rounded-lg text-align-center white--text card-letters medium-grey-background "
              >
                <b>{{ filters.filterAll.audience.length }}</b>
              </div>
            </div>
          </v-card> -->
          <v-card
            rounded="0"
            elevation="4"
            class="mt-4"
            width="100%"
            min-height="70"
            v-for="filter in filters"
            :key="filter.key"
            @click="filterSelect(filter)"
            :class="{ 'blue lighten-4': filter.selected == true }"
            :disabled="!filter.audience.length"
          >
            <div class="d-flex justify-space-between mx-2 my-4 py-2">
              <span
                ><b class="ma-2 word-break align-text-left">
                  {{ filter.message }}
                </b></span
              >

              <div
                class="py-2 ma-2 rounded-lg text-align-center white--text card-letters "
                :class="filter.classes"
              >
                <b>{{ filter.audience.length }}</b>
              </div>
            </div>
          </v-card>
          <!-- <v-card
            rounded="0"
            elevation="4"
            class="mt-4"
            width="100%"
            min-height="70"
          >
            <div class="d-flex justify-space-between mx-2 my-4 py-2">
              <span
                ><b class="ma-2 word-break align-text-left">
                  Users who have not started
                </b></span
              >

              <div
                class="py-2 ma-2 rounded-lg text-align-center white--text card-letters pink lighten-2 "
              >
                <b>{{ filterNotStarted.length }}</b>
              </div>
            </div>
          </v-card>
          <v-card
            rounded="0"
            elevation="4"
            class="mt-4"
            width="100%"
            min-height="70"
          >
            <div class="d-flex justify-space-between mx-2 my-4 py-2">
              <span
                ><b class="ma-2 word-break align-text-left">
                  Users who have started but not finished
                </b></span
              >

              <div
                class="py-2 ma-2 rounded-lg text-align-center white--text card-letters blue lighten-2 "
              >
                <b>{{ filterStarted.length }}</b>
              </div>
            </div>
          </v-card>
          <v-card
            rounded="0"
            elevation="4"
            class="mt-4"
            width="100%"
            min-height="70"
          >
            <div class="d-flex justify-space-between mx-2 my-4 py-2">
              <span
                ><b class="ma-2 word-break align-text-left">
                  Users who have completed
                </b></span
              >

              <div
                class="py-2 ma-2 rounded-lg text-align-center white--text card-letters green lighten-2 "
              >
                <b>{{ filterCompleted.length }}</b>
              </div>
            </div>
          </v-card> -->
        </div>
        <div v-if="slideKey === 1">
          <div class="align-text-left mb-5">
            <span class="header-text"
              >Let's build a message to share<span
                v-if="programMessage != null"
              >
                with your program
              </span></span
            >
          </div>
          <v-card
            class="d-flex flex-column align-start full-width pa-5"
            rounded="0"
            elevation="4"
          >
            <v-form ref="slide1Form" v-model="slide1.valid" class="full-width">
              <div class="d-flex align-center">
                <v-text-field
                  ref="titleInput"
                  v-model="slide1.title"
                  class="mt-5 mb-2"
                  outlined
                  label="Title"
                  :required="true"
                  :rules="[
                    v => !!v || 'A title is required!',
                    v =>
                      !!(v && 100 > v.length) ||
                      'Try and make your title shorter!'
                  ]"
                ></v-text-field>

                <v-icon
                  dark
                  v-if="largeVersion"
                  tabindex="-1"
                  @click="focusOnTitle"
                  class="border-radius pa-2 ml-4 mb-5 cursor-pointer"
                  :class="{
                    'accent-background':
                      slide1.title !== null && slide1.title.length > 0,
                    'grey-background':
                      slide1.title === null ||
                      (slide1.title !== null && slide1.title.length === 0)
                  }"
                  >mdi-pencil</v-icon
                >
              </div>
              <div class="d-flex align-center mb-4">
                <v-text-field
                  ref="subtitleInput"
                  v-model="slide1.subtitle"
                  outlined
                  label="Subtitle (optional)"
                  :rules="[
                    v =>
                      !!(!v || v.length < 255) ||
                      'Your subtitle should be shorter'
                  ]"
                ></v-text-field>
                <v-icon
                  dark
                  v-if="largeVersion"
                  tabindex="-1"
                  @click="focusOnSubtitle"
                  class="border-radius pa-2 ml-4 cursor-pointer"
                  :class="{
                    'accent-background':
                      slide1.subtitle !== null && slide1.subtitle.length > 0,
                    'grey-background':
                      slide1.subtitle === null ||
                      (slide1.subtitle !== null && slide1.subtitle.length === 0)
                  }"
                  >mdi-pencil</v-icon
                >
              </div>
            </v-form>
            <v-menu max-width="260" offset-x offset-y absolute>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="d-flex align-center mt-5 full-width image-container"
                >
                  <v-img
                    :lazy-src="slide1.imageURL"
                    :src="slide1.imageURL"
                    :key="slide1.imageURL"
                    :aspect-ratio="16 / 9"
                    v-bind="attrs"
                    v-on="on"
                    :width="calculatedImageWidth"
                    class="cursor-pointer"
                    @drop.prevent="dragAndDropImageFileChanged"
                    @dragover.prevent
                  >
                    <template v-slot:placeholder>
                      <div class="grey-background full-height full-width">
                        <h3 class=" pt-5 primary--text">
                          Add a banner photo
                        </h3>

                        <p class="mt-10">
                          We suggest an image size of 740 x 416 pixels (Or any
                          image with a 16 : 9 aspect ratio).
                        </p>
                        <p>
                          JPG, PNG files supported
                        </p>
                      </div>
                    </template></v-img
                  >
                  <v-icon
                    dark
                    v-if="largeVersion"
                    v-bind="attrs"
                    tabindex="-1"
                    v-on="on"
                    class="border-radius pa-2 ml-4 mb-5 cursor-pointer"
                    :class="{
                      'accent-background': slide1.imageURL !== null,
                      'grey-background': slide1.imageURL === null
                    }"
                    >mdi-pencil</v-icon
                  >
                </div>
              </template>
              <v-list>
                <input
                  id="uploader"
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="imageFileChanged"
                  @blur="imageFileChanged"
                />
                <v-list-item @click="uploadImage">
                  <v-list-item-title class="primary--text align-text-left"
                    ><v-icon class="mr-2" color="black">mdi-upload</v-icon
                    >Upload from computer</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="slide1.dialogImageUrl = true">
                  <v-list-item-title class="primary--text align-text-left"
                    ><v-icon class="mr-4" color="black">mdi-link</v-icon>By
                    URL</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="removeImage">
                  <v-list-item-title class="primary--text align-text-left"
                    ><v-icon color="black" class="mr-2">mdi-delete</v-icon
                    >Remove file</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>

            <TextEditor
              :shortcodes="true"
              :programRelated="programMessage != null ? true : false"
              :programHasEndDate="
                programMessage != null &&
                programMessage.endDate === null &&
                programMessage.rollingProgramLength === null
                  ? false
                  : true
              "
              :editorContent="slide1.content"
              @update-message="slide1.content = $event"
            />
            <!-- <div class="d-flex align-start mt-8">
              <vue-editor
                class="editor full-width"
                v-model="slide1.content"
                :editorToolbar="slide1.customToolbar"
                ref="quillEditor"
              ></vue-editor>
              <v-icon
                dark
                v-if="largeVersion"
                class="border-radius pa-2 ml-4"
                tabindex="-1"
                :class="{
                  'accent-background':
                    slide1.content !== null && slide1.content.length > 0,
                  'grey-background':
                    slide1.content === null ||
                    (slide1.content !== null && slide1.content.length === 0),
                }"
                >mdi-pencil</v-icon
              >
            </div>
            <div class="d-flex align-start mt-8">
              <v-expansion-panels accordion class="d-flex">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >Shortcodes</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-chip @click="insertShortcode('user.firstName')"
                      >First Name</v-chip
                    >
                    <v-chip @click="insertShortcode('user.lastName')"
                      >Last Name</v-chip
                    >
                    <v-chip @click="insertShortcode('program.progressPercent')"
                      >Percent Complete</v-chip
                    >
                    <v-chip
                      @click="insertShortcode('program.progressPercentInverse')"
                      >Percent Left To Go</v-chip
                    >
                    <v-chip @click="insertShortcode('program.progressToward')"
                      >Progress Toward</v-chip
                    >
                    <v-chip @click="insertShortcode('program.userStatus')"
                      >User's Program Status</v-chip
                    >
                    <v-chip @click="insertShortcode('program.daysRemaining')"
                      >Program Days Remaining (x days)</v-chip
                    >
                    <v-chip @click="insertShortcode('program.hoursRemaining')"
                      >Program Hours Remaining (x hours)</v-chip
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div> -->
          </v-card>
        </div>
        <v-container
          v-else-if="slideKey === 2"
          class="d-flex flex-column align-start"
        >
          <div class="d-flex justify-space-between full-width">
            <span class="header-text mb-10">Who can read this message?</span>
            <v-text-field
              outlined
              placeholder="Search"
              single-line
              dense
              hide-details
              v-model="groupTable.debounceSearch"
              class="shrink"
            ></v-text-field>
          </div>
          <GroupTable
            v-if="peopleOrGroups === 'groups'"
            ref="group-table"
            :selected="slide2.selectedGroups"
            :search="groupTable.search"
            @select-item="addToSelected"
          />
          <v-data-table
            v-else-if="peopleOrGroups === 'people'"
            v-model="slide2.selectedPeople"
            :headers="slide2.peopleColumns"
            :items="slide2.people"
            :search="slide2.tableSearch"
            item-key="userId"
            show-select
            :items-per-page="10"
            class="elevation-1 full-width"
            :loading="slide2.loadingTable"
            loading-text="Loading... Please wait"
            no-data-text="No people available"
          >
            <template v-slot:[`item.profilePicUrl`]="{ item }">
              <div>
                <v-list-item>
                  <v-list-item-avatar size="40" class="mx-auto">
                    <img :src="item.profilePicUrl" />
                  </v-list-item-avatar>
                </v-list-item>
              </div>
            </template>
            <template v-slot:[`item.tableDisplayName`]="{ item }">
              <span class="cursor-pointer selectable-blue-text">{{
                item.tableDisplayName
              }}</span>
              <br />
              <span class="cursor-pointer">{{
                item.businessEmail || item.businessPhone
              }}</span>
            </template>
          </v-data-table>
        </v-container>
        <v-container
          v-else-if="slideKey === 3"
          class="d-flex flex-column align-start"
        >
          <span class="header-text mb-5 align-text-left" v-if="!programMessage"
            >How do you want this message distributed?</span
          >
          <!-- <div class="pt-5 d-flex align-center" v-if="!programMessage">
            <v-select
              v-model="slide3.contextSelector"
              :items="slide3.contextItems"
              item-text="text"
              item-value="value"
              label="Choose the message type"
            />
          </div> -->
          <div class="pt-5 d-flex align-center">
            <v-checkbox
              v-model="slide3.showOnPlatform"
              :hide-details="true"
              class="pa-0 ma-0"
            ></v-checkbox>
            <span>
              Show in activity feed and logs
            </span>
          </div>
          <!-- <div class="pt-5 d-flex align-center">
            <v-checkbox
              v-model="slide3.showOnDashboard"
              :hide-details="true"
              class="pa-0 ma-0"
            ></v-checkbox>
            <span>
              Post on a participant's personalized dashboard
            </span>
          </div> -->
          <div class="pt-5 d-flex align-center">
            <v-checkbox
              v-model="slide3.sendEmail"
              :hide-details="true"
              class="pa-0 ma-0"
            ></v-checkbox>
            <span>
              Send an email with the full message
            </span>
          </div>
          <div class="pt-5 d-flex align-center">
            <v-checkbox
              v-model="slide3.sendText"
              :hide-details="true"
              class="pa-0 ma-0"
            ></v-checkbox>
            <span>
              Send a text message with a link to the message
            </span>
          </div>
          <div class="pt-5 d-flex align-center">
            <v-checkbox
              v-model="slide3.sendPush"
              :hide-details="true"
              class="pa-0 ma-0"
            ></v-checkbox>
            <span>
              Send a push notification through our mobile app
            </span>
          </div>
        </v-container>
      </v-col>
    </div>
    <!-- Image URL dialog -->
    <v-dialog persistent v-model="slide1.dialogImageUrl" width="400">
      <v-card rounded="0">
        <v-card-title class="word-break">
          Please enter the URL of the image
        </v-card-title>
        <v-text-field
          v-model="slide1.imageURLTemp"
          class="my-4 mx-6"
          outlined
          dense
          label="Image URL"
        ></v-text-field>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeImageUrlDialog(false)">Cancel</v-btn>
          <v-btn text color="primary" @click="closeImageUrlDialog(true)"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Image Cropping dialog -->
    <v-dialog persistent v-model="slide1.dialogImageCropper" width="600">
      <v-card rounded="0" class="px-12">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-camera</v-icon>
          <v-card-title class="word-break px-0 mx-0">
            Please crop the image below to a 16 : 9 aspect ratio.
          </v-card-title>
        </div>
        <cropper
          ref="cropper"
          :src="slide1.imageURLToCrop"
          :stencil-props="{
            aspectRatio: 16 / 9
          }"
        />
        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn text @click="closeImageCropDialog(false)">Cancel</v-btn>
          <v-btn text color="primary" @click="closeImageCropDialog(true)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Confirmation dialog -->
    <v-dialog persistent v-model="dialogConfirmation" width="400">
      <v-card rounded="0">
        <v-card-title class="word-break" v-if="!slide3.showSuccess">
          Please confirm you are ready to publish
        </v-card-title>
        <v-card-title class="word-break" v-else>
          All done. Congratulations!
        </v-card-title>
        <p class="grey--text mt-8 align-text-left ml-4">
          Activity card preview
        </p>
        <ActivityFeedCard
          class="confirmation-page-card mx-auto mb-4"
          :icon="
            programMessage != null
              ? 'mdi-chart-box'
              : slide3.contextSelector == 'general'
              ? 'mdi-format-list-text'
              : 'mdi-newspaper-variant-outline'
          "
          :iconColor="
            programMessage != null
              ? 'brand'
              : slide3.contextSelector == 'general'
              ? 'brand-cyan'
              : 'brand-yellow'
          "
          :title="
            programMessage != null
              ? programMessage.displayName + ' program'
              : slide3.contextSelector == 'general'
              ? 'General activity'
              : 'News update'
          "
          :date="computedActivityCardDate"
          :messageTitle="slide1.title"
          :messageSubtitle="slide1.subtitle"
          :image="slide1.imageURL"
          :imageFlag="slide1.includeImageInCard"
          :messageTitleFlag="true"
          :messageSubtitleFlag="true"
          :linkText="
            programMessage != null
              ? 'Go to program'
              : slide3.contextSelector == 'general'
              ? 'Read the update'
              : 'Read the news update'
          "
          :linkButtonFlag="true"
          :actionFlag="false"
          :disabled="true"
        />
        <p v-if="messageBatchWarning" class="mb-8">
          {{ messageBatchWarning }}
        </p>
        <v-card-actions v-if="!slide3.showSuccess">
          <v-btn
            text
            color="primary"
            @click="dialogConfirmation = false"
            :disabled="slide3.submitting"
            ><v-icon>mdi-chevron-left</v-icon>Back</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            @click="createNewsArticle"
            :loading="slide3.submitting"
            >Publish</v-btn
          >
        </v-card-actions>
        <v-card-actions class="mt-10" v-else>
          <v-btn color="primary" @click="closeMessageCreator" class="mx-auto"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ActivityFeedCard from "@/components/ActivityFeedCard.vue";
import TextEditor from "@/components/TextEditor.vue";
import GroupTable from "@/components/data-table/Groups.vue";

import CommunicationService from "@/services/CommunicationService";
import ProgramService from "@/services/ProgramService.js";

import { debounce } from "@/shared_data/functions";

import momentTz from "moment-timezone";
import { mapState } from "vuex";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

// import { VueEditor, Quill } from "vue2-editor";

// Quill.register(Quill.import("attributors/style/direction"), true);
// Quill.register(Quill.import("attributors/style/align"), true);

export default {
  name: "MessageCreator",
  components: {
    ActivityFeedCard,
    TextEditor,
    Cropper,
    GroupTable
  },
  props: {
    trigger: Boolean,
    // Indicates if we're letting the admin select from people or groups
    peopleOrGroups: String,
    largeVersion: Boolean,
    preSelectedAudience: Array,
    // Used only if we're creating a program message. Otherwise null
    programMessage: Object,
    preselectedFilter: String
  },
  data() {
    return {
      slideKey: 1,
      progress: "44",
      dialogConfirmation: false,
      slide1: {
        title: null,
        subtitle: null,
        valid: false,
        dialogImageUrl: false,
        dialogImageCropper: false,
        imageURLTemp: null,
        image: null,
        imageURL: null,
        imageURLToCrop: null,
        imageURLFileName: null,
        includeImageInCard: false,
        selectingImageFlag: false,
        content: null
      },
      slide2: {
        groupColumns: [
          {
            text: "Group ID",
            align: "start",
            sortable: true,
            value: "groupId",
            width: "10%"
          },
          { text: "Group", value: "groupDisplayName", width: "40%" },
          { text: "Group Type", value: "groupType", width: "20%" },
          {
            text: "Participants",
            value: "numInGroup",
            width: "20%"
          }
        ],
        selectedGroups: [],
        loadingTable: false,
        groups: [],
        peopleColumns: [
          {
            text: " ",
            align: "start",
            sortable: false,
            value: "profilePicUrl",
            width: "0%"
          },
          { text: "Name", value: "tableDisplayName", width: "25%" },
          { text: "Organization", value: "organization", width: "20%" },
          { text: "Status", value: "status", width: "10%" },
          { text: "Role", value: "businessRole", width: "23%" },
          { text: "Last Active", value: "lastActive", width: "17%" }
        ],
        people: [],
        selectedPeople: [],
        tableSearch: null
      },
      filters: {
        filterAll: {
          key: "filterAll",
          selected: false,
          message: "Everyone in program",
          audience: [],
          classes: "medium-grey-background"
        },
        // filterNotQualified: {
        //   key: "filterNotQualified",
        //   selected: false,
        //   message: "Users who have not qualified",
        //   audience: [],
        //   classes: "pink lighten-4"
        // },
        filterNotStarted: {
          key: "filterNotStarted",
          selected: false,
          message: "Not started",
          audience: [],
          classes: "pink lighten-2"
        },
        filterStarted: {
          key: "filterStarted",
          selected: false,
          message: "Started",
          audience: [],
          classes: "blue lighten-2"
        },
        filterCompleted: {
          key: "filterCompleted",
          selected: false,
          message: "Completed",
          audience: [],
          classes: "green lighten-2"
        }
      },
      slide3: {
        submitting: false,
        showSuccess: false,
        showOnPlatform: true,
        showOnDashboard: false,
        flagAsAlert: false,
        sendEmail: true,
        sendText: false,
        sendPush: false,
        contextSelector: "general",
        contextItems: [
          { text: "News", value: "news" },
          { text: "General", value: "general" }
        ]
      },
      groupTable: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null,
        columns: [
          // {
          //   text: "Group ID",
          //   align: "center",
          //   sortable: true,
          //   value: "groupId",
          //   width: "12%"
          // },
          // { text: "Group", value: "groupName", width: "25%" },
          // { text: "", value: "add" },
          {
            text: "Group ID",
            align: "start",
            sortable: true,
            value: "groupId",
            width: "10%"
          },
          { text: "Group", value: "groupName", width: "40%" },
          { text: "Group Type", value: "groupType", width: "20%" },
          {
            text: "Participants",
            value: "numInGroup",
            width: "20%",
            sortable: false
          }
        ]
      }
    };
  },
  created() {
    console.log("Created");
    console.log("Audience preselected: ", this.preSelectedAudience);
    if (
      this.preSelectedAudience !== null &&
      this.preSelectedAudience.length > 0
    ) {
      console.log("Audience preselected: ", this.preSelectedAudience);
    } else {
      if (this.peopleOrGroups === "groups") {
        // this.getGroups();
        // this.getGroupsV2(true);
        console.log(
          "Creating message for groups. We will load groups when they get to that slide"
        );
      }
      // else {
      //   this.getPeople();
      // }
    }
    //start on slide 0 for program messages (the participant filter)
    console.log("program message");
    console.log(this.programMessage);
    if (this.programMessage) {
      //this is a program message
      this.slideKey = 0;
      ProgramService.getProgramCacheByProgram(
        this.programMessage.programId
      ).then(programCache => {
        console.log("Program Cahce");
        console.log(programCache);

        //if there are multiple rule groups, users will show up multiple time in the cache
        //this loop should dedupe the cache
        var cleaned = [];
        programCache.forEach(function(itm) {
          if (!cleaned.find(x => x.userId == itm.userId)) cleaned.push(itm);
        });

        programCache = cleaned;

        programCache.forEach(function(cache) {
          //everyone goes into "all"
          this.filters.filterAll.audience.push(cache);
          // cache.progressPercent = parseFloat(cache.progressPercent);

          //the rest filter by progress. Could add not qualified to this too
          // if (!cache.qualified) {
          //   this.filters.filterNotQualified.audience.push(cache);
          // } else
          if (cache.progressPercent == 0) {
            this.filters.filterNotStarted.audience.push(cache);
          } else if (cache.progressPercent >= 100) {
            this.filters.filterCompleted.audience.push(cache);
          } else if (cache.progressPercent > 0) {
            this.filters.filterStarted.audience.push(cache);
          }
        }, this);
        if (this.preselectedFilter) {
          switch (this.preselectedFilter) {
            case "filterAll":
              this.filters.filterAll.selected = true;
              this.slideKey = 1;
              break;
            case "filterNotStarted":
              this.filters.filterNotStarted.selected = true;
              this.slideKey = 1;
              break;
            case "filterStarted":
              this.filters.filterStarted.selected = true;
              this.slideKey = 1;
              break;
            case "filterCompleted":
              this.filters.filterCompleted.selected = true;
              this.slideKey = 1;
              break;
          }
        }
      });
    } else {
      this.slideKey = 1;
    }
  },
  methods: {
    updateEditorContent(content) {
      this.slide1.content = content;
    },
    closeMessageCreator() {
      if (this.slideKey === 1) {
        this.$refs.slide1Form.reset();
        this.slide1 = {
          title: null,
          subtitle: null,
          dialogImageUrl: false,
          imageURLTemp: null,
          image: null,
          imageURL: null,
          includeImageInCard: false,
          selectingImageFlag: false,
          content: null
        };
      } else {
        this.slideKey = 1;
        this.slide1 = {
          title: null,
          subtitle: null,
          dialogImageUrl: false,
          imageURLTemp: null,
          image: null,
          imageURL: null,
          includeImageInCard: false,
          selectingImageFlag: false,
          content: null
        };
      }

      this.slide2.selectedGroups = [];
      this.slide2.loadingTable = false;
      this.slide2.tableSearch = null;
      this.slide2.selectedPeople = [];

      this.slide3.submitting = false;
      this.slide3.showSuccess = false;
      this.slide3.showOnDashboard = false;
      this.slide3.showOnPlatform = true;
      this.slide3.sendEmail = false;
      this.slide3.sendText = false;
      this.slide3.sendPush = false;

      //reset filters
      this.filters.filterAll.audience = [];
      this.filters.filterStarted.audience = [];
      this.filters.filterNotStarted.audience = [];
      this.filters.filterCompleted.audience = [];
      this.filters.filterStarted.audience = [];

      this.filters.filterAll.selected = false;
      this.filters.filterStarted.selected = false;
      this.filters.filterNotStarted.selected = false;
      this.filters.filterCompleted.selected = false;
      this.filters.filterStarted.selected = false;

      this.$emit("close-dialog");
    },
    goToSlide(index) {
      if (index === 0) {
        this.slideKey = 0;
      } else if (index === 1) {
        if (this.programMessage) {
          if (this.selectedAudience.length > 0) {
            this.slideKey = 1;
          } else {
            this.slideKey = 0;
          }
        } else {
          this.slideKey = 1;
        }
      } else if (
        index === 2 &&
        this.slide1.valid &&
        this.slide1.content !== null &&
        this.slide1.content.length > 0
      ) {
        this.slideKey = 2;
      } else if (
        index === 3 &&
        this.slide1.valid &&
        this.slide1.content !== null &&
        this.slide1.content.length > 0 &&
        ((this.programMessage != null && this.selectedAudience.length) ||
          (this.slide2.selectedGroups.length > 0 &&
            this.peopleOrGroups === "groups") ||
          (this.slide2.selectedPeople.length > 0 &&
            this.peopleOrGroups === "people") ||
          this.preSelectedAudience !== null)
      ) {
        this.slideKey = 3;
      }
    },
    goToNextSlide() {
      if (this.slideKey === 3) {
        //We're on the final slide so show confirmation
        this.dialogConfirmation = true;
      } else {
        if (this.preSelectedAudience === null && !this.programMessage) {
          //Things are normal
          var i = this.slideKey + 1;
          this.slideKey = i;
        } else {
          //If preSelectedAudience is defined, we are coming from people or groups, so we skip slide 2
          //Only other option is slideKey = 1, so we jump to 3
          if (this.slide1.content == null) {
            this.slideKey = 1;
          } else {
            this.slideKey = 3;
          }
        }
      }
    },
    goToPreviousSlide() {
      if (
        this.preSelectedAudience != null ||
        (this.programMessage && this.slideKey == 3)
      ) {
        // The audience is defined so we skip slide 2
        this.slideKey = 1;
      } else {
        var i = this.slideKey - 1;
        this.slideKey = i;
      }
    },
    focusOnTitle() {
      this.$refs.titleInput.focus();
    },
    focusOnSubtitle() {
      this.$refs.subtitleInput.focus();
    },
    filterSelect(filter) {
      if (filter.key == "filterAll") {
        //toggle for All
        if (filter.selected == true) {
          filter.selected = false;
        } else {
          filter.selected = true;
          //toggle the rest off
          // this.filters.filterNotQualified.selected = false;
          this.filters.filterNotStarted.selected = false;
          this.filters.filterStarted.selected = false;
          this.filters.filterCompleted.selected = false;
        }
      } else {
        //normal toggle
        if (filter.selected == true) {
          filter.selected = false;
        } else {
          filter.selected = true;
          this.filters.filterAll.selected = false; //toggle all off
        }
      }

      console.log(filter);
    },
    closeImageUrlDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      var url = this.slide1.imageURLTemp;
      if (submitPressed) {
        if (url == "" || url == null || url.replace(/\s+/g, "").length == 0) {
          this.slide1.imageURLTemp = null;
        } else {
          this.slide1.imageURL = url;
          this.slide1.image = null;
        }
      }
      //Regardless, we close dialog
      this.slide1.dialogImageUrl = false;
      this.slide1.imageURLTemp = null;
    },
    closeImageCropDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      if (submitPressed) {
        const { canvas } = this.$refs.cropper.getResult();
        this.slide1.imageURL = canvas.toDataURL();
        canvas.toBlob(blob => {
          // Do something with blob: upload to a server, download and etc.
          var file = new File([blob], this.slide1.imageURLFileName);
          this.slide1.image = file;

          //Regardless, we close dialog
          this.slide1.dialogImageCropper = false;
          this.slide1.imageURLToCrop = null;
          this.slide1.imageURLFileName = null;
        });
      } else {
        //Regardless, we close dialog
        this.slide1.dialogImageCropper = false;
        this.slide1.imageURLToCrop = null;
        this.slide1.imageURLFileName = null;
      }
    },
    uploadImage() {
      this.slide1.selectingImageFlag = true;
      window.addEventListener(
        "focus",
        () => {
          this.slide1.selectingImageFlag = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    imageFileChanged(e) {
      if (e.target.files.length > 0) {
        this.slide1.imageURLFileName = e.target.files[0].name;
        this.slide1.imageURLToCrop = URL.createObjectURL(e.target.files[0]);
        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        document.querySelector("#uploader").value = "";
        this.slide1.dialogImageCropper = true;
      }
    },
    dragAndDropImageFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        // Load file into temp variable for cropping
        this.slide1.imageURLToCrop = URL.createObjectURL(
          e.dataTransfer.files[0]
        );
        this.slide1.imageURLFileName = e.dataTransfer.files[0].name;

        this.slide1.dialogImageCropper = true;
      }
    },
    removeImage() {
      this.slide1.image = null;
      this.slide1.imageURL = null;
    },
    async createNewsArticle() {
      this.slide3.submitting = true;
      //We convert the current date/time into UTC
      console.log(
        momentTz()
          .utc()
          .format()
      );
      var startDate = momentTz()
        .utc()
        .format();

      //We convert the expiration date/time into UTC
      //Default is 1 year after sendDate
      console.log(
        momentTz()
          .add(1, "Y")
          .utc()
          .format()
      );
      var endDate = momentTz()
        .add(1, "Y")
        .utc()
        .format();

      var imageURL = this.slide1.imageURL;
      var image = this.slide1.image;
      if (imageURL !== null && imageURL.length === 0) {
        imageURL = null;
      }

      //We have to upload the image if one was uploaded
      if (image !== null && imageURL !== null) {
        //The user uploaded an image, so we uplaod that and wait for its reponse
        imageURL = await CommunicationService.uploadImage(image);
      } else if (image === null && imageURL === null) {
        imageURL = null;
        this.slide1.includeImageInCard = false;
      }

      //Ok now that the dates have been figured out, we need to manually add the title, subtitle, and image to the html
      // var newHtml = `<h1 id="title" style="text-align:center;">${this.slide1.title}</h1>`;
      // if (this.slide1.subtitle !== null) {
      //   newHtml =
      //     newHtml +
      //     `<h2 id="subtitle" style="text-align:center;">${this.slide1.subtitle}</h2>`;
      // }
      // if (imageURL !== null) {
      //   newHtml =
      //     newHtml +
      //     `<table align='center' border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 600px'><tr><td align='center' valign='top' style='padding: 40px 10px 40px 10px'><img id="img" alt="Image specified by sender" width="600px" height="337px" style='min-width: 600px; width: 600px;max-width:600px;height:337px;max-height:337px;object-fit: cover;display:block;margin-left:auto;margin-right:auto;' src='${imageURL}' /></td></tr></table>`;
      // }

      // var finalHtml = newHtml + this.slide1.content;

      if (this.preSelectedAudience === null && !this.programMessage) {
        if (this.peopleOrGroups === "people") {
          var audience = this.slide2.selectedPeople.map(x => x.userId || x);
        } else {
          audience = this.slide2.selectedGroups.map(x => x.groupId || x);
        }
      } else {
        if (!this.programMessage) {
          //Coming from people or groups
          audience = this.preSelectedAudience;
          console.log("used preselected audience");
        } else {
          //we're using filters in a programs comm
          audience = this.selectedAudience;
          console.log("used filtered audience");
          console.log(audience);
        }
      }

      var stringGroupIds = null;
      if (this.peopleOrGroups === "people") {
        console.log("people...");
        //array map to get paxId, otherwise it sends an array or participant objects
        audience = audience.map(x => x.userId || x);
        audience = [...new Set(audience)]; //removes dupe users
      } else if (!this.programMessage) {
        // if (this.programMessage) {
        //   stringGroupIds = this.programMessage.audience.groups.join();

        //array map to get groupId, otherwise it sends an array or group objects
        stringGroupIds = audience.map(x => x.groupId || x).join();
      }
      // }

      console.log("audience:");
      console.log(audience);

      var metadata = null;

      var context = "news";
      if (this.programMessage) {
        context = "program";
      } else if (this.slide3.contextSelector == "general") {
        context = "general";
      }

      var message = {
        clientId: this.programMessage
          ? this.programMessage.clientId
          : this.clientId,
        context: context,
        subcontext: context == "program" ? "dashboard" : null,
        contextResourceId:
          this.programMessage != null ? this.programMessage.programId : null,
        messageSubject: this.slide1.title,
        messageShortBody: this.slide1.subtitle,
        messageBody: this.slide1.content, //finalHtml
        displayOnPlatform: this.slide3.showOnPlatform,
        sendEmail: this.slide3.sendEmail,
        sendSMS: this.slide3.sendText,
        sendPush: this.slide3.sendPush,
        sendDashboard: this.slide3.showOnPlatform,
        // displayOnPlatform: this.slide3.showOnDashboard,
        selectedGroups: stringGroupIds,
        sendDate: startDate,
        expirationDate: endDate,
        imageURL: imageURL,
        displayImageInCardPreview: this.slide1.includeImageInCard,
        createdBy: this.userProfile.userId,
        updatedBy: this.userProfile.userId,
        messageMetadata: metadata
      };

      if (this.peopleOrGroups == "people") message.audience = audience;
      else message.groups = audience;

      console.log(message.messageBody);
      console.log(message);
      //Need to add check if we're looking at people or groups so we know which endpoint to hit
      // if (this.peopleOrGroups === "people") {
      CommunicationService.sendMessageV2(message)
        .then(x => {
          console.log(x);
          this.slide3.submitting = false;
          this.slide3.showSuccess = true;
        })
        .catch(error => {
          console.log("Error sending email! ", error);
          this.slide3.submitting = false;
        });
      // } else {
      //   CommunicationService.sendMessageByGroup(message)
      //     .then((x) => {
      //       console.log(x);
      //       this.slide3.submitting = false;
      //       this.slide3.showSuccess = true;
      //     })
      //     .catch((error) => {
      //       console.log("Error sending email! ", error);
      //     });
      // }
    },
    updateClientId() {
      this.slide2.selectedGroups = [];
    },
    async addToSelected(group, dontRemove = false) {
      console.log("Adding group to selected array", group);
      // Added because we can only pass one param in from the groups table
      if (group.dontRemove) dontRemove = true;
      let idx = this.slide2.selectedGroups.findIndex(
        x => x.groupId == group.groupId
      );
      if (idx == -1) this.slide2.selectedGroups.push(group);
      else if (!dontRemove) this.removeFromSelected(idx);
    },
    removeFromSelected(index) {
      // var user = this.values.selected[index];
      this.slide2.selectedGroups.splice(index, 1);
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    computedActivityCardDate() {
      return momentTz()
        .local()
        .format("YYYY-MM-DD h:mm a");
    },
    disableContinueButton() {
      if (this.slideKey === 0 && this.selectedFilters.length) {
        return false;
      } else if (
        this.slideKey === 1 &&
        this.slide1.valid &&
        this.slide1.content !== null &&
        this.slide1.content.length > 0
      ) {
        return false;
      } else if (
        this.slideKey === 2 &&
        ((this.slide2.selectedGroups.length > 0 &&
          this.peopleOrGroups === "groups") ||
          (this.slide2.selectedPeople.length > 0 &&
            this.peopleOrGroups === "people"))
      ) {
        return false;
      } else if (
        this.slideKey === 3 &&
        (this.slide3.showOnPlatform ||
          this.slide3.showOnDashboard ||
          this.slide3.sendEmail ||
          this.slide3.sendText ||
          this.slide3.sendPush ||
          this.slide3.flagAsAlert)
      ) {
        return false;
      } else {
        return true;
      }
    },
    peopleInSelectedGroups() {
      if (
        this.programMessage &&
        this.selectedAudience &&
        Array.isArray(this.selectedAudience)
      )
        return this.selectedAudience.length;
      if (this.peopleOrGroups != "groups") return 0;
      let count = 0;
      let aud = [];
      // if (this.preSelectedAudience !== null) {
      (this.preSelectedAudience !== null
        ? this.preSelectedAudience
        : this.slide2.selectedGroups
      ).forEach(o => {
        if (o.numUsers) count += o.numUsers;
        else if (o.GroupAssignments) aud = aud.concat(o.GroupAssignments);
      });

      if (count) return count;
      return [...new Set(aud)].length;
    },
    selectedFilters() {
      //var selected = this.filters.filter((item) => item.selected);
      var selected = [];
      for (const [key, value] of Object.entries(this.filters)) {
        if (value.selected) {
          console.log(key);
          selected.push(value);
        }
      }
      return selected;
    },
    selectedAudience() {
      var selected = [];
      for (const [key, value] of Object.entries(this.filters)) {
        if (value.selected && value.audience.length > 0) {
          console.log(key);
          console.log(value.audience);
          selected.push(value.audience);
        }
      }
      var selectedMerged = [].concat.apply([], selected);
      return selectedMerged;
    },
    computedColumnSize() {
      if (this.largeVersion) {
        return 6;
      } else {
        return 12;
      }
    },
    calculatedImageWidth() {
      // This is used for the image width because some images want to expand outside of the container, so I get that width and subtract the width of the edit icon
      // if (!this.mounted) {
      //   console.log("not mounted");
      //   return;
      // }
      if (document.getElementsByClassName("image-container").length > 0) {
        return (
          document.getElementsByClassName("image-container")[0].offsetWidth - 60
        );
      } else {
        return 600;
      }
    },
    messageBatchWarning() {
      const limit = Number(process.env.VUE_APP_MESSAGE_BATCH_LIMIT || 500);
      if (this.peopleInSelectedGroups < limit) return null;
      return "Due to the size of the recipient list, this message may take some extra time to be delivered.";
    }
  },
  watch: {
    trigger: function(newVal) {
      if (newVal !== null) {
        if (
          this.preSelectedAudience !== null &&
          this.preSelectedAudience.length > 0
        ) {
          console.log("Audience preselected: ", this.preSelectedAudience);
        } else {
          //We are loading the news creator again, so we reload
          if (this.peopleOrGroups === "groups") {
            console.log(
              "Creating message for groups. We will load groups when they get to that slide"
            );
          }
          // else if (this.peopleOrGroups === "people") {
          //   this.getPeople();
          // }
        }

        //start on slide 0 for program messages (the participant filter)
        console.log("program message");
        console.log(this.programMessage);
        if (this.programMessage) {
          this.slideKey = 0;
        } else {
          this.slideKey = 1;
        }
      }
    },
    "groupTable.debounceSearch": debounce(function(newVal) {
      this.groupTable.search = newVal;
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.shortcodes .v-chip {
  margin: 2px;
}
.card-letters {
  font-size: 25px;
  min-width: 50px;
}
.quillWrapper.editor {
  max-height: 100%;
}
.dark-blue-background {
  background-color: var(--v-brand-base);
}
.grey-background {
  background-color: var(--v-grey-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
.error-background {
  background-color: var(--v-error-base);
}
.primary-text {
  color: var(--v-primary-base);
}
.border-radius {
  border-radius: 100%;
}
.header-text {
  font-size: x-large;
}
.small-text {
  font-size: small;
}
.text-field-width {
  width: 400px;
}
.image-placeholder {
  background-color: var(--v-lightGrey-base);
}
.grey-background {
  background-color: var(--v-lightGrey-base);
}
.editor {
  max-height: 40vh;
}
.msg-body {
  width: 90%;
}
.confirmation-page-card {
  width: 90%;
}
</style>
