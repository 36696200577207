<template>
  <v-card flat>
    <v-toolbar dark color="brand" rounded="0">
      <v-btn dark icon @click="closeWindow">
        <v-icon large>mdi-close-circle</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>New Group</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-toolbar-items>
       
        <v-btn
          dark
          text
          @click="nextButtonClicked"
          v-if="!displaySimpleGroupResults"
          :disabled="selectedUserColumn === null"
        >
          Submit
        </v-btn>
        <v-btn
          dark
          text
          @click="createSimpleGroup"
          v-else
          :disabled="attributesForNewGroups.length == 0"
        >
          Select
        </v-btn>
      </v-toolbar-items> -->
    </v-toolbar>
    <v-divider />
    <v-card-text>
      <!-- <div class="d-flex flex-column full-width"> -->
      <h2 class="text-left mb-4 mx-4 mt-2">
        Create a simple group based on an attribute
      </h2>
      <p class="text-left mx-4 mt-3">
        Select a category from the drop down menu below and a list of attributes
        that can be used to define a group will be displayed
      </p>
      <p class="text-left mx-4 mt-3" v-if="source != 'groups'">
        If you need to create a different type of group, go to the
        <a @click="loadGroupsPage">Groups page</a>
      </p>
      <v-row justify="start" class="ml-4 mt-3" v-if="hasSubClients">
        <v-switch
          v-model="includeSubClients"
          :label="`Include Sub-Clients`"
          color="brandCyan"
          class="mb-2"
          @change="nextButtonClicked"
          hide-details
          :disabled="showRobinAnimation"
        ></v-switch>
        <p>
          Note: Groups that include sub-clients don't support external users
        </p></v-row
      >
      <v-row justify="center">
        <!-- <v-col class="d-flex flex-column mt-3" cols="12" sm="9" md="9" lg="9"> -->

        <v-select
          flat
          outlined
          v-model="selectedUserColumn"
          :items="userColumns"
          item-text="displayName"
          item-value="columnName"
          label="Category"
          solo
          class="mt-2 mx-6"
          @change="nextButtonClicked"
          color="brandCyan"
          dense
          :disabled="showRobinAnimation"
        ></v-select>
        <!-- </v-col> -->
      </v-row>
      <!-- <div class="d-flex align-center mb-5">
        <v-divider />
        <span class="mx-3">OR</span>
        <v-divider />
      </div> -->
      <!-- <h2 class="align-text-left mb-3">
        Create a complex group based on rules and attributes
      </h2>

      <v-row justify="center">
        <v-col class="d-flex flex-column mt-3" cols="12" sm="9" md="9" lg="9">
          <v-form ref="complexGroupForm" v-model="complexGroupForm.valid">
            <v-text-field
              outlined
              class="pa-1"
              v-model="complexGroup.groupDisplayName"
              :rules="[(v) => !!v || 'Group name is required']"
              label="Group name*"
            ></v-text-field>
            <v-switch
              class="pa-1 mb-2"
              v-model="complexGroup.includeSubClients"
              :label="`Include Sub-Clients`"
            ></v-switch>
            <v-btn color="primary" @click="loadRuleBuilder"
              >Launch Rule Builder</v-btn
            >
          </v-form>
        </v-col>
      </v-row> -->
    </v-card-text>

    <v-card-text v-if="displaySimpleGroupResults">
      <v-container fluid>
        <v-row class="d-flex flex-column align-center">
          <lottie
            v-if="showRobinAnimation"
            :options="lottieOptions"
            :height="110"
            :width="140"
            v-on:animCreated="handleAnimation"
          />
          <v-data-table
            v-if="showAttributeTable"
            v-model="attributesForNewGroups"
            :headers="groupColumns"
            :items="columnValues"
            item-key="value"
            show-select
            :single-select="true"
            :items-per-page="10"
            class="elevation-0 full-width table"
            loading-text="Loading groups... Please wait"
            color="brandCyan"
          >
            <template v-slot:[`item.value`]="{ item }">
              <span v-if="item.value != null">{{
                item.valuePretty || item.value
              }}</span>
              <span v-else
                >No
                {{
                  userColumns
                    .find(x => x.columnName == selectedUserColumn)
                    .displayName.toLowerCase()
                }}
                defined</span
              >
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="pb-8 d-flex justify-center">
      <v-btn
        color="brandCyan"
        class="white--text"
        :outlined="attributesForNewGroups.length == 0"
        depressed
        rounded
        width="190"
        @click="createSimpleGroup"
        :disabled="attributesForNewGroups.length == 0"
      >
        Add new group
      </v-btn>
    </v-card-actions>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </v-card>
</template>

<script>
import GroupService from "../../services/GroupService";
// import RulesService from "../services/RulesService";
// import LearningService from "../services/LearningService";
// import ProgramService from "../services/ProgramService";
import LoadingDialog from "@/components/loadingDialog.vue";
// import RuleBuilder from "@/components/RuleBuilderV3.vue";
import { mapState } from "vuex";

import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/robin/Eating.json";
import UserService from "../../services/UserService";

export default {
  name: "GroupCreatorWidget",
  components: {
    LoadingDialog,
    // RuleBuilder,
    Lottie
  },
  props: { clientId: Number, source: String, version: Number },
  data() {
    return {
      lottieOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true
      },
      anim: null,
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      userColumns: [
        { columnName: "status", displayName: "Status" },
        { columnName: "jobTitle", displayName: "Job Title" },
        { columnName: "businessCity", displayName: "Business City" },
        { columnName: "businessState", displayName: "Business State" },
        { columnName: "organization", displayName: "Organization" },
        { columnName: "clientId", displayName: "Client Name" },
        { columnName: "language", displayName: "Language" }
      ],
      groupColumns: [
        { text: "Attributes in this category", value: "value", width: "80%" },
        {
          text: "Participants",
          value: "participantCount",
          width: "20%",
          sortable: false,
          align: "center"
        }
      ],
      hasSubClients: false,
      includeSubClients: false,
      selectedUserColumn: null,
      displaySimpleGroupResults: false,
      showRobinAnimation: false,
      showAttributeTable: false,
      columnValues: [],
      metrics: [],
      modules: [],
      complexGroupForm: {
        valid: false,
        dialogRuleBuilder: false,
        ruleBuilderTrigger: false
      },
      complexGroup: {
        groupDisplayName: null,
        includeSubClients: false,
        rules: null,
        ruleName: null,
        ruleDescription: null,
        estimatedParticipants: 0
      },
      attributesForNewGroups: []
    };
  },
  created() {
    // this.getLearningModules();
    // this.getMetrics();
    if (!this.magicLinkToken)
      UserService.getClientsByClient(this.clientId)
        .then(response => {
          if (response.length > 0) {
            this.hasSubClients = true;
          }
        })
        .catch(error => {
          console.log("Error getting subclients", error.response || error);
        });
  },
  mounted() {},
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    getSimpleGroupColumnValues() {
      this.displaySimpleGroupResults = true;
      this.showRobinAnimation = true;
      this.showAttributeTable = false;
      this.attributesForNewGroups = [];

      GroupService.getSimpleGroupValues(
        this.selectedUserColumn,
        this.clientId,
        this.includeSubClients.toString(),
        this.magicLinkToken
      )
        .then(response => {
          console.log(response);

          this.columnValues = response;
          this.showRobinAnimation = false;
          this.showAttributeTable = true;
        })
        .catch(error => {
          console.log("Error!" + error.response);
          this.showLoadingDialog = false;
        });
    },
    async createSimpleGroup() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Creating Simple Group";
      this.loadingMessageLine1 = "Doing the complex part so you don't have to.";
      this.loadingMessageLine2 = "This should just take a minute.";
      console.log(
        "Creating " + this.attributesForNewGroups.length + " simple groups"
      );
      console.log(this.attributesForNewGroups);
      var groups = this.attributesForNewGroups.map(x => {
        let name =
          "Simple Group: " +
          this.userColumns
            .find(x => x.columnName == this.selectedUserColumn)
            .displayName.toLowerCase() +
          " is " +
          (x.value == null ? "empty" : x.valuePretty || x.value);
        return {
          clientId: this.clientId,
          status: "Active",
          groupName: name,
          groupDisplayName: name,
          displayName: name,
          groupType: "Simple",
          enrollmentRules: this.selectedUserColumn + ":" + x.value,
          hierarchyRules: null,
          includeSubClients: this.includeSubClients
        };
      });

      // Moved to v2 of API. Need to update this for all uses of this widget
      // if (this.version == 3) {
      try {
        const response = await GroupService.createGroupV2(
          groups,
          "simple",
          {},
          this.magicLinkToken
        );
        console.log("Group res ", response);
        let id = response?.result?.[0]?.groupId;
        this.getGroups(id);
        // this.getGroups(response?.result?.[0]);
      } catch (e) {
        console.log("Error creating simple group", e);
      } finally {
        this.showLoadingDialog = false;
        this.closeWindow();
      }
      return;
      // }
      // GroupService.createSimpleGroup(groups, this.magicLinkToken)
      //   .then(response => {
      //     console.log(response);

      //     if (this.version == 2) {
      //       groups.forEach((g, i) => {
      //         g.groupId = response[i].groupId;
      //         g.displayName = g.groupName;
      //       });
      //       this.getGroups(groups);
      //     } else this.getGroups([response[0].groupId]);
      //     this.showLoadingDialog = false;
      //     this.closeWindow();
      //   })
      //   .catch(error => {
      //     console.log("Error!" + error.response);
      //     this.showLoadingDialog = false;
      //   });
    },

    nextButtonClicked() {
      if (!this.selectedUserColumn) return;
      // if (this.complexGroup.groupDisplayName && this.complexGroup.rules) {
      //   //We create a complex group
      //   this.showLoadingDialog = true;
      //   this.loadingHeaderMessage = "Creating Complex Group";
      //   this.loadingMessageLine1 =
      //     "Doing the complex part so you don't have to.";
      //   this.loadingMessageLine2 = "This should just take a minute.";
      //   console.log("Creating complex group");
      //   var rules = this.complexGroup.rules;
      //   rules.name = this.complexGroup.ruleName;
      //   rules.description = this.complexGroup.ruleDescription;
      //   var obj = {
      //     clientId: this.clientId,
      //     groupName: this.complexGroup.groupDisplayName,
      //     groupDisplayName: this.complexGroup.groupDisplayName,
      //     includeSubClients: this.complexGroup.includeSubClients,
      //     rules: rules,
      //   };
      //   if (this.source) obj.source = this.source;
      //   GroupService.createComplexGroup(obj)
      //     .then((response) => {
      //       console.log(response.ruleGroup);
      //       this.getGroups(parseInt(response.ruleGroup.contextResourceId));
      //       this.closeWindow();
      //     })
      //     .catch((error) => {
      //       console.log("Error!" + error.ruleGroup);
      //       this.showLoadingDialog = false;
      //     });
      // } else if (this.selectedUserColumn !== null) {
      //We create a simple group so we have to fetch values
      this.getSimpleGroupColumnValues();
      // }
    },
    closeWindow() {
      this.selectedUserColumn = null;

      if (this.displaySimpleGroupResults) {
        this.complexGroup.groupDisplayName = null;
      }
      //  else {
      //   this.$refs.complexGroupForm.reset();
      // }
      // this.complexGroup.rules = null;
      // this.complexGroup.ruleName = null;
      // this.complexGroup.ruleDescription = null;
      this.displaySimpleGroupResults = false;
      this.$emit("close");
    },
    getGroups(prop) {
      this.$emit("get-groups", prop);
    },
    loadRuleBuilder() {
      this.complexGroupForm.dialogRuleBuilder = true;
      this.complexGroupForm.ruleBuilderTrigger = !this.complexGroupForm
        .ruleBuilderTrigger;
      this.complexGroup.ruleName = null;
      this.complexGroup.ruleDescription = null;
      this.complexGroup.rules = {
        rule: false,
        operator: "AND",
        id: 1,
        depth: 1,
        group: [],
        lastInGroup: true
      };
    },
    closeRuleBuilder(rules) {
      console.log(rules);
      this.complexGroupForm.dialogRuleBuilder = false;

      //If they clicked cancel, we don't overwrite rules
      // if (rules !== null) {
      this.complexGroup.rules = rules;
      // }
    },
    onRulesValueChanged(newVal) {
      this.complexGroup.rules = newVal;
    },
    onNameValueChanged(newVal) {
      this.complexGroup.ruleName = newVal;
    },
    onDescriptionValueChanged(newVal) {
      this.complexGroup.ruleDescription = newVal;
    },
    // getMetrics() {
    //   ProgramService.getMetrics()
    //     .then(response => {
    //       console.log("Metrics: ", response);
    //       var metrics = response
    //         .filter(
    //           metric =>
    //             metric.status !== "Deleted" &&
    //             (metric.clientId == this.clientId || metric.clientId == 0)
    //         )
    //         .map(x => {
    //           var obj = x;
    //           obj.id = x.metricId.toString();
    //           obj.context = "MetricDefinitions";
    //           obj.contextResourceId = x.metricId.toString();
    //           obj.contextColumn = "metricValue";
    //           obj.label = x.metricName;
    //           obj.dataType = x.dataType;
    //           obj.showNumberOptions =
    //             x.calculateProgress && x.dataType !== "BOOLEAN";
    //           return obj;
    //         });
    //       this.metrics = metrics;
    //     })
    //     .catch(error => {
    //       console.log("Error!" + error);
    //     });
    // },
    // getLearningModules() {
    //   LearningService.getAllModulesAdmin()
    //     .then((response) => {
    //       console.log("Learning Modules: ", response);
    //       var modules = response
    //         .filter(
    //           (module) =>
    //             module.status !== "Deleted" && module.clientId == this.clientId
    //         )
    //         .map((x) => {
    //           var obj = x;
    //           obj.id = x.learningModuleId.toString();
    //           obj.context = "LearningModules";
    //           obj.contextResourceId = x.learningModuleId.toString();
    //           obj.contextColumn = "metricValue";
    //           obj.label = "Module Progress: " + x.displayName.substring(0, 30);
    //           obj.dataType = "INTEGER";
    //           obj.showNumberOptions = true;
    //           return obj;
    //         });
    //       //Adds header because this array will be used to select metrics for a payoutRule
    //       this.modules = modules;
    //     })
    //     .catch((error) => {
    //       console.log("Error!" + error);
    //     });
    // },
    loadGroupsPage() {
      if (this.source == "PROGRAM_WIZARD" || this.source == "LEARNING_WIZARD") {
        this.$emit("push-to-groups");
      } else {
        this.$router.push({ name: "groups" });
      }
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "magicLinkToken"]),
    computedColumnDisplayName() {
      if (this.selectedUserColumn !== null) {
        return this.userColumns.find(
          x => x.columnName == this.selectedUserColumn
        ).displayName;
      } else {
        return null;
      }
    }
  },
  watch: {
    // "complexGroup.rules": {
    //   handler(newVal) {
    //     if (newVal !== null) {
    //       if (
    //         !(
    //           JSON.stringify(newVal).includes('"group":[]') ||
    //           JSON.stringify(newVal).includes('"contextColumn":null') ||
    //           JSON.stringify(newVal).includes('"operator":null') ||
    //           JSON.stringify(newVal).includes('"value":null') ||
    //           JSON.stringify(newVal).includes('"value":""')
    //         )
    //       ) {
    //         RulesService.estimateRuleGroup(
    //           [
    //             {
    //               context: "grouprules",
    //               audienceId: null,
    //               includeSubClients: this.complexGroup.includeSubClients
    //             }
    //           ],
    //           newVal,
    //           this.clientId
    //         ).then(rules => {
    //           this.complexGroup.estimatedParticipants = rules.filter(
    //             x => x.logic
    //           ).length;
    //         });
    //       }
    //     } else {
    //       this.complexGroup.estimatedParticipants = 0;
    //     }
    //   },
    //   deep: true
    // }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.table >>> .v-data-table-header {
  background-color: white;
}
</style>
