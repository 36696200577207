<template>
  <v-card flat elevation="0">
    <v-toolbar dark color="brand" rounded="0">
      <!-- <v-toolbar-items> -->
      <v-btn dark icon @click="closeWindow">
        <v-icon large>mdi-close-circle</v-icon>
      </v-btn>
      <!-- </v-toolbar-items> -->
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="!editingUser" class="d-flex align-center"
        ><v-icon class="mr-2 mt-n1">mdi-account-plus</v-icon>New
        person</v-toolbar-title
      >
      <v-toolbar-title v-else>Update a person</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden">
        <v-btn dark text @click="closeWindow">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <transition :name="userForm.transitionDirection" mode="out-in">
      <div v-if="activeSlide == keys.user" key="0" class="mt-10">
        <v-form
          ref="userForm"
          v-model="userForm.valid"
          class="full-width mt-6"
          onSubmit="return false;"
        >
          <v-card-text
            class="d-flex flex-column text-left align-center justify-center"
          >
            <v-autocomplete
              :items="clients"
              outlined
              dense
              attach
              item-text="formattedName"
              item-value="clientId"
              label="Client*"
              :disabled="editingUser"
              v-model="user.clientId"
              v-if="userProfile.clientId === 1 && !clientId"
              color="brandCyan"
            ></v-autocomplete>
            <div class="d-flex align-start text-field-row">
              <v-icon
                class="mr-3 mt-2"
                :color="userForm.validFirstName ? '#349E03' : 'red'"
                >{{
                  userForm.validFirstName
                    ? "mdi-check-circle"
                    : "mdi-close-circle"
                }}</v-icon
              >
              <v-form
                ref="userForm"
                v-model="userForm.validFirstName"
                class="full-width"
                onSubmit="return false;"
              >
                <v-text-field
                  v-model="user.firstName"
                  :rules="[
                    v => !!v || 'First name is required',
                    v =>
                      !!(v && v.length < 100) ||
                      'The first name should be shorter'
                  ]"
                  label="First name*"
                  required
                  outlined
                  dense
                  color="brandCyan"
                  @keyup.enter="createNewUser"
                ></v-text-field>
              </v-form>
            </div>
            <div class="d-flex align-start text-field-row">
              <v-icon
                class="mr-3 mt-2"
                :color="userForm.validLastName ? '#349E03' : 'red'"
                >{{
                  userForm.validLastName
                    ? "mdi-check-circle"
                    : "mdi-close-circle"
                }}</v-icon
              >
              <v-form
                ref="userForm"
                v-model="userForm.validLastName"
                class="full-width"
                onSubmit="return false;"
              >
                <v-text-field
                  v-model="user.lastName"
                  :rules="[
                    v => !!v || 'Last name is required',
                    v =>
                      !!(v && v.length < 100) ||
                      'The last name should be shorter'
                  ]"
                  label="Last name*"
                  outlined
                  dense
                  color="brandCyan"
                  @keyup.enter="createNewUser"
                ></v-text-field>
              </v-form>
            </div>
            <div class="d-flex align-start text-field-row">
              <v-icon
                class="mr-3 mt-2"
                :color="userForm.validEmail ? '#349E03' : 'red'"
                >{{
                  userForm.validEmail ? "mdi-check-circle" : "mdi-close-circle"
                }}</v-icon
              >
              <v-form
                ref="userForm"
                v-model="userForm.validEmail"
                class="full-width"
                onSubmit="return false;"
              >
                <v-text-field
                  v-model="user.businessEmail"
                  :rules="[
                    v => !!v || 'An email or phone number is required',
                    v =>
                      !!(v && v.length < 255) ||
                      'Your email/phone should be shorter',
                    v =>
                      !!(v && (emailRegex.test(v) || validPhone)) ||
                      'Your email/phone does not appear valid'
                  ]"
                  label="Email or Phone (include country code if non-US)"
                  outlined
                  dense
                  color="brandCyan"
                  @change="phoneValidation"
                  @input="phoneValidation"
                  @keyup.enter="createNewUser"
                ></v-text-field>
              </v-form>
            </div>
            <div class="d-flex align-start text-field-row">
              <v-icon
                class="mr-3 mt-2"
                :color="userForm.validCountry ? '#349E03' : 'red'"
                >{{
                  userForm.validCountry
                    ? "mdi-check-circle"
                    : "mdi-close-circle"
                }}</v-icon
              >
              <v-form
                ref="userForm"
                v-model="userForm.validCountry"
                class="full-width"
                onSubmit="return false;"
              >
                <v-select
                  :items="countries"
                  item-text="name"
                  item-value="code"
                  label="Business Country*"
                  v-model="user.businessCountry"
                  :rules="[v => !!v || 'Country is required']"
                  outlined
                  dense
                  color="brandCyan"
                  class="mr-0 pr-0"
                ></v-select>
              </v-form>
            </div>
            <div
              class="d-flex align-start text-field-row"
              v-if="user.businessCountry === '00'"
            >
              <v-icon
                class="mr-3 mt-2"
                :color="userForm.validOtherCountry ? '#349E03' : 'red'"
                >{{
                  userForm.validOtherCountry
                    ? "mdi-check-circle"
                    : "mdi-close-circle"
                }}</v-icon
              >
              <v-form
                ref="userForm"
                v-model="userForm.validOtherCountry"
                class="full-width"
                onSubmit="return false;"
              >
                <v-text-field
                  v-model="user.otherBusinessCountry"
                  :rules="[
                    v => !!v || 'Country code is required',
                    v =>
                      !!(v && v.length == 2) ||
                      'Country code should be 2 letters'
                  ]"
                  label="Enter country code here (2 digit code)*"
                  outlined
                  dense
                  color="brandCyan"
                  @keyup.enter="createNewUser"
                ></v-text-field>
              </v-form>
            </div>
          </v-card-text>
        </v-form>

        <v-card-actions class="pb-8 d-flex justify-center">
          <v-btn
            color="brandCyan"
            class="white--text"
            :outlined="!userForm.valid"
            depressed
            rounded
            width="190"
            @click="createNewUser"
            :disabled="
              !userForm.validFirstName ||
                !userForm.validLastName ||
                !userForm.validEmail ||
                !userForm.validCountry ||
                (user.businessCountry == '00' && !userForm.validOtherCountry)
            "
          >
            Add new contact
          </v-btn>
        </v-card-actions>
      </div>
      <div
        v-else-if="activeSlide == keys.loading"
        key="2"
        class="word-break mt-10 d-flex flex-column align-center text-center"
      >
        <v-card-text class="full-width pl-6">
          <h2>
            Creating the account...
          </h2>
        </v-card-text>

        <Robin
          :showText="false"
          :width="140"
          :height="140"
          animation="inflatableDance"
          :loop="true"
        />
      </div>
      <!-- Success message displayed -->
      <div v-else-if="activeSlide == keys.success" key="3">
        <!-- <v-card-title class="dialog-header" color="primary">
              Add a new person
            </v-card-title> -->
        <!-- <v-divider /> -->
        <v-card-text
          class="d-flex-column align-center justify-center mt-10 word-break"
        >
          <v-card-text class="full-width pl-6">
            <h2>
              Success!!
            </h2>
          </v-card-text>
          <div class="d-flex justify-center">
            <Robin
              :showText="false"
              :width="140"
              :height="140"
              animation="backflip"
              :loop="false"
              class="mb-8"
            />
          </div>
          <v-btn outlined color="brandCyan" @click="resetUserForm">
            <v-icon>mdi-plus</v-icon>Add more
          </v-btn>
          <v-btn
            class="ml-3 white--text"
            color="brandCyan"
            depressed
            @click="closeWindow"
          >
            Close
          </v-btn>
        </v-card-text>
      </div>
      <div v-else-if="activeSlide == keys.error" key="4">
        <v-card-text
          class="d-flex-column align-center justify-center mt-10 word-break"
        >
          <v-card-text class="full-width text-left pl-4">
            <h2 v-if="errorMessage">{{ errorMessage }}</h2>
            <h2 v-else>
              Looks like there was a problem creating your new person
            </h2>
          </v-card-text>
          <div class="justify-center">
            <p class="pl-4 full-width text-left">
              Feel free to reach out to customer service via one of the
              following methods...
            </p>
            <p class="pl-4 text-left full-width">
              Email -
              <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
            </p>
            <p class="pl-4 text-left full-width">
              Phone (Toll Free) - <b>(855) 264-3329</b>
            </p>
          </div>
          <v-btn
            outlined
            color="primary"
            @click="activeSlide = keys.user"
            class="mt-3"
          >
            Go back
          </v-btn>
          <v-btn
            class="ml-3 mt-3"
            color="primary"
            depressed
            @click="closeWindow"
          >
            Close
          </v-btn>
        </v-card-text>
      </div>
    </transition>
    <!-- <v-dialog v-model="errorDialog.display" width="500"> -->
    <ErrorDialog
      v-if="errorDialog.display"
      :title="errorDialog.title"
      :subtitle="errorDialog.subtitle"
      :button1Text="errorDialog.button1Text"
      :button2Text="errorDialog.button2Text"
      @button2="errorDialog.display = false"
      @close="errorDialog.display = false"
    />
  </v-card>
</template>

<script>
import OnboardingService from "../services/OnboardingService";
// import UserService from "../services/UserService";

import Robin from "@/components/Robin.vue";
import ErrorDialog from "@/components/ErrorDialog";

import { mapState } from "vuex";
import moment from "moment";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

import { countries, emailRegex } from "@/shared_data/data.js";

function initialState() {
  return {
    keys: {
      user: 0,
      bulk: 1,
      loading: 2,
      success: 3,
      error: 4
    },
    activeSlide: 0,
    e6: 1,
    createUserTab: 0,
    emailRegex,
    userForm: {
      valid: false,
      validFirstName: false,
      validLastName: false,
      validEmail: false,
      validPhone: false,
      validCountry: false,
      validOtherCountry: false,
      editing: false,
      transitionDirection: "topic-left",
      sendInviteToggle: true
    },
    user: {
      clientId: null,
      userId: null,
      firstName: null,
      lastName: null,
      userIdAtClient: null,
      profilePicUrl: null,
      originalBusinessEmail: null,
      businessEmail: null,
      emailDomain: null,
      businessPhone: null,
      formattedBusinessPhone: null,
      personalEmail: null,
      personalPhone: null,
      status: "Active",
      displayName: null,
      jobTitle: null,
      countryCode: null,

      birthday: null,
      roleStartDate: null,
      jobStartDate: null,
      businessAddress: null,
      businessCity: null,
      businessState: null,
      businessPostalCode: null,
      businessCountry: null,
      otherBusinessCountry: null,
      homeAddress: null,
      homeCity: null,
      homeState: null,
      homePostalCode: null,
      organization: null,
      token1: null,
      token2: null,
      token3: null,
      scheduledOnboardingDate: null,

      statusList: ["Active", "Inactive"]
    },
    countries: countries,
    passwordEmailLoading: false,
    passwordEmailSuccess: false,
    roleArray: [],

    // Used by the error component
    errorMessage: null,
    errorDialog: {
      display: false,
      override: false,
      title: null,
      subtitle: null,
      button1Text: null,
      button2Text: null
    }
  };
}

export default {
  name: "UserCreatorWidgetSimpleV2",
  components: {
    Robin,
    ErrorDialog
  },
  props: {
    clientId: Number,

    editingUser: Boolean,
    editUserObject: Object,
    source: String,
    external: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created");
    if (this.editingUser) {
      console.log("Loading in user object: ", this.editUserObject);
      this.user = JSON.parse(JSON.stringify(this.editUserObject));
      // if (this.budget.endDate != null) {
      //   this.budgetForm.endDateCheckBox = false;
      // }
    } else {
      // Preload country
      this.autofillForm();
    }
  },
  mounted() {},
  methods: {
    closeWindow() {
      this.resetUserForm();
      this.$emit("close");
    },
    getUsers(props = null) {
      this.$emit("get-users", props);
    },
    async createNewUser() {
      if (
        !this.userForm.validFirstName ||
        !this.userForm.validLastName ||
        !this.userForm.validEmail ||
        !this.userForm.validCountry ||
        (this.user.businessCountry == "00" && !this.userForm.validOtherCountry)
      )
        return;
      this.activeSlide = this.keys.loading;
      var businessCountryLocal = null;
      if (this.user.businessCountry === "00") {
        businessCountryLocal = this.user.otherBusinessCountry;
      } else {
        businessCountryLocal = this.user.businessCountry;
      }

      if (businessCountryLocal) {
        // If it exists, mark is as full uppercase
        businessCountryLocal = businessCountryLocal.toUpperCase();
      }

      var returnObj = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        displayName: this.user.firstName + " " + this.user.lastName,
        // Phone and email are tied to the same field. If we have a formatted number, that means we don't have an email
        businessEmail: this.formattedBusinessPhone
          ? null
          : this.user.businessEmail,
        businessPhone: this.formattedBusinessPhone,
        businessCountry: businessCountryLocal
      };
      if (this.userProfile.clientId === 1 && this.user.clientId !== null) {
        returnObj.clientId = this.user.clientId;
        //   var name = this.clients.find(x => x.clientId === this.user.clientId);
        //   returnObj.clientName = name.clientName;
      } else {
        returnObj.clientId = this.userProfile.clientId;
        //   returnObj.clientName = this.userProfile.Client.clientName;
      }

      // If the box isn't checked then we mark as null so we never send an email
      if (!this.userForm.sendInviteToggle)
        returnObj.scheduledOnboardingDate = null;
      else
        returnObj.scheduledOnboardingDate = moment(
          this.user.scheduledOnboardingDate
        )
          .utc()
          .format("YYYY-MM-DD HH:mm:ssZ");

      var source = "PEOPLE";
      if (this.source) source = this.source;
      returnObj.source = source;

      // For simple user creation we assume passwordless
      returnObj.passwordEnabled = false;
      returnObj.emailPasswordlessEnabled = !!returnObj.businessEmail;
      returnObj.smsPasswordlessEnabled = !!returnObj.businessPhone;
      if (returnObj.smsPasswordlessEnabled)
        returnObj.scheduledOnboardingDate = null;

      console.log("User created, ", returnObj);
      // UserService.getUserByEmail(returnObj.businessEmail).then(
      //   emailResponse => {
      //     var errorBool = false;
      //     // We get any users in the database with the selected email
      //     // If we find anyone that isn't this user, we show an error message
      //     for (var i = 0; i < emailResponse.length; i++) {
      //       if (emailResponse[i].userId != this.user.userId) {
      //         errorBool = true;
      //       }
      //     }
      //     if (errorBool) {
      //   console.log("Found user with matching email");
      //   this.errorDialog.display = true;
      //   this.errorDialog.title =
      //     "Uh oh! It looks like we already have a user with this email address.";
      //   this.errorDialog.subtitle = "Please try using another email";
      //   this.errorDialog.button2Text = "Close";
      //   this.activeSlide = this.keys.user;
      // } else {
      try {
        let response = await OnboardingService.createUserV2(
          returnObj,
          {
            wait: "true",
            source,
            // If no external users allowed then we'll error
            screen: this.external ? source : undefined
          },
          this.magicLinkToken
        );
        // .then(response => {
        console.log("User creation response: ", response.result);
        const createdUser =
          response && response.result && response.result.length
            ? response.result[0]
            : undefined;
        if (!createdUser || createdUser.invalid || createdUser.failed)
          throw {
            invalid: !createdUser
              ? "Failed to create the user"
              : createdUser.invalidReason || createdUser.failedReason
          };
        this.activeSlide = this.keys.success;
        this.getUsers(response.result[0]);
        // })
        // .catch(error => {
        //   console.log("Error creating user: ", error);
        //   this.activeSlide = this.keys.error;
        // });
      } catch (err) {
        console.log("Error creating user ", err);
        this.activeSlide = this.keys.error;
        if (err && err.invalid) this.errorMessage = err.invalid;
        else this.errorMessage = "There was a problem adding the new person";
      }
      // }
      // }
      // );
    },
    resetUserForm() {
      Object.assign(this.$data, initialState());
      this.autofillForm();
    },
    autofillForm() {
      var country = this.userProfile.businessCountry;
      if (countries.map(x => x.code).includes(country)) {
        this.user.businessCountry = country;
      } else {
        this.user.businessCountry = "00";
        this.user.otherBusinessCountry = country;
      }

      if (this.clientId) {
        console.log("Setting clientId to predefined");
        this.user.clientId = this.clientId;
      } else {
        console.log("Setting clientId to user clientId");
        this.user.clientId = this.userProfile.clientId;
      }

      this.user.scheduledOnboardingDate = moment().format("YYYY-MM-DDTHH:mm");
    },
    phoneValidation: function(val) {
      let isValid = false;
      isValid = isValidPhoneNumber(val, "US");
      this.validPhone = isValid;
      if (isValid)
        this.formattedBusinessPhone = parsePhoneNumber(val, "US").format(
          "E.164"
        );
      else this.formattedBusinessPhone = null;
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions", "magicLinkToken"])
    // validFirstName() {
    //   return true;
    // },
    //   validScheduledOnboarding() {
    //     if (
    //       !this.userForm.sendInviteToggle ||
    //       (this.userForm.sendInviteToggle &&
    //         this.user.scheduledOnboardingDate &&
    //         moment(this.user.scheduledOnboardingDate).isValid())
    //     )
    //       return true;
    //     else return false;
    //   },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.template-buttons {
  width: 260px;
  max-width: 260px;
  min-width: 260px;
}
.image-container {
  position: relative;
  width: 80px;
  max-width: 80px;
}
.delete-image-button {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: -10px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  padding: 3px;
  transition: 0.2s opacity;
  background-color: black;
}

.slide-group-button {
  padding: 5px 5px;
  border-radius: 7px;
  width: 300px;
  background-color: var(--v-lightGrey-base);
}
.slide-button {
  background-color: white;
  background: white;
  border: 1px solid;
  border-color: var(--v-grey-base);
}

.timestamp-input {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  line-height: 20px;
  padding: 8px 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  /* min-width: 235px; */
  /* color: rgba(0, 0, 0, 0.38); */
}

.text-field-row {
  width: 70%;
  min-width: 300px;
  max-width: 450px;
}
</style>
