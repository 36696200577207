import Vue from "vue";
import Vuex from "vuex";
import { main as axios, oauth as oauthAxios } from "../axios";
import createPersistedState from "vuex-persistedstate";
import VueJwtDecode from "vue-jwt-decode";

import router from "@/router/index.js";
import { getInstance } from "@/auth/index";

import UserService from "../services/UserService";
import WalletService from "../services/WalletService";
// import ReportService from "../services/ReportService";
import MarqetaService from "../services/MarqetaService";
import TriggerService from "../services/TriggerService";
import CommunicationService from "../services/CommunicationService";

Vue.use(Vuex);

localStorage.removeItem("failedLogin");

const storeDebugLog = (userId, key, value) => {
  var sup = {
    userId: userId,
    key: key,
    value: value
  };
  UserService.createDebugUserSupplemental(sup).catch(error => {
    console.log("Error creating user sup ", error);
  });
};

const defaultState = () => ({
  globalClientId: null,
  permissions: [],
  roles: [],
  token: null,
  tokenExpiration: null,
  tokenRefreshTask: null,
  magicLinkToken: null,
  magicLinkTokenExpiration: null,
  magicLinkTokenRefreshTask: null,
  magicLinkTokenExpirationWarning: false,
  magicLinkRoute: null,
  userProfile: null,
  clients: [],
  cashBalance: 0,
  // pendingBalance: 0,
  balances: [],
  loadingBalance: false,
  marqeta: {
    cards: [],
    canRegister: false,
    alreadyRegister: false,
    processingKYB: false,
    passedKYB: false
  },
  impersonate: null,
  loaded: false,
  failedLogin: false,
  searchArray: [],
  loadItemFromSearch: null,
  displayWalletMenu: false,
  displayMobilePopup: false,
  walletMenuScreen: "main",
  // Used so we know if the user clicked the wallet button in the nav bar or if we're toggling it from the wallet page
  sourceWalletPage: null,
  profilePageTab: 0,
  postItArray: [],
  reportTokens: [],
  // Used to save preference for list vs card view
  activity: {
    listView: false,
    showAdminOptions: true,
    unread: 0,
    unreadRefreshTask: null
  },
  // Used to store slack correlationId if user routes to us from Slack and has to go log in.
  slackWorkspaceCorrelationId: null
});

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: defaultState,
  mutations: {
    RESET_STATE(state) {
      // Used to reset state on app load
      console.log("Resetting state");
      state.loaded = false;
      state.displayWalletMenu = false;
      state.failedLogin = false;
      state.errorString = null;
      state.magicLinkToken = null;
      state.magicLinkTokenExpiration = null;
      state.magicLinkRoute = null;
      state.magicLinkTokenExpirationWarning = false;
      state.displayMobilePopup = false;
    },
    LOGOUT(state) {
      // Used to reset state on app load
      console.log("Erasing cache on logout");

      // Cancel token timeout
      if (state.tokenRefreshTask) clearTimeout(state.tokenRefreshTask);
      if (state.magicLinkTokenRefreshTask)
        clearTimeout(state.magicLinkTokenRefreshTask);
      if (state.activity.unreadRefreshTask)
        clearTimeout(state.activity.unreadRefreshTask);
      // window.localStorage.removeItem("vuex");
      Object.assign(state, defaultState());
    },
    SET_PERMISSION_DATA(state, data) {
      var decoded = VueJwtDecode.decode(data);
      state.tokenExpiration = decoded.exp;
      state.permissions = decoded.permissions || [];
      state.roles =
        decoded.roles || decoded["https://whistle.com/metadata"].roles || [];
      // localStorage.setItem("permissions", JSON.stringify(decoded.permissions));
    },
    SET_ERROR_STRING(state, data) {
      state.errorString = data;
    },
    SET_IMPERSONATE(state, data) {
      state.impersonate = data;
    },
    SET_USER(state, data) {
      state.userProfile = data;
      // Vue.set(state, "loaded", true);
      // state.loaded = true;
    },
    SET_CLIENT_ID(state, data) {
      state.globalClientId = data;
    },
    SET_CLIENTS(state, data) {
      try {
        state.clients = data;
      } catch (err) {
        console.log(
          "Error storing clients in localstorage. We'll scrap that idea"
        );
      }
    },
    SET_CASH_BALANCE(state, data) {
      state.cashBalance = data;
    },
    // SET_PENDING_BALANCE(state, data) {
    //   state.pendingBalance = data;
    // },
    SET_BALANCES(state, data) {
      state.balances = data;
    },
    SET_LOADING_BALANCE(state, data) {
      state.loadingBalance = data;
    },
    SET_TOKEN(state, data) {
      state.token = data;
      axios.defaults.headers.common["Authorization"] = `Bearer ${data}`;
    },
    SET_LOADED(state, data) {
      // state.loaded = data;
      Vue.set(state, "loaded", data);
    },
    SET_FAILED_LOGIN(state, data) {
      state.failedLogin = data;
    },
    SET_SEARCH_ARRAY(state, data) {
      state.searchArray = data;
    },
    SET_LOAD_ITEM_FROM_SEARCH(state, data) {
      state.loadItemFromSearch = data;
    },
    SET_DISPLAY_WALLET_MENU(state, data) {
      state.displayWalletMenu = data;
    },
    SET_WALLET_MENU_SCREEN(state, data) {
      state.walletMenuScreen = data;
    },
    SET_CONFETTI_PLAYED(state, data) {
      state.confettiPlayed = data;
    },
    SET_SOURCE_WALLET_PAGE(state, data) {
      state.sourceWalletPage = data;
    },
    SET_POST_IT_ARRAY(state, data) {
      state.postItArray.push(data);
    },
    SET_REPORT_TOKENS(state, data) {
      state.reportTokens = data;
    },
    PUSH_REPORT_TOKENS(state, data) {
      state.reportTokens.push(data);
    },
    SET_PROFILE_TAB(state, data) {
      state.profilePageTab = data;
    },
    SET_MARQETA(state, data) {
      state.marqeta = data;
    },
    SET_ACTIVITY_LIST_VIEW(state, data) {
      state.activity.listView = data;
    },
    SET_ACTIVITY_ADMIN_OPTIONS(state, data) {
      state.activity.showAdminOptions = data;
    },
    SET_ACTIVITY_UNREAD(state, data) {
      state.activity.unread = data;
    },
    SET_ACTIVITY_UNREAD_REFRESH_TASK(state, data) {
      state.activity.unreadRefreshTask = data;
    },
    SET_SLACK_WORKSPACE_CORRELATION_ID(state, data) {
      state.slackWorkspaceCorrelationId = data;
    },
    SET_TOKEN_REFRESH_TASK(state, data) {
      state.tokenRefreshTask = data;
    },
    SET_DISPLAY_MOBILE_POPUP(state, data) {
      state.displayMobilePopup = data;
    },
    SET_MAGIC_LINK_TOKEN(state, data) {
      state.magicLinkToken = data;

      oauthAxios.defaults.headers.common["Authorization"] = `Bearer ${data}`;
      // If we're using a magic link token, then we wipe the permissions array
      // if (data) state.permissions = [];
    },
    SET_MAGIC_LINK_EXPIRATION(state, data) {
      state.magicLinkTokenExpiration = data;
    },
    SET_MAGIC_LINK_TOKEN_REFRESH_TASK(state, data) {
      state.magicLinkTokenRefreshTask = data;
    },
    SET_MAGIC_LINK_ROUTE(state, data) {
      state.magicLinkRoute = data;
    },
    SET_MAGIC_LINK_EXPIRATION_WARNING(state, data) {
      state.magicLinkTokenExpirationWarning = data;
    }
  },
  actions: {
    // getPermissions({ commit }, userId) {
    //   console.log(userId);
    //   return axios
    //     .get(process.env.VUE_APP_USER_URL + "/userPermissions/" + userId)
    //     .then(({ data }) => {
    //       console.log("returned data is: ", data);
    //       commit("SET_PERMISSION_DATA", data);
    //     });
    // },
    resetState({ commit }) {
      commit("RESET_STATE");
      return;
    },
    logout({ commit }) {
      commit("LOGOUT");
      return;
    },
    // async isLoggedIn() {
    //   // We want to check if users token is valid. If not then we background refresh
    //   if (!this.state.token) return null;

    //   let expired =
    //     this.state.tokenExpiration - new Date().getTime() / 1000 < 20;
    //   console.log(
    //     "Token time until expiration (sec) ",
    //     this.state.tokenExpiration - new Date().getTime() / 1000
    //   );
    //   if (!expired) return this.state.token;
    //   else {
    //     // We have to fetch a new token.
    //     console.log("Must get new token");
    //     try {
    //       // We try and get the token silently.
    //       // This is where Auth0 will block the user if their session has expired or are blocked.
    //       const instance = getInstance();
    //       const newToken = await instance.getTokenSilently();
    //       // If a success, then we store the token and return so the app can continue
    //       this.dispatch("setToken", newToken);
    //       return newToken;
    //     } catch (err) {
    //       // There was an error. Either the user's session expired or they're blocked.
    //       console.log("Error refreshing token ", err);
    //       const instance = getInstance();
    //       console.log("Calling redirect inside store isLoggedIn");
    //       instance.loginWithEmbedded({
    //         connection: process.env.VUE_APP_ENVIRONMENT,
    //         appState: {
    //           targetUrl: router.app.$route.fullPath
    //         }
    //       });
    //     }
    //   }
    // },
    setErrorString({ commit }, state) {
      commit("SET_ERROR_STRING", state);
    },
    setClientId({ commit }, clientId) {
      console.log("Setting global clientId", clientId);
      commit("SET_CLIENT_ID", clientId);
    },
    async setUser({ commit, state, dispatch }, user) {
      let userId = user;
      commit("SET_ERROR_STRING", "get-user-" + userId);

      console.log("Getting user: " + userId);
      commit("SET_ERROR_STRING", "get-user2");
      await UserService.userProfileByEmail(state.magicLinkToken)
        .then(response => {
          commit("SET_ERROR_STRING", "pre-dispatch");
          commit("SET_USER", response);
          if (state.globalClientId == null || response.clientId != 1)
            dispatch("setClientId", response.clientId);
          commit("SET_ERROR_STRING", "post-dispatch");
          this.dispatch("setBalances", response.userId);
          commit("SET_ERROR_STRING", "setBalances");
          this.dispatch("setFailedLogin", false);
          commit("SET_ERROR_STRING", "complete-no-fail");
          return;
        })
        .catch(error => {
          commit(
            "SET_ERROR_STRING",
            "catch-" + JSON.stringify(error).substring(0, 150)
          );
          console.log("Error fetching user!", error);
          this.dispatch("setFailedLogin", true);
          // Now route user to login error page
          router.app.$router.push({ name: "login-error" });

          // storeDebugLog(userId, "Vue_Set_User_Fail_1", error);
          storeDebugLog(userId, "Vue_Set_User_Fail_2", JSON.stringify(error));
          storeDebugLog(
            userId,
            "Vue_Set_User_Fail_3",
            error.error ? JSON.stringify(error.error) : "no error.error"
          );
          storeDebugLog(
            userId,
            "Vue_Set_User_Fail_4",
            JSON.stringify(error, Object.getOwnPropertyNames(error))
          );
          storeDebugLog(
            userId,
            "Vue_Set_User_Fail_5",
            JSON.stringify(
              error && error.response
                ? error.response.data || error.response
                : error
            )
          );
          return;
        });
    },
    async setClients({ commit, state, dispatch }, page = 0) {
      if (
        state.userProfile.clientId == 1 &&
        state.permissions.includes("users:read:client")
      ) {
        console.log("Calling getClientsV2 for page ", page);
        // fetchLoop(0, true);
        // async function fetchLoop(page) {
        try {
          let params = {
            limit: 200,
            offset: page * 200,
            filter: `status = 'Active'`,
            extract: "clientId,clientName,annualPaymentCap",
            order: "clientId ASC"
          };
          var response = await UserService.getClientsV2(params);
          console.log("Got clients ", response.result);
          var clients = response.result.rows.map(x => {
            var obj = x;
            obj.formattedName = x.clientId + " - " + x.clientName;
            return obj;
          });

          if (page == 0) {
            commit("SET_CLIENTS", clients);
          } else {
            commit("SET_CLIENTS", state.clients.concat(clients));
            // this.table.users = this.table.users.concat(rows);
          }
          if (Number(state.clients.length) < Number(response.result.count)) {
            console.log("GETTING MORE CLIENTS");
            page = page + 1;
            dispatch("setClients", page);
            // fetchLoop(page, false);
          } else console.log("We've reached the end of the client list");
          // commit("SET_CLIENTS", clients);
        } catch (err) {
          console.log("Error getting clients ", err);
        }
        // }
      }
    },
    setToken({ commit, dispatch }, token) {
      console.log("Storing new token");
      commit("SET_TOKEN", token);
      commit("SET_PERMISSION_DATA", token);

      // Now we want to setup a timeout so it sleeps and fetches a new token right before it expires;
      dispatch("autoRefreshToken");

      if (!axios.interceptors.response.handlers.length)
        axios.interceptors.response.use(undefined, async error => {
          return new Promise(function(resolve, reject) {
            if (
              error.config &&
              error.response &&
              error.response.status === 401 &&
              !error.config.__isRetry
            ) {
              console.log("Refreshing token due to 401 error");
              dispatch(
                "autoRefreshToken",
                function(newToken) {
                  console.log("inside autoRefreshToken callback ", newToken);
                  error.config.__isRetry = true;
                  error.config.headers["Authorization"] = `Bearer ${newToken}`;
                  axios(error.config).then(resolve, reject);
                },
                function(
                  flag // true = invalid session, false = something else
                ) {
                  console.log("Could not refresh token");
                  reject(flag);
                }
              );
            } else throw error;
          });
        });

      return;
    },
    autoRefreshToken({ commit, state, dispatch }, refreshNowCallback) {
      const { tokenExpiration } = state;
      const now = Date.now() / 1000; //exp is represented in seconds since epoch
      let timeUntilRefresh = tokenExpiration - now;
      // if (timeUntilRefresh < 4 * 60) timeUntilRefresh -= 5;
      //if token will last less than 5 min then we just fetch 20 seconds before the token expires (mainly for testing)
      // else

      if (timeUntilRefresh > 3 * 60) timeUntilRefresh -= 3 * 60; //refreshes 3 minutes before it expires

      // This is called from the axios interceptor if we're getting a 401, so we immediately refresh and set the timeout to 0
      if (refreshNowCallback) timeUntilRefresh = 0;

      console.log("Calling autoRefresh in ", timeUntilRefresh);
      const refreshTask = setTimeout(async () => {
        // dispatch("refreshTokens", creds);
        try {
          // We try and get the token silently.
          // This is where Auth0 will block the user if their session has expired or are blocked.
          const instance = getInstance();
          const newToken = await instance.getTokenSilently({
            ignoreCache: true
          });
          // If a success, then we store the token and return so the app can continue
          dispatch("setToken", newToken);
          if (refreshNowCallback) refreshNowCallback(newToken);
          return newToken;
        } catch (err) {
          // There was an error. Either the user's session expired or they're blocked.
          console.log("Error refreshing token ", err);
          const instance = getInstance();
          console.log("Calling redirect inside index.js autoRefreshToken");
          instance.markAsUnauthenticated();
          instance.loginWithEmbedded({
            connection: process.env.VUE_APP_ENVIRONMENT,
            appState: {
              targetUrl: router.app.$route.fullPath
            }
          });
        }
      }, timeUntilRefresh * 1000);
      // Clear any old timeouts before continuing
      if (state.tokenRefreshTask) {
        console.log("Clearing old timeout");
        clearTimeout(state.tokenRefreshTask);
      }
      commit("SET_TOKEN_REFRESH_TASK", refreshTask);
    },
    async refreshWallet({ dispatch, commit, state }) {
      try {
        console.log("Beginning refreshWallet in vuex");
        commit("SET_LOADING_BALANCE", true);
        dispatch("setMarqeta");
        console.log("Going to set user in refreshWallet");
        await dispatch("setUser", state.userProfile.userId);
        console.log("Done setting user in refreshWallet!!");
        commit("SET_LOADING_BALANCE", false);
      } catch (e) {
        console.log("Error refreshing wallet ", e);
        commit("SET_LOADING_BALANCE", false);
      }
    },
    async setBalances({ commit, state }, userId) {
      console.log("Getting user wallet: " + userId);
      // V2 vs V1. V2 relies solely on Stellar balance and starting out is just used for Bayer
      // var version =
      //   this.state.userProfile &&
      //   ((this.state.userProfile.businessEmail &&
      //     this.state.userProfile.businessEmail.endsWith("@wewhistle.com")) ||
      //     this.state.userProfile.clientId == 166)
      //     ? 2
      //     : 1;
      await WalletService.checkUserBalanceV2(userId, state.magicLinkToken)
        .then(response => {
          // var cash = response.find(x => x.asset_code === "WhistleNotes");
          const cash = response.find(
            x => x.awardId == 1 && x.type == "DEFAULT"
          );
          const balance = cash ? cash.balance : 0;
          console.log({ cash });
          commit("SET_CASH_BALANCE", balance);

          const otherBalances = response.filter(x => x.awardId != 1);

          commit("SET_BALANCES", otherBalances);

          return response;
        })
        .catch(error => {
          console.log(
            "Error fetching user balance!",
            (error && error.response) || error
          );
        });
    },
    async setMagicLinkToken({ commit, dispatch }, data) {
      try {
        commit("SET_ERROR_STRING", "get-magic-link-" + data.magicLinkId);
        console.log("Magic link ID ", data.magicLinkId);
        console.log("Magic link  ", data.magicLink);

        console.log("Getting magic link: ", data.magicLink);
        commit("SET_ERROR_STRING", "get-magic-link2");
        let res = await UserService.getMagicLinkTokenV2(
          data.magicLinkId,
          data.magicLink
        );

        console.log("Got magic link response ", res);
        if (res.token) {
          commit("SET_MAGIC_LINK_TOKEN", res.token.access_token);
          commit("SET_PERMISSION_DATA", res.token.access_token);
          commit("SET_MAGIC_LINK_ROUTE", res.magicLink.redirectUri);
          let now = new Date().getTime() / 1000;
          let expiration = now + res.token.expires_in;
          console.log(
            `Magic link expiration: Current date - ${now}; Expires in ${expiration}`
          );
          commit("SET_MAGIC_LINK_EXPIRATION", expiration);
          // Set up timer to display session timeout error
          dispatch("watchMagicLinkExpiration");

          return res;
        } else throw "Missing token";
      } catch (err) {
        console.log("Error getting magic link token ", err);
        throw err;
      }
    },
    watchMagicLinkExpiration({ commit, state }) {
      const { magicLinkTokenExpiration } = state;
      const now = Date.now() / 1000; //exp is represented in seconds since epoch
      let timeUntilRefresh = magicLinkTokenExpiration - now;
      timeUntilRefresh -= 15; //refreshes 15 seconds before it expires

      console.log("Calling magic link autoRefresh in ", timeUntilRefresh);
      const refreshTask = setTimeout(async () => {
        try {
          commit("SET_MAGIC_LINK_EXPIRATION_WARNING", true);
        } catch (err) {
          // There was an error. Either the user's session expired or they're blocked.
          console.log("Error showing magic link display pop up ", err);
        }
      }, timeUntilRefresh * 1000);
      // Clear any old timeouts before continuing
      if (state.magicLinkTokenRefreshTask) {
        console.log("Clearing old timeout");
        clearTimeout(state.magicLinkTokenRefreshTask);
      }
      commit("SET_MAGIC_LINK_TOKEN_REFRESH_TASK", refreshTask);
    },
    setMagicLinkTokenExpirationWarning({ commit }, val) {
      commit("SET_MAGIC_LINK_EXPIRATION_WARNING", val);
    },
    setLoaded({ commit }, loadingState) {
      commit("SET_LOADED", loadingState);
    },
    setFailedLogin({ commit }, loginState) {
      commit("SET_FAILED_LOGIN", loginState);
    },
    setSearchArray({ commit }, array) {
      commit("SET_SEARCH_ARRAY", array);
    },
    setLoadItemFromSearch({ commit }, id) {
      commit("SET_LOAD_ITEM_FROM_SEARCH", id);
    },
    setDisplayWalletMenu({ commit }, value) {
      console.log("setting wallet ", value);
      commit("SET_DISPLAY_WALLET_MENU", value);
    },
    setWalletMenuScreen({ commit }, value) {
      console.log("setting wallet menu screen ", value);
      commit("SET_WALLET_MENU_SCREEN", value);
    },
    setConfettiPlayed({ commit }, value) {
      console.log("setting confetti played date ", value);
      commit("SET_CONFETTI_PLAYED", value);
    },
    setSourceWalletPage({ commit }, value) {
      commit("SET_SOURCE_WALLET_PAGE", value);
    },
    setPostItArray({ commit }, noteId) {
      console.log("Updating post-it note array for entry: ", noteId);
      commit("SET_POST_IT_ARRAY", noteId);
    },
    setProfilePageTab({ commit }, tab) {
      commit("SET_PROFILE_TAB", tab);
    },
    setActivityListView({ commit }, bool) {
      commit("SET_ACTIVITY_LIST_VIEW", bool);
    },
    setActivityAdminOptions({ commit }, bool) {
      commit("SET_ACTIVITY_ADMIN_OPTIONS", bool);
    },
    //  setReportTokens ({ commit, state }, userId) {
    //     // We only need to fetch for those with the permission to view reports
    //     if (this.state.permissions.includes("vue:read:reporting")) {
    //       console.log("Getting reporting tokens for user: " + userId);
    //       UserService.getUser(userId).then(user => {
    //         // var event = {
    //         //   event: "SET_USER_REPORTS",
    //         //   source: "WHISTLE_AUTH",
    //         //   category: "PLATFORM",
    //         //   date: new Date()
    //         // };
    //         // console.log("Creating event in db for ", event.event);
    //         // TriggerService.createActivity(event, state.magicLinkToken).catch(
    //         //   error => {
    //         //     console.log("Error creating activity ", error.response);
    //         //   }
    //         // );
    //         ReportService.getReports().then(reports => {
    //           var params = [];
    //           console.log("REports ", reports);
    //           reports.forEach(report => {
    //             params.push({
    //               reportId: report.reportId,
    //               name: report.parameterColumn,
    //               value: report.parameterColumn
    //                 ? user[report.parameterColumn]
    //                 : null
    //             });
    //           });

    //           event.event = "GET_REPORTS";
    //           console.log("Creating event in db for ", event.event);
    //           TriggerService.createActivity(event, state.magicLinkToken).catch(
    //             error => {
    //               console.log("Error creating activity ", error.response);
    //             }
    //           );
    //           if (reports.length > 0) {
    //             ReportService.getAllTokens(userId, params, user.Client)
    //               .then(response => {
    //                 console.log("Report tokens: ", response);
    //                 commit("SET_REPORT_TOKENS", response);
    //                 event.event = "SET_REPORT_TOKENS";
    //                 console.log("Creating event in db for ", event.event);
    //                 TriggerService.createActivity(
    //                   event,
    //                   state.magicLinkToken
    //                 ).catch(error => {
    //                   console.log("Error creating activity ", error.response);
    //                 });
    //                 return response;
    //               })
    //               .catch(error => {
    //                 console.log("Error getting reports ", error.response);
    //               });
    //           }
    //         });
    //       });
    //     }
    //   },
    pushReportTokens({ commit }, item) {
      commit("PUSH_REPORT_TOKENS", item);
    },
    setMarqeta({ commit, state }) {
      var object = {
        cards: [],
        alreadyRegistered: false,
        canRegister: false,
        processingKYB: false,
        passedKYB: false
      };
      if (
        (state.userProfile && state.userProfile.firstLogin) ||
        state.magicLinkToken
      ) {
        // If their first login, reset Marqeta state while waiting for endpoint
        console.log("COMMITTING MARQETA");
        commit("SET_MARQETA", object);
      }
      return MarqetaService.getCards(state.magicLinkToken)
        .then(response => {
          var event = {
            event: "GET_MARQETA_CARDS",
            source: "WHISTLE_AUTH",
            category: "PLATFORM",
            date: new Date()
          };
          console.log("Creating event in db for ", event.event);
          TriggerService.createActivity(event, state.magicLinkToken).catch(
            error => {
              console.log("Error creating activity ", error.response);
            }
          );
          // var business = response.business;
          // console.log("Marqeta card response: ", cards);
          if (response.state) object = response.state;

          if (response.error_code) {
            // object.cards = [];
            object.error_code = response.error_code;
            // If the user isn't set up, that means that we already got passed the business validation
            // if (response.error_code == 1190004) {
            //   object.alreadyRegistered = false;
            //   object.canRegister = true;
            //   object.processingKYB = false;
            // } else if (
            //   response.error_code == 1190019 ||
            //   response.error_code == 1190020
            // ) {
            //   // If the business isn't set up or they haven't passed KYB, then we don't let the user do anything
            //   // 1190020 means the user hasn't passed KYC yet so we stop them as well (this shouldn't happen)
            //   object.alreadyRegistered = false;
            //   object.canRegister = false;
            //   object.processingKYB = true;
            // } else if (response.error_code == 1190005) {
            //   object.processingKYB = false;
            //   object.alreadyRegistered = false;
            //   object.canRegister = false;
            // }
          } else {
            // var cards = response.cards;

            // // We've already found the user and the business in the database so they are already registered
            // object.cards = cards;
            // object.canRegister = true;
            // object.processingKYB = false;
            // object.alreadyRegistered = true;
            object.error_code = null;
          }
          // if (business.businessToken === business.actingBusinessToken) {
          //   object.submittedKYB = true;
          // }
          // if (business.status == "Pending") object.processingKYB = true;
          commit("SET_MARQETA", object);
          return object;
        })
        .catch(error => {
          console.log("Error fetching cards: ", error);
          var event = {
            event: "GET_MARQETA_CARDS_ERROR",
            source: "WHISTLE_AUTH",
            category: "PLATFORM",
            date: new Date()
          };
          console.log("Creating event in db for ", event.event);
          TriggerService.createActivity(event, state.magicLinkToken).catch(
            error => {
              console.log("Error creating activity ", error.response);
            }
          );
          commit("SET_MARQETA", object);
          return object;
        });
    },
    setSlackWorkspaceCorrelationId({ commit }, val) {
      commit("SET_SLACK_WORKSPACE_CORRELATION_ID", val);
    },
    setDisplayMobilePopup({ commit }, val) {
      commit("SET_DISPLAY_MOBILE_POPUP", val);
    },
    setImpersonate({ commit }, val) {
      commit("SET_IMPERSONATE", val);
    },
    async setUnreadMessages({ commit, state, dispatch }) {
      // Magic links don't show the nav so we don't need to get unreads
      if (state.magicLinkToken) return;
      let unread = 0;
      let timeout = 60000; // default of 60s
      try {
        let response = await CommunicationService.getUnreadMessagesV2(
          state.magicLinkToken
        );
        console.log("Got unread message response ", response);
        if (response && response.result) unread = response.result.count || 0;
        if (response && response.result && response.result.timeout_ms)
          timeout = response.result.timeout_ms;
      } catch (e) {
        console.log("Error getting unread message count: ", e);
      } finally {
        commit("SET_ACTIVITY_UNREAD", unread);

        console.log("Timeout? ", timeout);
        const refreshTask = setTimeout(() => {
          console.log("Dispatching setUnreadMessages after timeout");
          dispatch("setUnreadMessages");
        }, timeout);

        // Clear any old timeouts before continuing
        if (state.activity.unreadRefreshTask) {
          console.log("Clearing old activity timeout");
          clearTimeout(state.activity.unreadRefreshTask);
        }
        commit("SET_ACTIVITY_UNREAD_REFRESH_TASK", refreshTask);
      }
    }
  },
  getters: {
    permissions(state) {
      // console.log("store permissions getter");
      // console.log(state.permissions);
      return state.permissions;
    },
    hasPermission: state => permission => {
      console.log("has-----");
      console.log(state.permissions);
      return state.permissions.includes(permission);
    },
    getUserProfile(state) {
      return state.userProfile;
    }
  },
  modules: {}
});
