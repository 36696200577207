<template>
  <div class="full-height gradient-background" rounded="0">
    <div
      class="d-flex flex-column justify-space-between full-height container pb-10"
    >
      <v-row justify="center" class="full-width" no-gutters
        ><v-col
          cols="12"
          sm="12"
          md="3"
          lg="3"
          xl="3"
          class="align-text-left darkGrey--text justify-space-between d-flex flex-column"
        >
          <div>
            <div
              class="d-flex justify-center align-center mb-5 mt-10 hidden"
              v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
            >
              <v-btn small fab elevation="0" color="mediumGrey"
                ><v-icon large color="white">mdi-chevron-left</v-icon></v-btn
              >
              <ProgressBar class="progress-bar mx-12" />
              <v-btn small fab elevation="0" color="mediumGrey"
                ><v-icon large color="white">mdi-chevron-right</v-icon></v-btn
              >
            </div>

            <v-img
              v-if="client && client.companyPicUrl"
              :src="client.companyPicUrl"
              height="90"
              contain
            />

            <v-img
              v-else
              src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
              height="90"
              contain
            />

            <!-- <div
              v-if="
                showPaymentsMessaging &&
                  (currentSlide == keys.agreements || currentSlide == keys.card)
              "
            >
              <Robin
                :showText="true"
                :message="
                  currentSlide == keys.agreements
                    ? `Hey there! I'm Robin. I try and
              give advice and information to help you out. Let's set up your account!`
                    : `The Whistle Card is your personal debit card linked directly to your Whistle Wallet! Don't worry, this doesn't check your credit`
                "
                :width="60"
                :height="60"
                borderColor="accent"
                color="brandPaleBlue"
                animation="blinking"
                :loop="true"
                class="my-6"
              />
              <v-divider />
            </div> -->
            <div>
              <div class="d-flex align-start mx-4 mt-8 word-break">
                <v-icon
                  color="white"
                  x-small
                  class="brand-green-background circle-border-radius mini-icon mr-2"
                  >fa-check</v-icon
                >
                <span v-if="showPaymentsMessaging">
                  <span class="font-weight-bold">Simple</span> set up and card
                  management
                </span>
                <span v-else>
                  <span class="font-weight-bold">Convenient</span>
                  tools to make you more successful</span
                >
              </div>
              <div class="d-flex align-start mx-4 mt-5 word-break">
                <v-icon
                  color="white"
                  x-small
                  class="brand-green-background circle-border-radius mini-icon mr-2"
                  >fa-check</v-icon
                >
                <span
                  v-if="
                    showPaymentsMessaging && userProfile && userProfile.Client
                  "
                >
                  <span class="font-weight-bold">Easily</span> receive and spend
                  money sent by {{ userProfile.Client.clientName }}
                </span>
                <span v-else-if="showPaymentsMessaging">
                  <span class="font-weight-bold">Easily</span> receive and spend
                  money sent by your employer
                </span>
                <span v-else>
                  <span class="font-weight-bold">Earn</span> badges and payments
                  for learning</span
                >
              </div>
              <div class="d-flex align-start mx-4 mt-5 word-break">
                <v-icon
                  color="white"
                  x-small
                  class="brand-green-background circle-border-radius mini-icon mr-2"
                  >fa-check</v-icon
                >
                <span v-if="showPaymentsMessaging">
                  <span class="font-weight-bold">No</span> credit check
                  necessary
                </span>
                <span v-else>
                  <span class="font-weight-bold">Focus</span> on what's
                  important to learn with our microlearning technology</span
                >
              </div>
            </div>
          </div>
          <div v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
            <p
              class="white--text cursor-pointer font-weight-bold ml-10 mb-1"
              @click="downloadFile('terms')"
            >
              Whistle Terms
            </p>
            <p
              v-if="!$auth.isAuthenticated"
              class="white--text cursor-pointer font-weight-bold ml-10"
              @click="loginOrLogout"
            >
              Already have an account?
            </p>
            <p
              v-else
              class="white--text cursor-pointer font-weight-bold ml-10"
              @click="loginOrLogout"
            >
              Log out
            </p>
          </div>
        </v-col>
        <v-col cols="0" sm="0" md="1" lg="1" xl="1"></v-col>
        <v-col cols="12" sm="12" md="5" lg="5" xl="4">
          <div class="d-flex justify-center align-center my-3">
            <v-btn
              small
              color="primary"
              :class="{
                hidden:
                  !userProfile ||
                  !$auth.isAuthenticated ||
                  userProfile.clientId != 1
              }"
              @click="learningMessagingOverride = !learningMessagingOverride"
              >Switch text</v-btn
            >
            <!-- <v-select
              class="pa-1 language-selector hidden"
              dense
              prepend-icon="mdi-earth"
              :items="languages"
              item-text="name"
              item-value="code"
              label="Language"
              hide-details
              v-model="language"
            ></v-select> -->
          </div>
          <div class="box-outline form-card">
            <transition :name="slideDirection" mode="out-in">
              <v-row
                v-if="currentSlide == keys.agreements"
                key="0"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center mb-8 text-left"
              >
                <v-card-title
                  v-if="
                    userProfile &&
                      userProfile.Client &&
                      userProfile.Client.onboardingToken &&
                      userProfile.createdBy &&
                      userProfile.createdBy.includes(
                        userProfile.Client.onboardingToken
                      )
                  "
                  class="title-text word-break full-width mb-5"
                  >Let's finish setting up your Whistle account</v-card-title
                >
                <v-card-title
                  v-else-if="userProfile && userProfile.Client"
                  class="title-text word-break full-width mb-5"
                  >{{ userProfile.Client.clientName }} has invited you to set up
                  your Whistle account</v-card-title
                >
                <v-card-title
                  v-else
                  class="title-text word-break full-width mb-5"
                  >You've been invited to set up your Whistle
                  account</v-card-title
                >
                <p v-if="hasPassedKYB && showPaymentsMessaging" class="ml-4">
                  Whistle is being used by
                  {{ userProfile.Client.clientName }} to send payments.
                  <br /><br />Once you agree to the terms below, you'll just
                  need to provide some details for your new card!
                </p>
                <p
                  v-else-if="!hasPassedKYB && showPaymentsMessaging"
                  class="ml-4"
                >
                  Whistle is being used by
                  {{ userProfile.Client.clientName }} to send payments. After
                  agreeing to the terms below, you can learn more about our
                  app.<br /><br />
                  Once your company has passed a verification process, you can
                  claim your card from the Wallet page.
                </p>
                <p v-else class="ml-4">
                  Whistle is being used by
                  {{ userProfile.Client.clientName }} as a powerful learning
                  tool. <br /><br />After agreeing to the terms below, take
                  learning modules provided through the Whistle app to enhance
                  your skills!
                </p>
                <v-form
                  ref="form"
                  v-model="validAgreementForm"
                  class="full-width px-4"
                >
                  <div class="d-flex align-center text-left">
                    <v-checkbox
                      v-model="termsCheckbox"
                      class="mt-4 mb-0"
                      :rules="[v => !!v || '']"
                    />
                    <span
                      >I agree to the
                      <a @click="downloadFile('privacy_gdpr')"
                        >privacy policy</a
                      >
                      and
                      <a @click="downloadFile('terms')"
                        >terms and conditions</a
                      ></span
                    >
                  </div>
                  <!-- <v-divider />
                  <div class="d-flex align-center">
                    <v-checkbox
                      v-model="privacyCheckbox"
                      class="mt-4"
                      :rules="[(v) => !!v || '']"
                    />
                    <span
                      >I agree to the
                      <a @click="downloadFile('privacy_gdpr')"
                        >privacy policy</a
                      ></span
                    >
                  </div> -->
                </v-form>
              </v-row>
              <v-row
                v-if="currentSlide == keys.newUser"
                key="1"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center mb-8 text-left"
              >
                <v-card-title class="title-text word-break full-width mb-5"
                  >Welcome to Whistle! Let's set up a new account
                  {{
                    client && client.clientName
                      ? "for " + client.clientName
                      : ""
                  }}</v-card-title
                >
                <v-form
                  ref="form"
                  v-model="validNewUserForm"
                  class="full-width px-4"
                >
                  <v-text-field
                    ref="firstName"
                    label="First Name"
                    v-model="firstName"
                    :rules="[
                      v => !!v || 'Your first name is required',
                      v =>
                        !!(v && v.length < 40) ||
                        'Your first name should be shorter'
                    ]"
                  />
                  <v-text-field
                    ref="lastName"
                    label="Last Name"
                    v-model="lastName"
                    class="mt-4"
                    :rules="[
                      v => !!v || 'Your last name is required',
                      v =>
                        !!(v && v.length < 40) ||
                        'Your last name should be shorter'
                    ]"
                  />
                  <v-text-field
                    ref="email"
                    label="Email"
                    v-model="email"
                    class="mt-4"
                    :rules="[
                      v => !!v || 'Your email is required',
                      v =>
                        !!(v && v.length < 255) ||
                        'Your email should be shorter',
                      v =>
                        /^\w+([&.+\-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/.test(
                          v
                        ) || 'Your email does not appear valid'
                    ]"
                  />
                  <v-select
                    label="Country"
                    class="mt-4"
                    :items="countries"
                    v-model="country"
                    item-value="code"
                    item-text="name"
                    :rules="[v => !!v || 'Country is required']"
                  />
                  <v-text-field
                    v-if="country === '00'"
                    v-model="otherCountry"
                    class="mt-4"
                    :rules="[
                      v => !!v || 'Country code is required',
                      v =>
                        !!(v && v.length == 2) ||
                        'Country code should be 2 letters'
                    ]"
                    label="Country (2 digit code)*"
                  ></v-text-field>
                  <!-- <v-divider />
                  <div class="d-flex align-center">
                    <v-checkbox
                      v-model="privacyCheckbox"
                      class="mt-4"
                      :rules="[(v) => !!v || '']"
                    />
                    <span
                      >I agree to the
                      <a @click="downloadFile('privacy_gdpr')"
                        >privacy policy</a
                      ></span
                    >
                  </div> -->
                </v-form>
              </v-row>
              <v-row
                v-else-if="
                  currentSlide == keys.loading ||
                    currentSlide == keys.loadingOnboardingToken
                "
                key="9"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
              >
                <v-card-title
                  v-if="currentSlide == keys.loadingOnboardingToken"
                  class="title-text word-break full-width text-left"
                  >Loading your custom signup experience...</v-card-title
                >
                <v-card-title
                  v-else
                  class="title-text word-break full-width text-left"
                  >Setting up your account...</v-card-title
                >

                <Robin
                  :showText="false"
                  :width="140"
                  :height="140"
                  animation="inflatableDance"
                  :loop="true"
                />
              </v-row>
              <v-row
                v-if="currentSlide == keys.verifyEmail"
                key="2"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center mb-8 text-left"
              >
                <v-card-title class="title-text word-break full-width mb-3"
                  >Now just verify your email and set your password
                </v-card-title>
                <div
                  class="accent-background circle-border-radius icon-outer-ring mt-6"
                >
                  <v-icon
                    color="white"
                    x-large
                    class="icon-inner-ring accent-dark-background circle-border-radius"
                    >mdi-email-check-outline</v-icon
                  >
                </div>
                <caption class="mt-12">
                  We sent a verification email to
                  <br />
                  {{
                    email
                  }}
                </caption>

                <p class="font-weight-bold mt-8 text-center">
                  Check your inbox and click on the link inside to get started!
                </p>
                <p
                  class="cursor-pointer primary--text font-weight-bold"
                  @click="
                    (currentSlide = keys.error),
                      (displayEmailButton = true),
                      (errorMessage =
                        'If you continue to not receive your Whistle email invitation, please contact us')
                  "
                >
                  Email did not arrive?
                </p>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.error"
                key="3"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center mb-8 text-left"
              >
                <v-card-title class="title-text word-break" v-if="!errorMessage"
                  >Looks like your account needs some extra
                  attention</v-card-title
                >
                <v-card-title class="title-text word-break" v-else>{{
                  errorMessage
                }}</v-card-title>

                <p class="pl-4 full-width mt-8">
                  Feel free to reach out to customer service via one of the
                  following methods...
                </p>
                <p class="full-width pl-4">
                  Email -
                  <b
                    ><a href="mailto:help@wewhistle.com"
                      >help@wewhistle.com</a
                    ></b
                  >
                </p>
                <p class="full-width pl-4">
                  Phone (Toll Free) - <b>(855) 264-3329</b>
                </p>
                <div class="d-flex mt-6">
                  <v-btn
                    color="primary"
                    class="pa-5"
                    width="160"
                    v-if="displayEmailButton"
                    :loading="sendingResetEmail"
                    @click="sendResetEmail"
                    >Resend Email</v-btn
                  >
                  <v-btn
                    color="primary"
                    class="pa-5"
                    width="160"
                    v-else-if="displayLoginButton"
                    @click="loginOrLogout"
                    >Log In</v-btn
                  >
                  <v-btn
                    color="primary"
                    class="pa-5"
                    width="160"
                    v-else-if="displayEditButton"
                    @click="
                      (currentSlide = keys.newUser), (displayEditButton = false)
                    "
                    >Edit Details</v-btn
                  >
                </div>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.card"
                key="4"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center justify-start"
              >
                <!-- <div class="d-flex flex-column align-center"> -->
                <v-card-title class="title-text word-break mt-8"
                  >Claim your card</v-card-title
                >
                <div
                  class="brand-light-green-background circle-border-radius icon-outer-ring mt-10"
                >
                  <v-icon
                    color="white"
                    x-large
                    class="icon-inner-ring brand-green-background circle-border-radius"
                    >mdi-credit-card-outline</v-icon
                  >
                </div>
                <p class="mt-12" v-if="hasPassedKYB">
                  To receive funds, we need some info.<br /><br />The card is
                  your personal card, so make sure to set it up with with your
                  personal info and not work info.
                </p>
                <p class="mt-12" v-else>
                  Before you can claim your card, your organization has to pass
                  a verification process.
                </p>
                <p class="pt-18" v-if="hasPassedKYB">
                  This can also be done later on from the Wallet page.
                </p>
                <p class="mt-18" v-else>
                  Once that is done, check on the Wallet page to claim your
                  card!
                </p>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.success"
                key="5"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center justify-start"
              >
                <!-- <div class="d-flex flex-column align-center"> -->
                <v-card-title class="title-text word-break mt-8"
                  >Congratulations!</v-card-title
                >
                <div
                  class="brand-light-green-background circle-border-radius icon-outer-ring mt-10"
                >
                  <v-icon
                    color="white"
                    x-large
                    class="icon-inner-ring brand-green-background circle-border-radius"
                    >fa-check</v-icon
                  >
                </div>
                <caption class="mt-12">
                  You are all set to begin using Whistle
                </caption>
              </v-row>
            </transition>
            <v-row
              class="mb-6"
              align="center"
              justify="center"
              v-if="
                currentSlide != keys.loading &&
                  currentSlide != keys.loadingOnboardingToken &&
                  currentSlide != keys.error &&
                  currentSlide != keys.verifyEmail &&
                  currentSlide != keys.success
              "
            >
              <v-col>
                <v-btn
                  color="primary"
                  outlined
                  v-if="currentSlide == keys.card"
                  :disabled="
                    currentSlide == keys.agreements && !validAgreementForm
                  "
                  width="150"
                  class="pa-5 mt-8 mr-4"
                  @click="nextSlide(true)"
                  ><span>Later</span></v-btn
                >
                <v-btn
                  color="primary"
                  :disabled="
                    (currentSlide == keys.newUser && !validNewUserForm) ||
                      (currentSlide == keys.agreements &&
                        !validAgreementForm) ||
                      (currentSlide == keys.card && !hasPassedKYB)
                  "
                  width="150"
                  class="pa-5 mt-8"
                  :loading="loadingKYB"
                  @click="nextSlide"
                  ><span>{{
                    currentSlide == keys.card ? "Claim Card" : "Continue"
                  }}</span></v-btn
                >
              </v-col>
            </v-row>
          </div>
          <div
            v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            class="pt-3"
          >
            <p
              class="white--text cursor-pointer font-weight-bold mb-1"
              @click="downloadFile('terms')"
            >
              Whistle Terms
            </p>
            <p
              v-if="!$auth.isAuthenticated"
              class="white--text cursor-pointer font-weight-bold"
              @click="loginOrLogout"
            >
              Already have an account?
            </p>
            <p
              v-else
              class="white--text cursor-pointer font-weight-bold"
              @click="loginOrLogout"
            >
              Log out
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      fullscreen
      v-model="dialogCardRequest"
      transition="dialog-bottom-transition"
      class="z-index-front"
    >
      <v-card>
        <MarqetaCardRequest
          v-if="dialogCardRequest"
          :cards="marqeta.cards"
          :alreadyRegistered="marqeta.alreadyRegistered"
          @reset="closeKYB"
        ></MarqetaCardRequest>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { languages, countries } from "@/shared_data/data.js";

import Robin from "@/components/Robin.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import MarqetaCardRequest from "@/components/wallet/MarqetaCardRequest";

import moment from "moment";
import { mapState } from "vuex";

import UserService from "@/services/UserService";
import TriggerService from "@/services/TriggerService";
import OnboardingService from "@/services/OnboardingService";

export default {
  name: "ActivationV2",
  title: "Whistle | Setup",
  props: {
    alreadyRegistered: Boolean,
    cards: Array
  },
  components: {
    Robin,
    ProgressBar,
    MarqetaCardRequest
  },
  data() {
    return {
      currentSlide: 0,
      keys: {
        // First screen always. Shows agreements
        agreements: 0,
        // When saving agreements
        loading: 1,
        // Intro to card screen
        card: 2,
        // card success screen. Will show for a few seconds and immediately route
        success: 3,
        // general error screen
        error: 4,
        // Loading page to indicate that we're fetching the client based on the onboarding token
        loadingOnboardingToken: 5,
        // User signup/creation screen for brand new users that route from an onboarding token url param
        newUser: 6,
        // Email verification screen once user created their acct
        verifyEmail: 7
      },
      slideDirection: "topic-left",

      learningMessagingOverride: null,

      errorMessage: null,
      validAgreementForm: false,

      languages: languages,
      countries: countries,
      language: null,
      clientId: null,

      termsCheckbox: false,
      termsSupplementalId: null,
      // privacyCheckbox: false,
      privacySupplementalId: null,

      dialogCardRequest: false,
      loadingKYB: false,

      // v-form to validate
      validNewUserForm: false,
      // data fields to set up the new user.
      firstName: null,
      lastName: null,
      email: null,
      country: null,
      otherCountry: null,

      // Client object stored so we have their logo to display
      client: {},
      // Error handling booleans
      displayLoginButton: false,
      displayEditButton: false,
      displayEmailButton: false,
      // Loading bool for when resending password reset
      sendingResetEmail: false
    };
  },
  created() {
    this.language = "eng";
    this.country = "US";
    // If the user is using an onboarding link, we fetch that client first
    if (
      this.$route.name == "user-signup" &&
      this.$route.params &&
      this.$route.params.onboardingToken
    ) {
      this.getClientFromOnboardingLink();
    }
    if (this.$auth.isAuthenticated) {
      // Preload terms and privacy supplemental data
      if (this.userProfile && this.userProfile.UserSupplementals) {
        var userSupp = this.userProfile.UserSupplementals;
        var terms = userSupp.find(
          x => x.key === "Terms and Conditions Agreement"
        );
        if (terms) {
          this.termsSupplementalId = terms.userSupplementalId;
        } else {
          this.termsSupplementalId = null;
        }

        var privacy = userSupp.find(x => x.key === "Privacy Policy Agreement");
        if (privacy) {
          this.privacySupplementalId = privacy.userSupplementalId;
        } else {
          this.privacySupplementalId = null;
        }
      }

      if (
        this.privacySupplementalId &&
        this.termsSupplementalId &&
        this.userProfile.clientId != 1 &&
        (!this.userProfile.Client ||
          (this.userProfile.Client &&
            this.userProfile.Client.clientName !=
              "Rainforest QA Test Environment" &&
            this.userProfile.Client.clientName != "Demo Co")) &&
        !this.usingOnboardingLink
      ) {
        if (this.showPaymentsMessaging) {
          if (
            !this.hasPassedKYB ||
            (this.marqeta.alreadyRegistered && this.marqeta.cards.length > 0)
          ) {
            this.completeOnboardingFlow();
          } else {
            this.currentSlide = this.keys.card;
          }
        } else {
          this.completeOnboardingFlow();
        }
      }
      console.log(this.userProfile);
      if (
        this.currentSlide == this.keys.agreements &&
        this.userProfile.UserSupplementals.length == 0
      ) {
        this.insertActivity("FIRST_LOGIN");
      }
      // Update user status
      // if (
      //   !actionTaken &&
      //   this.privacySupplementalId &&
      //   this.termsSupplementalId &&
      //   (this.userProfile.platformStatus == "ACCOUNT_CREATION" ||
      //     this.userProfile.platformStatus == "EMAIL_VERIFIED")
      // ) {
      //   this.updateUser({
      //     platformStatus: "AGREEMENTS_SIGNED",
      //     platformStatusUpdateDate: moment().utc(),
      //   });
      // } else if (
      //   !actionTaken &&
      //   (this.userProfile.platformStatus === null ||
      //     this.userProfile.platformStatus == "ACCOUNT_CREATION")
      // ) {
      //   // This is their first login so update as email verified
      //   this.updateUser({
      //     platformStatus: "EMAIL_VERIFIED",
      //     platformStatusUpdateDate: moment().utc(),
      //   });
      // }
    }
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    // window.divNode.parentNode.removeChild(window.divNode);
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {
    loginOrLogout() {
      if (!this.$auth.isAuthenticated) {
        //Logs in
        this.$auth.loginWithEmbedded({
          connection: process.env.VUE_APP_ENVIRONMENT
        });
      } else {
        //Logs out
        this.$auth.logout({
          returnTo: window.location.origin
        });
      }
    },
    scrollToTop() {
      // if (!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs) {
      //   document.getElementsByClassName(
      //     "v-dialog v-dialog--active"
      //   )[0].scrollTop = 0;
      // } else {
      //   console.log("Scrolling on mobile");
      //   const cardY = document.getElementById("progress-bar");
      //   const rect = cardY.getBoundingClientRect();
      //   console.log(rect.top);
      //   window.scrollTo(0, rect.top - 90);
      // }
    },
    nextSlide(exit = false) {
      this.slideDirection = "topic-left";
      if (exit === true) {
        this.completeOnboardingFlow();
      } else if (this.currentSlide == this.keys.card) {
        // open up the dialog window
        this.loadingKYB = true;
        this.dialogCardRequest = true;
      } else if (this.usingOnboardingLink) {
        if (this.currentSlide == this.keys.newUser)
          this.createUserFromOnboardingLink();
      } else {
        this.scrollToTop();
        this.currentSlide += 1;

        if (this.currentSlide == this.keys.loading) {
          // We are submitting agreements
          this.saveAgreements();
        } else if (this.currentSlide == this.keys.success + 1) {
          console.log("All done");
          this.completeOnboardingFlow();
        }
      }
    },
    insertActivity(event) {
      var obj = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        event: event,
        source: "USER_ONBOARDING",
        category: "PLATFORM",
        date: new Date()
      };
      TriggerService.createActivity(obj, this.magicLinkToken)
        .then(response => {
          console.log("Response from inserting activity ", response);
        })
        .catch(error => {
          console.log("There was an error inserting activity ", error);
        });
    },
    saveAgreements() {
      var startTime = new Date();
      var userSuppArray = [
        {
          userId: this.userProfile.userId,
          key: "Terms and Conditions Agreement",
          value: moment().utc(),
          updatedBy: this.userProfile.userId
        },
        {
          userId: this.userProfile.userId,
          key: "Privacy Policy Agreement",
          value: moment().utc(),
          updatedBy: this.userProfile.userId
        }
      ];
      if (this.termsSupplementalId !== null) {
        userSuppArray[0].userSupplementalId = this.termsSupplementalId;
      }
      if (this.privacySupplementalId !== null) {
        userSuppArray[1].userSupplementalId = this.privacySupplementalId;
      }

      console.log("Updating/Creating User Supplemental: ", userSuppArray);
      UserService.bulkUpdateUserSupplemental(userSuppArray, this.magicLinkToken)
        .then(response => {
          console.log("User Supplemental response: ", response);
          var timeDiff = moment().diff(moment(startTime));
          if (timeDiff > 1500) {
            timeDiff = 1500;
          }
          setTimeout(() => {
            // If there was an error with the API, we show the error page
            if (response.error) {
              this.currentSlide = this.keys.error;
            } else {
              // If we're not ready for them to request a card, then we take them to the succes page.
              if (this.showPaymentsMessaging && this.hasPassedKYB) {
                this.currentSlide = this.keys.card;
                // this.updateUser({
                //   platformStatus: "AGREEMENTS_SIGNED",
                //   platformStatusUpdateDate: moment().utc(),
                // });
              } else {
                this.completeOnboardingFlow();
              }
            }
          }, 1500 - timeDiff);
        })
        .catch(error => {
          console.log("There was an error saving user supplemental: ", error);
          this.currentSlide = this.keys.error;
        });
    },
    downloadFile(name) {
      OnboardingService.downloadLegalDoc(name);
    },
    closeKYB(success) {
      this.dialogCardRequest = false;

      if (success) {
        this.completeOnboardingFlow(success);
      } else {
        this.loadingKYB = false;
      }
      this.$store.dispatch("setMarqeta").then(x => {
        if (x.cards.length > 0) {
          this.completeOnboardingFlow(true);
        }
        this.loadingKYB = false;
      });
    },
    completeOnboardingFlow(loadVirtualCard = false) {
      this.currentSlide = this.keys.success;
      // We have to mark them as having logged in
      // Completed the onboarding setup
      // Then push them to People
      var startTime = new Date();
      UserService.updateUser(
        this.userProfile.userId,
        {
          firstLogin: false,
          // platformStatus:
          //   // If they have a card then we're good
          //   loadVirtualCard === true || this.marqeta.cards.length > 0
          //     ? "ONBOARDING_REQUESTED_CARD"
          //     : this.showPaymentsMessaging && !this.hasPassedKYB
          //     ? // If they're payments and can't request a card
          //       "ONBOARDING_NO_KYB"
          //     : this.showPaymentsMessaging
          //     ? // If they're payments and didn't get a card
          //       "ONBOARDING_NO_CARD"
          //     : // If they're not payments
          //       "ONBOARDING_COMPLETED",
          // platformStatusUpdateDate: moment().utc(),
          updatedBy: this.userProfile.userId
        },
        this.magicLinkToken
      )
        .then(user => {
          console.log("User update response: ", user);

          // this.$store.dispatch("setUser", this.userProfile.userId).then((x) => {
          // console.log("Done with getting user", x);
          var timeDiff = moment().diff(moment(startTime));
          if (timeDiff > 1500) {
            timeDiff = 1500;
          }
          setTimeout(() => {
            if (user.error) {
              return (this.currentSlide = this.keys.error);
            }
            // Route to client default
            // checks if we're not already on the current route. Vuetify errors if we try to route user to the route they're currently on
            // If they're using a magic link, we only need to take them to activity or wallet
            if (
              this.magicLinkToken &&
              (this.magicLinkRoute == "/wallet" ||
                this.magicLinkRoute == "/activity")
            ) {
              if (this.$route.path != this.magicLinkRoute)
                this.$router.puush(this.magicLinkRoute);
            } else {
              if (
                this.$route.name !=
                  this.userProfile.Client.initialRouteOnLoad &&
                // The exception being procore
                this.$route.name != "procore"
              )
                this.$router.push({
                  name: this.userProfile.Client.initialRouteOnLoad
                });
            }
            if (loadVirtualCard) {
              this.$store.state.displayWalletMenu = true;
              this.$store.state.walletMenuScreen = "display-virtual";
            }
            // Then I manually flip the boolean that is forcing this onboarding flow to show up so it takes the user to the app
            this.$store.state.userProfile.firstLogin = false;
          }, 1500 - timeDiff);
          // });
        })
        .catch(error => {
          console.log("Error completing onboarding: ", error);
          return (this.currentSlide = this.keys.error);
        });
    },
    sendResetEmail() {
      this.sendingResetEmail = true;
      UserService.resetPassword(this.email).then(x => {
        console.log("Password reset email ", x);
        this.sendingResetEmail = false;
      });
    },
    async getClientFromOnboardingLink() {
      try {
        var startTime = new Date();
        let onboardingToken = this.$route.params.onboardingToken;

        this.currentSlide = this.keys.loadingOnboardingToken;
        this.errorMessage = "Uh oh, we couldn't find your signup experience";
        this.client = {};

        console.log(
          "Getting client based on onboarding link: ",
          onboardingToken
        );
        let response = await UserService.getClientFromOnboardingLink(
          onboardingToken
        );
        console.log("Client response: ", response);
        var timeDiff = moment().diff(moment(startTime));
        if (timeDiff > 1500) timeDiff = 1500;

        setTimeout(() => {
          //  If we got a client back, everything is normal. If no clients were returned then we throw an error
          if (response.result && response.result.count > 0) {
            console.log("Found a client! ", response.result.rows);
            this.client = response.result.rows[0];
            this.errorMessage = null;
            this.currentSlide = this.keys.newUser;
          } else {
            // No client found
            console.log("No clients found with onboarding token");
            this.currentSlide = this.keys.error;
          }
        }, 1500 - timeDiff);
      } catch (err) {
        console.log("Error getting client from onboarding token");
        this.currentSlide = this.keys.error;
      }
    },
    async createUserFromOnboardingLink() {
      try {
        var startTime = new Date();
        let onboardingToken = this.$route.params.onboardingToken;

        this.currentSlide = this.keys.loading;

        let user = {
          firstName: this.firstName,
          lastName: this.lastName,
          businessEmail: this.email,
          businessCountry:
            this.country != "00" ? this.country : this.otherCountry
        };
        console.log("Creating user based on onboarding link: ", user);
        let response = await OnboardingService.createUserFromOnboardingLink(
          onboardingToken,
          user
        );
        console.log("User creation response: ", response);
        var timeDiff = moment().diff(moment(startTime));
        if (timeDiff > 1500) timeDiff = 1500;

        setTimeout(() => {
          //  If we got a client back, everything is normal. If no clients were returned then we throw an error

          this.errorMessage = null;
          this.currentSlide = this.keys.verifyEmail;
        }, 1500 - timeDiff);
      } catch (err) {
        console.log("Error creating user from onboarding token");
        this.errorMessage = "Uh oh, we had trouble creating your new account";
        this.currentSlide = this.keys.error;

        if (err.error_code && err.error_code.endsWith("022")) {
          this.errorMessage =
            "We found an account matching the email you entered.";
          this.displayLoginButton = true;
        } else if (err.error_code && err.error_code.endsWith("011")) {
          this.errorMessage =
            "The email you entered does not appear to be valid.";
          this.displayEditButton = true;
        } else if (err.error_code && err.error_code.endsWith("194")) {
          this.errorMessage = "Uh oh, we couldn't find your signup experience";
        }
      }
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "marqeta",
      "permissions",
      "magicLinkToken",
      "magicLinkRoute"
    ]),
    disableNextButton() {
      return (
        (this.currentSlide == this.keys.user && !this.validAgreementForm) ||
        (this.currentSlide == this.keys.agreements &&
          (!this.cardholderAgreement ||
            !this.eSignAgreement ||
            !this.issuerPrivacyPolicy))
      );
    },
    hasPassedKYB() {
      if (this.marqeta && this.marqeta.canRegister) {
        return true;
      }
      return false;
    },
    showPaymentsMessaging() {
      if (
        (this.userProfile &&
          this.userProfile.Client &&
          this.userProfile.Client.onboardingLanguage &&
          this.userProfile.Client.onboardingLanguage
            .toLowerCase()
            .includes("payment")) ||
        (this.client &&
          this.client.onboardingLanguage &&
          this.client.onboardingLanguage.toLowerCase().includes("payment"))
      ) {
        if (this.learningMessagingOverride) return false;
        return true;
      } else {
        if (this.learningMessagingOverride) return true;
        return false;
      }
    },
    usingOnboardingLink() {
      return this.$route.name == "user-signup" &&
        this.$route.params.onboardingToken
        ? true
        : false;
    }
  },

  watch: {}
};
</script>

<style scoped>
.gradient-background {
  background: linear-gradient(
      90deg,
      rgba(161, 215, 110, 1) 35%,
      rgba(10, 172, 235, 1) 100%
    ),
    white;
  background-size: 100% 120px, 100%;
  background-repeat: no-repeat;
  background-position: bottom, top;
}

.box-outline {
  border: solid;
  border-color: lightgray;
  border-width: 2px;
  background-color: white;
}

.form-card {
  /* min-height: 550px; */
  /* min-height: 80vh; */
  min-height: calc(100% - 100px);
}

.icon-outer-ring {
  width: 110px;
  height: 110px;
}

.large-icon {
  transform: scale(1.5);
}

.icon-inner-ring {
  width: 94px;
  margin: 8px;
  height: 94px;
}

.language-selector {
  max-width: 250px;
}

.title-text {
  font-size: 1.5em;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.progress-bar {
  width: calc(50% - 80px);
}

.mini-icon {
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
}
.download-icon {
  min-width: 24px;
}
</style>
