<template>
  <div class="full-width">
    <div class="d-flex justify-space-between align-center mx-2 mb-4">
      <v-btn text color="primary" @click="close"
        ><v-icon>mdi-chevron-left</v-icon>Back</v-btn
      >
      <v-text-field
        outlined
        placeholder="Search"
        prepend-icon="mdi-magnify"
        clearable
        single-line
        dense
        hide-details
        v-model="search"
        class="shrink"
      ></v-text-field>
    </div>
    <v-data-table
      :headers="budgetColumns"
      :items="budgetActivity"
      :search="search"
      item-key="budgetActivityId"
      :items-per-page="10"
      class="elevation-0 full-width budget-table"
      loading-text="Loading... Please wait"
      no-data-text="No requests found"
    >
      <template v-slot:[`item.amount`]="{ item }">
        <span
          ><span v-if="item.awardId == 1">$</span
          >{{
            formatCurrency(truncateNum(item.amount ? item.amount : 0, 2))
          }}</span
        >
      </template>
      <!-- <template v-slot:[`item.formattedStatus`]="{ item }">
        <div class="d-flex align-center">
          <v-icon
            v-if="item.formattedStatus == 'Complete'"
            class="mr-2"
            color="success"
            >mdi-check-circle-outline</v-icon
          >
          <v-icon
            v-else-if="item.formattedStatus == 'Pending'"
            class="mr-2"
            color="orange"
            >mdi-clock-outline</v-icon
          >
          <v-icon
            v-else-if="item.formattedStatus == 'Request Pending'"
            class="mr-2"
            color="orange"
            >mdi-account-clock</v-icon
          >

          <span>{{ item.formattedStatus }}</span>
        </div>
      </template> -->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center">
          <v-btn
            width="100"
            class="mr-2"
            outlined
            color="red"
            :disabled="loadingRequestId !== null"
            :loading="loadingRequestId == item.budgetActivityId"
            @click="
              declineRequest(
                item.budgetActivityId,
                item.budgetId,
                item.sourceBudgetId
              )
            "
            >Decline</v-btn
          >
          <v-btn
            width="100"
            color="primary"
            :disabled="loadingRequestId !== null"
            @click="loadBudgetTransfer(item)"
            >Accept</v-btn
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import Robin from "@/components/Robin";
import WalletService from "@/services/WalletService";
import moment from "moment";
import { mapState } from "vuex";
import { truncateNumber, numberWithCommas } from "@/shared_data/functions";

export default {
  name: "BudgetActivityRequests",
  props: { budgets: Array, clientId: Number, budgetId: Number },
  components: {
    // Robin,
  },
  data() {
    return {
      currentSlide: 0,
      slideDirection: "topic-left",
      search: null,
      loadingRequestId: null,
      budgetColumns: [
        { text: "Date", value: "createdAtFormatted", width: "15%" },
        { text: "Budget", value: "budgetName", width: "20%" },
        // { text: "Type", value: "type", width: "10%" },
        { text: "Description", value: "description", width: "30%" },
        { text: "Amount", value: "amount", width: "15%" },
        // {
        //   text: "Status",
        //   value: "formattedStripeStatus",
        //   width: "25%",
        //   align: "center",
        // },
        {
          text: "",
          value: "actions",
          width: "25%",
          align: "center"
        }
      ]
    };
  },
  created() {},
  beforeDestroy() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    close() {
      this.$emit("close");
    },
    declineRequest(id, budgetId, sourceId) {
      var startTime = new Date();
      this.loadingRequestId = id;
      // we want to mark it as declined and then we want to avoid refreshing all budgets again and again
      // So we locally update the variable
      WalletService.updateBudgetFundingRequest(id, "Declined").then(resp => {
        console.log("Got response from updating activity: ", resp);
        // resp.forEach((x) => (x.name = x.bank_name + "..." + x.last4));
        var timeDiff = moment().diff(moment(startTime));
        if (timeDiff >= 1500) {
          timeDiff = 1500;
        }
        setTimeout(() => {
          if (!resp.error) {
            // The array we update depends on where we came from (filtering for a specific budget, or viewing for a parent)
            var index = this.budgets.findIndex(x => x.budgetId == budgetId);
            if (index !== -1) {
              this.budgets[index].fundRequestCount =
                this.budgets[index].fundRequestCount <= 0
                  ? 0
                  : this.budgets[index].fundRequestCount - 1;
              if (this.budgetId) {
                var actIndex = this.budgets[index].BudgetActivities.findIndex(
                  x => x.budgetActivityId == id
                );
                if (actIndex !== -1) {
                  this.budgets[index].BudgetActivities[actIndex].status =
                    "Declined";
                  this.budgets[index].BudgetActivities[
                    actIndex
                  ].displayInFundingRequests = false;
                }
              } else {
                actIndex = this.budgets[index].SourceBudgetActivities.findIndex(
                  x => x.budgetActivityId == id
                );
                if (actIndex !== -1) {
                  this.budgets[index].SourceBudgetActivities[actIndex].status =
                    "Declined";
                  this.budgets[index].SourceBudgetActivities[
                    actIndex
                  ].displayInFundingRequests = false;
                }
              }

              var sourceIndex = this.budgets.findIndex(
                x => x.budgetId == sourceId
              );
              if (sourceIndex !== -1) {
                if (this.budgetId) {
                  actIndex = this.budgets[
                    sourceIndex
                  ].BudgetActivities.findIndex(x => x.budgetActivityId == id);
                  if (actIndex !== -1) {
                    this.budgets[sourceIndex].BudgetActivities[
                      actIndex
                    ].status = "Declined";
                    this.budgets[sourceIndex].BudgetActivities[
                      actIndex
                    ].displayInFundingRequests = false;
                  }
                } else {
                  actIndex = this.budgets[
                    sourceIndex
                  ].SourceBudgetActivities.findIndex(
                    x => x.budgetActivityId == id
                  );
                  if (actIndex !== -1) {
                    this.budgets[sourceIndex].SourceBudgetActivities[
                      actIndex
                    ].status = "Declined";
                    this.budgets[sourceIndex].SourceBudgetActivities[
                      actIndex
                    ].displayInFundingRequests = false;
                  }
                }
              }
            }
          }
          this.loadingRequestId = null;
        }, 1500 - timeDiff);
      });
    },
    loadBudgetTransfer(activity) {
      // we have to find the source and destination budgets to send
      // We have to find the source and destination
      var destination = this.budgets.find(x => x.budgetId == activity.budgetId);
      var source = this.budgets.find(
        x => x.budgetId == activity.sourceBudgetId
      );
      this.$emit("load-budget-transfer", {
        source: source,
        destination: destination,
        amount: activity.amount,
        budgetActivityId: activity.budgetActivityId
      });
    }
  },
  computed: {
    ...mapState(["userProfile", "displayWalletMenu", "walletMenuScreen"]),
    budgetActivity() {
      if (!this.budgets) {
        return [];
      }
      var budgetActivity = [];
      var filtered = this.budgets.filter(
        x =>
          x.permissions &&
          x.permissions.owner &&
          x.clientId == this.clientId &&
          (!this.budgetId || x.budgetId == this.budgetId)
      );
      filtered.forEach(budget => {
        budgetActivity = budgetActivity.concat(
          this.budgetId
            ? budget.BudgetActivities.filter(x => x.displayInFundingRequests)
            : budget.SourceBudgetActivities.filter(
                x => x.displayInFundingRequests
              )
        );
      });

      return budgetActivity
        .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
        .filter(activity => activity.stripeStatus != "failed")
        .map(activity => {
          activity.createdAtFormatted = moment(activity.createdAt)
            .utc()
            .local()
            .format("MMM DD, YYYY");
          return activity;
        });
    }
  },
  watch: {}
};
</script>

<style scoped>
.budget-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.budget-table >>> .v-data-table-header {
  background-color: white;
}
</style>
