var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{key:1,staticClass:"cursor-pointer",attrs:{"hide-default-header":!_vm.displayHeaders,"headers":_vm.headers,"items":_vm.visibleItems,"items-per-page":_vm.table.itemsPerPage,"footer-props":_vm.table.footerProps,"options":_vm.table.options,"server-items-length":_vm.includeEveryone && _vm.table.total == 0 ? 1 : _vm.table.total,"show-expand":false,"expanded":[],"loading":_vm.table.loading,"loading-text":"loading","must-sort":"","sort-by":"groupId","sort-desc":"","no-data-text":"No groups found","no-results-text":"No groups found","item-key":"groupId","id":"group-table"},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)},"click:row":function($event){return _vm.selectItem($event, false)}},scopedSlots:_vm._u([{key:`item.select`,fn:function({ item }){return [_c('v-simple-checkbox',{attrs:{"color":"brandCyan","ripple":false,"value":_vm.selected.find(x => x.groupId == item.groupId) ? true : false},on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(item, false)}}})]}},{key:`item.info`,fn:function({ item }){return [(item.groupId != 0)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.previewItem(item)}}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-dots-horizontal")])],1):_vm._e()]}},{key:`item.groupDisplayName`,fn:function({ item }){return [_c('span',{staticClass:"word-break"},[_vm._v(_vm._s(item.displayName))])]}},{key:"loading",fn:function(){return [_c('v-progress-circular',{staticClass:"my-8",attrs:{"indeterminate":"","color":"primary","size":50,"width":4}})]},proxy:true},{key:`item.numInGroup`,fn:function({ item }){return [(
              item.groupId != 0 ||
                !(_vm.totalUserCount === null || _vm.totalUserCount === undefined)
            )?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-multiple")]),_c('span',{staticClass:"mr-5"},[_vm._v(_vm._s(!item ? "-" : item.numUsers !== undefined ? item.numUsers : "-"))])],1):_vm._e()]}}],null,true)}),_c('v-bottom-sheet',{attrs:{"inset":"","width":"500"},model:{value:(_vm.dialog.preview),callback:function ($$v) {_vm.$set(_vm.dialog, "preview", $$v)},expression:"dialog.preview"}},[(_vm.item)?_c('v-sheet',{staticClass:"text-center",attrs:{"height":_vm.loading.assignments &&
            (!_vm.item.GroupAssignments || !_vm.item.GroupAssignments.length)
              ? '355px'
              : '475px'}},[_c('div',{staticClass:"pt-4"},[_c('div',{staticClass:"d-flex align-center justify-space-between mx-6"},[_c('v-btn',{staticClass:"hidden",attrs:{"fab":"","color":"lightGrey","small":""}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('v-card-title',{staticClass:"word-break"},[_c('b',[_vm._v(" "+_vm._s(_vm.item.displayName || _vm.item.groupDisplayName || _vm.item.groupName))])]),_c('v-btn',{attrs:{"fab":"","color":"mediumGrey","small":"","depressed":"","elevation":"0"},on:{"click":function($event){_vm.dialog.preview = !_vm.dialog.preview}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-down")])],1)],1),_c('div',[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.item.groupType + " group " || "Unknown Group Type ")+" ")]),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-account")]),_c('h3',[_vm._v(" "+_vm._s(_vm.item.numUsers ? _vm.item.numUsers : _vm.item.GroupAssignments ? _vm.item.GroupAssignments.length : 0)+" ")])],1),(
                  _vm.loading.assignments == false &&
                    (!_vm.item.GroupAssignments || !_vm.item.GroupAssignments.length)
                )?_c('p',{staticClass:"mx-5 mt-7"},[_vm._v(" It looks like there aren't any people in this group. Try choosing a different group. ")]):_c('v-virtual-scroll',{staticClass:"mt-2 mb-5",attrs:{"height":"250","item-height":"45","items":(_vm.item.numUsers || 0) > (_vm.item.GroupAssignments || []).length
                    ? [...(_vm.item.GroupAssignments || []), { button: true }]
                    : _vm.item.GroupAssignments || []},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [(item.button)?_c('v-btn',{staticClass:"mb-3 mt-4",attrs:{"loading":_vm.loading.assignments,"color":"brandCyan","rounded":"","text":""},on:{"click":function($event){return _vm.getGroupAssignments(false)}}},[_vm._v("+ Load more")]):_c('v-list-item',{key:item.groupAssignmentId},[_c('v-list-item-content',{staticClass:"text-left ml-2 mt-2"},[_c('span',{staticClass:"text-overflow-clip"},[_vm._v(_vm._s(item.displayName || item.User.displayName))])]),_c('v-list-item-content',{staticClass:"text-left mt-2 text-overflow-clip"},[_c('span',{staticClass:"text-overflow-clip"},[_vm._v(_vm._s(item.User.businessEmail || item.User.businessPhone))])])],1),_c('v-divider',{staticClass:"mx-5"})]}}],null,false,1960057583)})],1),(_vm.item && _vm.item.groupId)?_c('v-btn',{staticClass:"mt-auto white--text",attrs:{"color":"brandCyan","rounded":"","depressed":"","width":"90"},on:{"click":function($event){return _vm.selectItem(_vm.item, true)}}},[_vm._v("Add")]):_vm._e()],1)]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }