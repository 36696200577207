<template>
  <v-card flat elevation="0">
    <v-toolbar dark color="#09ACEC" rounded="0">
      <v-toolbar-title> Export {{ table }}s </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="closeWindow">
          Cancel
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <div v-if="activeSlide == keys.user" key="0" class="mt-10">
      <v-card-text class="d-flex flex-column text-left pl-6">
        <h2>Select the {{ table }} columns you want in the file</h2>

        <v-form
          ref="userForm"
          v-model="userForm.valid"
          class="full-width pl-3 mt-6"
        >
          <v-autocomplete
            :items="alteredClientArr"
            outlined
            dense
            item-text="formattedName"
            item-value="clientId"
            label="Client*"
            v-model="clientId"
            v-if="userProfile.Client.clientId === 1"
          ></v-autocomplete>
          <v-autocomplete
            :items="columns"
            v-model="selectedColumns"
            outlined
            dense
            multiple
            label="Columns in file"
            :loading="loading.columns"
          ></v-autocomplete>
          <p>Leave all boxes unchecked to download a file with all columns</p>

          <!-- <p class="mt-3">
              Your file should contain a single column with either email address
              or phone number of the users to delete
            </p>
            <div @drop.prevent="dragAndDropCSVFileChanged" @dragover.prevent>
              <v-file-input
                label="Select your CSV"
                class="fileInput"
                accept=".csv"
                v-model="file"
                outlined
                dense
                prepend-inner-icon="$file"
                prepend-icon=""
                color="brandCyan"
              ></v-file-input>
            </div> -->
        </v-form>
      </v-card-text>
      <v-card-actions
        class="pb-8 pl-9 d-flex flex-column justify-start align-start"
      >
        <v-btn
          color="brandCyan"
          class="white--text"
          depressed
          @click="exportFile"
          :loading="loading.csv"
        >
          Export file
        </v-btn>
        <p v-if="errorMessage" class="red--text text-left mt-3">
          {{ errorMessage }}
        </p>
      </v-card-actions>
    </div>
    <div
      v-else-if="activeSlide == keys.loading"
      key="2"
      class="word-break mt-10 d-flex flex-column align-center text-left"
    >
      <v-card-text class="full-width pl-6">
        <h2>
          Deleting the selected users...
        </h2>

        <span class="text--caption mt-2" v-if="file"
          >This might take a little while but we will email you once we're
          done.</span
        >
      </v-card-text>
    </div>
    <!-- Success message displayed -->
    <div v-else-if="activeSlide == keys.success" key="3">
      <!-- <v-card-title class="dialog-header" color="primary">
                  Add a new person
                </v-card-title> -->
      <!-- <v-divider /> -->
      <v-card-text
        class="d-flex-column align-center justify-center mt-10 word-break"
      >
        <v-card-text class="full-width pl-6">
          <h2>
            Success!!
          </h2>
        </v-card-text>
        <div class="d-flex justify-center"></div>

        <v-btn
          class="ml-3 white--text"
          color="brandCyan"
          depressed
          @click="closeWindow"
        >
          Close
        </v-btn>
      </v-card-text>
    </div>
    <div
      v-else-if="activeSlide == keys.error || activeSlide == keys.csvError"
      key="4"
    >
      <v-card-text
        class="d-flex-column align-center justify-center mt-10 word-break"
      >
        <v-card-text class="full-width text-left pl-4">
          <h2>
            {{
              errorMessage
                ? errorMessage
                : "Looks like there was a problem processing your file"
            }}
          </h2>
        </v-card-text>
        <div class="justify-center">
          <p class="pl-4 full-width text-left">
            Feel free to reach out to customer service via one of the following
            methods...
          </p>
          <p class="pl-4 text-left full-width">
            Email -
            <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
          </p>
          <p class="pl-4 text-left full-width">
            Phone (Toll Free) - <b>(855) 264-3329</b>
          </p>
        </div>
        <div
          class="text-left pl-4 pt-4 top-border"
          v-if="fileFailedRequirements.length > 0"
        >
          <p v-for="row in fileFailedRequirements" :key="row.index">
            <span class="font-weight-bold">Row {{ row.index }}:</span> Missing
            {{ row.fields.join(", ") }}
          </p>
        </div>
        <v-btn outlined color="brandCyan" @click="goBackFromError" class="mt-3">
          Go back
        </v-btn>
        <v-btn
          class="ml-3 mt-3 white--text"
          color="brandCyan"
          depressed
          @click="closeWindow"
        >
          Close
        </v-btn>
      </v-card-text>
    </div>

    <!-- Dialog to show results of CSV upload for users -->
    <v-dialog v-model="showUploadResults" width="500">
      <v-card rounded="0" class="pa-6 word-break">
        <h2 class="mt-2">
          <v-icon large class="mr-2" color="error">mdi-alert</v-icon>CSV Bulk
          Deletion Results
        </h2>
        <v-card-text class="d-flex flex-column justify-center align-center">
          <p class="my-3" v-if="fileSuccess.length > 0">
            {{ fileSuccess.length }}
            {{ fileSuccess.length == 1 ? "person" : "people" }} successfully
            deleted!
          </p>
          <p class="my-3" v-if="fileFailedRequirements.length > 0">
            Looks like some rows in your CSV were missing some required fields.
          </p>
          <p class="mb-3" v-if="fileFailedRequirements.length > 0">
            Rows: {{ fileFailedRequirements.toString() }}
          </p>

          <p class="mb-1 mt-3" v-if="fileErrors.length > 0">
            Uh oh! It looks like we had some trouble with finding or deleting
            the following users:
          </p>
          <p
            v-for="(error, index) in fileErrors"
            :key="`error-email-${index}`"
            class="errorEmailList mb-2"
          >
            <span class="font-weight-bold">{{ error }}</span
            >{{
              error.invalidReason
                ? " - " + error.invalidReason
                : error.failedReason
                ? " - " + error.failedReason
                : ""
            }}
          </p>
        </v-card-text>

        <v-card-actions class="mx-12">
          <v-spacer />
          <v-btn
            color="brandCyan"
            class="white--text"
            depressed
            @click="clearFileArrays"
            width="130"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="errorDialog.display" width="500"> -->
    <ErrorDialog
      v-if="errorDialog.display"
      :title="errorDialog.title"
      :subtitle="errorDialog.subtitle"
      :button1Text="errorDialog.button1Text"
      :button2Text="errorDialog.button2Text"
      @button2="errorDialog.display = false"
      @close="errorDialog.display = false"
    />
  </v-card>
</template>

<script>
import UserService from "@/services/UserService";

import ErrorDialog from "@/components/ErrorDialog";

import { mapState } from "vuex";

function initialState() {
  return {
    keys: {
      user: 0,
      bulk: 1,
      loading: 2,
      success: 3,
      error: 4,
      csvError: 5
    },
    activeSlide: 0,
    emailErrorDialog: false,
    showUploadResults: false,
    file: undefined,
    fileSuccess: [],
    fileErrors: [],
    fileFailedRequirements: [],
    confirmDelete: false,
    errorMessage: null,

    userForm: {
      valid: false,
      imageUrlFileName: null,
      editing: false,
      showSuccess: false,
      transitionDirection: "topic-right"
    },

    // Used by the error component
    errorDialog: {
      display: false,
      override: false,
      title: null,
      subtitle: null,
      button1Text: null,
      button2Text: null
    },

    clientId: null,
    columns: [],
    selectedColumns: [],
    loading: {
      columns: false,
      csv: false
    }
  };
}

export default {
  name: "CSVExport",
  components: {
    ErrorDialog
  },
  props: {
    table: {
      type: String,
      default: "User"
    }
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created");
    this.clientId = this.globalClientId || this?.userProfile?.clientId;
    this.getColumns();
  },
  mounted() {},
  methods: {
    async getColumns() {
      try {
        const res = await UserService.getExportColumns(
          this.table == "UserClient" ? "UserClient,User" : this.table
        );
        console.log(res);
        this.columns = res?.rows || res?.result?.rows || res || [];
        this.selectedColumns = this.columns;
      } catch (e) {
        console.log("error getting columns", e);
      }
    },

    closeWindow() {
      this.$emit("close");
    },

    getUsers(preloadId = null) {
      this.$emit("reset-selected");
      this.$emit("get-users", preloadId);
    },

    async exportFile() {
      try {
        console.log("exportFile...");
        this.loading.csv = true;
        this.errorMessage = null;

        await UserService.exportDataV2(this.table, {
          clientId: this.clientId,
          columns: this.selectedColumns.length
            ? this.selectedColumns.join(",")
            : undefined
        });
      } catch (e) {
        console.log("Error exporting file ", e);
        this.errorMessage = "Error exporting file";
      } finally {
        this.loading.csv = false;
      }
    },

    clearFileArrays() {
      this.showUploadResults = false;
      this.fileSuccess = [];
      this.fileErrors = [];
      this.fileFailedRequirements = [];
      this.closeWindow();
    },

    goBackFromError() {
      this.errorMessage = null;
      this.showUploadResults = false;
      this.fileSuccess = [];
      this.fileErrors = [];
      this.fileFailedRequirements = [];
      this.activeSlide = this.keys.user;
    },

    dragAndDropCSVFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        // Load file into temp variable for cropping
        console.log(e.dataTransfer.files[0]);
        if (
          e.dataTransfer.files[0].name.endsWith(".csv") ||
          e.dataTransfer.files[0].type == "text/csv"
        )
          this.file = e.dataTransfer.files[0];
      }
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "globalClientId"]),
    alteredClientArr() {
      if (this?.userProfile?.clientId != 1) return [];

      return [
        { clientId: 0, formattedName: "All clients" },
        ...this.clients.slice(1)
      ];
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
