<template>
  <v-app-bar
    app
    clipped-left
    flat
    color="white"
    class="top-nav full-screen-width"
  >
    <div class="d-flex align-center justify-space-between full-screen-width">
      <v-btn icon small class="d-sm-flex d-md-none mr-2" @click="openNav"
        ><v-icon>mdi-menu</v-icon></v-btn
      >
      <!-- {{ wid }} -->
      <div class="d-flex align-center ml-3">
        <!-- Whistle Logo -->
        <!-- Display Whistle logo if not whitelabeling or the company logo url is null or doesn't start with https -->
        <v-img
          v-if="
            userProfile &&
              userProfile.Client &&
              (userProfile.Client.whitelabel == false ||
                userProfile.Client.companyPicUrl == null ||
                (!userProfile.Client.companyPicUrl.startsWith('https') &&
                  !userProfile.Client.companyPicUrl.startsWith('data:image')))
          "
          src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
          :height="imageHeight"
          :width="imageWidth"
          contain
          class="cursor-pointer"
          @click="$router.push({ name: 'Home' })"
          :class="{
            'hidden-sm-and-down': userProfile.Client.whitelabel == true
          }"
        />
        <!-- Client / Company Logo -->
        <v-img
          v-if="
            userProfile &&
              userProfile.Client &&
              userProfile.Client.companyPicUrl !== null
          "
          :src="userProfile.Client.companyPicUrl"
          :height="imageHeight"
          :width="imageWidth"
          contain
          class="ml-5"
          :class="{
            'hidden-sm-and-down': userProfile.Client.whitelabel == false
          }"
        />
      </div>
      <!-- <v-spacer class="d-none d-sm-flex"></v-spacer> -->
      <!-- <SearchBar class="hidden-sm-and-down"></SearchBar> -->
      <!-- <v-spacer class="d-none d-sm-flex"></v-spacer> -->
      <div class="d-flex align-center">
        <!-- Begin impersonation button -->
        <v-btn
          class="hidden-sm-and-down"
          v-if="
            !magicLinkToken && this.permissions.includes('vue:can:impersonate')
          "
          text
          @click="toggleImpersonate()"
        >
          <span> Switch to {{ impersonateState }} View</span>
          <v-icon class="ml-2">mdi-account</v-icon>
        </v-btn>

        <!-- End impersonation button -->

        <!-- Begin wallet button -->
        <v-btn
          v-if="displayWallet"
          color="brandCyan"
          text
          @click="toggleWallet"
          class="mr-1"
          :x-small="$vuetify.breakpoint.xsOnly"
        >
          <span class="hidden-sm-and-down mr-2">Wallet</span>
          <v-icon>mdi-wallet</v-icon>
        </v-btn>
        <!-- End wallet button -->

        <!-- Begin Magic Link button -->
        <v-btn
          v-if="magicLinkToken"
          text
          @click="refreshPage"
          :x-small="$vuetify.breakpoint.xsOnly"
        >
          Continue to Whistle
          <v-icon class="ml-1 mb-1" v-if="!$vuetify.breakpoint.xs"
            >mdi-arrow-right</v-icon
          >
        </v-btn>
        <!-- End Magic Link button -->
        <!-- Begin account button -->
        <v-btn
          v-else
          text
          @click="toggleProfile"
          :x-small="$vuetify.breakpoint.xsOnly"
        >
          <span class="hidden-sm-and-down mr-2">My Account</span>
          <v-img
            v-bind:src="userProfile.profilePicUrl"
            :aspect-ratio="1 / 1"
            v-if="userProfile && userProfile.profilePicUrl !== null"
            width="35"
            height="35"
            class="circle-border-radius profile-picture"
          />
          <v-icon v-else class="profile-picture circle-border-radius icon-image"
            >mdi-account</v-icon
          >
        </v-btn>
        <!-- End account button -->
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "navBar",
  components: {
    // Wallet,
    // SearchBar,
  },
  props: {
    msg: String,
    walletMenu: Boolean
  },
  data() {
    return {};
  },
  created() {
    //Starts Drew on participant view for demo
    if (
      this.userProfile &&
      this.userProfile.userId === "auth0|5fd7ae16c71f8c006909e72b"
    ) {
      this.impersonateState = "participant";
    }
    // console.log(this.$store.state.impersonate);
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    refreshPage() {
      // We refresh the page to basically switch away from magic link auth to auth0.
      // this allows us to check their session to know if they're already logged in.
      document.location.reload();
    },
    toggleProfile() {
      this.$emit("toggle-profile");
    },
    toggleWallet() {
      // console.log(this.walletMenu);
      // if (this.walletMenu == true) {
      //   this.walletMenu = false;
      // } else {
      //   this.walletMenu = true;
      // }

      this.$emit("wallet-menu");
    },
    toggleImpersonate() {
      //ok so here's what's up
      //right now there's not much for impersonate to do, so it really just hides the admin pages from the nav
      //and routes you to a participant page
      //Once we actually finish some participant pages I want to work on a way to create some 'dummy users'
      //I'm thinking we can maybe load some permissions into the user's meta? Does it work that way?
      //And then microservices could read from that meta if it exists and if the token has the vue:can:impersonate base permission

      if (this.impersonate) {
        this.impersonateState = null;
      } else {
        if (
          this.$route.name != "activity" &&
          this.$route.name != "learning" &&
          this.$route.name != "programs"
        ) {
          this.$router.push({ name: "activity" });
        }
        this.impersonateState = "participant";
      }
      console.log(this.impersonate);
    },
    openNav() {
      console.log("Toggle");
      this.$emit("toggle-nav");
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "clients",
      "impersonate",
      "permissions",
      "magicLinkToken"
    ]),

    //this is rudementary for now. I think eventually we'll want to populate a dropdown of roles you can impersonate
    //but if you're already impersonating, the only option is to return to admin
    impersonateState: {
      get: function() {
        if (this.impersonate) {
          return "admin";
        } else {
          return "participant";
        }
      },
      set: function(newVal) {
        this.$store.dispatch("setImpersonate", newVal);
      }
    },
    imageHeight() {
      var size = this.$vuetify.breakpoint.name;
      if (size == "xs") {
        return 45;
      } else if (size == "sm") {
        return 50;
      } else {
        return 50;
      }
    },
    imageWidth() {
      var size = this.$vuetify.breakpoint.name;
      if (size == "xs") {
        return 90;
      } else if (size == "sm") {
        return 100;
      } else {
        return 120;
      }
    },
    wid() {
      console.log(window.innerWidth);
      return window.innerWidth;
    },
    displayWallet() {
      if (this.magicLinkToken) return false;
      else if (this.userProfile && this.userProfile.Client)
        return this.userProfile.Client.displayWalletPage;
      return true;
    }
  }
};
</script>

<style scoped>
.v-list-item--dense .v-list-item__icon,
.v-list--dense .v-list-item .v-list-item__icon {
  height: 24px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.v-list.v-list--dense {
  padding: 0;
}

.profile-picture {
  border: 2px solid;
  border-color: var(--v-grey-base);
}
.icon-image {
  height: 35px;
  width: 35px;
}
</style>

<style>
header.top-nav .v-toolbar__content {
  border-bottom: 6px solid var(--v-brand-base);
}
</style>
