<template>
  <div>
    <v-dialog
      fullscreen
      value="true"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="brandDarkCyan" rounded="0" elevation="0">
          <v-btn dark icon large @click="reset" class="ml-1">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title class="d-flex align-center font-weight-bold"
            ><v-icon v-if="!isMobile" class="mr-3">mdi-party-popper</v-icon>
            Send a recognition</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            dark
            icon
            large
            @click="reset"
            class="ml-1 hidden"
            v-if="userProfile.clientId != 1"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-autocomplete
            v-else
            outlined
            dense
            hide-details
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client"
            style="max-width: 250px"
            v-model="clientId"
            @change="refreshClientId"
            :disabled="slideKey > keys.review"
          ></v-autocomplete>
        </v-toolbar>
        <v-row
          class="full-height mt-6 pb-6 container-row"
          justify="center"
          align="center"
          height="100%"
          no-gutters
        >
          <v-col
            cols="11"
            sm="11"
            md="5"
            lg="4"
            xl="3"
            class="main-card rounded-lg px-5 d-flex flex-column justify-space-between"
          >
            <div>
              <div class="d-flex justify-space-between align-center mt-10">
                <pill-nav
                  :steps="pillNavSteps"
                  width="100%"
                  :height="40"
                  :step="slideKey"
                  :value="slideKey > keys.review ? progressBarValue : undefined"
                  :color="loading.submitting ? 'brandDarkCyan' : 'brandCyan'"
                  @input="goToSlide"
                >
                </pill-nav>
                <div class="lottie-container">
                  <v-btn
                    :width="isMobile ? 90 : 110"
                    rounded
                    depressed
                    color="brandCyan"
                    class="white--text font-weight-bold"
                    @click="goToNextSlide"
                    :disabled="disableContinueButton"
                  >
                    <v-icon
                      color="white"
                      class="mr-1"
                      v-if="slideKey == keys.sent"
                      >mdi-checkbox-marked-circle</v-icon
                    >
                    <v-icon
                      color="white"
                      class="mr-1"
                      v-else-if="!isMobile && slideKey != keys.review"
                      >mdi-arrow-right-circle</v-icon
                    >
                    <span
                      v-if="slideKey !== keys.sent"
                      style="margin-top: 2px"
                      >{{
                        slideKey == keys.sent
                          ? "Close"
                          : slideKey == keys.review
                          ? "Send"
                          : "Next"
                      }}</span
                    >
                  </v-btn>
                  <div v-if="lottie.particle" class="lottie-particle-effect">
                    <LottiePlayer
                      animation="particles"
                      :height="250"
                      :width="250"
                      :speed="1.5"
                      :loop="false"
                    />
                  </div>
                </div>
              </div>

              <!-- </div> -->
              <transition :name="slideDirection" mode="out-in">
                <div
                  v-if="slideKey === keys.recipient"
                  :key="keys.recipient"
                  class="full-width text-left"
                >
                  <p class="text-h6 font-weight-bold mt-12">
                    Who are you recognizing?
                  </p>
                  <!-- <p class=" mt-2">
                    Each recipient of a
                    {{ paymentTileName || "Team Rewards Budgets" }} will be able
                    to send those funds to team members as rewards,
                    recognitions, or bonuses.
                  </p> -->
                  <div class="d-flex align-center flex-wrap mt-4">
                    <div
                      v-for="(user, index) in values.selected"
                      :key="user.userId"
                      :style="{ 'max-width': '100%' }"
                    >
                      <v-chip
                        class="ma-1 selected-audience-chip"
                        close
                        outlined
                        color="brandCyan"
                        @click:close="removeUserFromSelected(index)"
                      >
                        <span class="text-truncate black--text">{{
                          user.displayName
                        }}</span>
                      </v-chip>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between align-center mt-10">
                    <v-text-field
                      label="Start typing a name, email, or phone"
                      v-model="data.debounceSearch"
                      outlined
                      flat
                      dense
                      hide-details
                      class="search-field mr-3"
                      @focus="changeSearchTab"
                    >
                    </v-text-field>
                    <v-btn
                      v-if="
                        (data.activeAudienceTab == 2 && canCreateGroup) ||
                          (canCreateUser && data.activeAudienceTab != 2)
                      "
                      fab
                      depressed
                      small
                      color="brandCyan"
                      @click="
                        if (data.activeAudienceTab == 2) {
                          dialog.createGroup = true;
                        } else {
                          dialog.createUser = true;
                        }
                      "
                      ><v-icon color="white">{{
                        data.activeAudienceTab == 2
                          ? "mdi-account-multiple-plus"
                          : "mdi-account-plus"
                      }}</v-icon></v-btn
                    >
                  </div>
                  <div
                    v-if="(permissions || []).includes('vue:read:groups')"
                    class="d-flex justify-space-between align-center mt-6"
                    :class="{ 'mx-14': !isMobile }"
                  >
                    <!-- <span
                      @click="changeAudienceTab(0)"
                      class="rounded-pill audience-tab cursor-pointer"
                      :class="{
                        'active-audience-tab': data.activeAudienceTab == 0
                      }"
                      >Recent</span
                    > -->
                    <span
                      @click="changeAudienceTab(1)"
                      class="rounded-pill audience-tab cursor-pointer"
                      :class="{
                        'active-audience-tab': data.activeAudienceTab == 1
                      }"
                      >People</span
                    >
                    <span
                      @click="changeAudienceTab(2)"
                      class="rounded-pill audience-tab cursor-pointer"
                      :class="{
                        'active-audience-tab': data.activeAudienceTab == 2
                      }"
                      >Groups</span
                    >
                  </div>
                  <div class="text-left mt-2">
                    <v-divider class="mt-5" />
                    <!-- Table for people -->
                    <div v-if="data.activeAudienceTab <= 1">
                      <UserTable
                        ref="user-table"
                        :selected="selectedUsers"
                        :search="data.search"
                        @select-item="addUserToSelected($event, false)"
                      />
                    </div>
                    <div v-else>
                      <GroupTable
                        ref="group-table"
                        :selected="values.selected"
                        :search="data.search"
                        @select-item="addUserToSelected($event, false)"
                        @update-item="updateSelectedItem"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey === keys.memo"
                  :key="keys.memo"
                  class="full-width text-left"
                >
                  <p class="text-h6 font-weight-bold mb-0 mt-12">
                    Tell them what was special
                  </p>
                  <p class="font-italic mb-5 mt-0 grey--text font-weight-bold">
                    <!-- {{ feedback }} -->
                    <template v-if="isAdmin">
                      <span
                        class="px-5"
                        :key="k"
                        v-for="(v, k) in feedback.vibe"
                      >
                        <v-badge
                          class="nudge-up"
                          bottom
                          dot
                          left
                          :color="v ? 'green' : 'grey'"
                        >
                          {{ k }}
                        </v-badge>
                      </span>
                    </template>
                    <span v-else>behavior, feeling, impact</span>
                  </p>
                  <v-textarea
                    placeholder="Daniel, I am consistently impressed by your patience with every customer interaction that you face. Your ability to create space for their needs to be heard and calm approach to problem solving allow us to remain a trusted partner to our customers."
                    outlined
                    v-model="values.memo"
                    color="brandCyan"
                  ></v-textarea>
                  <template v-if="isAdmin">
                    <template v-if="!feedback.state">
                      <v-btn
                        color="blue"
                        v-if="feedback.recommendation"
                        outlined
                        @click="progressTo(feedback.state || 'default')"
                      >
                        <v-avatar class="white pa-1" size="32px">
                          <v-img
                            contain
                            src="@/assets/robin/Curious.png"
                            height="32px"
                          />
                        </v-avatar>
                        Improve my Recognition
                      </v-btn>
                    </template>
                    <template v-else-if="feedback.state === 'waiting'">
                      <div
                        class="d-flex border rounded blue--text pa-2"
                        style="border: 1px solid #2196f3"
                      >
                        <v-avatar class="white pa-1" size="32px">
                          <v-img
                            contain
                            src="@/assets/robin/Curious.png"
                            height="32px"
                          />
                        </v-avatar>
                        <typing />
                      </div>
                    </template>
                    <template v-else-if="feedback.state === 'done'">
                      <div
                        class="border rounded blue--text pa-2"
                        style="border: 1px solid #2196f3"
                      >
                        <div class="d-flex flex-column">
                          <p>{{ feedback.recommendation }}</p>
                          <div class="d-flex justify-space-between">
                            <div class="d-flex justify-start">
                              <v-avatar class="white pa-1" size="32px">
                                <v-img
                                  contain
                                  src="@/assets/robin/Curious.png"
                                  height="32px"
                                />
                              </v-avatar>
                            </div>
                            <div class="d-flex justify-space-between">
                              <v-btn
                                outlined
                                rounded
                                class="mx-2"
                                small
                                color="blue"
                                @click="aiTryAgain"
                                >Try Again</v-btn
                              >
                              <v-btn
                                outlined
                                rounded
                                class="mx-2"
                                small
                                color="blue"
                                @click="values.memo = feedback.recommendation"
                                >Use this</v-btn
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                  <p class="text-h6 font-weight-bold mb-0 mt-6">Visibility</p>
                  <div class="d-flex align-center mb-3">
                    <v-radio-group row v-model="values.visibility"
                      ><v-radio
                        value="PRIVATE"
                        label="Private"
                        color="brandCyan"
                      ></v-radio
                      ><v-radio
                        value="PUBLIC"
                        label="Public"
                        color="brandCyan"
                      ></v-radio
                    ></v-radio-group>

                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }"
                        ><v-icon v-on="on" v-bind="attrs" class="ml-3 mb-1"
                          >mdi-help-circle-outline</v-icon
                        ></template
                      ><span
                        >A public recognition will be displayed company-wide in
                        the Activity Feed</span
                      ></v-tooltip
                    >
                  </div>
                  <!-- <div class="d-flex align-center mb-3">
                    <span>Private</span>
                    <v-switch
                      hide-details
                      true-value="PUBLIC"
                      false-value="PRIVATE"
                      v-model="values.visibility"
                      class="my-0 py-0 mx-3"
                      color="brandCyan"
                    />
                    <span>Public</span>
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }"
                        ><v-icon v-on="on" v-bind="attrs" class="ml-3" small
                          >mdi-help-circle-outline</v-icon
                        ></template
                      ><span
                        >A public recognition will be displayed company-wide in
                        the Activity Feed</span
                      ></v-tooltip
                    >
                  </div> -->
                </div>
                <div
                  v-else-if="slideKey === keys.celebration"
                  :key="keys.celebration"
                  class="full-width text-left"
                >
                  <p class="text-h6 font-weight-bold mb-5 mt-12">
                    Add some flair to your recognition
                  </p>
                  <ElementSelector
                    v-if="loading.budgets || visibleBudgets.length"
                    type="reward"
                    ref="reward-selector"
                    :reviewPage="false"
                    :loading="loading.presets"
                    :cashAward="cashAward"
                    :presets="data.presets"
                    :emoji="values.rewardEmoji"
                    :customEmoji="values.customRewardEmoji"
                    :amount="values.rewardAmount"
                    :customAmount="values.customRewardAmount"
                    @select-reward="selectPaymentPreset"
                    @select-custom-reward="addCustomPreset"
                    @update-custom-amount="values.customRewardAmount = $event"
                    @update-custom-emoji="values.customRewardEmoji = $event"
                    @clear="clearPaymentPreset"
                  ></ElementSelector>
                  <ElementSelector
                    type="gif"
                    ref="gif-selector"
                    :reviewPage="false"
                    :gif="values.gif"
                    @select-gif="selectGif"
                    @clear="clearGif"
                  ></ElementSelector>

                  <div
                    class="cursor-pointer mt-6"
                    v-if="loading.values || data.values.length"
                  >
                    <v-select
                      outlined
                      multiple
                      chips
                      deletable-chips
                      return-object
                      hide-selected
                      attach
                      :menu-props="{ top: true, offsetY: true }"
                      :loading="loading.values"
                      v-model="values.companyValues"
                      :items="data.values"
                      item-text="displayName"
                      color="brandCyan"
                      label="Company values"
                      class="full-width"
                      :no-data-text="
                        values.companyValues && values.companyValues.length
                          ? 'No other company values available'
                          : 'No company values available'
                      "
                    />
                  </div>
                  <!-- {{ values.gif }}
                  <v-img
                    src="http://gph.is/19LN9TL"
                    width="200"
                    contain
                  ></v-img>
                  <v-img
                    src="https://media4.giphy.com/media/UO5elnTqo4vSg/200.gif?cid=66b1752f2j3n2t7sufx84kajdgezzmahapqfi92skzddqfr1&ep=v1_gifs_trending&rid=200.gif&ct=g"
                    width="200"
                    contain
                  ></v-img>
                  <img
                    src="https://giphy.com/embed/UO5elnTqo4vSg"
                    width="200"
                  /> -->

                  <!-- <div class="d-flex justify-space-between align-center mb-5">
                    <div class="d-flex flex-column text-sm-left">
                      <span class="brandCyan--text">Amount per person</span>
                      <v-text-field
                        outlined
                        dense
                        placeholder="0.00"
                        color="brandCyan"
                        :prefix="cashAward ? '$' : ''"
                        class="mt-0 pt-0 amount-text-field"
                        type="number"
                        hide-details
                        v-model="values.amount"
                        :rules="rules.amount"
                      ></v-text-field>
                    </div>

                    <div class="d-flex align-center brandCyan--text mt-4">
                      <v-progress-circular
                        indeterminate
                        :size="20"
                        :width="2"
                        color="brandCyan"
                        v-if="loading.distinctUsers"
                      ></v-progress-circular>
                      <span v-else> {{ paymentAudienceCount }}</span>
                      <v-icon color="brandCyan" class="ml-1"
                        >mdi-account-multiple</v-icon
                      >
                    </div> -->
                  <!-- <div class="d-flex flex-column text-sm-left">
                      <span class="">Total funded</span>
                      <v-text-field
                        outlined
                        readonly
                        dense
                        placeholder="0.00"
                        color="brandCyan"
                        :prefix="cashAward ? '$' : ''"
                        class="mt-0 pt-0 amount-text-field"
                        type="number"
                        hide-details
                        v-model="totalAmount"
                        :rules="rules.amount"
                      ></v-text-field>
                    </div>
                  </div> -->
                </div>
                <div
                  v-else-if="slideKey === keys.review"
                  :key="keys.review"
                  class="full-width text-left pb-4"
                >
                  <div>
                    <p class="text-h6 font-weight-bold mb-5 mt-12">
                      Does this look right?
                    </p>
                    <div class="d-flex flex-wrap align-center">
                      <v-progress-circular
                        indeterminate
                        :size="20"
                        :width="2"
                        color="brandCyan"
                        v-if="loading.distinctUsers"
                      ></v-progress-circular>
                      <span v-else
                        >{{ paymentAudienceCount }} recipient{{
                          paymentAudienceCount == 1 ? "" : "s"
                        }}:</span
                      >
                      <div
                        v-for="user in values.selected"
                        :key="user.userId"
                        :style="{ 'max-width': '100%' }"
                      >
                        <v-chip
                          @click="goToSlide(keys.recipient)"
                          class="ma-1 selected-audience-chip"
                          outlined
                          color="brandCyan"
                        >
                          <span class="text-truncate black--text">{{
                            user.displayName
                          }}</span>
                        </v-chip>
                      </div>
                    </div>
                    <div
                      class="red--text mt-5"
                      v-if="amountRemainingInBudget < 0"
                    >
                      Over budget by {{ amountRemainingInBudgetFormatted }}.
                      Please
                      <a
                        @click="dialog.funding = true"
                        v-if="
                          selectedBudget &&
                            selectedBudget.permissions &&
                            selectedBudget.permissions.owner
                        "
                        >add more funds</a
                      ><span v-else>add more funds</span>
                      or select a different budget at the bottom.
                    </div>
                    <p class="text-caption mb-1 darkGrey--text mt-5">Memo</p>
                    <v-textarea
                      placeholder="Daniel, I am consistently impressed by your patience with every customer interaction that you face. Your ability to create space for their needs to be heard and calm approach to problem solving allow us to remain a trusted partner to our customers."
                      outlined
                      hide-details
                      v-model="values.memo"
                      color="brandCyan"
                    ></v-textarea>
                    <div class="d-flex align-center mt-2 mb-5">
                      <v-radio-group row v-model="values.visibility"
                        ><v-radio
                          value="PRIVATE"
                          label="Private"
                          color="brandCyan"
                        ></v-radio
                        ><v-radio
                          value="PUBLIC"
                          label="Public"
                          color="brandCyan"
                        ></v-radio
                      ></v-radio-group>

                      <v-tooltip top
                        ><template v-slot:activator="{ on, attrs }"
                          ><v-icon v-on="on" v-bind="attrs" class="ml-3 mb-1"
                            >mdi-help-circle-outline</v-icon
                          ></template
                        ><span
                          >A public recognition will be displayed company-wide
                          in the Activity Feed</span
                        ></v-tooltip
                      >
                    </div>
                    <!-- <div class="d-flex align-center mt-2">
                      <span>Private</span>
                      <v-switch
                        hide-details
                        true-value="PUBLIC"
                        false-value="PRIVATE"
                        v-model="values.visibility"
                        class="my-0 py-0 mx-3"
                        color="brandCyan"
                      />
                      <span>Public</span>
                      <v-tooltip top
                        ><template v-slot:activator="{ on, attrs }"
                          ><v-icon v-on="on" v-bind="attrs" class="ml-3" small
                            >mdi-help-circle-outline</v-icon
                          ></template
                        ><span
                          >A public recognition will be displayed company-wide
                          in the Activity Feed</span
                        ></v-tooltip
                      >
                    </div> -->
                    <ElementSelector
                      v-if="hasReward"
                      type="reward"
                      ref="reward-selector"
                      :reviewPage="true"
                      :loading="loading.presets"
                      :cashAward="cashAward"
                      :presets="data.presets"
                      :emoji="values.rewardEmoji"
                      :customEmoji="values.customRewardEmoji"
                      :amount="values.rewardAmount"
                      :customAmount="values.customRewardAmount"
                      @select-reward="selectPaymentPreset"
                      @select-custom-reward="addCustomPreset"
                      @update-custom-amount="values.customRewardAmount = $event"
                      @update-custom-emoji="values.customRewardEmoji = $event"
                      @clear="clearPaymentPreset"
                    />
                    <ElementSelector
                      v-if="values.gif"
                      type="gif"
                      ref="gif-selector"
                      :reviewPage="true"
                      :gif="values.gif"
                      @select-gif="selectGif"
                      @clear="clearGif"
                    />
                    <div class="mb-6" v-if="values.companyValues.length">
                      <v-select
                        outlined
                        multiple
                        chips
                        deletable-chips
                        return-object
                        hide-selected
                        attach
                        :menu-props="{ top: true, offsetY: true }"
                        :loading="loading.values"
                        v-model="values.companyValues"
                        :items="data.values"
                        item-text="displayName"
                        color="brandCyan"
                        label="Company values"
                        class="full-width"
                        :no-data-text="
                          values.companyValues && values.companyValues.length
                            ? 'No other company values available'
                            : 'No company values available'
                        "
                      />
                    </div>
                    <div
                      class="d-flex justify-space-between align-center full-width"
                    >
                      <p
                        class="review-page-text mb-0"
                        :class="{ 'text-xs': $vuetify.breakpoint.xs }"
                      >
                        Send time:
                      </p>
                      <!-- <input
                        class="timestamp-input review-page-field"
                        type="datetime-local"
                        v-model="values.sendDate"
                      /> -->
                      <v-radio-group
                        row
                        v-model="values.sendDateNow"
                        @change="updateSendDate"
                        ><v-radio
                          :value="true"
                          label="Now"
                          color="brandCyan"
                        ></v-radio
                        ><v-radio
                          :value="false"
                          label="Later"
                          color="brandCyan"
                        ></v-radio
                      ></v-radio-group>
                    </div>
                    <div
                      class="d-flex justify-end align-center full-width mt-0"
                      v-if="!values.sendDateNow"
                    >
                      <input
                        class="timestamp-input review-page-field"
                        type="datetime-local"
                        v-model="values.sendDate"
                      />
                    </div>
                    <div
                      class="d-flex justify-start align-center full-width mt-5"
                      v-if="selectedBudget && hasReward"
                    >
                      <p
                        class="review-page-text mt-0 mb-0"
                        :class="{ 'text-xs': $vuetify.breakpoint.xs }"
                      >
                        Reward source:
                      </p>
                      <v-select
                        outlined
                        dense
                        attach
                        :menu-props="{ bottom: true, offsetY: true }"
                        color="brandCyan"
                        :items="visibleBudgets"
                        :loading="loadingBudgetsComputed"
                        v-model="values.budgetId"
                        item-value="budgetId"
                        item-text="displayNameWithBalance"
                        :no-data-text="
                          loadingBudgetsComputed
                            ? 'Loading your budgets'
                            : 'No budgets found'
                        "
                        :rules="[v => !!v || 'Budget is required']"
                        @blur="sendBudgetIdTrigger"
                        class="review-page-field"
                        hide-details
                      >
                        <template v-slot:item="{ item }">
                          <div
                            class="d-flex align-center justify-space-between full-width"
                          >
                            <span
                              :title="item.budgetDisplayName"
                              class="budget-name-container text-overflow-clip"
                            >
                              {{ item.budgetDisplayName }}
                            </span>
                            <span class="text-right word-break"
                              >{{ item.formattedBalance }}
                            </span>
                          </div>
                        </template></v-select
                      >
                    </div>
                    <div
                      v-if="
                        selectedBudget &&
                          selectedBudget.permissions &&
                          selectedBudget.permissions.owner &&
                          hasReward
                      "
                      class="mt-4 full-width d-flex justify-end"
                    >
                      <v-btn
                        text
                        :color="
                          amountRemainingInBudget < 0 ? 'error' : 'brandCyan'
                        "
                        @click="dialog.funding = true"
                      >
                        + Add funds
                      </v-btn>
                    </div>
                    <!-- <div class="d-flex justify-start align-start full-width mb-2">
                  <p class="review-page-text mb-0 mt-2">Funds expire:</p>
                  <div class="d-flex flex-column review-page-field">
                    <v-select
                      outlined
                      dense
                      attach
                      :menu-props="{ top: true, offsetY: true }"
                      color="brandCyan"
                      :items="data.expirationOptions"
                      v-model="values.expiration"
                      item-value="id"
                      item-text="label"
                      class=""
                      hide-details
                    >
                    </v-select>
                    <input
                      v-if="values.expiration == 7"
                      class="timestamp-input mt-2"
                      type="datetime-local"
                      v-model="values.expirationCustom"
                    />
                  </div>
                </div>
                <div class="d-flex justify-start align-center full-width mb-2">
                  <p class="review-page-text mb-0">Recurring:</p>
                  <v-select
                    outlined
                    dense
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    color="brandCyan"
                    :items="data.recurringOptions"
                    :rules="rules.recurring"
                    v-model="values.recurring"
                    item-value="id"
                    item-text="label"
                    class="review-page-field"
                    hide-details
                  >
                  </v-select>
                </div>
                <div
                  class="d-flex justify-start align-center full-width mb-2"
                  v-if="values.recurring != null && values.recurring != 1"
                >
                  <p class="review-page-text mb-0"># of payments:</p>
                  <v-text-field
                    outlined
                    dense
                    color="brandCyan"
                    class="mt-0 pt-0 review-page-field"
                    type="number"
                    hide-details
                    v-model="values.recurringNum"
                    :rules="rules.amount"
                  ></v-text-field>
                </div> -->
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.submitting"
                  :key="keys.submitting"
                  class="full-width text-left"
                >
                  <div>
                    <p class="text-h6 font-weight-bold mb-5 mt-12">
                      Processing...
                    </p>
                    <div class="d-flex flex-column align-center"></div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.sent"
                  :key="keys.sent"
                  class="full-width text-center pt-12"
                >
                  <p class="text-h6 word-break font-weight-bold mb-3">
                    You just made someone's day better!
                  </p>
                  <!-- <p class="full-width">
                Your payments are being processed. You will be notified once
                completed.
              </p> -->
                  <div class="d-flex justify-center mt-6">
                    <Robin
                      :height="120"
                      :width="140"
                      class=""
                      :loop="false"
                      animation="backflip"
                    />
                  </div>
                  <div class="d-flex justify-center mt-12 mb-5">
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text"
                      @click="reset"
                      width="95"
                      ><span>Close</span></v-btn
                    >
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.error"
                  :key="keys.error"
                  class="full-width text-left pt-12"
                >
                  <p class="text-h6 word-break font-weight-bold mb-3">
                    We had some trouble sending your payment. <br />Please try
                    again later.
                  </p>
                  <p class="full-width">
                    If this problem continues, feel free to reach out to
                    customer service via one of the following methods...
                  </p>
                  <p class="pl-4 full-width">
                    Email -
                    <b
                      ><a href="mailto:help@wewhistle.com"
                        >help@wewhistle.com</a
                      ></b
                    >
                  </p>
                  <p class="pl-4 full-width">
                    Phone (Toll Free) - <b>(855) 264-3329</b>
                  </p>

                  <div class="d-flex justify-center my-5">
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text mt-4"
                      @click="(slideKey = keys.review), (progressBarValue = 0)"
                      ><v-icon class="mr-1">mdi-chevron-left</v-icon
                      ><span style="margin-top: 1px">Go back</span></v-btn
                    >
                  </div>
                </div>
              </transition>
            </div>

            <!-- <div
              v-if="slideKey == keys.review || slideKey == keys.submitting"
              class="mt-2 mb-5 d-flex flex-column align-center"
            >
              <v-btn
                rounded
                depressed
                color="brandCyan"
                class="white--text"
                @click="submitPayment"
                :disabled="disableContinueButton"
              >
                {{
                  slideKey == keys.review ? "Create budgets" : "Processing"
                }}</v-btn
              >
            </div> -->
          </v-col>
        </v-row>
        <v-overlay
          v-if="
            dialog.createGroup ||
              dialog.celebrations ||
              dialog.reward ||
              dialog.createUser ||
              dialog.categoryDrawer ||
              dialog.funding ||
              dialog.annualRewardPopup
          "
        />
        <!-- User creator widget -->
        <v-navigation-drawer
          v-model="dialog.createUser"
          temporary
          fixed
          right
          width="500"
        >
          <UserCreatorWidgetSimple
            v-if="dialog.createUser"
            @close="dialog.createUser = false"
            @get-users="getUsers"
            :clientId="clientId"
            source="RECOGNITION_WIZARD"
          ></UserCreatorWidgetSimple>
        </v-navigation-drawer>
        <!-- Group creator widget -->
        <v-navigation-drawer
          v-model="dialog.createGroup"
          temporary
          fixed
          right
          width="500"
        >
          <GroupCreatorWidget
            v-if="dialog.createGroup"
            @get-groups="getGroups($event, true)"
            @close="dialog.createGroup = false"
            :clientId="clientId"
            :version="3"
            source="RECOGNITION_WIZARD"
          />
        </v-navigation-drawer>
        <!-- Budget funding -->
        <v-navigation-drawer
          v-model="dialog.funding"
          temporary
          fixed
          right
          width="500"
          disable-route-watcher
        >
          <StripePaymentMethodsManager
            v-if="dialog.funding"
            @reset="dialog.funding = false"
            @get-budgets="getBudgets"
            :destinationBudget="selectedBudget"
            :budgets="data.budgets"
            :clientId="clientId"
            source="RECOGNITION_WIZARD"
            :displayBudgetTransfer="false"
          />
        </v-navigation-drawer>
        <!-- Giphy celebrations -->
        <v-navigation-drawer
          v-model="dialog.celebrations"
          temporary
          fixed
          right
          width="500"
        >
          <GIFDrawer
            v-if="dialog.celebrations"
            @close="dialog.celebrations = false"
            @select-gif="selectGif"
          />
        </v-navigation-drawer>
        <!-- Reward preset options -->
        <v-navigation-drawer
          v-model="dialog.reward"
          temporary
          fixed
          right
          width="500"
        >
          <RewardDrawer
            v-if="dialog.reward"
            :loading="loading.presets"
            :cashAward="cashAward"
            :presets="data.presets"
            :customEmoji="values.customRewardEmoji"
            :customAmount="values.customRewardAmount"
            @close="dialog.reward = false"
            @select-reward="selectPaymentPreset"
            @select-custom-reward="addCustomPreset"
            @update-custom-emoji="values.customRewardEmoji = $event"
            @update-custom-amount="values.customRewardAmount = $event"
          />
        </v-navigation-drawer>
      </v-card>
    </v-dialog>

    <!-- Error dialog -->
    <v-dialog v-model="dialogError" persistent width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-4">
        <div class="d-flex justify-center align-center mx-0 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-2"
            >mdi-alert</v-icon
          >
          <v-card-title
            class="word-break text-left exit-warning-text mx-0 px-0"
          >
            {{ errorHeader || "There was an error sending your recognition" }}
          </v-card-title>
        </div>

        <p v-if="errorText">{{ errorText }}</p>
        <p
          v-for="user in annualPaymentCapErrorArray"
          :key="user.userId"
          class="mb-1"
        >
          {{ user.formattedOutput }}
        </p>

        <v-card-actions class="mx-12">
          <!-- <v-btn
            color="primary"
            @click="closeProgramBuilder"
            outlined
            width="130"
            >Don't save</v-btn
          > -->
          <v-btn
            class="mx-auto white--text"
            depressed
            rounded
            color="brandCyan"
            @click="
              (slideKey = keys.review),
                (dialogError = false),
                (progressBarValue = 0),
                (annualPaymentCapErrorArray = [])
            "
            width="130"
            >Go Back</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.annualRewardPopup" width="500">
      <PopupDialog
        v-if="dialog.annualRewardPopup"
        icon="mdi-check-circle"
        iconColor="success"
        :centerText="true"
        title="Great, you're all done!"
        subtitle="You can now go back to the previous tab and mark the failed reward as 'resolved'."
        button2Text="Ok"
        button2Width="140"
        @button2="dialog.annualRewardPopup = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Typing from "../components/vertex-ai/Typing.vue";
import BudgetService from "@/services/BudgetService";
import TriggerService from "@/services/TriggerService";
import UserService from "@/services/UserService";
import GroupService from "@/services/GroupService";
import WalletService from "@/services/WalletService";
import RecognitionService from "@/services/RecognitionService";

// import { truncateNumber, numberWithCommas } from "@/shared_data/functions";

import PillNav from "@/components/PillNav";
import Robin from "@/components/Robin";
import UserCreatorWidgetSimple from "@/components/UserCreatorWidgetSimpleV2.vue";
import GroupCreatorWidget from "@/components/groups/GroupCreatorWidgetSimplified.vue";
import GroupTable from "@/components/data-table/Groups.vue";
import UserTable from "@/components/data-table/Users.vue";
import StripePaymentMethodsManager from "@/components/stripe/StripePaymentMethodsManager.vue";
import PopupDialog from "@/components/PopupDialog";
// import EmojiPicker from "@/components/EmojiPicker.vue";
// /import GIFDrawer from "@/components/recognition/GIFDrawer";
// import RewardDrawer from "@/components/recognition/RewardDrawer";
import LottiePlayer from "@/components/LottiePlayer.vue";
import ElementSelector from "@/components/recognition/ElementSelector";

import currency from "currency.js";
import moment from "moment";
import { mapState } from "vuex";
import { debounce } from "@/shared_data/functions";

function initialState() {
  return {
    slideKey: 1,
    dialogError: false,
    errorText: null,
    errorHeader: null,
    annualPaymentCapErrorArray: [],
    slideDirection: "topic-left",
    keys: {
      recipient: 1,
      // payment: 2,
      memo: 2,
      celebration: 3,
      review: 4,
      submitting: 5,
      // send: 101,
      sent: 102,
      error: 103
    },
    loading: {
      submitting: false,
      users: false,
      groups: false,
      groupAssignments: false,
      distinctUsers: false,
      recent: false,
      budgets: false,
      presets: false,
      values: false
    },
    feedback: {
      vibe: {
        behavior: false,
        feeling: false,
        impact: false
      },
      recommendation: "",
      state: null
    },
    lottie: {
      particle: false
    },

    data: {
      search: null,
      debounceSearch: null,
      activeAudienceTab: 1,
      users: [],
      groups: [],
      budgets: [],
      presets: [],
      values: [],
      parentPaymentRules: [],
      expirationOptions: [
        { id: 1, label: "Never" },
        { id: 2, label: "1 Week" },
        { id: 3, label: "1 Month" },
        { id: 4, label: "3 Months" },
        { id: 5, label: "6 Months" },
        { id: 6, label: "1 Year" },
        { id: 7, label: "Other" }
      ],
      recurringOptions: [
        { id: 1, label: "Nope, just one time" },
        { id: 2, label: "Weekly" },
        { id: 3, label: "Monthly" },
        { id: 4, label: "Quarterly" },
        { id: 5, label: "Annually" }
      ]
    },
    dialog: {
      previewAudience: false,
      selectedAudience: {},
      createGroup: false,
      createUser: false,
      categoryDrawer: false,
      funding: false,
      celebrations: false,
      reward: false,
      companyValue: false,
      annualRewardPopup: false
    },
    values: {
      budgetId: null,
      selected: [],
      distinctUsers: 0,
      paymentPresetId: null,
      preset: null,
      amount: null,
      totalAmount: null,
      classification: null,
      name: null,
      memo: null,
      visibility: null, // "PUBLIC",
      sendDate: null, //moment().format("YYYY-MM-DDTHH:mm"),
      sendDateNow: true,
      expiration: 1,
      expirationCustom: null,
      recurring: 1,
      recurringNum: "1",
      categoryIndex: 0,
      gif: null,
      rewardEmoji: null,
      rewardAmount: null,
      customRewardEmoji: null, //"💰",
      customRewardAmount: null,
      companyValues: []
    },
    rules: {
      amount: [
        v => !!(v && parseFloat(v) > 0) || "Amount must be greater than 0",
        v =>
          !!(
            v &&
            (!v.toString().includes(".") ||
              (v.toString().includes(".") &&
                v.toString().substring(v.toString().indexOf(".") + 1).length <
                  3))
          ) || `Amount shouldn't be longer than 2 decimals.`
      ],
      recurring: [
        v =>
          !!(v && parseFloat(v) > 0) || "# of payments must be greater than 0",
        v =>
          !!(v && !v.toString().includes(".")) ||
          "# of payments must be a whole number"
      ]
    },
    progressBarValue: 0,
    budgetPlatformActivityCounter: 0,
    categoryDescriptionPreview: null,
    userTable: {
      itemsPerPage: 10,
      page: 1,
      // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
      awaitingPage: 1,
      search: null,
      debounceSearch: null,
      loading: false,
      users: [],
      total: 0,
      selected: [],
      options: {},
      footerProps: {
        "items-per-page-options": [10, 25, 50, 100]
      },
      latestFilter: null,
      latestSort: null,
      columns: [
        { text: "Name", value: "displayName", width: "40%" },
        { text: "Email", value: "businessEmail", width: "50%" },
        { text: "", value: "add" }
      ]
    },
    anim: null
  };
}

export default {
  name: "RecognitionWizard",
  title: "Recognition Wizard | Whistle",
  components: {
    Typing,
    Robin,
    UserCreatorWidgetSimple,
    GroupCreatorWidget,
    GroupTable,
    UserTable,
    StripePaymentMethodsManager,
    // EmojiPicker,
    LottiePlayer,
    // RewardDrawer,
    // GIFDrawer,
    PillNav,
    ElementSelector,
    PopupDialog
  },
  props: {
    budgets: Array,
    loadingBudgets: Boolean,
    previousRoute: {
      type: String,
      default: "activity"
    },
    previousRouteProps: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return initialState();
  },
  created() {
    if (this.$route.query.memo) this.values.memo = this.$route.query.memo;
    if (this.$route.query.amount)
      this.values.rewardAmount = Number(this.$route.query.amount);
    if (this.$route.query.emoji)
      this.values.rewardEmoji = this.$route.query.emoji;
    if (this.$route.query.visibility)
      this.values.visibility = this.$route.query.visibility;
    if (this.$route.query.mediaUrl)
      this.values.gif = this.$route.query.mediaUrl;
    if (this.$route.query.userId)
      this.getCreatedUsers(this.$route.query.userId);

    this.getCompanyValues();
    this.getPaymentPresets();

    if (!this.budgets) this.getBudgets();

    this.createPlatformActivity("OPENED_RECOGNITION_WIZARD");
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  beforeDestroy() {},
  methods: {
    progressTo(step) {
      step = step || "default";
      this[`progress_${step}`]();
    },
    progress_default() {
      this.feedback.state = "waiting";
      setTimeout(() => {
        this.feedback.state = "done";
      }, Math.random() * 3000 + 250);
    },
    progress_waiting() {},
    progress_done() {},
    formatCurrency(amount, cash = true) {
      return currency(amount).format({ symbol: cash ? "$" : "" });
    },
    resetAllData() {
      // Used to reset the state when the user clicks out of the payments console.
      Object.assign(this.$data, initialState());
    },
    reset() {
      if (this.previousRoute)
        this.$router.push({
          name: this.previousRoute,
          params: this.previousRouteProps || {}
        });
      else {
        this.resetAllData();
        this.$router.push("/activity");

        // this.$emit("reset");
      }
    },
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },

    goToSlide(index) {
      // Figures out what step we're going into so we can insert the proper platform activity
      const oldIndex = this.slideKey;
      var platformActivityEvent = null;
      if (this.slideKey == this.keys.recipient)
        platformActivityEvent = "SELECTED_RECOGNITION_RECIPIENTS";
      else if (this.slideKey == this.keys.memo) {
        platformActivityEvent = "ADDED_RECOGNITION_MEMO";
      } else if (this.slideKey == this.keys.celebration)
        platformActivityEvent = "ADDED_RECOGNITION_CELEBRATION";
      // else if (this.slideKey == this.keys.expiration)
      //   platformActivityEvent = "SELECTED_PAYMENT_EXPIRATION_DATE";
      // this.disableContinueButton
      // We have to block if the user has already sent payment
      if (this.slideKey >= 1 && this.slideKey < this.keys.submitting) {
        if (index < this.slideKey) {
          this.slideDirection = "topic-right";
          this.scrollToTop();
          return (this.slideKey = index);
        } else {
          this.slideDirection = "topic-left";
        }

        // We route depending on true's and false's in the computed array
        var foundFalse = false;
        // Index is relative to all. We have to equate it to the index in our current route;
        var routeIndex = this.route.findIndex(x => x == index);

        // When using goToSlide to jump around, we have to make sure the user has completed all steps prior to the step that they're trying to route to.
        for (var i = 0; i < routeIndex; i++) {
          if (!this.routeValidation[i]) {
            foundFalse = true;
          }
        }

        // If we only found true values in the array, then we didn't find a false and therefore can continue
        if (!foundFalse) {
          this.scrollToTop();
          this.slideKey = index;

          if (oldIndex == this.keys.recipient)
            this.getDistinctUsersFromGroups();
          if (platformActivityEvent)
            this.createPlatformActivity(platformActivityEvent);
        }
      }
    },
    goToNextSlide() {
      this.slideDirection = "topic-left";
      this.scrollToTop();
      const oldIndex = this.slideKey;

      if (this.slideKey === this.keys.review) {
        //We're on the final slide so show confirmation
        //Or we are editing and the message has already sent, so they can only update the message body
        this.playParticleEffect();
        this.createRecognition();
      } else if (this.slideKey == this.keys.sent) {
        this.reset();
      } else {
        // Figures out what step we're going into so we can insert the proper platform activity
        var platformActivityEvent = null;
        if (this.slideKey == this.keys.recipient)
          platformActivityEvent = "SELECTED_RECOGNITION_RECIPIENTS";
        else if (this.slideKey == this.keys.memo) {
          platformActivityEvent = "ADDED_RECOGNITION_MEMO";
        } else if (this.slideKey == this.keys.celebration)
          platformActivityEvent = "ADDED_RECOGNITION_CELEBRATION";

        // The route has been established. Now we have to check if they can proceed
        var routeIndex = this.route.findIndex(x => x == this.slideKey);

        // if (
        //   this.route[routeIndex + 1] == this.keys.mccCustom &&
        //   this.slideProvisional.selectedPresets.length > 0
        // ) {
        //   // We can skip custom MCC page
        //   routeIndex++;
        // }
        this.playParticleEffect();
        this.slideKey = this.route[routeIndex + 1];

        if (oldIndex == this.keys.recipient) this.getDistinctUsersFromGroups();

        if (platformActivityEvent)
          this.createPlatformActivity(platformActivityEvent);
      }
    },
    goToPreviousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();

      // The route has been established. Now we have to check if they can proceed
      var routeIndex = this.route.findIndex(x => x == this.slideKey);
      var counter = 1;

      this.slideKey = this.route[routeIndex - counter];
    },
    loadBarSleeper(incr, dur, override = false) {
      setTimeout(() => {
        console.log("Incrementing progress", this.progressBarValue);
        if (!this.loading.submitting || override) {
          // We just want to skip to the end
          this.progressBarValue = 100;
        } else {
          this.progressBarValue += incr;
          // We loop IF no error has occurred
          if (
            this.progressBarValue < 90 &&
            this.slideKey != this.keys.error &&
            !this.dialogError
          )
            this.loadBarSleeper(incr, dur);
        }
      }, dur);
    },
    refreshClientId(clientId) {
      this.clientId = clientId;

      this.slideKey = this.keys.recipient;
      this.values.budgetId = null;
      this.values.selected = [];

      // We emit this so the parent (Payment Admin will refresh budgets)
      this.$emit("update-client", clientId);
      this.getBudgets();
      this.getCompanyValues();
      this.getPaymentPresets();
    },
    preloadBudget() {
      // Preload the master budget
      if (this.values.budgetId) return;
      const clientBudgets = (this.budgets || this.data.budgets).filter(
        x =>
          x.clientId == this.clientId &&
          // x.awardId == 1 &&
          x.status == "Active" &&
          Number(x.budgetBalance) > 0
      );

      console.log("preloadBudget", this.$route.query.budgetId);
      if (this.$route.query.budgetId) {
        const queryBudget = clientBudgets.find(
          x => x.budgetId == this.$route.query.budgetId
        );
        console.log("preloadBudget", queryBudget);
        if (queryBudget) return (this.values.budgetId = queryBudget.budgetId);
      }

      const acctBalance = clientBudgets.find(x => x.masterBudget);
      const cashBudget = clientBudgets.find(x => x.awardId == 1);
      if (acctBalance) this.values.budgetId = acctBalance.budgetId;
      else if (cashBudget) this.values.budgetId = cashBudget.budgetId;
      else if (clientBudgets.length)
        this.values.budgetId = clientBudgets[0].budgetId;
    },
    createPlatformActivity(event) {
      var obj = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        event: event,
        source: "RECOGNITION_WIZARD",
        category: "PAYMENTS",
        date: new Date()
      };
      TriggerService.createActivity(obj, this.magicLinkToken)
        .then(response => {
          console.log("Response from inserting activity ", response);
        })
        .catch(error => {
          console.log("There was an error inserting activity ", error);
        });
    },
    sendBudgetIdTrigger() {
      // We want to fire off a platform activity for when they update the payment memo, but this can be done on any screen.
      // So this gets called when the payment memo field loses focus
      // We also want to make sure this only gets sent once.
      if (this.values.budgetId && this.budgetPlatformActivityCounter == 0) {
        this.createPlatformActivity("SELECTED_BUDGET_WIZARD_BUDGET");
        this.budgetPlatformActivityCounter++;
      }
    },
    changeAudienceTab(newIndex) {
      if (newIndex == this.data.activeAudienceTab) return;
      const oldTab = this.data.activeAudienceTab;
      const oldSearchValue = this.data.search;
      this.$set(this.data, "activeAudienceTab", newIndex);
      // this.data.activeAudienceTab = newIndex;

      this.data.debounceSearch = null;
      this.data.search = null;
      // for some reason, if we change the tab, the user table's options don't initialize until you interact with the table once. But if you set the page to 2 then it forces it to init????
      if (this.userTable.options && !this.userTable.options.itemsPerPage) {
        this.userTable.options.page = 2;
        console.log("Setting new page val");
      }

      // If we're leaving users and there was a search value, we refetch users
      // if (oldTab == 1 && oldSearchValue) this.getUsersV2New(true);
    },
    changeSearchTab() {
      console.log("Focused on search bar");
      if (this.data.activeAudienceTab == 0) this.data.activeAudienceTab = 1;
      // for some reason, if we change the tab, the user table's options don't initialize until you interact with the table once. But if you set the page to 2 then it forces it to init????
      if (this.userTable.options && !this.userTable.options.itemsPerPage)
        this.userTable.options.page = 2;
    },
    async getBudgets() {
      try {
        this.loading.budgets = true;
        const clientId = this.clientId;
        let response = await BudgetService.getBudgets(
          {
            lite: 1,
            clientId
          },
          // this.magicLinkToken,
          2
        );
        if (clientId != this.clientId)
          return console.log("Ignoring budget response due to client change");
        console.log("Get budgets ", response);
        this.data.budgets = response;
        this.preloadBudget();
      } catch (e) {
        console.log("Error getting budgets ", e);
      } finally {
        this.loading.budgets = false;
        if (
          !this.data.budgets.length ||
          !this.data.budgets.find(x => x.budgetBalance > 0)
        ) {
          this.values.rewardAmount = null;
          this.values.rewardEmoji = null;
          this.dialog.reward = false;
        }
      }
    },
    async getCreatedUsers(userId) {
      try {
        this.loading.users = true;
        let filter = `status != 'Deleted' && clientId=${this.clientId}`;
        if (userId) filter += ` && userId = '${userId}'`;
        let limit = 1;
        let offset = 0;
        let sort = "createDate DESC";
        const options = {
          filter: filter,
          limit: limit,
          sort: sort,
          offset: offset
        };

        // console.log(options);

        let response = await UserService.getUsersV2(
          options,
          this.magicLinkToken
        );
        // console.log("RESPONSE");
        // console.log(response);
        console.log("GOT RESPONSE AFTER FETCHING NEW USER ", response);
        const newUser = response.result.rows[0];
        if (!newUser) return console.log("No user found");
        newUser.display = true;
        this.data.users.push(newUser);
        // We have to find the index to put this user and drop them in there.
        this.data.users = this.data.users.sort((a, b) => {
          const firstNameCompare = a.firstName.localeCompare(b.firstName);
          if (firstNameCompare == 0) {
            return a.lastName.localeCompare(b.lastName);
          } else return firstNameCompare;
        });

        this.loading.users = false;
        this.addUserToSelected(newUser);
      } catch (error) {
        console.log(error);
        this.loading.users = false;
      }
    },
    getGroups(groupId, wipeSearch = false) {
      // Uses the group table widget
      console.log("getGroups", { groupId, wipeSearch });
      if (wipeSearch) {
        this.data.search = undefined;
        this.data.debounceSearch = undefined;
      }
      this.$nextTick(() => {
        if (this.$refs["group-table"])
          this.$refs["group-table"].getGroups(
            true,
            "getGroups - recognition wizard",
            groupId
          );
      });
    },
    getUsers(userId) {
      // Uses the group table widget
      if (this.$refs["user-table"])
        this.$refs["user-table"].getUsers(
          true,
          "getUsers - budget wizard",
          userId
        );
    },
    async getPaymentPresets() {
      try {
        this.loading.presets = true;
        const clientId = this.clientId;
        let results = await WalletService.getRecognitionPaymentPresetsV2(
          clientId,
          this.magicLinkToken
        );
        if (clientId != this.clientId)
          return console.log("Ignoring preset response due to client change");
        console.log("Got payment presets ", results.result.rows);
        this.data.presets = results.result.rows.filter(
          x => x.status != "Disabled"
        );
      } catch (err) {
        console.log("Error with payment presets ", err);
      } finally {
        this.loading.presets = false;
      }
    },
    async getCompanyValues() {
      try {
        this.loading.values = true;
        const clientId = this.clientId;
        let results = await RecognitionService.getCompanyValues(
          {
            clientId,
            filter: `clientId.isIn([0,${clientId}]) && status != 'Deleted' && displayName != null`,
            limit: 20
            // expand: "Icon"
          },
          this.magicLinkToken
        );
        if (clientId != this.clientId)
          return console.log(
            "Ignoring company value response due to client change"
          );
        console.log("Got company values ", results.result.rows);
        this.data.values = results.result.rows.filter(
          x => x.status != "Disabled"
        );
      } catch (err) {
        console.log("Error with company values ", err);
      } finally {
        this.loading.values = false;
      }
    },
    formatPresetAmount(amt) {
      // This will format large amounts as 1k or 1.5m, etc
      if (amt > 999.99)
        return (
          (this.cashAward ? "$" : "") +
          Intl.NumberFormat("en", { notation: "compact" }).format(
            currency(amt).value
          )
        );
      else return currency(amt).format({ symbol: this.cashAward ? "$" : "" });
      // return ).format();
    },
    updateSelectedItem(e) {
      if (!e.groupId) return console.log("no group id");

      this.values.selected.forEach(o => {
        if (o.groupId == e.groupId) {
          console.log("UPDATING SELECTED NUM USERS ", e.numUsers);
          o.numUsers = e.numUsers || 0;
          // this.getDistinctUsersFromGroups();
        }
      });
    },
    async getDistinctUsersFromGroups() {
      console.log("getDistinctUsersFromGroups()");
      // assign to slidePeople.selectedItem.GroupAssignments
      const groups = this.values.selected
        .filter(x => x.groupId)
        .map(x => x.groupId);
      if (!groups.length) return;
      const users = this.values.selected
        .filter(x => x.userId)
        .map(x => x.userId);
      this.loading.distinctUsers = true;
      try {
        const response = await GroupService.getDistinctUsersFromGroupsV2(
          groups,
          users,
          this.magicLinkToken
        );

        this.values.distinctUsers = response;
        console.log("New distinct user count", this.values.distinctUsers);
      } catch (error) {
        console.log("Error getting distinct number of users ", error);
        this.values.distinctUsers = 0;
      } finally {
        this.loading.distinctUsers = false;
      }
    },
    async addUserToSelected(user, dontRemove = false) {
      console.log("Adding user/group to selected array", user);
      // Added because we can only pass one param in from the groups table
      if (user.dontRemove) dontRemove = true;
      this.dialog.previewAudience = false;
      if (user.userId) {
        let idx = this.values.selected.findIndex(x => x.userId == user.userId);
        if (idx == -1) this.values.selected.push(user);
        else if (!dontRemove) this.removeUserFromSelected(idx);
      } else {
        let idx = this.values.selected.findIndex(
          x => x.groupId == user.groupId
        );
        if (idx == -1) this.values.selected.push(user);
        else if (!dontRemove) this.removeUserFromSelected(idx);

        // Wipe distinct user count so we have to manually calculate it again rather than using the cached API value
        this.values.distinctUsers = 0;
      }
    },
    removeUserFromSelected(index) {
      // var user = this.values.selected[index];
      this.values.selected.splice(index, 1);

      // Wipe distinct user count so we have to manually calculate it again rather than using the cached API value
      this.values.distinctUsers = 0;
    },
    loadCelebrationsDrawer() {
      this.dialog.celebrations = true;
    },
    selectGif(gifEvent) {
      this.values.gif = gifEvent;
    },
    clearGif() {
      this.values.gif = null;
    },
    loadRewardDrawer() {
      this.dialog.reward = true;
    },
    selectPaymentPreset(preset) {
      this.values.rewardAmount = preset.amount;
      this.values.rewardEmoji = preset.emoji;
    },
    clearPaymentPreset() {
      this.values.rewardEmoji = null;
      this.values.rewardAmount = null;
      this.values.customRewardEmoji = null;
    },
    selectCustomEmoji(emoji) {
      this.values.customRewardEmoji = emoji;
    },
    addCustomPreset() {
      if (!this.values.customRewardEmoji) this.values.customRewardEmoji = "💰";
      this.values.rewardEmoji = this.values.customRewardEmoji;
      this.values.rewardAmount = Number(this.values.customRewardAmount);
    },
    loadCompanyValueDrawer() {
      this.dialog.companyValue = true;
    },
    clearCompanyValue() {
      this.values.companyValues = null;
    },
    selectCompanyValue(value) {
      const existingIndex = this.values.companyValues.findIndex(
        x => x.companyValueId == value.companyValueId
      );
      if (existingIndex !== -1)
        this.values.companyValues.splice(existingIndex, 1);
      else this.values.companyValues.push(value);
      // this.dialog.companyValue = false;
    },
    async createRecognition() {
      // Show loading bar
      this.errorText = this.errorHeader = null;
      console.log("Submitting recognition! ", this.keys.submitting);
      this.slideKey = this.keys.submitting;
      this.loading.submitting = true;
      const progressIncrement = 2;
      const sleepDur = 125;
      this.loadBarSleeper(progressIncrement, sleepDur, false);

      try {
        var object = {
          clientId: this.clientId,
          budgetId: this.values.budgetId,
          memo: this.computedNote,
          amount: this.values.rewardAmount
            ? currency(this.values.rewardAmount)
            : null,
          emoji: this.values.rewardEmoji,
          // sendDate: moment(this.values.sendDate)
          //   .utc()
          //   .format("YYYY-MM-DD HH:mm:ssZ"),
          visibility: this.values.visibility,
          audience: {
            userId: this.values.selected
              .filter(x => x.userId)
              .map(x => x.userId),
            groupId: this.values.selected
              .filter(x => x.groupId)
              .map(x => x.groupId)
          },
          source: "RECOGNITION_WIZARD",
          mediaUrl: this.values.gif,
          companyValues: this.values.companyValues,
          celebrationId: 1
        };

        if (!this.values.sendDateNow)
          object.sendDate = moment(this.values.sendDate)
            .utc()
            .format("YYYY-MM-DD HH:mm:ssZ");

        if (!object.budgetId && object.emoji) {
          console.log("Wiping reward due to no budget");
          object.amount = null;
          object.emoji = null;
        }
        console.log(object);
        // Display loading screen
        // var response;
        var response = await RecognitionService.createRecognition(
          object,
          this.magicLinkToken
        );
        this.loading.submitting = false;
        this.loadBarSleeper(progressIncrement, sleepDur, true);
        console.log(response);
        if (typeof response == "string") {
          if (response.startsWith("The sum of all payouts")) {
            this.dialogError = true;
            this.errorText =
              "The total payment sum is more than the selected budget's balance.";
          }
        } else if (response.error) {
          this.slideKey = this.keys.error;
        } else {
          // Display success screen
          this.slideKey = this.keys.sent;

          if (this.$route.query.sourcePage == "annualrewardwizard")
            this.dialog.annualRewardPopup = true;
        }
        // })
      } catch (error) {
        console.log("Error sending recognition! ", error);
        if (error && error.error_code && typeof error.error_code == "string") {
          if (error.error_code.endsWith("0100")) {
            this.dialogError = true;
            this.errorHeader = "The budget does not have enough funds";
            this.errorText =
              "The total reward amount is more than what's available in this budget. Please add more funds or use a different budget.";
            return;
          } else if (
            error.error_code == "1070127" ||
            error.error_code == "1070128"
          ) {
            this.dialogError = true;
            if (error.error_code == "1070127")
              this.errorHeader =
                "The users below cannot receive any more payments for the year.";
            else
              this.errorHeader =
                "The users below cannot receive the full payment. The amounts each user is capped at are listed below.";
            this.errorText = null;
            this.annualPaymentCapErrorArray = error.data || [];
            return;
          }
        } else if (error && error.error) {
          this.slideKey = this.keys.error;
          return;
        }
        this.dialogError = true;
        this.errorText =
          "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
      }
    },
    updateSendDate() {
      const val = this.values.sendDateNow;
      // console.log("New toggle value ", val);
      if (val) this.values.sendDate = null;
      else this.values.sendDate = moment().format("YYYY-MM-DDTHH:mm");
    },
    playParticleEffect() {
      this.lottie.particle = true;
      setTimeout(() => {
        this.lottie.particle = false;
      }, 1000);
    },
    updateFeedback() {
      this.feedback.state = null;
      this.feedback.recommendation = null;
      // this.feedback.vibe = { behavior: false, feeling: false, impact: false };
      return axios
        .post(
          "/evaluate-recognition",
          {
            recognition: this.values.memo
          },
          {
            baseURL: process.env.VUE_APP_AI_URL,
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          }
        )
        .then(r => {
          console.log("Res:", r);
          const { data } = r;
          // for (let k of data.vibe) {
          //   console.log("k of data.vibe", k, "of", data.vibe, data.vibe[k]);
          //   this.feedback.vibe[k] = data.vibe[k];
          // }
          Object.assign(this.feedback.vibe, data.vibe);
          this.feedback.recommendation = data.recommendation;
          console.log({ data }, { feedback: { ...this.feedback } });
        })
        .catch(e => {});
    },
    aiTryAgain() {
      this.feedback.state = "waiting";
      return axios
        .post(
          "/evaluate-recognition/retry",
          {
            recognition: this.values.memo,
            state: {
              data: this.feedback.vibe
            }
          },
          {
            baseURL: process.env.VUE_APP_AI_URL,
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          }
        )
        .then(r => {
          console.log("Res:", r);
          const { data } = r;
          // for (let k of data.vibe) {
          //   console.log("k of data.vibe", k, "of", data.vibe, data.vibe[k]);
          //   this.feedback.vibe[k] = data.vibe[k];
          // }
          Object.assign(this.feedback.vibe, data.vibe);
          this.feedback.recommendation = data.recommendation;
          console.log({ data }, { feedback: { ...this.feedback } });
          this.feedback.state = "done";
        })
        .catch(e => {
          this.feedback.state = null;
        })
        .finally(() => {});
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "clients",
      "permissions",
      "globalClientId",
      "magicLinkToken"
    ]),
    isAdmin() {
      return this.userProfile?.businessEmail?.includes("@wewhistle.com");
    },
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    disableContinueButton: {
      cache: false,
      get: function() {
        // Compare to route validation
        // We check the index within the route that we're in and use routeValidation to see if we've satisfied the requirements
        var routeIndex = this.route.findIndex(x => x == this.slideKey);
        return !this.routeValidation[routeIndex];
      }
    },
    visibleBudgets() {
      // var awardId = null;
      const budgetArr = (this.budgets || this.data.budgets).filter(x => {
        return (
          x.status == "Active" &&
          x.clientId == this.clientId &&
          Number(x.budgetBalance) > 0
        );
      });
      return budgetArr;
    },
    selectedBudget: {
      cache: false,
      get: function() {
        if (this.values.budgetId !== null) {
          var budget = (this.budgets || this.data.budgets).find(
            x => x.budgetId == this.values.budgetId
          );

          return budget;
        }
        return null;
      }
    },
    cashAward() {
      return !(this.selectedBudget && this.selectedBudget.awardId != 1);
    },
    singularAwardUnit() {
      return this.selectedBudget &&
        this.selectedBudget.awardId != 1 &&
        this.selectedBudget.Award
        ? this.selectedBudget.Award.unitSingular || "unit"
        : null;
    },
    pluralAwardUnit() {
      return this.selectedBudget &&
        this.selectedBudget.awardId != 1 &&
        this.selectedBudget.Award
        ? this.selectedBudget.Award.unitPlural || "units"
        : null;
    },
    amountRemainingInBudget() {
      if (this.selectedBudget && this.selectedBudget.budgetBalance) {
        return currency(this.selectedBudget.budgetBalance).subtract(
          this.totalAmount
        ).value;
      } else {
        return 0;
      }
    },
    amountRemainingInBudgetFormatted() {
      return `${currency(Math.abs(this.amountRemainingInBudget)).format({
        symbol: this.cashAward ? "$" : ""
      })}${
        this.cashAward
          ? ""
          : this.amountRemainingInBudget == 1
          ? ` ${this.singularAwardUnit}`
          : ` ${this.pluralAwardUnit}`
      }`;
    },
    pillNavSteps() {
      return [
        {
          step: this.keys.recipient,
          icon: "account-multiple"
          // active: this.confirmPageReset
        },
        {
          step: this.keys.memo,
          icon: "message-text"
        },
        {
          step: this.keys.celebration,
          icon: "gift-outline"
        },
        {
          step: this.keys.review,
          icon: "rocket-launch"
        }
      ];
    },
    route: {
      cache: false,
      get: function() {
        // We establish the route depending on what payment type
        // This dynamic array of keys will be used by routeValidation, disableContinuButton, etc
        return [
          this.keys.recipient,
          this.keys.memo,
          this.keys.celebration,
          this.keys.review,
          this.keys.submitting,
          this.keys.sent
        ];
      }
    },
    routeValidation: {
      cache: false,
      get: function() {
        var array = [];

        // This uses this.route and compiles an array of true/false values for their entire route to tell us if they've completed everything that they need to
        // Used by disableContinueButton and goToSlide
        this.route.forEach(page => {
          // Page 1
          // console.log(page);
          if (page == this.keys.recipient && this.paymentAudienceCount > 0) {
            array.push(true);
          }
          // else if (
          //   page == this.keys.payment &&
          //   this.values.budgetId &&
          //   this.amountRemainingInBudget >= 0 &&
          //   this.totalAmount > 0 &&
          //   this.values.categoryIndex != null
          // ) {
          //   array.push(true);
          // }
          else if (page == this.keys.celebration) {
            array.push(true);
          } else if (
            page == this.keys.memo &&
            this.values.memo &&
            this.values.memo.length &&
            this.values.visibility
          ) {
            array.push(true);
          } else if (
            page == this.keys.review &&
            this.values.memo &&
            this.values.memo.length &&
            // Added so they don't accidentally submit if they're over budget
            !this.loading.distinctUsers &&
            this.amountRemainingInBudget >= 0
          ) {
            array.push(true);
          } else if (page == this.keys.submitting) {
            array.push(false);
          } else if (page == this.keys.sent) {
            // console.log("sent");
            array.push(true);
          } else {
            // console.log("ELSE");
            array.push(false);
          }
          // console.log(array[array.length - 1]);
        });

        // console.log(array);
        return array;
      }
    },
    loadingBudgetsComputed() {
      if (this.budgets) return this.loadingBudgets;
      return this.loading.budgets;
    },
    computedNote() {
      if (!this.values.memo) return null;
      // let arr = [];
      // if (this.values.memo) {
      //   this.values.memo
      //     .split("\n")
      //     .forEach(item => arr.push(`${item.trim()}<br />`));
      // }
      // return arr.join("");
      return this.values.memo;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    visibleAudience: {
      get: function() {
        // We are checking the active group, so it doesn't re-run when slidePeople.users gets populaged
        if (this.data.activeAudienceTab == 2) {
          return this.data.groups; //.filter((x) => x.display);
        }
        // else if (this.data.activeAudienceTab == 0) return this.recentUsers; //.filter((x) => x.display);

        var startingIndex = this.userTable.options.page - 1 || 0;
        let itemsPerPage =
          (this.userTable.options && this.userTable.options.itemsPerPage) ||
          this.userTable.itemsPerPage;
        return this.data.users
          .slice()
          .slice(
            startingIndex * itemsPerPage,
            startingIndex * itemsPerPage + itemsPerPage
          )
          .filter(x => x !== undefined);

        // .filter((x) => x.display);
      },
      cache: false
    },
    audienceHeaders() {
      if (this.data.activeAudienceTab == 2) {
        return [
          {
            align: "start",
            sortable: false,
            value: "select",
            width: "35px"
          },
          {
            align: "start",
            sortable: true,
            value: "groupDisplayName"
          },
          {
            align: "start",
            sortable: true,
            value: "numUsers",
            width: "50%"
          },

          {
            value: "info",
            width: "100px",
            align: "end",
            sortable: false
          }
        ];
      }

      return [
        {
          align: "start",
          sortable: false,
          value: "select",
          width: "35px"
        },

        {
          align: "start",
          sortable: true,
          value: "displayName",
          width: "90%"
        },
        {
          align: " d-none",
          sortable: true,
          value: "businessEmail",
          width: "0%"
        },
        {
          align: " d-none",
          sortable: true,
          value: "businessPhone",
          width: "0%"
        },
        {
          value: "info",
          width: "10%",
          align: "end",
          sortable: false
        }
      ];
    },
    paymentAudienceCount() {
      if (
        this.loading.distinctUsers &&
        this.values.selected.find(x => x.groupId)
      )
        return 0;
      if (
        this.values.distinctUsers &&
        this.values.selected.find(x => x.groupId)
      ) {
        console.log("Returning distinct users ", this.values.distinctUsers);
        return this.values.distinctUsers;
      }
      let count = 0;
      var arr = [];

      this.values.selected.forEach(item => {
        if (item.groupId && item.numUsers) count += item.numUsers;
        else if (item.groupId && item.GroupAssignments)
          arr = arr.concat(item.GroupAssignments.map(x => x.userId));
        else if (item.userId) arr.push(item.userId);
      });

      if (count != 0) return count;
      return [...new Set(arr)].length;
    },
    // totalPayment: {
    //   get: function() {
    //     if (!this.values.amount) return 0;
    //     console.log("amount in get totalPayment", this.values.amount);
    //     return currency(this.values.amount) * this.paymentAudience.length;
    //   },
    //   set: function(newVal) {
    //     // We must set values.amount
    //     console.log("Setter totalAmount", newVal);
    //     // this.values.totalAmount = newVal;
    //     if (this.paymentAudience.length == 0) this.values.amount = 0;
    //     else
    //       this.values.amount = currency(newVal).divide(
    //         this.paymentAudience.length
    //       );
    //     //this.values.amount = newVal / this.paymentAudience.length;
    //   },
    // },
    formattedAmount() {
      return currency(this.values.rewardAmount).value;
    },
    totalAmount() {
      return currency(this.values.rewardAmount).multiply(
        this.paymentAudienceCount
      ).value;
    },
    formattedTotalPayment() {
      let v = this.totalAmount;
      return `${currency(v).format({ symbol: this.cashAward ? "$" : "" })} ${
        this.cashAward
          ? ""
          : v == 1
          ? this.singularAwardUnit
          : this.pluralAwardUnit
      }`;
    },
    selectedGroups: {
      get: function() {
        return this.values.selected.filter(x => x.groupId);
      },
      set: function() {
        // console.log("Adding el ", o);
      }
    },
    selectedUsers: {
      get: function() {
        return this.values.selected.filter(x => x.userId);
      },
      set: function() {
        // console.log("Adding el ", o);
      }
    },
    hasReward() {
      return !!(this.values.rewardEmoji && this.values.rewardAmount);
    },
    canCreateUser() {
      return (this.permissions || []).find(x => x == "users:create:user");
    },
    canCreateGroup() {
      return (this.permissions || []).find(x => x == "groups:create:group");
    }
  },
  watch: {
    "values.memo": debounce(async function() {
      // alert("w0t");
      if (!this.isAdmin) return;
      await this.updateFeedback();
      // alert("noway");
    }, 1000),
    budgets: function(newVal, oldVal) {
      if (oldVal.length == 0) {
        console.log("Got budget watcher", oldVal);

        this.preloadBudget();
      }
    },
    // "data.budgets": function(newVal, oldVal) {
    //   if (oldVal.length == 0) {
    //     console.log("Got budget watcher", oldVal);

    //     this.preloadBudget();
    //   }
    // },
    "data.debounceSearch": debounce(function(newVal) {
      this.data.search = newVal;
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.error-background {
  background-color: var(--v-error-base);
}

.header-text {
  font-size: x-large;
}

.light-grey-background {
  background-color: var(--v-lightGrey-base);
}

.people-group-button {
  padding: 5px 5px;
  border-radius: 7px;
  width: 200px;
  background-color: var(--v-lightGrey-base);
}
.audience-button {
  background-color: white;
  background: white;
  border: 1px solid;
  border-color: var(--v-grey-base);
}
.profile-picture {
  border: 2px solid;
  border-color: var(--v-grey-base);
}
.icon-image {
  height: 105px;
  width: 105px;
}

.text-overflow-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.amount-table {
  padding-top: 1px;
  border: solid 1px;
  border-color: lightgray;
  border-radius: 4px;
}

.amount-table >>> .v-data-table-header {
  background-color: white;
}
.side-nav-bold-text {
  color: var(--v-brand-base);
  font-weight: bold;
}
.side-nav-text {
  color: var(--v-brand-base);
}
.side-nav-icons {
  border-radius: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
}
.side-nav-box {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 35px;
  padding-top: 7px;
}
.side-nav-panel {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-top: 7px;
}
.budget-select >>> .v-list-item {
  font-size: 0.8125rem;
}
.category-div {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
}
.selected-category {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  height: 45px;
  cursor: pointer;
}
.category-container {
  cursor: pointer;
}
.category-container:hover .category-div {
  background-color: #e8e8ec;
}
.selected-category-background {
  background-color: #e8e8ec;
}
.timestamp-input {
  margin-top: 0px;
  margin-bottom: 0px;
}
.rounded-border {
  border: 1px solid lightgray;
  border-radius: 5px;
}
.selected-people-content >>> .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
.input-error {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
  border: 2px solid var(--v-error-base) !important;
}

#note {
  background: var(--v-lightGrey-base);
  padding: 10px 10px;
  margin-left: 24px;
  width: calc(100% - 24px);
  border-radius: 6px;
}

/* outline used for the main content card */
.main-card {
  border: solid 1px;
  border-color: lightgray;
  min-height: calc(100vh - 120px);
}

.budget-name-container {
  width: calc(100% - 120px);
}

.progress-bar {
  width: 70%;
  min-width: 220px;
  max-width: 600px;
}

/* progress bar text */
.progress-bar >>> .v-progress-linear__content {
  justify-content: flex-start;
  margin-left: 15px;
}

.progress-bar >>> .v-progress-linear__determinate {
  border-radius: 0 9999px 9999px 0;
}

.progress-bar >>> .v-progress-linear__background {
  left: 0 !important;
  width: 100% !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Hides extra padding that for some reason exists in v-row */
.container-row {
  overflow-x: hidden;
}

/* Provides spacing for each audience tab */
.audience-tab {
  padding: 8px 20px 8px 20px;
}

/* Bkgnd color for selected audience tab */
.active-audience-tab {
  background-color: #e8e8e8 !important;
}

/* Provides feedback when you hover over an audience tab */
.audience-tab:hover {
  background: lightgray;
}

/* Changes the close icon's color for selected audience chips */
.selected-audience-chip >>> .v-chip__close {
  color: lightgray;
}

.preset-loading {
  height: 70px;
}
/* Outer container for row of preset carousel */
.preset-carousel {
  overflow-x: scroll;
}
/* Card for each preset */
.preset-card {
  border: 1px solid lightgray;
  width: 75px;
  min-width: 75px;
  /* background: white !important; */
}

.icon-img {
  width: 70px;
  height: 70px;
}
.preset-card >>> .icon-img {
  width: 50px;
  height: 50px;
}
.preset-card >>> .text {
  font-size: small;
}

/* Sets width for amt field */
.amount-text-field >>> .v-input__slot {
  max-width: 150px;
  width: 150px;
  min-width: 150px;
}

.review-page-field {
  width: 65% !important;
  min-width: 170px !important;
  max-width: 400px !important;
  margin-left: 12px;
}
.review-page-text {
  width: 35% !important;
}
.box-border {
  border: 1px solid lightgray;
}
.box-border-green {
  border-color: var(--v-brandDarkGreen-base);
}
.box-border-cyan {
  border-color: var(--v-brandCyan-base);
}

.preset-emoji {
  font-size: 40px;
}

/* Thickens the border on audience chips */
.selected-audience-chip.v-chip.v-chip--outlined {
  border-width: 2px !important;
}
.lottie-container {
  position: relative;
}
.lottie-particle-effect {
  position: absolute;
  top: -105px;
  left: -77px;
  /* z-index: 1; */
  pointer-events: none;
}

/* Adds grey hover effect when selecting a payment preset */
.celebration-box:hover {
  background-color: #eeeeee;
}
</style>
