<template>
  <div>
    <v-toolbar dark color="brandDarkCyan" rounded="0">
      <v-btn dark icon large @click="reset" class="ml-1">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title class="d-flex align-center font-weight-bold"
        ><v-icon v-if="!isMobile" class="mr-3">mdi-cash-fast</v-icon> Send a
        reward</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn
        dark
        icon
        large
        @click="reset"
        class="ml-1 hidden"
        v-if="userProfile.clientId != 1"
      >
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
      <v-autocomplete
        v-else
        outlined
        dense
        hide-details
        :items="clients"
        item-text="formattedName"
        item-value="clientId"
        label="Client"
        style="max-width: 250px;"
        v-model="clientId"
        @change="refreshClientId"
        :disabled="slideKey > keys.review"
      ></v-autocomplete>
    </v-toolbar>
    <v-row
      class="full-height mt-6 pb-6 container-row"
      justify="center"
      align="center"
      height="100%"
      no-gutters
    >
      <v-col
        cols="11"
        sm="11"
        md="5"
        lg="4"
        xl="3"
        class="main-card rounded-lg px-5 d-flex flex-column justify-space-between"
      >
        <div>
          <div class="d-flex justify-space-between align-center mt-10">
            <v-progress-linear
              :value="
                slideKey <= keys.review ? slideKey * 25 : progressBarValue
              "
              :color="loading.submitting ? 'brandDarkCyan' : 'brandCyan'"
              height="40"
              class="pill-nav mr-6"
            >
              <!-- <div style="left: 0; width: 20%; height: 40px; background: red;"></div> -->
              <div class="d-flex justify-space-between align-center">
                <div
                  @click="goToSlide(keys.recipient)"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.recipient }"
                >
                  <v-icon color="white" :small="$vuetify.breakpoint.xs"
                    >mdi-account-multiple</v-icon
                  >
                </div>
                <div
                  @click="goToSlide(keys.payment)"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.payment }"
                >
                  <v-icon color="white" :small="$vuetify.breakpoint.xs"
                    >mdi-credit-card-outline</v-icon
                  >
                </div>
                <div
                  @click="goToSlide(keys.memo)"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.memo }"
                >
                  <v-icon color="white" :small="$vuetify.breakpoint.xs"
                    >mdi-message-text</v-icon
                  >
                </div>
                <div
                  @click="goToSlide(keys.review)"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{
                    'active-pill': slideKey == keys.review
                  }"
                >
                  <v-icon color="white" :small="$vuetify.breakpoint.xs"
                    >mdi-rocket-launch</v-icon
                  >
                </div>
              </div>
            </v-progress-linear>
            <v-btn
              :width="isMobile ? 90 : 110"
              rounded
              depressed
              color="brandCyan"
              class="white--text font-weight-bold"
              @click="goToNextSlide"
              :disabled="disableContinueButton"
            >
              <v-icon color="white" class="mr-1" v-if="slideKey == keys.sent"
                >mdi-checkbox-marked-circle</v-icon
              >
              <v-icon
                color="white"
                class="mr-1"
                v-else-if="!isMobile && slideKey != keys.review"
                >mdi-arrow-right-circle</v-icon
              >
              <span v-if="slideKey !== keys.sent" style="margin-top:2px;">{{
                slideKey == keys.sent
                  ? "Close"
                  : slideKey == keys.review
                  ? "Send"
                  : "Next"
              }}</span>
            </v-btn>
          </div>
          <!-- <div class="d-flex justify-space-between align-center mt-10"> 
            <div class="pill-nav mr-6">
              <div class="d-flex justify-space-between align-center">
                <div
                  @click="slideKey = keys.submitting"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.budget }"
                >
                  <v-icon color="white">mdi-wallet</v-icon>
                </div>
                <div
                  @click="slideKey = keys.memo"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.memo }"
                >
                  <v-icon color="white">mdi-message-text-outline</v-icon>
                </div>
                <div
                  @click="slideKey = keys.csv"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.csv }"
                >
                  <v-icon color="white">mdi-upload</v-icon>
                </div>
                <div
                  @click="slideKey = keys.review"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{
                    'active-pill':
                      slideKey == keys.review ||
                      slideKey == keys.submittingValidation,
                  }"
                >
                  <v-progress-circular
                    color="white"
                    indeterminate
                    :size="25"
                    :width="4"
                    v-if="slideKey == keys.submittingValidation"
                  ></v-progress-circular>
                  <v-icon color="white" v-else>mdi-rocket-launch</v-icon>
                </div>
              </div>
            </div>-->

          <!-- </div> -->
          <transition :name="slideDirection" mode="out-in">
            <div
              v-if="slideKey === keys.recipient"
              :key="keys.recipient"
              class="full-width text-left"
            >
              <p class="text-h6 font-weight-bold mt-12">
                Who is receiving this reward?
              </p>
              <div class="d-flex align-center flex-wrap mt-4">
                <div
                  v-for="(user, index) in values.selected"
                  :key="user.userId || user.groupId"
                  :style="{ 'max-width': '100%' }"
                >
                  <v-chip
                    class="ma-1 selected-audience-chip"
                    close
                    outlined
                    color="brandGreen"
                    @click:close="removeUserFromSelected(index)"
                  >
                    <span class="text-truncate black--text">{{
                      user.displayName
                    }}</span>
                  </v-chip>
                </div>
              </div>
              <div class="d-flex justify-space-between align-center mt-10">
                <v-text-field
                  label="Start typing a name, email, or phone"
                  v-model="data.debounceSearch"
                  outlined
                  flat
                  dense
                  hide-details
                  class="search-field mr-3"
                  @focus="changeSearchTab"
                >
                </v-text-field>
                <v-btn
                  fab
                  depressed
                  small
                  color="brandCyan"
                  @click="
                    if (data.activeAudienceTab == 2) {
                      dialog.createGroup = true;
                    } else {
                      dialog.createUser = true;
                    }
                  "
                  ><v-icon color="white">{{
                    data.activeAudienceTab == 2
                      ? "mdi-account-multiple-plus"
                      : "mdi-account-plus"
                  }}</v-icon></v-btn
                >
              </div>
              <div class="d-flex justify-space-between align-center mt-6">
                <span
                  @click="changeAudienceTab(0)"
                  class="rounded-pill audience-tab cursor-pointer"
                  :class="{
                    'active-audience-tab': data.activeAudienceTab == 0
                  }"
                  >Recent</span
                >
                <span
                  @click="changeAudienceTab(1)"
                  class="rounded-pill audience-tab cursor-pointer"
                  :class="{
                    'active-audience-tab': data.activeAudienceTab == 1
                  }"
                  >People</span
                >
                <span
                  @click="changeAudienceTab(2)"
                  class="rounded-pill audience-tab cursor-pointer"
                  :class="{
                    'active-audience-tab': data.activeAudienceTab == 2
                  }"
                  >Groups</span
                >
              </div>
              <div class="text-left mt-2 ">
                <v-divider class="mt-5" />
                <!-- Table for people -->
                <div v-if="data.activeAudienceTab == 0">
                  <v-data-table
                    :headers="audienceHeaders"
                    :items="visibleAudience"
                    :search="data.search"
                    item-key="id"
                    class="elevation-0 full-width cursor-pointer"
                    :loading="
                      loading.recent ||
                        loading.recentUsers ||
                        loading.recentGroups
                    "
                    hide-default-header
                    @click:row="addUserToSelected($event, false)"
                    mobile-breakpoint="0"
                    loading-text="Loading... Please wait"
                    no-data-text="No recent activity found"
                    no-results-text="No results found"
                  >
                    <template v-slot:[`item.info`]="{ item }">
                      <v-btn icon @click.stop="previewItem(item)"
                        ><v-icon color="grey"
                          >mdi-dots-horizontal</v-icon
                        ></v-btn
                      >
                    </template>
                    <template v-slot:[`item.select`]="{ item }">
                      <v-simple-checkbox
                        color="brandCyan"
                        @click.stop="addUserToSelected(item, false)"
                        :ripple="false"
                        :value="
                          values.selected.find(
                            x =>
                              (item.userId && x.userId == item.userId) ||
                              (item.groupId && x.groupId == item.groupId)
                          )
                            ? true
                            : false
                        "
                      />
                    </template>
                  </v-data-table>
                </div>
                <div v-else-if="data.activeAudienceTab == 1">
                  <UserTable
                    ref="user-table"
                    :selected="selectedUsers"
                    :search="data.search"
                    @select-item="addUserToSelected($event, false)"
                  />
                  <!-- <v-data-table
                    v-model="selectedUsers"
                    :headers="audienceHeaders"
                    :items="visibleAudience"
                    :options.sync="userTable.options"
                    :server-items-length="userTable.total"
                    :search="data.search"
                    item-key="userId"
                    class="elevation-0 full-width cursor-pointer"
                    :loading="loading.users || loading.recent"
                    hide-default-header
                    @click:row="addUserToSelected($event, false)"
                    mobile-breakpoint="0"
                    loading-text="Loading... Please wait"
                    no-data-text="No people found"
                    no-results-text="No results found"
                    :footer-props="userTable.footerProps"
                  >
                    <template v-slot:[`item.info`]="{ item }">
                      <v-btn icon @click.stop="previewItem(item)"
                        ><v-icon color="grey"
                          >mdi-dots-horizontal</v-icon
                        ></v-btn
                      >
                    </template>
                    <template v-slot:[`item.select`]="{ item }">
                      <v-simple-checkbox
                        color="brandCyan"
                        @click.stop="addUserToSelected(item, false)"
                        :ripple="false"
                        :value="
                          selectedUsers.find(x => x.userId == item.userId)
                            ? true
                            : false
                        "
                      />
                    </template>
                  </v-data-table> -->
                </div>
                <div v-else>
                  <GroupTable
                    ref="group-table"
                    :selected="selectedGroups"
                    :search="data.search"
                    @select-item="addUserToSelected($event, false)"
                  />
                  <!-- <v-data-table
                    v-model="selectedGroups"
                    :headers="audienceHeaders"
                    :items="visibleAudience"
                    :search="data.search"
                    item-key="groupId"
                    class="elevation-0 full-width cursor-pointer"
                    :loading="loading.groups"
                    hide-default-header
                    @click:row="addUserToSelected($event, false)"
                    mobile-breakpoint="0"
                    loading-text="Loading... Please wait"
                    no-data-text="No groups found"
                    no-results-text="No results found"
                  >
                    <template v-slot:[`item.numUsers`]="{ item }">
                      <div class="d-flex align-center">
                        <v-icon class="mr-2">mdi-account-multiple</v-icon>
                        <span class="mr-5">{{
                          item.GroupAssignments
                            ? item.GroupAssignments.length
                            : 0
                        }}</span>
                      </div>
                    </template>
                    <template v-slot:[`item.groupDisplayName`]="{ item }">
                      <span class="word-break">{{
                        item.groupDisplayName
                      }}</span>
                    </template>
                    <template v-slot:[`item.info`]="{ item }">
                      <v-btn icon @click.stop="previewItem(item)"
                        ><v-icon color="grey"
                          >mdi-dots-horizontal</v-icon
                        ></v-btn
                      >
                    </template>
                    <template v-slot:[`item.select`]="{ item }">
                      <v-simple-checkbox
                        color="brandCyan"
                        @click.stop="addUserToSelected(item, false)"
                        :ripple="false"
                        :value="
                          selectedGroups.find(x => x.groupId == item.groupId)
                            ? true
                            : false
                        "
                      />
                    </template>
                  </v-data-table> -->
                </div>
                <v-bottom-sheet
                  v-model="dialog.previewAudience"
                  inset
                  width="500"
                >
                  <v-sheet
                    class="text-center"
                    :height="
                      dialog.selectedAudience.groupId &&
                      (loading.groupAssignments ||
                        !dialog.selectedAudience.GroupAssignments ||
                        dialog.selectedAudience.GroupAssignments.length == 0)
                        ? '355px'
                        : '475px'
                    "
                    v-if="dialog.selectedAudience"
                  >
                    <!-- <v-btn
                class="mt-6"
                text
                color="error"
                @click="slidePeople.infoSheet = !slidePeople.infoSheet"
              >
                close
              </v-btn> -->
                    <div class="pt-4">
                      <div
                        class="d-flex align-center justify-space-between mx-6"
                      >
                        <v-btn fab color="lightGrey" small class="hidden"
                          ><v-icon>mdi-chevron-down</v-icon></v-btn
                        >
                        <v-card-title class="word-break">
                          <b> {{ dialog.selectedAudience.displayName }}</b>
                        </v-card-title>
                        <v-btn
                          fab
                          color="mediumGrey"
                          small
                          depressed
                          elevation="0"
                          @click="
                            dialog.previewAudience = !dialog.previewAudience
                          "
                          ><v-icon color="white"
                            >mdi-chevron-down</v-icon
                          ></v-btn
                        >
                      </div>
                      <div
                        v-if="
                          dialog.selectedAudience &&
                            dialog.selectedAudience.userId
                        "
                      >
                        <v-img
                          v-bind:src="dialog.selectedAudience.profilePicUrl"
                          v-if="dialog.selectedAudience.profilePicUrl"
                          height="110"
                          width="110"
                          max-height="110"
                          max-width="110"
                          class="circle-border-radius profile-picture mx-auto"
                        />
                        <v-icon
                          v-else
                          x-large
                          class="profile-picture circle-border-radius icon-image"
                          >mdi-account</v-icon
                        >

                        <p class="mb-1 mt-4">
                          {{
                            dialog.selectedAudience.jobTitle ||
                              "Unknown Job Title"
                          }}
                        </p>
                        <p class="mb-1">
                          {{
                            dialog.selectedAudience.organization ||
                              "Unknown Organization"
                          }}
                        </p>
                        <p>
                          {{
                            dialog.selectedAudience.businessCity
                              ? dialog.selectedAudience.businessCity
                              : ""
                          }}{{
                            dialog.selectedAudience.businessCity &&
                            dialog.selectedAudience.businessState
                              ? ", "
                              : !dialog.selectedAudience.businessCity &&
                                !dialog.selectedAudience.businessState
                              ? "Unknown Location"
                              : ""
                          }}
                          {{
                            dialog.selectedAudience.businessState
                              ? dialog.selectedAudience.businessState
                              : ""
                          }}
                        </p>
                        <v-divider class="mx-7" />
                        <div class="d-flex ml-6 mt-6">
                          <p class="darkGrey--text mb-2">
                            Email:
                            <b class="black--text">{{
                              dialog.selectedAudience.businessEmail
                                ? dialog.selectedAudience.businessEmail
                                : "-"
                            }}</b>
                          </p>
                        </div>
                        <div class="d-flex ml-6">
                          <p class="darkGrey--text">
                            Phone:
                            <b class="black--text">{{
                              dialog.selectedAudience.businessPhone
                                ? dialog.selectedAudience.businessPhone
                                : "-"
                            }}</b>
                          </p>
                        </div>
                      </div>
                      <div v-else>
                        <div class="d-flex align-center justify-center">
                          <p class="mb-0">
                            {{
                              dialog.selectedAudience.groupType + " group " ||
                                "Unknown Group Type "
                            }}
                          </p>
                          <v-icon class="ml-2">mdi-account</v-icon>
                          <h3>
                            {{
                              dialog.selectedAudience.GroupAssignments
                                ? dialog.selectedAudience.GroupAssignments
                                    .length
                                : 0
                            }}
                          </h3>
                        </div>
                        <p
                          v-if="
                            loading.groupAssignments == false &&
                              (!dialog.selectedAudience.GroupAssignments ||
                                dialog.selectedAudience.GroupAssignments
                                  .length == 0)
                          "
                          class="mx-5 mt-7"
                        >
                          Uh oh, it looks like there aren't any people in this
                          group. Try choosing a different group to pay
                        </p>
                        <v-virtual-scroll
                          height="250"
                          class="mt-2 mb-5"
                          item-height="45"
                          :items="dialog.selectedAudience.GroupAssignments"
                          v-else-if="loading.groupAssignments == false"
                        >
                          <template v-slot:default="{ item }">
                            <v-list-item :key="item.groupAssignmentId">
                              <v-list-item-content
                                class="text-left ml-2 mt-2 text-overflow-clip"
                              >
                                <span>{{ item.User.displayName }}</span>
                              </v-list-item-content>
                              <v-list-item-content
                                class="text-left mt-2 text-overflow-clip"
                              >
                                <span>{{
                                  item.User.businessEmail ||
                                    item.User.businessPhone
                                }}</span></v-list-item-content
                              >
                            </v-list-item>
                            <v-divider class="mx-5" />
                          </template>
                        </v-virtual-scroll>
                        <div
                          class="d-flex flex-column align-items-center mt-4"
                          v-else
                        >
                          <Robin
                            :showText="false"
                            :width="160"
                            :height="160"
                            animation="flossing"
                            :loop="true"
                            class="mt-2 mx-auto"
                          />
                          <p>Grabbing users in the group, hold tight...</p>
                        </div>
                      </div>
                      <!-- Hide button if loading group assignments or no group assignments and on groups table -->
                      <v-btn
                        v-if="
                          (dialog.selectedAudience.groupId &&
                            dialog.selectedAudience.GroupAssignments &&
                            dialog.selectedAudience.GroupAssignments.length >
                              0 &&
                            loading.groupAssignments == false) ||
                            !dialog.selectedAudience.groupId
                        "
                        color="brandCyan"
                        rounded
                        depressed
                        width="90"
                        @click="
                          addUserToSelected(dialog.selectedAudience, true)
                        "
                        class="mt-auto white--text"
                        >Pay</v-btn
                      >
                    </div>
                  </v-sheet>
                </v-bottom-sheet>
              </div>
            </div>
            <div
              v-else-if="slideKey === keys.payment"
              :key="keys.payment"
              class="full-width text-left"
            >
              <p class="text-h6 font-weight-bold mb-5 mt-12">
                What are you sending?
              </p>
              <div
                v-if="loading.presets"
                class="d-flex justify-center preset-loading"
              >
                <v-progress-circular
                  color="brandCyan"
                  indeterminate
                  :size="40"
                  :width="3"
                ></v-progress-circular>
              </div>
              <div
                class="d-flex preset-carousel pb-2"
                v-else-if="data.presets.length > 0"
              >
                <v-card
                  class="d-flex flex-column justify-center align-center rounded preset-card text-center mx-2"
                  :class="{
                    'light-grey-background':
                      values.paymentPresetId == preset.paymentPresetId
                  }"
                  :style="
                    values.paymentPresetId != preset.paymentPresetId
                      ? 'background: white !important;'
                      : ''
                  "
                  elevation="0"
                  v-for="preset in data.presets"
                  :key="preset.paymentPresetId"
                  :ripple="false"
                  @click="loadPreset(preset)"
                >
                  <span class="text my-1">
                    {{ preset.displayName }}
                  </span>
                  <div
                    :style="
                      `background: ${preset.backgroundColor || '#349E03'}`
                    "
                    class="icon-img rounded-lg d-flex justify-center align-center pa-1"
                  >
                    <v-img
                      v-if="preset.Icon && preset.Icon.imageUrl"
                      :src="preset.Icon.imageUrl"
                      width="35"
                      height="35"
                      contain
                    />
                  </div>
                  <span class="my-1 text">
                    {{ formatPresetAmount(preset.amount) }}
                  </span>
                </v-card>
              </div>

              <p
                class="text-h6 font-weight-bold mt-5"
                v-if="loading.presets || data.presets.length"
              >
                Or enter an amount below
              </p>
              <div class="d-flex justify-space-between align-center mb-5">
                <div class="d-flex flex-column text-sm-left">
                  <span class="brandCyan--text">Amount per person</span>
                  <v-text-field
                    outlined
                    dense
                    placeholder="0.00"
                    color="brandCyan"
                    :prefix="!cashAward ? '' : '$'"
                    class="mt-0 pt-0 amount-text-field"
                    type="number"
                    hide-details
                    :rules="rules.amount"
                    v-model="values.amount"
                  ></v-text-field>
                </div>
                <div class="d-flex align-center brandCyan--text mt-4">
                  <v-progress-circular
                    indeterminate
                    :size="20"
                    :width="2"
                    color="brandCyan"
                    v-if="loading.distinctUsers"
                  ></v-progress-circular>
                  <span v-else>{{ paymentAudienceCount }}</span
                  ><v-icon color="brandCyan" class="ml-1"
                    >mdi-account-multiple</v-icon
                  >
                </div>
                <div class="d-flex flex-column text-sm-left">
                  <span class="">Total payment</span>
                  <v-text-field
                    outlined
                    dense
                    placeholder="0.00"
                    color="brandCyan"
                    :prefix="
                      selectedBudget && selectedBudget.awardId != 1 ? '' : '$'
                    "
                    class="mt-0 pt-0 amount-text-field"
                    type="number"
                    hide-details
                    readonly
                    v-model="totalAmount"
                  ></v-text-field>
                </div>
              </div>
              <p v-if="amountRemainingInBudget < 0" class="mt-5">
                Over budget by
                <span class="font-weight-bold ml-1 error--text">
                  {{ amountRemainingInBudgetFormatted }}</span
                >
              </p>
              <p class="text-caption ml-2 mb-1 mt-12">Deducted from</p>
              <v-select
                outlined
                dense
                attach
                :menu-props="{ top: true, offsetY: true }"
                color="brandCyan"
                :items="visibleBudgets"
                :loading="loadingBudgets"
                v-model="values.budgetId"
                item-value="budgetId"
                item-text="displayNameWithBalance"
                :no-data-text="
                  loadingBudgets ? 'Loading your budgets' : 'No budgets found'
                "
                :rules="[v => !!v || 'Budget is required']"
                @blur="sendBudgetIdTrigger"
              >
                <template v-slot:item="{ item }">
                  <div
                    class="d-flex align-center justify-space-between full-width"
                  >
                    <span class="budget-name-container text-overflow-clip">
                      {{ item.budgetDisplayName }}
                    </span>
                    <span
                      ><span v-if="item.awardId == 1">$ </span
                      >{{ formatCurrency(item.budgetBalance) }}
                      <span v-if="item.awardId != 1 && item.Award">{{
                        item.budgetBalance == 1 && item.Award.unitSingular
                          ? item.Award.unitSingular
                          : item.budgetBalance != 1 && item.Award.unitPlural
                          ? item.Award.unitPlural
                          : ""
                      }}</span></span
                    >
                  </div>
                </template></v-select
              >

              <div v-if="!values.paymentPresetId">
                <div class="d-flex align-center mb-3">
                  <v-switch
                    hide-details
                    v-model="values.suggest"
                    class="my-0 py-0 mx-1"
                    color="brandCyan"
                  />
                  <span>Suggest a use for these funds</span>
                  <v-tooltip top
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon v-on="on" v-bind="attrs" class="ml-3" small
                        >mdi-help-circle-outline</v-icon
                      ></template
                    ><span
                      >Suggesting a use will cause this payment to show as a
                      separate item in the wallet,<br />
                      making it clear which funds are allocated for a given
                      purpose.</span
                    ></v-tooltip
                  >
                </div>

                <!-- <span class="text-caption ml-2">Deposited to</span> -->
                <div
                  class="mx-auto d-flex align-center selected-category category-container full-width mb-5"
                  v-if="values.suggest"
                  @click="dialog.categoryDrawer = true"
                >
                  <v-icon
                    class="side-nav-icons ml-2 mr-2"
                    color="white"
                    :style="{ 'background-color': '#349E03' }"
                    >{{ categoryOptions[values.categoryIndex].icon }}</v-icon
                  >
                  <div
                    class="d-flex justify-start align-baseline font-weight-bold brand--text"
                  >
                    <p class="ml-2 my-auto">
                      {{ categoryOptions[values.categoryIndex].label }}
                    </p>
                  </div>
                  <v-icon large class="ml-auto mr-2">mdi-chevron-right</v-icon>
                </div>
              </div>
            </div>
            <div
              v-else-if="slideKey === keys.memo"
              :key="keys.memo"
              class="full-width text-left"
            >
              <p class="text-h6 font-weight-bold mb-5 mt-12">
                Add a note?
                <span class="font-weight-regular"
                  >It's optional, but recommended.</span
                >
              </p>
              <v-textarea
                label="Payment memo"
                outlined
                color="brandCyan"
                v-model="values.memo"
              ></v-textarea>
              <!-- <div class="d-flex align-center mb-3">
                <span>Private</span>
                <v-switch
                  hide-details
                  v-model="values.public"
                  class="my-0 py-0 mx-3"
                  color="brandCyan"
                />
                <span>Public</span>
                <v-tooltip top
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon v-on="on" v-bind="attrs" class="ml-3" small
                      >mdi-help-circle-outline</v-icon
                    ></template
                  ><span
                    >A public recognition will be displayed company-wide in the
                    Activity Feed</span
                  ></v-tooltip
                >
              </div> -->
            </div>

            <div
              v-else-if="slideKey === keys.review"
              :key="keys.review"
              class="full-width text-left"
            >
              <div>
                <p class="text-h6 font-weight-bold mb-5 mt-12">
                  Does this look right?
                </p>
                <div class="d-flex justify-start align-center">
                  <div
                    :style="
                      `background: ${(values.preset &&
                        values.preset.backgroundColor) ||
                        '#349E03'}`
                    "
                    class="icon-img rounded-lg d-flex justify-center align-center pa-1 mr-3"
                  >
                    <v-img
                      v-if="
                        values.preset &&
                          values.preset.Icon &&
                          values.preset.Icon.imageUrl
                      "
                      :src="values.preset.Icon.imageUrl"
                      width="45"
                      height="45"
                      contain
                    />
                    <v-icon v-else color="white" large>mdi-wallet</v-icon>
                  </div>
                  <div class="d-flex flex-column">
                    <p class="mb-0">
                      for
                      <v-progress-circular
                        indeterminate
                        :size="20"
                        :width="2"
                        color="brandCyan"
                        v-if="loading.distinctUsers"
                      ></v-progress-circular>
                      <span
                        v-else
                        @click="goToSlide(keys.recipient)"
                        class="brandCyan--text font-weight-bold cursor-pointer"
                        >{{ paymentAudienceCount }}</span
                      >
                      {{ paymentAudienceCount == 1 ? "person" : "people" }}
                    </p>
                    <p class="mb-1">
                      Total payment of
                      <span class="font-weight-bold ml-1">
                        {{ formattedTotalPayment }}</span
                      >
                    </p>
                  </div>
                </div>
                <p v-if="amountRemainingInBudget < 0" class="mt-5">
                  Over budget by
                  <span class="font-weight-bold ml-1 error--text">
                    {{ amountRemainingInBudgetFormatted }}</span
                  >
                </p>

                <p class="text-caption mb-1 grey--text mt-3">Payment memo</p>
                <v-textarea outlined v-model="values.memo"></v-textarea>
                <!-- <div class="d-flex align-center mb-3">
                  <span>Private</span>
                  <v-switch
                    hide-details
                    v-model="values.public"
                    class="my-0 py-0 mx-3"
                    color="brandCyan"
                  />
                  <span>Public</span>
                  <v-tooltip top
                    ><template v-slot:activator="{ on, attrs }"
                      ><v-icon v-on="on" v-bind="attrs" class="ml-3" small
                        >mdi-help-circle-outline</v-icon
                      ></template
                    ><span
                      >A public recognition will be displayed company-wide in
                      the Activity Feed</span
                    ></v-tooltip
                  >
                </div> -->
                <p class="text-caption mb-0 grey--text mt-3 mb-2">
                  Payment details
                </p>
                <div
                  class="d-flex justify-start align-center full-width mb-2"
                  v-if="selectedBudget"
                >
                  <p
                    class="review-page-text mt-0 mb-0"
                    :class="{ 'text-xs': $vuetify.breakpoint.xs }"
                  >
                    Payment source:
                  </p>
                  <v-select
                    outlined
                    dense
                    attach
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="brandCyan"
                    :items="visibleBudgets"
                    :loading="loadingBudgets"
                    v-model="values.budgetId"
                    item-value="budgetId"
                    item-text="displayNameWithBalance"
                    :no-data-text="
                      loadingBudgets
                        ? 'Loading your budgets'
                        : 'No budgets found'
                    "
                    :rules="[v => !!v || 'Budget is required']"
                    @blur="sendBudgetIdTrigger"
                    class="review-page-field"
                    hide-details
                  >
                    <template v-slot:item="{ item }">
                      <div
                        class="d-flex align-center justify-space-between full-width"
                      >
                        <span class="budget-name-container text-overflow-clip">
                          {{ item.budgetDisplayName }}
                        </span>
                        <span
                          ><span v-if="item.awardId == 1">$ </span
                          >{{ formatCurrency(item.budgetBalance) }}
                          <span v-if="item.awardId != 1 && item.Award">{{
                            item.budgetBalance == 1 && item.Award.unitSingular
                              ? item.Award.unitSingular
                              : item.budgetBalance != 1 && item.Award.unitPlural
                              ? item.Award.unitPlural
                              : ""
                          }}</span></span
                        >
                      </div>
                    </template></v-select
                  >
                </div>
                <div class="d-flex justify-start align-center full-width mb-6 ">
                  <p
                    class="review-page-text mb-0"
                    :class="{ 'text-xs': $vuetify.breakpoint.xs }"
                  >
                    Time of reward:
                  </p>
                  <input
                    class="timestamp-input review-page-field"
                    type="datetime-local"
                    v-model="values.sendDate"
                  />
                </div>
                <!-- <div class="d-flex justify-start align-start full-width mb-2">
                  <p class="review-page-text mb-0 mt-2">Funds expire:</p>
                  <div class="d-flex flex-column review-page-field">
                    <v-select
                      outlined
                      dense
                      attach
                      :menu-props="{ top: true, offsetY: true }"
                      color="brandCyan"
                      :items="data.expirationOptions"
                      v-model="values.expiration"
                      item-value="id"
                      item-text="label"
                      class=""
                      hide-details
                    >
                    </v-select>
                    <input
                      v-if="values.expiration == 7"
                      class="timestamp-input mt-2"
                      type="datetime-local"
                      v-model="values.expirationCustom"
                    />
                  </div>
                </div>
                <div class="d-flex justify-start align-center full-width mb-2">
                  <p class="review-page-text mb-0">Recurring:</p>
                  <v-select
                    outlined
                    dense
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    color="brandCyan"
                    :items="data.recurringOptions"
                    :rules="rules.recurring"
                    v-model="values.recurring"
                    item-value="id"
                    item-text="label"
                    class="review-page-field"
                    hide-details
                  >
                  </v-select>
                </div>
                <div
                  class="d-flex justify-start align-center full-width mb-2"
                  v-if="values.recurring != null && values.recurring != 1"
                >
                  <p class="review-page-text mb-0"># of payments:</p>
                  <v-text-field
                    outlined
                    dense
                    color="brandCyan"
                    class="mt-0 pt-0 review-page-field"
                    type="number"
                    hide-details
                    v-model="values.recurringNum"
                    :rules="rules.amount"
                  ></v-text-field>
                </div> -->
              </div>
            </div>
            <div
              v-else-if="slideKey == keys.submitting"
              :key="keys.submitting"
              class="full-width text-left"
            >
              <div>
                <p class="text-h6 font-weight-bold mb-5 mt-12">
                  Processing...
                </p>
                <div class="d-flex flex-column align-center"></div>
              </div>
            </div>
            <div
              v-else-if="slideKey == keys.sent"
              :key="keys.sent"
              class="full-width text-left pt-12"
            >
              <p class="text-h6 word-break font-weight-bold mb-3">
                You just made someone's day better!
              </p>
              <!-- <p class="full-width">
                Your payments are being processed. You will be notified once
                completed.
              </p> -->
              <div class="d-flex justify-center mt-6">
                <Robin
                  :height="120"
                  :width="140"
                  class=""
                  :loop="false"
                  animation="backflip"
                />
              </div>
              <div class="d-flex justify-center mt-12 mb-5">
                <v-btn
                  depressed
                  rounded
                  color="brandCyan"
                  class="white--text"
                  @click="reset"
                  width="95"
                  ><span>Close</span></v-btn
                >
              </div>
            </div>
            <div
              v-else-if="slideKey == keys.error"
              :key="keys.error"
              class="full-width text-left pt-12"
            >
              <p class="text-h6 word-break font-weight-bold mb-3">
                We had some trouble sending your payment. <br />Please try again
                later.
              </p>
              <p class="full-width">
                If this problem continues, feel free to reach out to customer
                service via one of the following methods...
              </p>
              <p class="pl-4 full-width">
                Email -
                <b
                  ><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b
                >
              </p>
              <p class="pl-4 full-width">
                Phone (Toll Free) - <b>(855) 264-3329</b>
              </p>

              <div class="d-flex justify-center my-5">
                <v-btn
                  depressed
                  rounded
                  color="brandCyan"
                  class="white--text mt-4"
                  @click="(slideKey = keys.review), (progressBarValue = 0)"
                  ><v-icon class="mr-1">mdi-chevron-left</v-icon
                  ><span style="margin-top:1px;">Go back</span></v-btn
                >
              </div>
            </div>
          </transition>
        </div>

        <div
          v-if="slideKey == keys.review || slideKey == keys.submitting"
          class="mt-2 mb-5 d-flex flex-column align-center"
        >
          <v-btn
            rounded
            depressed
            color="brandCyan"
            class="white--text"
            @click="submitPayment"
            :disabled="disableContinueButton"
          >
            {{ slideKey == keys.review ? "Send reward" : "Processing" }}</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <!-- Error dialog -->
    <v-dialog v-model="dialogError" persistent width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-center align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-2"
            >mdi-alert</v-icon
          >
          <v-card-title
            class="word-break text-left exit-warning-text mx-0 px-0"
          >
            There was an error sending your payment
          </v-card-title>
        </div>

        <p>{{ errorText }}</p>
        <p
          v-for="user in annualPaymentCapErrorArray"
          :key="user.userId"
          class="mb-1"
        >
          {{ user.formattedOutput }}
        </p>

        <v-card-actions class="mx-12">
          <!-- <v-btn
            color="primary"
            @click="closeProgramBuilder"
            outlined
            width="130"
            >Don't save</v-btn
          > -->
          <v-btn
            class="mx-auto white--text"
            depressed
            rounded
            color="brandCyan"
            @click="
              (slideKey = keys.review),
                (dialogError = false),
                (progressBarValue = 0),
                (annualPaymentCapErrorArray = [])
            "
            width="130"
            >Go Back</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay
      v-if="dialog.createGroup || dialog.createUser || dialog.categoryDrawer"
    />
    <v-navigation-drawer
      v-if="dialog.categoryDrawer"
      v-model="dialog.categoryDrawer"
      temporary
      fixed
      right
      width="500"
    >
      <v-toolbar dark color="#349E03" rounded="0" class="mb-15">
        <v-toolbar-title>Select a payment category</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialog.categoryDrawer = false">
            Close
            <v-icon class="ml-2">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div
        class="mx-auto mt-2 d-flex align-center category-container"
        :style="{ 'max-height': '40px', width: '80%' }"
        v-for="(option, index) in categoryOptions"
        :key="option.label"
        @click="selectOneTimeCategory(index)"
        @mouseover="previewCategoryDescription(index)"
        @mouseleave="resetCategoryDescriptionPreview"
      >
        <v-icon
          class="side-nav-icons mr-2"
          color="white"
          :style="{ 'background-color': '#349E03' }"
          >{{ option.icon }}</v-icon
        >
        <div
          class="d-flex justify-start align-baseline category-div font-weight-bold brand--text"
          :class="
            values.categoryIndex == index ? 'selected-category-background' : ''
          "
        >
          <p class="ml-2 my-auto">{{ option.label }}</p>
        </div>
      </div>
      <p
        class="mb-2 mt-6 text-body-2 text-left grey--text"
        :style="{ 'margin-left': '50px' }"
      >
        Category description and restrictions
      </p>
      <v-divider
        :style="{ 'margin-left': '50px', 'margin-right': '50px' }"
      ></v-divider>
      <p class="mt-2 mx-auto text-body-2" :style="{ width: '80%' }">
        {{ computedOneTimeCategoryDescription }}
      </p>
    </v-navigation-drawer>
    <!-- User creator widget -->
    <v-navigation-drawer
      v-model="dialog.createUser"
      temporary
      fixed
      right
      width="500"
    >
      <UserCreatorWidgetSimple
        v-if="dialog.createUser"
        @close="dialog.createUser = false"
        @get-users="getUsers"
        :clientId="clientId"
        source="PAYMENTS_WIZARD"
      ></UserCreatorWidgetSimple>
    </v-navigation-drawer>
    <!-- Group creator widget -->
    <v-navigation-drawer
      v-model="dialog.createGroup"
      temporary
      fixed
      right
      width="500"
    >
      <GroupCreatorWidget
        v-if="dialog.createGroup"
        @get-groups="getGroups($event, true)"
        @close="dialog.createGroup = false"
        :clientId="clientId"
        :version="3"
        source="PAYMENTS_WIZARD"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import WalletService from "@/services/WalletService";
import TriggerService from "@/services/TriggerService";
import UserService from "@/services/UserService";
import GroupService from "@/services/GroupService";

import { truncateNumber, numberWithCommas } from "@/shared_data/functions";

import Robin from "@/components/Robin";
import UserCreatorWidgetSimple from "./UserCreatorWidgetSimpleV2.vue";
import GroupCreatorWidget from "@/components/groups/GroupCreatorWidgetSimplified.vue";
import UserTable from "@/components/data-table/Users.vue";
import GroupTable from "@/components/data-table/Groups.vue";

import currency from "currency.js";
import moment from "moment";
import { mapState } from "vuex";
import { debounce } from "@/shared_data/functions";

function initialState() {
  return {
    slideKey: 1,
    dialogError: false,
    errorText: null,
    annualPaymentCapErrorArray: [],
    slideDirection: "topic-left",
    keys: {
      recipient: 1,
      payment: 2,
      memo: 3,
      review: 4,
      submitting: 5,
      // send: 101,
      sent: 102,
      error: 103
    },
    loading: {
      submitting: false,
      users: false,
      groups: false,
      groupAssignments: false,
      recent: false,
      recentUsers: false,
      recentGroups: false,
      presets: false,
      distinctUsers: false
    },
    data: {
      search: null,
      debounceSearch: null,
      activeAudienceTab: 0,
      users: [],
      recentUsers: [],
      recentGroups: [],
      groups: [],
      presets: [],
      budgets: [],
      parentPaymentRules: [],
      expirationOptions: [
        { id: 1, label: "Never" },
        { id: 2, label: "1 Week" },
        { id: 3, label: "1 Month" },
        { id: 4, label: "3 Months" },
        { id: 5, label: "6 Months" },
        { id: 6, label: "1 Year" },
        { id: 7, label: "Other" }
      ],
      recurringOptions: [
        { id: 1, label: "Nope, just one time" },
        { id: 2, label: "Weekly" },
        { id: 3, label: "Monthly" },
        { id: 4, label: "Quarterly" },
        { id: 5, label: "Annually" }
      ]
    },
    dialog: {
      previewAudience: false,
      selectedAudience: {},
      createGroup: false,
      createUser: false,
      categoryDrawer: false
    },
    values: {
      budgetId: null,
      selected: [],
      paymentPresetId: null,
      preset: null,
      amount: null,
      // totalAmount: null,
      classification: null,
      memo: null,
      sendDate: moment().format("YYYY-MM-DDTHH:mm"),
      expiration: 1,
      expirationCustom: null,
      recurring: 1,
      recurringNum: "1",
      categoryIndex: 0,
      // public: true,
      suggest: false,
      distinctUsers: 0
    },
    rules: {
      amount: [
        v =>
          !!(v && parseFloat(v) > 0) || "Payment amount must be greater than 0",
        v =>
          !!(
            v &&
            (!v.toString().includes(".") ||
              (v.toString().includes(".") &&
                v.toString().substring(v.toString().indexOf(".") + 1).length <
                  3))
          ) || `Payment amount shouldn't be longer than 2 decimals.`
      ],
      recurring: [
        v =>
          !!(v && parseFloat(v) > 0) || "# of payments must be greater than 0",
        v =>
          !!(v && !v.toString().includes(".")) ||
          "# of payments must be a whole number"
      ]
    },
    progressBarValue: 0,
    budgetPlatformActivityCounter: 0,
    categoryOptions: [
      // {
      //   // Warning: If you change this label, you need to change the reference in the template. It relies on this label to know to render the expiration toggle
      //   label: "General expenses",
      //   icon: "mdi-wallet",
      //   description:
      //     "These funds belong to the recipient and they can spend them however they see fit. This should be used when sending bonuses.",
      //   mccDescription: null,
      //   mccGroupDefinitionId: null
      // },
      {
        label: "Meal",
        icon: "mdi-food-outline",
        description:
          "Meal funds are intended to be used for buying a meal at a restaurant, fast food, or grocery store, and are restricted to these kinds of transactions.",
        mccDescription: "Meals",
        mccGroupDefinitionId: 1
      },
      {
        label: "Coffee",
        icon: "mdi-coffee-outline",
        description:
          "Coffee funds are intended to be used for buying a coffee or snack at a restaurant, fast food, or grocery store, and are restricted to these kinds of transactions.",
        mccDescription: "Meals",
        mccGroupDefinitionId: 1
      },
      {
        label: "Office supplies",
        icon: "mdi-paperclip",
        description:
          "These funds are intended to be used to buy office supplies.",
        mccDescription: "Office supplies",
        mccGroupDefinitionId: 4
      },
      {
        label: "Wellness",
        icon: "mdi-run",
        description:
          "These funds are intended to be used by the employee to help them live a healthier life, whether that be by paying for a gym membership or mental health services.",
        mccDescription: null,
        mccGroupDefinitionId: null
      },
      {
        label: "Culture",
        icon: "mdi-emoticon-happy-outline",
        description:
          "Culture funds are to be used at the employees discretion on anything benefitting the team or organization.",
        mccDescription: null,
        mccGroupDefinitionId: null
      },
      {
        label: "Travel",
        icon: "mdi-train-car",
        description:
          "Travel funds can only be used for transportation (including air, car services or rental, public transportation), hotels and lodging, gas stations and restaurants.",
        mccDescription: "Travel",
        mccGroupDefinitionId: 2
      },
      {
        label: "Philanthropy",
        icon: "mdi-charity",
        description:
          "These funds can be used to democratize corporate giving, by enabling employees to make charitable donations.",
        mccDescription: null,
        mccGroupDefinitionId: null
      },
      {
        label: "Business expense",
        icon: "mdi-briefcase-outline",
        description:
          "This category of funds is for general business expenses and is treated like a corporate credit limit.",
        mccDescription: null,
        mccGroupDefinitionId: null
      }
    ],
    categoryDescriptionPreview: null,
    navigationItemWidth: 200,
    userTable: {
      itemsPerPage: 10,
      page: 1,
      // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
      awaitingPage: 1,
      search: null,
      debounceSearch: null,
      loading: false,
      users: [],
      total: 0,
      selected: [],
      options: {},
      footerProps: {
        "items-per-page-options": [10, 25, 50, 100]
      },
      latestFilter: null,
      latestSort: null,
      columns: [
        { text: "Name", value: "displayName", width: "40%" },
        { text: "Email", value: "businessEmail", width: "50%" },
        { text: "", value: "add" }
      ]
    }
  };
}

export default {
  name: "PaymentWizardV3",
  components: {
    Robin,
    UserCreatorWidgetSimple,
    GroupCreatorWidget,
    UserTable,
    GroupTable
  },
  props: {
    budgets: Array,
    loadingBudgets: Boolean
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created payment wizard V3");
    // this.getUsers();

    // this.clientId = this.userProfile.clientId;
    this.preloadBudget();
    this.getRecentParentPaymentRules();
    this.getPaymentPresets();

    this.createPlatformActivity("OPENED_PAYMENTS_WIZARD");
    window.addEventListener("resize", this.refreshScreenSize);
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
    this.refreshScreenSize();
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }

    window.removeEventListener("resize", this.refreshScreenSize);
  },
  beforeDestroy() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    resetAllData() {
      // Used to reset the state when the user clicks out of the payments console.
      Object.assign(this.$data, initialState());
    },
    reset() {
      this.resetAllData();
      this.$emit("reset");
      // Comments out get-budgets, that is now called in resetPaymentWizard in payment admin
      //   this.$emit("get-budgets", "paymentWizard reset");
    },
    // getBudgets() {
    //   this.$emit("get-budgets");
    // },
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },
    refreshScreenSize() {
      setTimeout(() => {
        if (document.getElementsByClassName("pill-nav").length > 0) {
          this.navigationItemWidth = document.getElementsByClassName(
            "pill-nav"
          )[0].offsetWidth;
        } else {
          this.navigationItemWidth = 200;
        }
        console.log("Nav width ", this.navigationItemWidth);
      }, 60);
    },
    goToSlide(index) {
      // Figures out what step we're going into so we can insert the proper platform activity
      const oldIndex = this.slideKey;
      var platformActivityEvent = null;
      if (this.slideKey == this.keys.recipient)
        platformActivityEvent = "SELECTED_PROVISIONAL_PAYMENT_RECIPIENTS";
      else if (this.slideKey == this.keys.memo) {
        platformActivityEvent = "ADDED_PROVISIONAL_PAYMENT_MEMO";
      } else if (this.slideKey == this.keys.payment)
        platformActivityEvent = "SELECTED_PROVISIONAL_PAYMENT_AMOUNT";
      // else if (this.slideKey == this.keys.expiration)
      //   platformActivityEvent = "SELECTED_PAYMENT_EXPIRATION_DATE";
      // this.disableContinueButton
      // We have to block if the user has already sent payment
      if (this.slideKey >= 1 && this.slideKey < this.keys.submitting) {
        if (index < this.slideKey) {
          this.slideDirection = "topic-right";
          this.scrollToTop();
          return (this.slideKey = index);
        } else {
          this.slideDirection = "topic-left";
        }

        // We route depending on true's and false's in the computed array
        var foundFalse = false;
        // Index is relative to all. We have to equate it to the index in our current route;
        var routeIndex = this.route.findIndex(x => x == index);

        // When using goToSlide to jump around, we have to make sure the user has completed all steps prior to the step that they're trying to route to.
        for (var i = 0; i < routeIndex; i++) {
          if (!this.routeValidation[i]) {
            foundFalse = true;
          }
        }

        // If we only found true values in the array, then we didn't find a false and therefore can continue
        if (!foundFalse) {
          this.scrollToTop();
          this.slideKey = index;

          if (oldIndex == this.keys.recipient)
            this.getDistinctUsersFromGroups();
          if (platformActivityEvent)
            this.createPlatformActivity(platformActivityEvent);
        }
      }
    },
    goToNextSlide() {
      this.slideDirection = "topic-left";
      this.scrollToTop();
      const oldIndex = this.slideKey;

      if (this.slideKey === this.keys.review) {
        //We're on the final slide so show confirmation
        //Or we are editing and the message has already sent, so they can only update the message body
        this.submitPayment();
      } else if (this.slideKey == this.keys.sent) {
        this.reset();
      } else {
        // Figures out what step we're going into so we can insert the proper platform activity
        var platformActivityEvent = null;
        if (this.slideKey == this.keys.recipient)
          platformActivityEvent = "SELECTED_PROVISIONAL_PAYMENT_RECIPIENTS";
        else if (this.slideKey == this.keys.memo) {
          platformActivityEvent = "ADDED_PROVISIONAL_PAYMENT_MEMO";
        } else if (this.slideKey == this.keys.payment)
          platformActivityEvent = "SELECTED_PROVISIONAL_PAYMENT_AMOUNT";

        // The route has been established. Now we have to check if they can proceed
        var routeIndex = this.route.findIndex(x => x == this.slideKey);

        // if (
        //   this.route[routeIndex + 1] == this.keys.mccCustom &&
        //   this.slideProvisional.selectedPresets.length > 0
        // ) {
        //   // We can skip custom MCC page
        //   routeIndex++;
        // }
        this.slideKey = this.route[routeIndex + 1];

        if (oldIndex == this.keys.recipient) this.getDistinctUsersFromGroups();

        if (platformActivityEvent)
          this.createPlatformActivity(platformActivityEvent);
      }
    },
    goToPreviousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();

      // The route has been established. Now we have to check if they can proceed
      var routeIndex = this.route.findIndex(x => x == this.slideKey);
      var counter = 1;

      this.slideKey = this.route[routeIndex - counter];
    },

    async submitPayment() {
      // Show loading bar
      console.log("Submitting payment! ", this.keys.submitting);
      this.slideKey = this.keys.submitting;
      this.loading.submitting = true;
      const progressIncrement = 1.5;
      const sleepDur = 150;
      this.loadBarSleeper(progressIncrement, sleepDur, false);

      try {
        var object = {
          clientId: this.clientId,
          budgetId: this.values.budgetId,
          description: this.computedNote,
          amount: currency(this.values.amount),
          paymentType:
            (this.values.categoryIndex != null &&
              this.computedOneTimeCategory.label == "General expenses") ||
            (this.values.categoryIndex != null && !this.values.suggest) ||
            this.values.paymentPresetId
              ? "DEFAULT"
              : "PROVISIONAL",
          classification:
            this.values.paymentPresetId && this.values.preset
              ? this.values.preset.classification
              : this.computedOneTimeCategory.label,
          startDate: moment(this.values.sendDate)
            .utc()
            .format("YYYY-MM-DD HH:mm:ssZ"),
          endDate: null,
          frequency: "ONE_TIME",
          clawback: false,
          // public: this.values.public,
          purpose: this.computedNote,
          audience: {
            userId: this.values.selected
              .filter(x => x.userId)
              .map(x => x.userId),
            groupId: this.values.selected
              .filter(x => x.groupId)
              .map(x => x.groupId)
          },
          mid: [],
          paymentPresetId: this.values.paymentPresetId,
          sourceUserId: this.userProfile.userId,
          source: "PAYMENTS_WIZARD"
        };

        console.log(object);
        // Display loading screen
        // var response;
        var response = await WalletService.createPaymentV2(
          object,
          this.magicLinkToken
        );
        this.loading.submitting = false;
        this.loadBarSleeper(progressIncrement, sleepDur, true);
        console.log(response);
        if (typeof response == "string") {
          if (response.startsWith("The sum of all payouts")) {
            this.dialogError = true;
            this.errorText =
              "The total payment sum is more than the selected budget's balance.";
          }
        } else if (response.error) {
          this.slideKey = this.keys.error;
        } else {
          // Display success screen
          this.slideKey = this.keys.sent;
        }
        // })
      } catch (error) {
        console.log("Error sending payment! ", error);
        if (error && error.error && typeof error.error == "string") {
          if (
            error.error.startsWith("Budget does not have enough ") ||
            error.error_code == "1070100"
          ) {
            this.dialogError = true;
            this.errorText =
              "The total payment amount is more than the selected budget's balance.";
            return;
          } else if (
            error.error_code == "1070127" ||
            error.error_code == "1070128"
          ) {
            this.dialogError = true;
            if (error.error_code == "1070127")
              this.errorText =
                "The users below cannot receive any more payments for the year.";
            else
              this.errorText =
                "The users below cannot receive the full payment. The amounts each user is capped at are listed below.";
            this.annualPaymentCapErrorArray = error.data || [];
            return;
          }
        } else if (error && error.error) {
          this.slideKey = this.keys.error;
          return;
        }
        this.dialogError = true;
        this.errorText =
          "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
      }
    },
    loadBarSleeper(incr, dur, override = false) {
      setTimeout(() => {
        console.log("Incrementing progress", this.progressBarValue);
        if (!this.loading.submitting || override) {
          // We just want to skip to the end
          this.progressBarValue = 100;
        } else {
          this.progressBarValue += incr;
          // We loop IF no error has occurred
          if (
            this.progressBarValue < 90 &&
            this.slideKey != this.keys.error &&
            !this.dialogError
          )
            this.loadBarSleeper(incr, dur);
        }
      }, dur);
    },
    refreshClientId(clientId) {
      this.clientId = clientId;

      this.slideKey = this.keys.recipient;
      this.data.users = [];
      this.values.budgetId = null;
      this.values.selected = [];

      // We emit this so the parent (Payment Admin will refresh budgets)
      this.$emit("update-client", clientId);
      this.getRecentParentPaymentRules();
      this.getPaymentPresets();
    },
    preloadBudget() {
      // Preload the master budget
      // return early if there's already a value
      if (this.values.budgetId) return;
      let clientBudgets = this.budgets.filter(
        x =>
          x.clientId == this.clientId &&
          // x.awardId == 1 &&
          x.status == "Active" &&
          Number(x.budgetBalance) > 0
      );
      const acctBalance = clientBudgets.find(x => x.masterBudget);
      const cashBudget = clientBudgets.find(x => x.awardId == 1);

      if (acctBalance) this.values.budgetId = acctBalance.budgetId;
      else if (cashBudget) this.values.budgetId = cashBudget.budgetId;
      else if (clientBudgets.length)
        this.values.budgetId = clientBudgets[0].budgetId;
    },
    createPlatformActivity(event) {
      var obj = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        event: event,
        source: "PAYMENTS_WIZARD",
        category: "PAYMENTS",
        date: new Date()
      };
      TriggerService.createActivity(obj, this.magicLinkToken)
        .then(response => {
          console.log("Response from inserting activity ", response);
        })
        .catch(error => {
          console.log("There was an error inserting activity ", error);
        });
    },
    sendBudgetIdTrigger() {
      // We want to fire off a platform activity for when they update the payment memo, but this can be done on any screen.
      // So this gets called when the payment memo field loses focus
      // We also want to make sure this only gets sent once.
      if (this.values.budgetId && this.budgetPlatformActivityCounter == 0) {
        this.createPlatformActivity("SELECTED_PROVISIONAL_PAYMENT_BUDGET");
        this.budgetPlatformActivityCounter++;
      }
    },
    selectOneTimeCategory(index) {
      console.log(index);
      this.values.categoryIndex = index;
    },
    previewCategoryDescription(index) {
      this.categoryDescriptionPreview = this.categoryOptions[index].description;
    },
    resetCategoryDescriptionPreview() {
      this.categoryDescriptionPreview = null;
    },
    changeAudienceTab(newIndex) {
      if (newIndex == this.data.activeAudienceTab) return;
      const oldTab = this.data.activeAudienceTab;
      const oldSearchValue = this.data.search;
      this.$set(this.data, "activeAudienceTab", newIndex);
      // this.data.activeAudienceTab = newIndex;

      this.data.debounceSearch = null;
      this.data.search = null;
      // for some reason, if we change the tab, the user table's options don't initialize until you interact with the table once. But if you set the page to 2 then it forces it to init????
      if (this.userTable.options && !this.userTable.options.itemsPerPage) {
        this.userTable.options.page = 2;
        console.log("Setting new page val");
      }

      // If we're leaving users and there was a search value, we refetch users
      // if (oldTab == 1 && oldSearchValue) this.getUsersV2New(true);
    },
    changeSearchTab() {
      console.log("Focused on search bar");
      if (this.data.activeAudienceTab == 0) this.data.activeAudienceTab = 1;
      // for some reason, if we change the tab, the user table's options don't initialize until you interact with the table once. But if you set the page to 2 then it forces it to init????
      if (this.userTable.options && !this.userTable.options.itemsPerPage)
        this.userTable.options.page = 2;
    },
    async getRecentParentPaymentRules() {
      try {
        this.loading.recent = true;

        let results = await WalletService.getParentPaymentRulesV2(
          {
            filter: `clientId == ${this.clientId} && status != 'Deleted'`,
            limit: 10,
            sort: "createDate DESC",
            extract: "audience,parentPaymentRuleId,clientId"
          },
          this.magicLinkToken
        );
        console.log("Got recent parent payment rules ", results.result.rows);
        this.data.parentPaymentRules = results.result.rows;

        // Now we want to fetch those individual users from the audience
        let recentUserIds = [];
        let recentGroupIds = [];
        this.data.parentPaymentRules.forEach(rule => {
          if (rule.audience && rule.audience.userId)
            recentUserIds = [
              ...new Set(recentUserIds.concat(rule.audience.userId))
            ];

          if (rule.audience && rule.audience.groupId)
            recentGroupIds = [
              ...new Set(recentGroupIds.concat(rule.audience.groupId))
            ];
        });
        console.log("Recent users ", recentUserIds);
        console.log("Recent groups ", recentGroupIds);
        this.getRecentUsers(recentUserIds);
        this.getRecentGroups(recentGroupIds);
      } catch (err) {
        console.log("Error with parent payment rules ", err);
      } finally {
        this.loading.recent = false;
      }
    },
    async getPaymentPresets() {
      try {
        this.loading.presets = true;
        const clientId = this.clientId;
        let results = await WalletService.getPaymentPresetsV2(
          {
            clientId: clientId,
            filter: `clientId.isIn([0,${clientId}]) && status != 'Deleted' && iconId != null`,
            limit: 20,
            expand: "Icon"
          },
          this.magicLinkToken
        );
        if (clientId != this.clientId)
          return console.log("Ignoring preset response due to client change");
        console.log("Got payment presets ", results.result.rows);
        this.data.presets = results.result.rows.filter(
          x => x.status != "Disabled"
        );
      } catch (err) {
        console.log("Error with payment presets ", err);
      } finally {
        this.loading.presets = false;
      }
    },
    async getRecentUsers(userIds) {
      // let v1 = process.env.VUE_APP_ENVIRONMENT !== "dev";
      try {
        if (userIds.length == 0) return;
        this.loading.recentUsers = true;

        let filter = `status = 'Active' && User.status != "Deleted" && clientId=${
          this.clientId
        } && userId.isIn(${JSON.stringify(userIds.slice(0, 10))})`;

        // if first page, fetch double
        let limit = 10; //page == 1 ? options.itemsPerPage * 2 : options.itemsPerPage;
        let offset = 0;
        let extract =
          "clientId,userId,firstName,lastName,displayName,jobTitle,organization,profilePicUrl,businessCity,businessState,businessEmail,businessPhone";
        let params = {
          limit,
          offset,
          screen: "payment_wizard",
          clientId: this.clientId,
          userId: userIds
            .slice(0, 10)
            .filter(Boolean)
            .join(","),
          external: 1
        };
        let response = await UserService.getUsersV2(
          params,
          this.magicLinkToken
        );

        if (
          // The only thing that would stop us from using the result is if the clientId changed
          this.clientId != params.clientId
        ) {
          console.log("Ignoring recentUser response");
          return;
        }

        console.log("Recent user response ", response);
        let rows = response.result.rows;

        //Prune the returned users for the data we need for the table
        rows = rows.map(function(user) {
          user.displayName = user.firstName + " " + user.lastName;

          user.id = user.userId;
          user.name = user.displayName || user.firstName + " " + user.lastName;
          return user;
        });

        // If at the beginning we just grab the first results and then append the rest of the empty array
        // If not the beginning, we append the existing fetched array, then the new results, and anything after
        this.data.recentUsers = rows;
      } catch (e) {
        console.log("Error in getRecentUsers", e);
      } finally {
        this.loading.recentUsers = false;
      }
    },
    async getRecentGroups(groupIds) {
      // let v1 = process.env.VUE_APP_ENVIRONMENT !== "dev";
      try {
        if (!groupIds.length) return;
        this.loading.recentGroups = true;

        let filter = `status = 'Active' && clientId=${
          this.clientId
        } && groupId.isIn(${JSON.stringify(groupIds.slice(0, 10))})`;

        // if first page, fetch double
        let limit = 10; //page == 1 ? options.itemsPerPage * 2 : options.itemsPerPage;
        let offset = 0;
        let params = {
          filter,
          limit,
          offset,
          expand: "null",
          clientId: this.clientId
        };
        let response = await GroupService.getGroupsV2(
          params,
          this.magicLinkToken
        );

        if (
          // The only thing that would stop us from using the result is if the clientId changed
          this.clientId != params.clientId
        ) {
          console.log("Ignoring recentGroup response");
          return;
        }

        console.log("Recent group response ", response);
        let rows = response.result.rows;

        //Prune the returned users for the data we need for the table
        rows = rows.map(function(grp) {
          grp.displayName = grp.name = grp.groupDisplayName || grp.groupName;
          grp.id = grp.groupId;
          grp.numUsers = 1;
          return grp;
        });

        // If at the beginning we just grab the first results and then append the rest of the empty array
        // If not the beginning, we append the existing fetched array, then the new results, and anything after
        this.data.recentGroups = rows;
      } catch (e) {
        console.log("Error in getRecentGroups", e);
      } finally {
        this.loading.recentGroups = false;
      }
    },
    getGroups(groupId, wipeSearch = false) {
      // Uses the group table widget
      console.log("getGroups", { groupId, wipeSearch });
      if (wipeSearch) {
        this.data.search = undefined;
        this.data.debounceSearch = undefined;
      }
      this.$nextTick(() => {
        if (this.$refs["group-table"])
          this.$refs["group-table"].getGroups(
            true,
            "getGroups - payment wizard",
            groupId
          );
      });
    },
    getUsers(userId) {
      // Uses the group table widget
      if (this.$refs["user-table"])
        this.$refs["user-table"].getUsers(
          true,
          "getUsers - payment wizard",
          userId
        );
      else if (userId && typeof userId == "object")
        this.addUserToSelected(userId, true);
    },
    async getDistinctUsersFromGroups() {
      // assign to slidePeople.selectedItem.GroupAssignments
      const groups = this.values.selected
        .filter(x => x.groupId)
        .map(x => x.groupId);
      if (!groups.length) return;
      const users = this.values.selected
        .filter(x => x.userId)
        .map(x => x.userId);
      console.log("getDistinctUsersFromGroups()");
      this.loading.distinctUsers = true;
      try {
        const response = await GroupService.getDistinctUsersFromGroupsV2(
          groups,
          users,
          this.magicLinkToken
        );

        this.values.distinctUsers = response;
        console.log("New distinct user count", this.values.distinctUsers);
      } catch (error) {
        console.log("Error getting distinct number of users ", error);

        this.values.distinctUsers = 0;
      } finally {
        this.loading.distinctUsers = false;
      }
    },
    async getGroupAssignments(groupId) {
      // assign to slidePeople.selectedItem.GroupAssignments
      const startTime = new Date();
      this.loading.groupAssignments = true;
      try {
        let filter = `status != 'Deleted' && groupId=${groupId}`;
        let limit = Number.MAX_SAFE_INTEGER;
        let offset = 0;
        let expand = "User";

        const groupAssignmentOptions = {
          filter: filter,
          limit: limit,
          offset: offset,
          expand: expand
        };

        console.log(groupAssignmentOptions);

        const response = await GroupService.getGroupAssignmentV2(
          groupAssignmentOptions,
          this.magicLinkToken
        );
        const formattedUsers = response.result.rows.map(x => {
          x.User.displayName = x.User.displayName
            ? x.User.displayName
            : x.User.firstName + " " + x.User.lastName;
          return x;
        });
        console.log(response);
        // Time it took for call
        let timeDiff = moment().diff(moment(startTime));
        if (timeDiff >= 2000) {
          timeDiff = 2000;
        }
        setTimeout(() => {
          this.dialog.selectedAudience.GroupAssignments = formattedUsers;

          this.loading.groupAssignments = false;
        }, 2000 - timeDiff);
      } catch (error) {
        console.log("Error ", error);
        this.loading.groupAssignments = false;
      }
    },
    preloadGroup(groupIds) {
      console.log("Calling preload group");
      var preloadedGroups = this.data.groups.filter(x =>
        groupIds.includes(x.groupId)
      );

      preloadedGroups.forEach(group => {
        this.addUserToSelected(group);
      });
      this.dialog.createGroup = false;
    },
    previewItem(item) {
      if (item.userId) {
        console.log("Previewing user", item);
        this.dialog.selectedAudience = item;
        this.dialog.previewAudience = true;
      } else if (item.groupId) {
        console.log("Previewing group", item);
        this.dialog.selectedAudience = item;
        this.getGroupAssignments(item.groupId);
        this.dialog.previewAudience = true;
      }
    },
    async addUserToSelected(user, dontRemove = false) {
      console.log("Adding user/group to selected array", user);
      this.dialog.previewAudience = false;
      if (user.userId) {
        let idx = this.values.selected.findIndex(x => x.userId == user.userId);
        if (idx == -1) this.values.selected.push(user);
        else if (!dontRemove) this.removeUserFromSelected(idx);
      } else {
        let idx = this.values.selected.findIndex(
          x => x.groupId == user.groupId
        );
        if (idx == -1) this.values.selected.push(user);
        else if (!dontRemove) this.removeUserFromSelected(idx);

        // Wipe distinct user count so we have to manually calculate it again rather than using the cached API value
        this.values.distinctUsers = 0;
      }
    },
    removeUserFromSelected(index) {
      this.values.selected.splice(index, 1);

      // Wipe distinct user count so we have to manually calculate it again rather than using the cached API value
      this.values.distinctUsers = 0;
    },
    loadPreset(preset) {
      let prevPreset = this.values.preset;
      if (this.values.paymentPresetId == preset.paymentPresetId) {
        console.log("Resetting preset");
        this.values.paymentPresetId = null;
        this.values.preset = null;
        this.values.classification = null;

        // Reset the memo if it's the default
        if (
          this.values.memo &&
          prevPreset &&
          prevPreset.defaultMemo &&
          prevPreset.defaultMemo == this.values.memo
        )
          this.values.memo = null;
        return;
      }
      // If they had another preset and haven't changed anything from the default, then we change it. Otherwise we leave it alone
      if (
        !this.values.memo ||
        (this.values.memo &&
          prevPreset &&
          prevPreset.defaultMemo &&
          prevPreset.defaultMemo == this.values.memo)
      ) {
        console.log("Assigning new value for memo");
        this.values.memo = preset.defaultMemo;
      }

      this.values.paymentPresetId = preset.paymentPresetId;
      this.values.preset = preset;
      this.values.amount = preset.amount;
      this.values.classification = preset.classification;

      // if (preset.amount) this.updateTotalAmount(preset.amount);
    },
    formatPresetAmount(amt) {
      // This will format large amounts as 1k or 1.5m, etc
      if (amt > 999.99)
        return (
          "$" +
          Intl.NumberFormat("en", { notation: "compact" }).format(
            currency(amt).value
          )
        );
      else return currency(amt).format();
      // return ).format();
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "clients",
      "permissions",
      "globalClientId",
      "magicLinkToken"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    disableContinueButton: {
      cache: false,
      get: function() {
        // Compare to route validation
        // We check the index within the route that we're in and use routeValidation to see if we've satisfied the requirements
        var routeIndex = this.route.findIndex(x => x == this.slideKey);
        return !this.routeValidation[routeIndex];
      }
    },
    visibleBudgets() {
      // var awardId = null;
      const budgetArr = this.budgets.filter(x => {
        return (
          // x.awardId == 1 &&
          x.status == "Active" &&
          x.clientId == this.clientId &&
          (Number(x.allocatedBalance) > 0 || Number(x.budgetBalance) > 0)
        );
      });
      return budgetArr;
    },
    selectedBudget: {
      cache: false,
      get: function() {
        if (this.values.budgetId !== null) {
          var budget = this.budgets.find(
            x => x.budgetId == this.values.budgetId
          );

          return budget;
        }
        return null;
      }
    },
    amountRemainingInBudget() {
      if (this.selectedBudget && this.selectedBudget.budgetBalance) {
        return currency(this.selectedBudget.budgetBalance).subtract(
          this.totalAmount
        );
      } else {
        return 0;
      }
    },
    amountRemainingInBudgetFormatted() {
      if (this.selectedBudget && this.selectedBudget.awardId != 1)
        return currency(this.amountRemainingInBudget);
      return currency(this.amountRemainingInBudget).format();
    },
    route: {
      cache: false,
      get: function() {
        // We establish the route depending on what payment type
        // This dynamic array of keys will be used by routeValidation, disableContinuButton, etc
        return [
          this.keys.recipient,
          this.keys.payment,
          this.keys.memo,
          this.keys.review,
          this.keys.submitting,
          this.keys.sent
        ];
      }
    },
    routeValidation: {
      cache: false,
      get: function() {
        var array = [];

        // This uses this.route and compiles an array of true/false values for their entire route to tell us if they've completed everything that they need to
        // Used by disableContinueButton and goToSlide
        this.route.forEach(page => {
          // Page 1
          // console.log(page);
          if (page == this.keys.recipient && this.paymentAudienceCount > 0) {
            array.push(true);
          } else if (
            page == this.keys.payment &&
            this.values.budgetId &&
            this.amountRemainingInBudget >= 0 &&
            this.totalAmount > 0 &&
            this.values.categoryIndex != null
          ) {
            array.push(true);
          } else if (page == this.keys.memo) {
            array.push(true);
          } else if (
            page == this.keys.review &&
            this.amountRemainingInBudget >= 0 &&
            !this.loading.distinctUsers
          ) {
            array.push(true);
          } else if (page == this.keys.submitting) {
            array.push(false);
          } else if (page == this.keys.sent) {
            // console.log("sent");
            array.push(true);
          } else {
            // console.log("ELSE");
            array.push(false);
          }
          // console.log(array[array.length - 1]);
        });

        // console.log(array);
        return array;
      }
    },
    computedNote() {
      if (!this.values.memo) return null;
      let arr = [];
      if (this.values.memo) {
        this.values.memo
          .split("\n")
          .forEach(item => arr.push(`${item.trim()}<br />`));
      }
      return arr.join("");
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    computedOneTimeCategoryDescription: {
      cache: false,
      get: function() {
        if (this.categoryDescriptionPreview == null) {
          return this.categoryOptions[this.values.categoryIndex].description;
        } else {
          return this.categoryDescriptionPreview;
        }
      }
    },
    computedOneTimeCategory() {
      return this.categoryOptions[this.values.categoryIndex];
    },
    recentUsers() {
      let arr = [];
      this.data.parentPaymentRules.forEach(rule => {
        if (!rule.audience) return;
        if (rule.audience.userId)
          rule.audience.userId.forEach(userId => {
            let foundUser = this.data.recentUsers.find(x => x.userId == userId);
            if (foundUser) arr.push(foundUser);
          });

        if (rule.audience.groupId)
          rule.audience.groupId.forEach(groupId => {
            let foundGroup = this.data.recentGroups.find(
              x => x.groupId == groupId
            );
            if (foundGroup) arr.push(foundGroup);
          });
      });
      return [
        ...new Set(
          arr.map(x => {
            x.id = x.userId || x.groupId;
            return x;
          })
        )
      ];
    },
    visibleAudience: {
      get: function() {
        // We are checking the active group, so it doesn't re-run when slidePeople.users gets populaged
        // if (this.data.activeAudienceTab == 2) {
        //   return this.data.groups; //.filter((x) => x.display);
        // } else
        // if (this.data.activeAudienceTab == 0)
        return this.recentUsers; //.filter((x) => x.display);

        // var startingIndex = this.userTable.options.page - 1 || 0;
        // let itemsPerPage =
        //   (this.userTable.options && this.userTable.options.itemsPerPage) ||
        //   this.userTable.itemsPerPage;
        // return this.data.users
        //   .slice()
        //   .slice(
        //     startingIndex * itemsPerPage,
        //     startingIndex * itemsPerPage + itemsPerPage
        //   )
        //   .filter(x => x !== undefined);

        // .filter((x) => x.display);
      },
      cache: false
    },
    audienceHeaders() {
      // if (this.data.activeAudienceTab == 2) {
      //   return [
      //     {
      //       align: "start",
      //       sortable: false,
      //       value: "select",
      //       width: "35px"
      //     },
      //     {
      //       align: "start",
      //       sortable: true,
      //       value: "groupDisplayName"
      //     },
      //     {
      //       align: "start",
      //       sortable: true,
      //       value: "numUsers",
      //       width: "50%"
      //     },

      //     {
      //       value: "info",
      //       width: "100px",
      //       align: "end",
      //       sortable: false
      //     }
      //   ];
      // }

      return [
        {
          align: "start",
          sortable: false,
          value: "select",
          width: "35px"
        },

        {
          align: "start",
          sortable: true,
          value: "displayName",
          width: "90%"
        },
        {
          align: " d-none",
          sortable: true,
          value: "businessEmail",
          width: "0%"
        },
        {
          align: " d-none",
          sortable: true,
          value: "businessPhone",
          width: "0%"
        },
        {
          value: "info",
          width: "10%",
          align: "end",
          sortable: false
        }
      ];
    },
    paymentAudience() {
      var arr = [];

      this.values.selected.forEach(item => {
        if (item.groupId && item.GroupAssignments)
          arr = arr.concat(item.GroupAssignments.map(x => x.userId));
        else if (item.userId) arr.push(item.userId);
      });

      return [...new Set(arr)];
    },
    paymentAudienceCount() {
      if (
        this.loading.distinctUsers &&
        this.values.selected.find(x => x.groupId)
      )
        return 0;
      if (
        this.values.distinctUsers &&
        this.values.selected.find(x => x.groupId)
      ) {
        console.log("Returning distinct users ", this.values.distinctUsers);
        return this.values.distinctUsers;
      }
      let count = 0;
      var arr = [];

      this.values.selected.forEach(item => {
        if (item.groupId && item.numUsers) count += item.numUsers;
        else if (item.groupId && item.GroupAssignments)
          arr = arr.concat(item.GroupAssignments.map(x => x.userId));
        else if (item.userId) arr.push(item.userId);
      });

      if (count != 0) return count;
      return [...new Set(arr)].length;
    },
    cashAward() {
      return !(this.selectedBudget && this.selectedBudget.awardId != 1);
    },
    singularAwardUnit() {
      return this.selectedBudget &&
        this.selectedBudget.awardId != 1 &&
        this.selectedBudget.Award
        ? this.selectedBudget.Award.unitSingular || "unit"
        : null;
    },
    pluralAwardUnit() {
      return this.selectedBudget &&
        this.selectedBudget.awardId != 1 &&
        this.selectedBudget.Award
        ? this.selectedBudget.Award.unitPlural || "units"
        : null;
    },
    formattedAmount() {
      return currency(this.values.amount).value;
    },
    totalAmount() {
      return currency(this.values.amount).multiply(this.paymentAudienceCount)
        .value;
    },
    formattedTotalPayment() {
      let v = this.totalAmount;
      return `${currency(v).format({ symbol: this.cashAward ? "$" : "" })} ${
        this.cashAward
          ? ""
          : v == 1
          ? this.singularAwardUnit
          : this.pluralAwardUnit
      }`;
    },
    selectedGroups: {
      get: function() {
        return this.values.selected.filter(x => x.groupId);
      },
      set: function() {
        // console.log("Adding el ", o);
      }
    },
    selectedUsers: {
      get: function() {
        return this.values.selected.filter(x => x.userId);
      },
      set: function() {
        // console.log("Adding el ", o);
      }
    }
  },
  watch: {
    budgets: function(newVal, oldVal) {
      if (oldVal.length == 0 || !this.values.budgetId) {
        console.log("Got budget watcher", oldVal);

        this.preloadBudget();
      }
    },
    "data.debounceSearch": debounce(function(newVal) {
      this.data.search = newVal;
      // if (this.data.activeAudienceTab == 1) this.getUsersV2New(true);
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.error-background {
  background-color: var(--v-error-base);
}

.header-text {
  font-size: x-large;
}

.light-grey-background {
  background-color: var(--v-lightGrey-base);
}

.people-group-button {
  padding: 5px 5px;
  border-radius: 7px;
  width: 200px;
  background-color: var(--v-lightGrey-base);
}
.audience-button {
  background-color: white;
  background: white;
  border: 1px solid;
  border-color: var(--v-grey-base);
}
.profile-picture {
  border: 2px solid;
  border-color: var(--v-grey-base);
}
.icon-image {
  height: 105px;
  width: 105px;
}

.text-overflow-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.amount-table {
  padding-top: 1px;
  border: solid 1px;
  border-color: lightgray;
  border-radius: 4px;
}

.amount-table >>> .v-data-table-header {
  background-color: white;
}
.side-nav-bold-text {
  color: var(--v-brand-base);
  font-weight: bold;
}
.side-nav-text {
  color: var(--v-brand-base);
}
.side-nav-icons {
  border-radius: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
}
.side-nav-box {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 35px;
  padding-top: 7px;
}
.side-nav-panel {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-top: 7px;
}
.budget-select >>> .v-list-item {
  font-size: 0.8125rem;
}
.category-div {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
}
.selected-category {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  height: 45px;
  cursor: pointer;
}
.category-container {
  cursor: pointer;
}
.category-container:hover .category-div {
  background-color: #e8e8ec;
}
.selected-category-background {
  background-color: #e8e8ec;
}
.timestamp-input {
  margin-top: 0px;
  margin-bottom: 0px;
}
.rounded-border {
  border: 1px solid lightgray;
  border-radius: 5px;
}
.selected-people-content >>> .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
.input-error {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
  border: 2px solid var(--v-error-base) !important;
}

#note {
  background: var(--v-lightGrey-base);
  padding: 10px 10px;
  margin-left: 24px;
  width: calc(100% - 24px);
  border-radius: 6px;
}

/* outline used for the main content card */
.main-card {
  border: solid 1px;
  border-color: lightgray;
  min-height: calc(100vh - 120px);
}

/* Used for the pill nav at the top of the main card */
.pill-nav {
  /* background: var(--v-grey-base); */
  /* background: var(--v-brandDarkGreen-base); */
  /* opacity: 0.3; */
  width: calc(100% - 20px);
  min-height: 40px;
  max-height: 40px;
  border-radius: 999px;
  /* padding: 0 20px 0 20px; */
}
.pill-nav >>> .pill-item {
  border-radius: 999px;
  /* min-width: 60px; */
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1 !important;
}
.pill-nav >>> .active-pill {
  /* border-radius: 999px; */
  /* background: var(--v-brandGreen-base); */
}

.budget-name-container {
  width: calc(100% - 120px);
}

.progress-bar {
  width: 70%;
  min-width: 220px;
  max-width: 600px;
}

/* progress bar text */
.progress-bar >>> .v-progress-linear__content {
  justify-content: flex-start;
  margin-left: 15px;
}

.progress-bar >>> .v-progress-linear__determinate {
  border-radius: 0 9999px 9999px 0;
}

.progress-bar >>> .v-progress-linear__background {
  left: 0 !important;
  width: 100% !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Hides extra padding that for some reason exists in v-row */
.container-row {
  overflow-x: hidden;
}

/* Provides spacing for each audience tab */
.audience-tab {
  padding: 8px 20px 8px 20px;
}

/* Bkgnd color for selected audience tab */
.active-audience-tab {
  background-color: #e8e8e8 !important;
}

/* Provides feedback when you hover over an audience tab */
.audience-tab:hover {
  background: lightgray;
}

/* Changes the close icon's color for selected audience chips */
.selected-audience-chip >>> .v-chip__close {
  color: lightgray;
}

.preset-loading {
  height: 70px;
}
/* Outer container for row of preset carousel */
.preset-carousel {
  overflow-x: scroll;
}
/* Card for each preset */
.preset-card {
  border: 1px solid lightgray;
  width: 75px;
  min-width: 75px;
  /* background: white !important; */
}

.icon-img {
  width: 70px;
  height: 70px;
}
.preset-card >>> .icon-img {
  width: 50px;
  height: 50px;
}
.preset-card >>> .text {
  font-size: small;
}

/* Sets width for amt field */
.amount-text-field >>> .v-input__slot {
  max-width: 150px;
  width: 150px;
  min-width: 150px;
}

.review-page-field {
  width: 65% !important;
  min-width: 170px !important;
  max-width: 400px !important;
  margin-left: 12px;
}
.review-page-text {
  width: 35% !important;
}
</style>
