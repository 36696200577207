<template>
  <div class="full-height">
    <v-card width="100%" rounded="0" class="full-height">
      <div id="header" class="d-flex justify-start" width="100%">
        <v-icon large dark class="pa-2 ml-3 my-2 brand-background border-radius"
          >mdi-account</v-icon
        >
        <v-card-title> Account Information </v-card-title>
      </div>
      <v-divider class="mx-4" />
      <v-row class="my-2 mx-2">
        <v-col cols="12" sm="12" md="3">
          <div
            class="my-4 align-text-right d-flex flex-column align-end justify-center"
          >
            <span class="mr-3 mb-1"><b>Popular links</b></span>
            <v-btn
              text
              small
              color="primary"
              class="mb-1"
              @click="resetOnboardingFlow"
              v-if="
                userProfile !== null &&
                  (userProfile.clientId == 1 ||
                    (userProfile.clientId == 166 &&
                      userProfile.businessEmail &&
                      userProfile.businessEmail.endsWith('@wewhistle.com')) ||
                    userProfile.Client.clientName == 'Demo Co' ||
                    userProfile.Client.clientName ==
                      'Rainforest QA Test Environment')
              "
              >Reset Onboarding (Demo)</v-btn
            >
            <v-btn
              text
              small
              color="primary"
              class="mb-1"
              @click="userTokenDialog = true"
              v-if="showDevButtons"
              >View my token</v-btn
            >
            <v-btn
              v-if="
                userProfile &&
                  userProfile.Client &&
                  userProfile.Client.displayWalletPage
              "
              text
              small
              color="primary"
              class="mb-1"
              @click="openWallet"
              >View my wallet</v-btn
            >
            <v-btn
              text
              small
              color="primary"
              class="mb-1"
              @click="downloadPrivacy"
              >Privacy Policy</v-btn
            >
            <v-btn
              text
              small
              color="primary"
              class="mb-1"
              @click="downloadTerms"
              >Terms of Service</v-btn
            >
            <v-btn
              text
              small
              color="primary"
              class="mb-1"
              href="mailto:help@wewhistle.com"
              >Contact Us</v-btn
            >
            <v-btn
              text
              small
              color="primary"
              class="mb-1"
              @click="gdprDialog = true"
              >GDPR Request</v-btn
            >
            <SlackDownload
              v-if="displaySlackButton"
              :userId="userProfile.userId"
            />
          </div>
          <v-divider></v-divider>
          <p
            v-if="userProfile && userProfile.Client.customProfileMessage"
            v-html="userProfile.Client.customProfileMessage"
            class="py-3 text-left"
          ></p>
          <div
            class="align-text-left d-flex flex-column py-2"
            v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
          >
            <b class="mb-2"
              >Learning Badges ({{
                badges.filter(x => x.context == "LEARNING").length
              }})</b
            >
            <!-- We hide the badges for anyone without a client image for now, because that is the most reliable way to hide it from non-Demo clients -->
            <div class="py-2 d-flex flex-wrap">
              <div
                v-for="badge in badges.filter(x => x.context == 'LEARNING')"
                :key="badge.badgeId"
                class="cursor-pointer"
              >
                <v-img
                  v-if="badge.imageUrl"
                  :src="badge.imageUrl"
                  :max-width="65"
                  :height="65"
                  contain
                  class="mx-2 mt-1"
                  @click="loadBadgePage(badge.badgeId)"
                />
                <v-img
                  v-else
                  src="@/assets/Demo/Trophy_Badge.png"
                  :max-width="65"
                  :height="65"
                  contain
                  class="mx-2 mt-1"
                  @click="loadBadgePage(badge.badgeId)"
                />
              </div>
            </div>
            <b class="mb-2"
              >Program Badges ({{
                badges.filter(x => x.context == "PROGRAM").length
              }})</b
            >
            <div class="py-2 d-flex flex-wrap">
              <div
                v-for="badge in badges.filter(x => x.context == 'PROGRAM')"
                :key="badge.badgeId"
                class="cursor-pointer"
              >
                <v-img
                  v-if="badge.imageUrl"
                  :src="badge.imageUrl"
                  :max-width="65"
                  :height="65"
                  contain
                  class="mx-2 mt-1 cursor-pointer"
                  @click="loadBadgePage(badge.badgeId)"
                />
                <v-img
                  v-else
                  src="@/assets/Demo/Trophy_Badge.png"
                  :max-width="65"
                  :height="65"
                  contain
                  class="mx-2 mt-1 cursor-pointer"
                  @click="loadBadgePage(badge.badgeId)"
                />
              </div>
            </div>
            <div></div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="9">
          <v-tabs v-model="tab" show-arrows>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab :key="1"> Profile </v-tab>
            <!-- <v-tab :key="2"> Settings </v-tab> -->
            <v-tab :key="2"> Communication </v-tab>
            <!-- <v-tab :key="3"> Dashboard </v-tab> -->
            <v-tab :key="3"> Badges </v-tab>
            <v-tab :key="4"> Awards </v-tab>
          </v-tabs>
          <v-divider />
          <v-tabs-items v-model="tab">
            <v-tab-item :key="1">
              <v-row
                ><v-col cols="12" sm="12" md="9" lg="9">
                  <v-row>
                    <v-col
                      cols="12"
                      class="align-text-left d-flex flex-wrap full-width"
                    >
                      <b class="my-2">Basic Info</b>
                      <v-form
                        v-model="validForm1"
                        class="full-width d-flex flex-wrap"
                      >
                        <v-text-field
                          dense
                          class="pa-1"
                          :class="{
                            'text-field':
                              !$vuetify.breakpoint.sm &&
                              !$vuetify.breakpoint.xs,
                            'full-width':
                              $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                          }"
                          v-model="user.firstName"
                          label="First name*"
                          :rules="[
                            v => !!v || 'First name is required',
                            v =>
                              !!(v && v.length < 100) ||
                              'First name should be shorter'
                          ]"
                        ></v-text-field>
                        <v-text-field
                          dense
                          class="pa-1"
                          :class="{
                            'text-field':
                              !$vuetify.breakpoint.sm &&
                              !$vuetify.breakpoint.xs,
                            'full-width':
                              $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                          }"
                          v-model="user.lastName"
                          label="Last name*"
                          :rules="[
                            v => !!v || 'Last name is required',
                            v =>
                              !!(v && v.length < 100) ||
                              'Last name should be shorter'
                          ]"
                        ></v-text-field>
                        <v-text-field
                          dense
                          class="pa-1"
                          :class="{
                            'text-field':
                              !$vuetify.breakpoint.sm &&
                              !$vuetify.breakpoint.xs,
                            'full-width':
                              $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                          }"
                          v-model="user.displayName"
                          label="Preferred name"
                        ></v-text-field>
                        <v-menu
                          ref="birthdayMenu"
                          v-model="user.birthdayMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="user.birthday"
                              label="Birthday"
                              readonly
                              dense
                              class="pa-1"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="birthdayPicker"
                            v-model="user.birthday"
                            :max="new Date().toISOString().substr(0, 10)"
                            @change="saveBirthday"
                          ></v-date-picker>
                        </v-menu>
                        <div
                          class="d-flex align-start"
                          :class="{
                            'text-field':
                              !$vuetify.breakpoint.sm &&
                              !$vuetify.breakpoint.xs,
                            'full-width':
                              $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                          }"
                        >
                          <v-text-field
                            dense
                            class="pa-1"
                            :disabled="true"
                            v-model="user.businessEmail"
                            label="Primary Email*"
                          ></v-text-field>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs" class="mt-2 mr-2"
                                >mdi-help-circle</v-icon
                              >
                            </template>
                            <span
                              >Please contact a Whistle administrator within
                              your organization if you wish to change your
                              primary email attached to your account.</span
                            >
                          </v-tooltip>
                        </div>
                        <!-- BusinessEmail rules  :rules="[
                            v => !!v || 'Work email is required',
                            v =>
                              emailValidation.test(v) || 'Email must be valid'
                          ]" -->
                        <v-text-field
                          dense
                          class="pa-1"
                          :class="{
                            'text-field':
                              !$vuetify.breakpoint.sm &&
                              !$vuetify.breakpoint.xs,
                            'full-width':
                              $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                          }"
                          v-model="user.personalEmail"
                          label="Secondary Email"
                          :rules="
                            user.personalEmail
                              ? [
                                  v =>
                                    !!(
                                      !v ||
                                      (v &&
                                        /^\w+([&.+\-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/.test(
                                          v
                                        ))
                                    ) || 'Email must be valid'
                                ]
                              : []
                          "
                        ></v-text-field>
                        <div
                          class="d-flex align-start"
                          :class="{
                            'text-field':
                              !$vuetify.breakpoint.sm &&
                              !$vuetify.breakpoint.xs,
                            'full-width':
                              $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                          }"
                        >
                          <vue-tel-input-vuetify
                            dense
                            class="mt-1 px-1 full-width"
                            v-model="user.businessPhone"
                            :value="user.businessPhone"
                            label="Phone number"
                            placeholder="xxx-xxx-xxxx"
                            :preferredCountries="['US']"
                            :defaultCountry="businessCountryCode.code"
                            :disabledFetchingCountry="true"
                            :disabled="true"
                            v-on:country-changed="businessCountryCodeChanged"
                            @validate="businessPhoneValidation"
                            @input="updateBusinessPhone"
                          ></vue-tel-input-vuetify>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs" class="mt-2 mr-2"
                                >mdi-help-circle</v-icon
                              >
                            </template>
                            <span
                              >Please contact a Whistle administrator within
                              your organization if you wish to change your
                              primary phone number attached to your
                              account.</span
                            >
                          </v-tooltip>
                        </div>
                        <vue-tel-input-vuetify
                          dense
                          class="mt-1 px-1"
                          :class="{
                            'text-field':
                              !$vuetify.breakpoint.sm &&
                              !$vuetify.breakpoint.xs,
                            'full-width':
                              $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                          }"
                          v-model="user.personalPhone"
                          :value="user.personalPhone"
                          label="Additional phone number (xxx-xxx-xxxx)"
                          placeholder="xxx-xxx-xxxx"
                          :preferredCountries="['US']"
                          :defaultCountry="personalCountryCode.code"
                          :disabledFetchingCountry="true"
                          v-on:country-changed="personalCountryCodeChanged"
                          @validate="personalPhoneValidation"
                          @input="updatePersonalPhone"
                        ></vue-tel-input-vuetify>

                        <v-text-field
                          v-if="userProfile"
                          dense
                          disabled
                          class="pa-1"
                          :class="{
                            'text-field':
                              !$vuetify.breakpoint.sm &&
                              !$vuetify.breakpoint.xs,
                            'full-width':
                              $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                          }"
                          v-model="userProfile.jobStartDate"
                          label="Job Start Date"
                        ></v-text-field>
                        <v-text-field
                          v-if="userProfile"
                          dense
                          disabled
                          class="pa-1"
                          :class="{
                            'text-field':
                              !$vuetify.breakpoint.sm &&
                              !$vuetify.breakpoint.xs,
                            'full-width':
                              $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                          }"
                          v-model="userProfile.roleStartDate"
                          label="Role Start Date"
                        ></v-text-field>
                        <v-select
                          class="pa-1"
                          :class="{
                            'text-field':
                              !$vuetify.breakpoint.sm &&
                              !$vuetify.breakpoint.xs,
                            'full-width':
                              $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                          }"
                          :items="languages"
                          item-text="name"
                          item-value="code"
                          label="Preferred Language"
                          v-model="user.language"
                        ></v-select>
                      </v-form>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-btn
                      v-if="
                        userProfile &&
                          userProfile.passwordEnabled &&
                          userProfile.businessEmail
                      "
                      text
                      class="ml-4"
                      color="primary"
                      @click="resetPassword"
                      :loading="submittingPasswordRequest"
                      >Reset My Password</v-btn
                    >
                  </v-row>
                  <v-row
                    class="mt-10 d-flex flex-column align-center"
                    justify="center"
                  >
                    <b class="align-text-left full-width mb-2 ml-3"
                      >Preferences</b
                    >
                    <p class="grey--text mt-4 mx-4 footer-text">
                      This information helps us build a better experience as you
                      use the platform.
                    </p>
                    <b class="align-text-left full-width px-4 mb-2">
                      Most of the time, I'm more of an...
                    </b>
                    <div class="d-flex justify-space-between slider">
                      <p class="align-text-left">Introvert</p>
                      <p class="align-text-right">Extrovert</p>
                    </div>
                    <v-slider
                      v-model="userSupplemental.introvertOrExtrovert"
                      class="slider"
                      ticks="always"
                      tick-size="4"
                      min="1"
                      max="6"
                    ></v-slider>
                    <b class="align-text-left full-width px-4 mb-2">
                      Most of the time, I enjoy...
                    </b>
                    <div class="d-flex justify-space-between slider">
                      <p class="align-text-left">Collaborating</p>
                      <p class="align-text-right">Competing</p>
                    </div>
                    <v-slider
                      v-model="userSupplemental.competitionOrCollaboration"
                      class="slider"
                      ticks="always"
                      tick-size="4"
                      min="1"
                      max="6"
                    ></v-slider>
                    <b class="align-text-left full-width px-4 mb-2">
                      Most of the time, I...
                    </b>
                    <div class="d-flex justify-space-between slider">
                      <p class="align-text-left">Worry about things</p>
                      <p class="align-text-right">Rarely worry</p>
                    </div>
                    <v-slider
                      v-model="userSupplemental.worrying"
                      class="slider"
                      ticks="always"
                      tick-size="4"
                      min="1"
                      max="6"
                    ></v-slider>
                    <b class="align-text-left full-width px-4 mb-2">
                      Most of the time, I am...
                    </b>
                    <div class="d-flex justify-space-between slider">
                      <p class="align-text-left">Cautious</p>
                      <p class="align-text-right">Impulsive</p>
                    </div>
                    <v-slider
                      v-model="userSupplemental.impulsiveOrCautious"
                      class="slider"
                      ticks="always"
                      tick-size="4"
                      min="1"
                      max="6"
                    ></v-slider>
                    <b class="align-text-left full-width px-4 mb-2">
                      Most of the time, I prefer...
                    </b>
                    <div class="d-flex justify-space-between slider">
                      <p class="align-text-left">Being appreciated</p>
                      <p class="align-text-right">Earning rewards</p>
                    </div>
                    <v-slider
                      v-model="userSupplemental.appreciatedOrRewards"
                      class="slider"
                      ticks="always"
                      tick-size="4"
                      min="1"
                      max="6"
                    ></v-slider> </v-row
                ></v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                  order="first"
                  order-md="last"
                  class="d-flex align-center flex-column pt-14"
                  v-if="userProfile !== null"
                >
                  <v-btn
                    v-if="
                      user.profilePicUrl == null || user.isSelectingProfilePic
                    "
                    depressed
                    :loading="user.isSelectingProfilePic"
                    @click="displayImageSelector"
                    class="ma-2 image-button"
                    fab
                    medium
                    color="primary"
                    @drop.prevent="dragAndDropImageFileChanged"
                    @dragover.prevent
                  >
                    <v-icon x-large>
                      mdi-camera
                    </v-icon>
                  </v-btn>
                  <div v-else class="image-container">
                    <v-img
                      :src="user.profilePicUrl"
                      height="150"
                      width="150"
                      max-height="150"
                      max-width="150"
                      @click="displayImageSelector"
                      @drop.prevent="dragAndDropImageFileChanged"
                      @dragover.prevent
                      class="cursor-pointer circle-border-radius mx-auto image-border"
                    />
                    <v-btn
                      icon
                      dark
                      small
                      class="pa-4 delete-image-button"
                      @click="deleteProfilePicture"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </div>

                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/*"
                    @change="imageFileChanged"
                  />
                  <v-btn
                    class="mt-10"
                    medium
                    color="primary"
                    :disabled="disableSaveButton"
                    :loading="submittingChanges"
                    @click="saveProfile"
                  >
                    <!-- (!personalPhoneNumberValid &&
                          user.formattedPersonalPhone !== null) ||
                        (user.personalPhone != null &&
                          user.personalPhone.length == 0) -->
                    Save Changes
                  </v-btn>
                </v-col></v-row
              >
            </v-tab-item>
            <v-tab-item :key="2">
              <v-card
                flat
                rounded="0"
                class="d-flex justify-start align-start flex-column pa-4"
                ><b>Preferences</b>
                <div
                  class="d-flex py-3 px-5 mt-2 flex-column align-start preference-box"
                >
                  <v-switch
                    v-model="communicationPreferences.emailPreference"
                    label="Email notifications"
                    hide-details
                  ></v-switch>
                  <v-switch
                    v-model="communicationPreferences.smsPreference"
                    label="Text notifications"
                    hide-details
                    class="mt-10"
                  ></v-switch>
                  <a
                    class="mt-2 ml-11"
                    :class="{ hidden: !communicationPreferences.smsPreference }"
                    @click="dialog.smsTroubleshoot = true"
                    >Still not getting texts?</a
                  >
                  <v-switch
                    v-model="communicationPreferences.pushPreference"
                    label="Push notifications"
                    hide-details
                    class="mt-2"
                  ></v-switch>
                  <v-btn
                    class="mt-6"
                    color="primary"
                    :disabled="!checkIfCommPreferencesChanged"
                    @click="saveCommunicationPreferences"
                    >Save Changes</v-btn
                  >
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item :key="3">
              <v-card flat class="d-flex flex-wrap">
                <v-card
                  v-if="badges.length == 0"
                  flat
                  class="align-text-left px-4 pt-4 full-width"
                >
                  <!-- <v-card-title class="px-0 mx-0">
                    Badges
                  </v-card-title>
                  <v-divider class="pb-4" /> -->
                  <b class="word-break"
                    >Once you start earning badges, this is where they'll be
                    listed!</b
                  >
                  <v-img
                    src="@/assets/Placeholder_Screens/Winner.png"
                    width="40%"
                    min-width="250"
                    max-width="350"
                    class="mx-auto"
                  />
                </v-card>
                <v-card
                  v-for="badge in badges"
                  :key="badge.badgeId"
                  min-width="300"
                  width="30%"
                  class="mx-3 my-3 pt-4"
                  @click="badge.showDescription = !badge.showDescription"
                  min-height="225"
                >
                  <div
                    class="d-flex flex-column justify-space-between align-center full-height"
                    v-if="!badge.showDescription"
                  >
                    <b>{{ badge.displayName }}</b>

                    <v-img
                      v-if="badge.imageUrl"
                      :src="badge.imageUrl"
                      :max-width="80"
                      :height="80"
                      contain
                      class="mx-auto my-1"
                    />
                    <v-img
                      v-else
                      src="@/assets/Demo/Trophy_Badge.png"
                      :max-width="80"
                      :height="80"
                      contain
                      class="mx-auto my-1"
                    />
                    <p>Last earned {{ badge.formattedDate }}</p>
                  </div>
                  <div v-else class="d-flex flex-column align-center">
                    <div class="d-flex align-center justify-start">
                      <v-img
                        v-if="badge.imageUrl"
                        :src="badge.imageUrl"
                        :max-width="35"
                        :height="35"
                        contain
                        class="mr-2 my-1"
                      />
                      <v-img
                        v-else
                        src="@/assets/Demo/Trophy_Badge.png"
                        :max-width="35"
                        :height="35"
                        contain
                        class="mr-2 my-1"
                      />
                      <b
                        v-if="badge.displayName.length < 25"
                        class="align-text-left"
                        >{{ badge.displayName }}</b
                      >
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <b v-bind="attrs" v-on="on" class="align-text-left">{{
                            badge.displayName.substring(0, 25) + ".."
                          }}</b>
                        </template>
                        <span>{{ badge.displayName }}</span>
                      </v-tooltip>
                    </div>
                    <p class="align-text-left mx-4 mt-2 word-break">
                      {{ badge.description }}
                    </p>
                  </div>
                </v-card></v-card
              >
            </v-tab-item>
            <v-tab-item :key="4">
              <!-- <v-card flat class="d-flex flex-wrap max-width full-width"> -->
              <v-card
                flat
                rounded="0"
                class="d-flex flex-column justify-start align-start pa-4"
                ><b
                  >Awards ({{
                    balances.filter(x => parseFloat(x.balance).toFixed(2) > 0)
                      .length
                  }})</b
                >
                <v-card
                  v-for="award in balances.filter(
                    x => parseFloat(x.balance).toFixed(2) > 0
                  )"
                  :key="award.asset_code"
                  min-width="50%"
                  width="30%"
                  class="mx-3 my-3 py-3"
                  min-height="100"
                >
                  <div
                    class="d-flex py-3 px-5 align-center justify-space-between"
                  >
                    <div class="d-flex flex-column align-text-left">
                      <h3>
                        {{
                          awards.find(
                            x => award.asset_code == x.stellarAssetCode
                          )
                            ? awards.find(
                                x => award.asset_code == x.stellarAssetCode
                              ).displayName
                            : "Award"
                        }}
                      </h3>
                      <h4 class="mt-2">
                        Balance:
                        {{
                          award.balance
                            ? parseFloat(award.balance).toFixed(2)
                            : 0
                        }}
                      </h4>
                    </div>
                    <v-btn
                      v-if="
                        awards.find(
                          x => award.asset_code == x.stellarAssetCode
                        ) &&
                          awards.find(
                            x => award.asset_code == x.stellarAssetCode
                          ).supplementAwardInfo &&
                          awards.find(
                            x => award.asset_code == x.stellarAssetCode
                          ).supplementAwardInfo.physicalItems &&
                          awards.find(
                            x => award.asset_code == x.stellarAssetCode
                          ).supplementAwardInfo.physicalItems.length > 0
                      "
                      color="primary"
                      @click="openAwardRedemptionForm(award.asset_code)"
                      >Redeem</v-btn
                    >
                  </div>
                </v-card>
              </v-card>
              <v-card
                v-if="userProfile && userProfile.AwardOrders"
                flat
                rounded="0"
                class="d-flex flex-column justify-start align-start pa-4"
                ><b>Orders ({{ userProfile.AwardOrders.length }})</b>
                <v-card
                  v-for="order in userProfile.AwardOrders"
                  :key="order.awardOrderId"
                  min-width="50%"
                  width="30%"
                  class="mx-3 my-3 py-3"
                  min-height="100"
                >
                  <div
                    class="d-flex py-3 px-5 align-center justify-space-between"
                  >
                    <div class="d-flex flex-column align-text-left">
                      <h3>Order {{ order.awardOrderId }}</h3>
                      <p class="mt-2" v-if="order.orderDate">
                        Order Date:
                        <b>{{ formatDate(order.orderDate) }}</b>
                      </p>
                      <p>
                        Status:
                        <b>{{ order.orderStatus }}</b>
                      </p>
                      <p v-if="order.shipDate">
                        Ship Date:
                        <b>{{ formatDate(order.shipDate) }}</b>
                      </p>
                      <p v-if="order.trackingNumber">
                        Tracking:
                        <b>{{ order.trackingNumber }}</b>
                      </p>
                    </div>
                  </div>
                </v-card>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
    <!-- Dialog to show confirmation after resetting password -->
    <v-dialog v-model="passwordResetDialog" persistent width="400">
      <v-card class="py-3">
        <v-card-text
          class="d-flex flex-column justify-center align-center pt-4"
        >
          <h2>Your password reset is on the way!</h2>

          <p class="my-3">Be on the lookout for your password reset email.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="passwordResetDialog = false"
            color="primary"
            class="mr-2"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog to show confirmation after resetting password -->
    <v-dialog v-model="emailErrorDialog" persistent width="350">
      <v-card class="py-3">
        <h2 class="mt-2">
          <v-icon class="mr-2" color="error">mdi-alert</v-icon>Duplicate email
          found
        </h2>
        <v-card-text class="d-flex flex-column justify-center align-center">
          <p class="my-3">
            Uh oh! It looks like we already found a user with this email
            address.
          </p>
          <p>Please try using another email.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="emailErrorDialog = false" color="primary" class="mr-2"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog to show success after linking to Slack -->
    <v-dialog v-model="slackDialog" persistent width="500">
      <v-card class="py-3">
        <h2 class="mt-2">
          <v-icon
            class="mr-2  circle-border-radius check-icon"
            :class="{ red: slackError, 'brand-green-background': !slackError }"
            large
            color="white"
            >{{ slackError ? "mdi-close" : "mdi-check" }}</v-icon
          >{{ slackError ? "We encountered a problem" : "Nicely done!" }}
        </h2>
        <v-card-text
          class="mt-4 d-flex flex-column justify-center align-center"
        >
          <p class="my-3" v-if="slackError">
            There was a problem linking your Whistle and Slack accounts. Please
            try again.
          </p>
          <p class="my-3" v-else>
            Your Whistle and Slack accounts have been successfully linked.
          </p>
        </v-card-text>
        <v-card-actions>
          <!-- <v-spacer /> -->
          <v-btn
            @click="(slackDialog = false), (slackError = false)"
            color="primary"
            class="mx-auto mt-4"
            width="170"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- GDPR Dialog window -->
    <v-dialog width="500" v-model="gdprDialog">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-6 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-2"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            Do you have a GDPR data request?
          </v-card-title>
        </div>
        <span class="word-break exit-warning-text">
          Please contact our GDPR data officer at
          <a href="mailto:privacy@wewhistle.com?subject=GDPR Data Request"
            >privacy@wewhistle.com</a
          >
          for all requests. Please include your name and details on your
          request.
        </span>
        <v-card-actions class="mx-12">
          <v-btn
            color="primary"
            @click="gdprDialog = false"
            outlined
            width="130"
            >Close</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            href="mailto:privacy@wewhistle.com?subject=GDPR Data Request"
            width="130"
            >Email</v-btn
          >
        </v-card-actions>
      </v-card></v-dialog
    >
    <!-- SMS Troubleshooting Dialog window -->
    <v-dialog width="500" v-model="dialog.smsTroubleshoot">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-6 mb-3">
          <v-icon color="error" x-large class="exit-warning-icon mr-2"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            Having issues receiving Whistle texts?
          </v-card-title>
        </div>
        <span class="word-break exit-warning-text">
          You might be unsubscribed from our number. <br />Please try texting
          "START" to +1 (205) 635 - 7282.
        </span>
        <v-card-actions class="mt-4">
          <v-btn
            color="brandCyan"
            class="white--text mx-auto"
            @click="dialog.smsTroubleshoot = false"
            width="130"
            depressed
            >Close</v-btn
          >
        </v-card-actions>
      </v-card></v-dialog
    >
    <!-- Image Cropping dialog -->
    <v-dialog persistent v-model="dialogImageCropper" width="600">
      <v-card rounded="0" class="px-12">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-camera</v-icon>
          <v-card-title class="word-break px-0 mx-0">
            Please crop the image below to a 1 : 1 aspect ratio.
          </v-card-title>
        </div>
        <cropper
          ref="cropper"
          :src="imageUrlToCrop"
          :stencil-props="{
            aspectRatio: 1 / 1
          }"
        />
        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn
            text
            :disabled="user.isSelectingProfilePic"
            @click="closeImageCropDialog(false)"
            >Cancel</v-btn
          >
          <v-btn
            text
            color="primary"
            @click="closeImageCropDialog(true)"
            :loading="user.isSelectingProfilePic"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Token for displaying token -->
    <v-dialog v-model="userTokenDialog" hide-overlay width="600">
      <v-card color="primary" dark>
        <v-card-text>
          {{ token }}
          <!-- <v-divider></v-divider>
            <v-icon @click="copyToken">mdi-eye-settings-outline</v-icon> -->
        </v-card-text>
      </v-card></v-dialog
    >
    <!-- Drawer used for viewing an existing group -->
    <v-navigation-drawer
      v-model="displayAwardRedemption"
      temporary
      fixed
      right
      width="500"
    >
      <AwardRedemption
        v-if="displayAwardRedemption"
        @reset="closeAwardRedemptionForm"
        @update-user="getUserProfile()"
        :awards="awards"
        :assetCode="assetCodeToOrder"
      />
    </v-navigation-drawer>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </div>
</template>

<script>
import UserService from "../services/UserService";
import OnboardingService from "@/services/OnboardingService.js";
import BudgetService from "@/services/BudgetService.js";
import BadgeService from "../services/BadgeService";
import SlackService from "../services/SlackService";

import LoadingDialog from "../components/loadingDialog";
import AwardRedemption from "../components/AwardRedemption";
import SlackDownload from "@/components/SlackDownload";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

import moment from "moment";
import { languages, emailRegex } from "@/shared_data/data.js";

import { mapState } from "vuex";

export default {
  name: "Profile",
  title: "Whistle | Profile",
  components: { LoadingDialog, AwardRedemption, SlackDownload, Cropper },
  props: { initialPageIndexToLoad: Number },
  data() {
    return {
      userProfile: null,
      userTokenDialog: false,
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      submittingChanges: false,
      languages: languages,
      gdprDialog: false,
      dialogImageCropper: false,
      image: null,
      imageUrlToCrop: null,
      imageUrlFileName: null,

      emailValidation: emailRegex,

      dialog: {
        smsTroubleshoot: false
      },
      badges: [],
      awards: [],
      user: {
        firstName: null,
        lastName: null,
        displayName: null,
        businessEmail: null,
        businessPhone: null,
        personalEmail: null,
        personalPhone: null,
        formattedBusinessPhone: null,
        formattedPersonalPhone: null,
        birthday: null,
        birthdayMenu: false,
        profilePicUrl: null,
        isSelectingProfilePic: false,
        language: null
      },
      userSupplemental: {
        introvertOrExtrovert: null,
        originalIntrovertOrExtrovert: null,
        introvertSupplementalId: null,
        impulsiveOrCautious: null,
        originalImpulsiveOrCautious: null,
        impulsiveSupplementalId: null,
        worrying: null,
        originalWorrying: null,
        worryingSupplementalId: null,
        competitionOrCollaboration: null,
        originalCompetitionOrCollaboration: null,
        competitionSupplementalId: null,
        appreciatedOrRewards: null,
        originalAppreciatedOrRewards: null,
        appreciationSupplementalId: null
      },
      communicationPreferences: {
        emailPreference: true,
        emailPreferenceId: null,
        originalEemailPreference: null,
        smsPreference: true,
        smsPreferenceId: null,
        originalSmsPreference: null,
        pushPreference: true,
        pushPreferenceId: null,
        originalPushPreference: null
      },
      businessCountryCode: { code: null, value: null },
      personalCountryCode: { code: null, value: null },
      businessPhoneNumberValid: false,
      personalPhoneNumberValid: false,
      submittingPasswordRequest: false,
      passwordResetDialog: false,
      emailErrorDialog: false,
      slackDialog: false,
      slackError: false,
      validForm1: false,
      displayAwardRedemption: false,
      assetCodeToOrder: null
    };
  },
  beforeMount() {
    // window.addEventListener("beforeunload", this.preventNav);
  },
  created() {
    console.log("profile created");
    if (this.$auth) {
      if (this.$route.name == "profileslacksuccess") this.slackDialog = true;
      else if (
        this.$route.name == "slacksetupuser" &&
        this.$route.query.correlationId
      ) {
        console.log(
          "Connecting whistle user to slack user ",
          this.$route.query.correlationId
        );
        this.storeSlackAssociation();
      }
      this.getUserProfile();
      this.getBadgesByUser();
      this.getAwards();
      this.$store.dispatch("setBalances", this.$auth.user.sub);
      console.log(this.initialPageIndexToLoad);
      if (
        this.initialPageIndexToLoad !== undefined &&
        this.initialPageIndexToLoad !== null &&
        !isNaN(this.initialPageIndexToLoad)
      ) {
        this.tab = this.initialPageIndexToLoad;
      } else {
        this.tab = 0;
      }
    }
    // let stripeScript = document.createElement("script");
    // stripeScript.setAttribute("src", "https://checkout.stripe.com/checkout.js");
    // document.head.appendChild(stripeScript);
    // console.log("stripe added");
  },
  mounted() {},
  methods: {
    async getUserProfile() {
      this.loadingHeaderMessage = "Loading profile";
      this.loadingMessageLine1 = "Please wait";
      this.loadingMessageLine2 = "This should just take a second!";
      // We only want to show this if we're not showing the Slack success
      if (!this.slackDialog) this.showLoadingDialog = true;
      await UserService.userProfileByEmail(this.magicLinkToken)
        .then(response => {
          console.log(response);
          this.businessCountryCode.code = response.businessCountry;
          this.personalCountryCode.code = response.homeCountry;

          // if (
          //   response.businessPhone !== null &&
          //   response.businessPhone.includes("+")
          // ) {
          //   var businessPhone = response.businessPhone.substring(
          //     response.businessPhone.indexOf("-") + 1
          //   );
          //   // this.user.formattedBusinessPhone =
          // } else {
          var businessPhone = response.businessPhone;
          // }
          // if (
          //   response.personalPhone !== null &&
          //   response.personalPhone.includes("+")
          // ) {
          //   var personalPhone = response.personalPhone.substring(
          //     response.personalPhone.indexOf("-") + 1
          //   );
          // } else {
          var personalPhone = response.personalPhone;
          // }
          this.userProfile = response;
          this.user.firstName = response.firstName;
          this.user.lastName = response.lastName;
          this.user.displayName = response.displayName;
          this.user.birthday = response.birthday;
          this.user.businessEmail = response.businessEmail;

          this.user.businessPhone = businessPhone;
          this.user.personalEmail = response.personalEmail;
          this.user.personalPhone = personalPhone;
          this.user.profilePicUrl = response.profilePicUrl;
          this.user.language = response.language;

          if (response.UserSupplementals) {
            var userSupp = response.UserSupplementals;
            var introvert = userSupp.find(
              x => x.key === "Introvert or Extrovert"
            );
            if (introvert !== undefined) {
              if (introvert.value > 6) {
                introvert.value = 6;
              }
              this.userSupplemental.introvertOrExtrovert = introvert.value;
              this.userSupplemental.originalIntrovertOrExtrovert =
                introvert.value;
              this.userSupplemental.introvertSupplementalId =
                introvert.userSupplementalId;
            } else {
              this.userSupplemental.introvertOrExtrovert = null;
              this.userSupplemental.introvertSupplementalId = null;
              this.userSupplemental.originalIntrovertOrExtrovert = null;
            }

            var impulsive = userSupp.find(
              x => x.key === "Impulsive or Cautious"
            );
            if (impulsive !== undefined) {
              if (impulsive.value > 6) {
                impulsive.value = 6;
              }
              this.userSupplemental.impulsiveOrCautious = impulsive.value;
              this.userSupplemental.originalImpulsiveOrCautious =
                impulsive.value;
              this.userSupplemental.impulsiveSupplementalId =
                impulsive.userSupplementalId;
            } else {
              this.userSupplemental.impulsiveOrCautious = null;
              this.userSupplemental.impulsiveSupplementalId = null;
              this.userSupplemental.originalImpulsiveOrCautious = null;
            }

            var worry = userSupp.find(
              x => x.key === "Worry About Things Or Rarely Worry"
            );
            if (worry !== undefined) {
              if (worry.value > 6) {
                worry.value = 6;
              }
              this.userSupplemental.worrying = worry.value;
              this.userSupplemental.originalWorrying = worry.value;
              this.userSupplemental.worryingSupplementalId =
                worry.userSupplementalId;
            } else {
              this.userSupplemental.worrying = null;
              this.userSupplemental.worryingSupplementalId = null;
              this.userSupplemental.originalWorrying = null;
            }

            var competition = userSupp.find(
              x => x.key === "Competition or Collaboration"
            );
            if (competition !== undefined) {
              if (competition.value > 6) {
                competition.value = 6;
              }
              this.userSupplemental.competitionOrCollaboration =
                competition.value;
              this.userSupplemental.originalCompetitionOrCollaboration =
                competition.value;
              this.userSupplemental.competitionSupplementalId =
                competition.userSupplementalId;
            } else {
              this.userSupplemental.competitionOrCollaboration = null;
              this.userSupplemental.originalCompetitionOrCollaboration = null;
              this.userSupplemental.competitionSupplementalId = null;
            }

            var rewards = userSupp.find(
              x => x.key === "Recognition or Rewards"
            );
            if (rewards !== undefined) {
              if (rewards.value > 6) {
                rewards.value = 6;
              }
              this.userSupplemental.appreciatedOrRewards = rewards.value;
              this.userSupplemental.originalAppreciatedOrRewards =
                rewards.value;
              this.userSupplemental.appreciationSupplementalId =
                rewards.userSupplementalId;
            } else {
              this.userSupplemental.appreciatedOrRewards = null;
              this.userSupplemental.originalAppreciatedOrRewards = null;
              this.userSupplemental.appreciationSupplementalId = null;
            }
          }

          if (response.UserCommunicationPreferences) {
            var commPref = response.UserCommunicationPreferences;
            var sms = commPref.find(x => x.deliveryMethod === "sms");
            if (sms) {
              this.communicationPreferences.smsPreference = sms.value;
              this.communicationPreferences.smsPreferenceId =
                sms.communicationPreferenceId;
              this.communicationPreferences.originalSmsPreference = sms.value;
            } else {
              this.communicationPreferences.smsPreference = false;
              this.communicationPreferences.smsPreferenceId = null;
              this.communicationPreferences.originalSmsPreference = null;
            }

            var email = commPref.find(x => x.deliveryMethod === "email");
            if (email) {
              this.communicationPreferences.emailPreference = email.value;
              this.communicationPreferences.emailPreferenceId =
                email.communicationPreferenceId;
              this.communicationPreferences.originalEmailPreference =
                email.value;
            } else {
              this.communicationPreferences.emailPreference = true;
              this.communicationPreferences.emailPreferenceId = null;
              this.communicationPreferences.originalEmailPreference = null;
            }

            var push = commPref.find(x => x.deliveryMethod === "push");
            if (push) {
              this.communicationPreferences.pushPreference = push.value;
              this.communicationPreferences.pushPreferenceId =
                push.communicationPreferenceId;
              this.communicationPreferences.originalPushPreference = push.value;
            } else {
              this.communicationPreferences.pushPreference = true;
              this.communicationPreferences.pushPreferenceId = null;
              this.communicationPreferences.originalPushPreference = null;
            }
          }

          this.showLoadingDialog = false;
        })
        .catch(error => {
          console.log("Error! " + error.response);
          this.showLoadingDialog = false;
        });
    },
    loadBadgePage(badgeId) {
      this.tab = 2;
      console.log(badgeId);
      var badgeIndex = this.badges.findIndex(x => x.badgeId == badgeId);
      console.log(badgeIndex);
      if (badgeIndex !== -1) {
        this.badges[badgeIndex].showDescription = true;
      }
    },
    getAwards() {
      BudgetService.getAwards()
        .then(response => {
          console.log("Awards: ", response);
          var awards = response.filter(x => x.status != "Deleted");

          awards.forEach(function(award) {
            award.displayName = award.awardDisplayName
              ? award.awardDisplayName
              : award.awardName;
          });
          this.awards = awards;
          console.log(this.awards);

          this.loadingAwardTable = false;
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    copyToken() {
      const el = document.createElement("textarea");
      el.value = this.token;
      document.body.appendChild(el);
      console.log(el.value);
      el.select();
      document.execCommand("copy");
      console.log("copioed");
      //document.body.removeChild(el);
    },
    // stripeCharge() {
    //   // if (!StripeCheckout) {
    //   //   let StripeCheckout = 1;
    //   // }
    //   window.stripeHandler = window.StripeCheckout.configure({
    //     key:
    //       "pk_test_51Hm3SXEGgqkhysasXpCZFK6iYBGcpV7YuG9FWSP5NXSX2q9w3uh9u8txudc9rdtNL2AErfS1pUMKZDzvX801YLUX00JzWCsd9x",
    //     locale: "auto",
    //     name: "Whistle",
    //     description: "Whistle Test Transaction",
    //     token: function(token) {
    //       alert(token.id);
    //     },
    //   });
    //   window.stripeHandler.open({
    //     amount: 100,
    //     email: "ben@wewhistle.com",
    //   });
    // },
    businessCountryCodeChanged(country) {
      this.businessCountryCode.code = country.iso2;
      this.businessCountryCode.value = country.dialCode;
    },
    personalCountryCodeChanged(country) {
      this.personalCountryCode.code = country.iso2;
      this.personalCountryCode.value = country.dialCode;
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    openWallet() {
      this.$store.dispatch("setWalletMenuScreen", "main");
      this.$store.dispatch("setDisplayWalletMenu", true);
    },
    downloadTerms() {
      OnboardingService.downloadLegalDoc("terms");
    },
    downloadPrivacy() {
      OnboardingService.downloadLegalDoc("privacy");
    },
    displayImageSelector() {
      this.$refs.uploader.click();
    },
    closeImageCropDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      var clientId = this.userProfile.clientId;
      if (submitPressed) {
        this.user.isSelectingProfilePic = true;
        const { canvas } = this.$refs.cropper.getResult();
        canvas.toBlob(blob => {
          // Do something with blob: upload to a server, download and etc.
          var file = new File([blob], this.imageUrlFileName);
          console.log("Uploading new profile picture: ", file);
          UserService.uploadProfilePicture(
            this.userProfile.userId,
            file,
            clientId
          )
            .then(imageResponse => {
              console.log("Image upload response: ", imageResponse);
              // We got an image url back so now we update the user object with this image
              this.user.profilePicUrl = imageResponse.filePath;
              this.$store.state.userProfile.profilePicUrl =
                imageResponse.filePath;
              this.user.isSelectingProfilePic = false;
              //Regardless, we close dialog
              this.dialogImageCropper = false;
              this.imageURLToCrop = null;
              this.imageURLFileName = null;
            })
            .catch(error => {
              console.log("Error uploading image: ", error);
              //Regardless, we close dialog
              this.dialogImageCropper = false;
              this.imageURLToCrop = null;
              this.imageURLFileName = null;
              this.user.isSelectingProfilePic = false;
            });
        });
      } else {
        //Regardless, we close dialog
        this.dialogImageCropper = false;
        this.imageUrlToCrop = null;
        this.imageUrlFileName = null;
      }
    },
    imageFileChanged(e) {
      // this.user.isSelectingProfilePic = true;
      if (e.target.files.length > 0) {
        this.imageUrlToCrop = URL.createObjectURL(e.target.files[0]);
        this.imageUrlFileName = e.target.files[0].name;
        this.dialogImageCropper = true;
      }
    },
    dragAndDropImageFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        // Load file into temp variable for cropping
        this.imageUrlToCrop = URL.createObjectURL(e.dataTransfer.files[0]);
        this.imageUrlFileName = e.dataTransfer.files[0].name;

        this.dialogImageCropper = true;
      }
    },
    deleteProfilePicture() {
      this.user.profilePicUrl = null;
      UserService.deleteProfilePicture(
        this.userProfile.userId,
        this.userProfile.clientId
      )
        .then(imageResponse => {
          console.log(imageResponse);
          this.$store.state.userProfile.profilePicUrl = null;
        })
        .catch(error => {
          this.showLoadingDialog = false;
          console.log("Error deleting image: ", error);
        });
    },
    preventNav(event) {
      if (this.checkIfAnythingChanged) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    businessPhoneValidation: function({ number, isValid }) {
      // Do stuff with the arguments passed by the vue-tel-input component
      console.log(number);
      if (isValid) {
        this.user.formattedBusinessPhone = number.e164; //rfc3966.replace("tel:", "");
        this.businessPhoneNumberValid = true;
      } else {
        this.businessPhoneNumberValid = false;
      }
    },
    updateBusinessPhone: function() {
      if (!this.user.businessPhone || !this.businessPhoneNumberValid) {
        this.user.formattedBusinessPhone = null;
        this.businessPhoneNumberValid = false;
      }
    },
    personalPhoneValidation: function({ number, isValid }) {
      // Do stuff with the arguments passed by the vue-tel-input component
      console.log(number);
      console.log(isValid);
      if (isValid) {
        this.user.formattedPersonalPhone = number.e164; //.rfc3966.replace("tel:", "");
        this.personalPhoneNumberValid = true;
      } else {
        this.personalPhoneNumberValid = false;
      }
    },
    updatePersonalPhone: function() {
      if (!this.user.personalPhone || !this.personalPhoneNumberValid) {
        this.user.formattedPersonalPhone = null;
        this.personalPhoneNumberValid = false;
      }
    },
    resetPassword() {
      this.submittingPasswordRequest = true;
      UserService.resetPassword(this.user.businessEmail).then(response => {
        console.log(response);
        this.submittingPasswordRequest = false;
        this.passwordResetDialog = true;
      });
    },
    saveProfile() {
      this.loadingHeaderMessage = "Updating profile";
      this.loadingMessageLine1 = "Please wait";
      this.loadingMessageLine2 = "This should just take a second!";
      this.showLoadingDialog = true;
      // UserService.getUserByEmail(this.user.businessEmail).then(
      //   emailResponse => {
      // console.log(emailResponse);
      // var errorBool = false;
      // // We get any users in the database with the selected email
      // // If we find anyone that isn't this user, we show an error message
      // for (var i = 0; i < emailResponse.length; i++) {
      //   if (emailResponse[i].userId != this.userProfile.userId) {
      //     errorBool = true;
      //   }
      // }

      // if (errorBool) {
      //   console.log("Found user with email");
      //   this.emailErrorDialog = true;
      //   this.showLoadingDialog = false;
      // } else {
      // console.log("Email is valid, so we continue");
      var body = {
        // businessPhone: this.user.formattedBusinessPhone,
        personalPhone: this.user.formattedPersonalPhone,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        displayName: this.user.displayName,
        birthday: this.user.birthday,
        // We comment out the email so that the separate auth0 email call can occur and if it fails, it fails
        // businessEmail:
        //   this.user.businessEmail == null
        //     ? null
        //     : this.user.businessEmail.toLowerCase(),
        personalEmail:
          this.user.personalEmail == null
            ? null
            : this.user.personalEmail.toLowerCase(),
        businessCountry: this.businessCountryCode.code,
        homeCountry: this.personalCountryCode.code,
        language: this.user.language
      };
      console.log(body);
      UserService.updateUser(
        this.userProfile.userId,
        body,
        this.magicLinkToken
      ).then(user => {
        console.log(user);
        var userSuppArray = [
          {
            userId: this.userProfile.userId,
            key: "Introvert or Extrovert",
            value: this.userSupplemental.introvertOrExtrovert,
            updatedBy: this.userProfile.userId
          },
          {
            userId: this.userProfile.userId,
            key: "Worry About Things Or Rarely Worry",
            value: this.userSupplemental.worrying,
            updatedBy: this.userProfile.userId
          },
          {
            userId: this.userProfile.userId,
            key: "Impulsive or Cautious",
            value: this.userSupplemental.impulsiveOrCautious,
            updatedBy: this.userProfile.userId
          },
          {
            userId: this.userProfile.userId,
            key: "Competition or Collaboration",
            value: this.userSupplemental.competitionOrCollaboration,
            updatedBy: this.userProfile.userId
          },
          {
            userId: this.userProfile.userId,
            key: "Recognition or Rewards",
            value: this.userSupplemental.appreciatedOrRewards,
            updatedBy: this.userProfile.userId
          }
        ];

        //Then we have to check if we originally stored ID's for user supplemental records in order to update them
        if (this.userSupplemental.introvertSupplementalId !== null) {
          userSuppArray[0].userSupplementalId = this.userSupplemental.introvertSupplementalId;
        }
        if (this.userSupplemental.worryingSupplementalId !== null) {
          userSuppArray[1].userSupplementalId = this.userSupplemental.worryingSupplementalId;
        }
        if (this.userSupplemental.impulsiveSupplementalId !== null) {
          userSuppArray[2].userSupplementalId = this.userSupplemental.impulsiveSupplementalId;
        }
        if (this.userSupplemental.competitionSupplementalId !== null) {
          userSuppArray[3].userSupplementalId = this.userSupplemental.competitionSupplementalId;
        }
        if (this.userSupplemental.appreciationSupplementalId !== null) {
          userSuppArray[4].userSupplementalId = this.userSupplemental.appreciationSupplementalId;
        }
        console.log(userSuppArray);

        UserService.bulkUpdateUserSupplemental(
          userSuppArray,
          this.magicLinkToken
        ).then(userSupp => {
          console.log(userSupp);
          if (user.success) {
            this.getUserProfile();
            this.$store.dispatch("setUser", this.userProfile.userId);
            this.showLoadingDialog = false;
          }
        });
      });
      // }
      //   }
      // );
    },
    saveCommunicationPreferences() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Saving Changes";
      this.loadingMessageLine1 =
        "Your new preferences should be reflected immediately.";
      this.loadingMessageLine2 = null;
      var preferences = [
        {
          userId: this.userProfile.userId,
          communicationType: null,
          deliveryMethod: "sms",
          value: this.communicationPreferences.smsPreference,
          updatedBy: this.userProfile.userId
        },
        {
          userId: this.userProfile.userId,
          communicationType: null,
          deliveryMethod: "email",
          value: this.communicationPreferences.emailPreference,
          updatedBy: this.userProfile.userId
        },
        {
          userId: this.userProfile.userId,
          communicationType: null,
          deliveryMethod: "push",
          value: this.communicationPreferences.pushPreference,
          updatedBy: this.userProfile.userId
        }
      ];

      //Then we have to check if we originally stored ID's for user supplemental records in order to update them
      if (this.communicationPreferences.smsPreferenceId) {
        preferences[0].communicationPreferenceId = this.communicationPreferences.smsPreferenceId;
      } else {
        preferences[0].createdBy = this.userProfile.userId;
      }
      if (this.communicationPreferences.emailPreferenceId) {
        preferences[1].communicationPreferenceId = this.communicationPreferences.emailPreferenceId;
      } else {
        preferences[1].createdBy = this.userProfile.userId;
      }
      if (this.communicationPreferences.pushPreferenceId) {
        preferences[2].communicationPreferenceId = this.communicationPreferences.pushPreferenceId;
      } else {
        preferences[2].createdBy = this.userProfile.userId;
      }
      UserService.bulkUpdateUserCommunicationPreferences(
        preferences,
        this.magicLinkToken
      ).then(pref => {
        this.showLoadingDialog = false;
        console.log("Comm Pref response: ", pref);
        // Now we just update the "original" variables that we use to detect a change
        // We also update the preference ids if they didn't exist before
        this.communicationPreferences.originalPushPreference = this.communicationPreferences.pushPreference;
        this.communicationPreferences.originalEmailPreference = this.communicationPreferences.emailPreference;
        this.communicationPreferences.originalSmsPreference = this.communicationPreferences.smsPreference;

        if (!this.communicationPreferences.smsPreferenceId) {
          let smsRes = pref.find(x => x.deliveryMethod == "sms");
          if (smsRes)
            this.communicationPreferences.smsPreferenceId =
              smsRes.communicationPreferenceId;
        }
        if (!this.communicationPreferences.emailPreferenceId) {
          let emailRes = pref.find(x => x.deliveryMethod == "email");
          if (emailRes)
            this.communicationPreferences.emailPreferenceId =
              emailRes.communicationPreferenceId;
        }
        if (!this.communicationPreferences.pushPreferenceId) {
          let pushRes = pref.find(x => x.deliveryMethod == "push");
          if (pushRes)
            this.communicationPreferences.pushPreferenceId =
              pushRes.communicationPreferenceId;
        }
      });
    },
    resetOnboardingFlow() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Resetting Onboarding";
      this.loadingMessageLine1 = "After this is done,";
      this.loadingMessageLine2 = "you will be taken back to onboarding.";
      var body = {
        firstLogin: true
      };
      UserService.updateUser(
        this.userProfile.userId,
        body,
        this.magicLinkToken
      ).then(user => {
        console.log("Successfully updated user ", user);
        this.$store.state.userProfile.firstLogin = true;
        this.showLoadingDialog = false;
      });
    },
    getBadgesByUser() {
      console.log("Fetching badges by user");
      BadgeService.getBadgesByUser().then(badges => {
        console.log("Badges returned: ", badges);
        badges.forEach(badge => {
          badge.formattedDate = moment(badge.UserBadge.createdAt)
            .local()
            .format("MMMM D, YYYY");
          badge.showDescription = false;
        });
        this.badges = badges;
      });
    },
    saveBirthday(date) {
      this.$refs.birthdayMenu.save(date);
    },
    closeAwardRedemptionForm() {
      this.displayAwardRedemption = false;
    },
    openAwardRedemptionForm(assetCode) {
      this.displayAwardRedemption = true;
      this.assetCodeToOrder = assetCode;
    },
    formatDate(date) {
      if (date) {
        return moment(date)
          .local()
          .format("MMM DD, YYYY h:mm a");
      } else {
        return null;
      }
    },
    async storeSlackAssociation() {
      if (process.env.VUE_APP_SLACK_URL) {
        console.log("Storing slack assocation with User (passing query param)");
        try {
          await SlackService.storeSlackUserAssociation(
            this.$route.query.correlationId
          );
          this.slackDialog = true;
          this.clearSlackUrl();
        } catch (err) {
          this.slackDialog = true;
          this.slackError = true;
        }
      }
      // else if (
      //   this.slackLanguage &&
      //   process.env.VUE_APP_SLACK_URL &&
      //   passQueryParam &&
      //   this.$route.query.correlationId
      // ) {
      //   console.log(
      //     "Storing slack assocation with Client (passing query param)"
      //   );
      //   SlackService.storeSlackWorkspaceAssociation(
      //     this.$route.query.correlationId
      //   );
      // }
    },
    clearSlackUrl() {
      let query = Object.assign({}, this.$route.query);
      if (query.correlationId) {
        delete query.correlationId;
        this.$router.replace({ query });
      }
    }
  },
  computed: {
    ...mapState(["token", "balances", "profilePageTab", "magicLinkToken"]),
    disableSaveButton() {
      if (
        !this.validForm1 ||
        // !this.businessPhoneNumberValid ||
        (!this.businessPhoneNumberValid && this.user.businessPhone) ||
        (!this.personalPhoneNumberValid && this.user.personalPhone)
      )
        return true;
      return false;
    },
    displaySlackButton() {
      if (this.userProfile && this.$route.query.slack) return true;
      return false;
    },
    checkIfAnythingChanged() {
      if (
        this.userProfile.firstName !== this.user.firstName ||
        this.userProfile.lastName !== this.user.lastName ||
        this.userProfile.businessEmail !== this.user.businessEmail ||
        this.userProfile.businessPhone !== this.user.formattedBusinessPhone ||
        this.userProfile.personalEmail != this.user.personalEmail ||
        this.userProfile.personalPhone != this.user.formattedPersonalPhone ||
        this.userProfile.displayName !== this.user.displayName ||
        this.userProfile.birthday !== this.user.birthday ||
        this.userProfile.language !== this.user.language ||
        this.userSupplemental.impulsiveOrCautious !=
          this.userSupplemental.originalImpulsiveOrCautious ||
        this.userSupplemental.worrying !=
          this.userSupplemental.originalWorrying ||
        this.userSupplemental.introvertOrExtrovert !=
          this.userSupplemental.originalIntrovertOrExtrovert ||
        this.userSupplemental.appreciatedOrRewards !=
          this.userSupplemental.originalAppreciatedOrRewards ||
        this.userSupplemental.competitionOrCollaboration !=
          this.userSupplemental.originalCompetitionOrCollaboration
      ) {
        return true;
      }
      return false;
    },
    checkIfCommPreferencesChanged() {
      console.log(this.communicationPreferences);
      if (
        this.communicationPreferences.smsPreference !=
          this.communicationPreferences.originalSmsPreference ||
        this.communicationPreferences.emailPreference !=
          this.communicationPreferences.originalEmailPreference ||
        this.communicationPreferences.pushPreference !=
          this.communicationPreferences.originalPushPreference
      ) {
        return true;
      }
      return false;
    },
    tab: {
      get: function() {
        return this.profilePageTab;
      },
      set: function(newValue) {
        this.$store.dispatch("setProfilePageTab", newValue);
      }
    },
    showDevButtons() {
      if (
        process.env.VUE_APP_ENVIRONMENT != "prod" ||
        (this.userProfile && this.userProfile.clientId == 1)
      ) {
        return true;
      }
      return false;
    }
  },
  watch: {
    "user.birthdayMenu"(val) {
      val &&
        setTimeout(() => {
          this.$refs.birthdayPicker.activePicker = "YEAR";
        });
    }
  }
};
</script>

<style scoped>
.border-radius {
  border-radius: 5px;
}
.brand-background {
  background-color: var(--v-brand-base);
}
.image-container {
  position: relative;
}
.delete-image-button {
  position: absolute;
  z-index: 1;
  right: 15px;
  top: -10px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  padding: 3px;
  transition: 0.2s opacity;
  background-color: black;
}
.image-button {
  width: 150px;
  height: 150px;
}
.image-border {
  border: 2px solid;
  border-color: var(--v-grey-base);
}
.slider {
  width: 90%;
}
.preference-box {
  width: 100%;
  border: 1px solid;
  border-color: var(--v-grey-base);
}
.text-field {
  width: 50%;
  max-width: 50%;
}

/* Used for the check icon shown on the slack success popup */
.check-icon {
  min-width: 35px;
  width: 35px;
  min-height: 35px;
  height: 35px;
}
</style>
