<template>
  <div class="full-height">
    <v-card width="100%" rounded="0" class="full-height">
      <div id="header" class="d-flex justify-start" width="100%">
        <v-icon large dark class="pa-2 ml-3 my-2 brand-background border-radius"
          >mdi-calculator-variant</v-icon
        >
        <v-card-title> {{ computedPageHeader }} </v-card-title>
      </div>
      <v-divider class="mx-4" />
      <v-row class="my-2 ml-3 mr-2">
        <v-col sm="12" md="3">
          <!-- <h2 class="align-text-left mb-3">
            Set up budgets to fund your programs
          </h2>
          <v-divider></v-divider> -->
          <div
            class="my-4 align-text-right d-flex flex-column align-end justify-center"
          >
            <v-autocomplete
              outlined
              dense
              class="pa-1 mb-2 full-width"
              hide-details
              :items="clients"
              item-text="formattedName"
              item-value="clientId"
              label="Client"
              v-model="clientId"
              @change="refreshClientId"
              v-if="userProfile.clientId === 1 && clients.length > 0"
            ></v-autocomplete>
            <!-- <span class="mr-3 mb-1" v-if="selected.length === 1"
              >{{ selected.length }} Budget Selected</span
            >
            <span class="mr-3 mb-1" v-else
              >{{ selected.length }} Budgets Selected</span
            > -->
            <div v-if="accountBalance" class="text-left full-width">
              <div class="d-flex justify-space-between">
                <span>Total Funds</span>
                <span
                  >$
                  {{
                    formatNum(
                      truncateNum(accountBalance.totalAccountBalance, 2)
                    )
                  }}</span
                >
              </div>
              <!-- <div class="d-flex justify-space-between mediumGrey--text my-2">
                <span>Allocated</span>
                <span
                  >$
                  {{
                    formatNum(truncateNum(accountBalance.allocatedBalance, 2))
                  }}</span
                >
              </div>
              <v-divider />
              <div class="d-flex justify-space-between mt-2">
                <span>Available</span>
                <span
                  >$
                  {{
                    formatNum(
                      truncateNum(accountBalance.formattedBudgetBalance, 2)
                    )
                  }}</span
                >
              </div> -->
              <div
                class="d-flex justify-space-between mt-6 mb-4 mediumGrey--text"
              >
                <span>Pending Incoming Transfers</span>
                <span
                  >$
                  {{ formatNum(truncateNum(pendingTransfersTotal, 2)) }}</span
                >
              </div>
              <!-- <div class="d-flex justify-center mb-4">
                <v-btn
                  v-if="
                    (accountBalance.permissions &&
                      accountBalance.permissions.owner) ||
                      (permissions.includes('wallets:manualfund:budget') &&
                        userProfile.clientId == 1)
                  "
                  color="primary"
                  small
                  class=""
                  :loading="budgetFundingLoading === accountBalance.budgetId"
                  @click.native.stop="
                    fundBudget(null, accountBalance, null, false, null, null)
                  "
                  >Add Funds</v-btn
                >
              </div> -->
            </div>
            <v-btn
              v-if="permissions.includes('wallets:fund:budget')"
              text
              small
              color="primary"
              class="mb-2"
              @click="loadBudgetActivity(null)"
              >View Funding Activity</v-btn
            >
            <v-btn
              v-if="
                budgets.filter(x => x.permissions && x.permissions.owner)
                  .length > 0
              "
              text
              small
              color="primary"
              class="mb-2"
              @click="
                fundBudget(null, null, 'budget-transfer', false, null, null)
              "
              >Transfer Funds</v-btn
            >
            <v-btn
              v-if="totalFundingRequests > 0"
              text
              small
              color="error"
              class="mb-2"
              @click="loadBudgetActivityRequests(null)"
              ><v-icon color="error" class="exit-warning-icon mr-4"
                >mdi-alert</v-icon
              >View {{ totalFundingRequests }} Funding
              {{ totalFundingRequests == 1 ? "Request" : "Requests" }}
            </v-btn>
          </div>
          <v-divider></v-divider>
          <div
            v-if="clientFundingStatus && !clientFundingStatus.canFundMore"
            class="text-left"
          >
            <h3 class="mt-4">
              Why can't I add more funds to my budgets?
            </h3>
            <p class="pr-8 mt-2">
              Whistle's banking partners require a business verification process
              to be completed before adding more funds to your budgets. This
              process can be completed on the
              <span
                @click="loadKYB"
                :class="{
                  'primary--text': canCompleteKYB,
                  'cursor-pointer': canCompleteKYB
                }"
                >Payment Admin page</span
              >
              by a Whistle Finance Admin
            </p>
            <v-divider></v-divider>
          </div>
          <div
            v-else-if="clientFundingStatus && !clientFundingStatus.passedKYB"
            class="text-left"
          >
            <h3 class="mt-4" v-if="clientFundingStatus.showLimitWarning">
              You're approaching your $10,000 funding limit!
            </h3>
            <!-- <h3 class="mt-4" v-else>
              Don't forget to complete your business verification!
            </h3> -->
            <p class="pr-8 mt-2" v-if="clientFundingStatus.showLimitWarning">
              Whistle's banking partners require a business verification process
              to be completed after adding $10,000 to your budgets. You have
              currently loaded ${{
                formatNum(clientFundingStatus.amountFunded)
              }}. This process can be completed on the
              <span
                @click="loadKYB"
                :class="{
                  'primary--text': permissions.includes(
                    'marqeta:create:business'
                  ),
                  'cursor-pointer': permissions.includes(
                    'marqeta:create:business'
                  )
                }"
                >Payment Admin page</span
              >
              by a user with the Finance Admin or Client Admin role, or you can
              talk to your Whistle CSM for additional help and information.
            </p>
            <v-divider></v-divider>
          </div>

          <v-card
            rounded="0"
            elevation="2"
            class="mt-4"
            color="brandPaleBlue"
            v-show="!postItArray.includes('budgets-1')"
          >
            <v-card-title class="d-flex justify-space-between">
              <v-icon color="grey">mdi-lightbulb-outline</v-icon>
              <v-btn icon
                ><v-icon
                  color="grey"
                  @click="$store.dispatch('setPostItArray', 'budgets-1')"
                  >mdi-close</v-icon
                ></v-btn
              >
            </v-card-title>
            <v-card-text class="align-text-left">
              <span>
                <!-- Budgets are the simple way to fund programs. It is important to
                have a funded budget before a program begins or your program may
                be affected. -->

                Budgets are where you can allocate money to be used for your per
                diems, spot bonuses, and more.
              </span>
              <br />
              <br />
              <span
                >Learn more about the budgets and awards in the
                <a @click="openFAQ">Whistle FAQ.</a>
              </span>
              <!-- <br />
              <br />
              - <span class="selectable-blue-text">Budgets</span>
              <br />
              - <span class="selectable-blue-text">Awards</span> -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12" md="9">
          <div v-if="!viewBudgetActivity && !viewBudgetActivityRequests">
            <div class="d-flex justify-space-between align-center mr-2 mb-3">
              <div class="d-flex">
                <v-btn
                  text
                  color="primary"
                  class="mr-2"
                  to="/budgets"
                  active-class="no-active"
                  >Budgets</v-btn
                >
                <div
                  class="award-button mr-2"
                  v-if="permissions.includes('vue:read:awards')"
                >
                  <v-btn
                    text
                    color="primary"
                    to="/budgets/awards"
                    active-class="no-active"
                    >Awards</v-btn
                  >
                </div>
                <div
                  class="badges-button"
                  v-if="permissions.includes('vue:read:badges')"
                >
                  <v-btn
                    text
                    color="primary"
                    to="/budgets/badges"
                    active-class="no-active"
                    >Badges</v-btn
                  >
                </div>
              </div>
              <v-text-field
                outlined
                placeholder="Search"
                clearable
                single-line
                dense
                hide-details
                v-model="budgetTableSearch"
                class="shrink"
              ></v-text-field>
              <v-menu
                v-model="budgetFilterMenu"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="primary"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                    >Filter</v-btn
                  >
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-action>
                      <v-checkbox
                        v-model="budgetFilterValue"
                        @change="updateFilter"
                        value="parent"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content class="text-left">
                      <v-list-item-title
                        >Show top level budgets</v-list-item-title
                      >
                    </v-list-item-content>
                    <!-- <v-list-item-action
                      ><v-btn text small color="primary"
                        >Only</v-btn
                      ></v-list-item-action
                    > -->
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <v-checkbox
                        v-model="budgetFilterValue"
                        @change="updateFilter"
                        value="children"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content class="text-left">
                      <v-list-item-title
                        >Show budgets with children</v-list-item-title
                      >
                    </v-list-item-content>
                    <!-- <v-list-item-action
                      ><v-btn text small color="primary"
                        >Only</v-btn
                      ></v-list-item-action
                    > -->
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                v-if="permissions.includes('budgets:create:budget')"
                color="brandCyan"
                class="white--text mr-3"
                depressed
                @click="loadBudgetBuilder(null, false)"
                >Add budget</v-btn
              >
            </div>
            <div>
              <v-data-table
                v-model="selected"
                :headers="budgetColumns"
                :items="visibleBudgets"
                item-key="budgetId"
                :items-per-page="10"
                :search="budgetTableSearch"
                class="elevation-0 budget-table"
                :item-class="editBudgetClass"
                :loading="loadingBudgetTable"
                loading-text="Loading... Please wait"
                no-data-text="No budgets found"
                show-expand
                single-expand
                :expanded.sync="expandedBudgetTable"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                @update:sort-by="updateSort('by', $event)"
                @update:sort-desc="updateSort('desc', $event)"
                @click:row="
                  e => {
                    if (
                      !expandedBudget ||
                      expandedBudget.budgetId != e.budgetId
                    ) {
                      expandedBudgetTable = [e];
                    } else {
                      expandedBudgetTable = [];
                    }
                  }
                "
              >
                <template v-slot:[`item.data-table-expand`]="{ item }">
                  <v-btn
                    v-if="
                      !expandedBudget ||
                        expandedBudget.budgetId != item.budgetId
                    "
                    icon
                    @click.stop.native="expandedBudgetTable = [item]"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    icon
                    @click.stop.native="expandedBudgetTable = []"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="d-flex justify-space-between mt-2 mx-4">
                      <div
                        class="d-flex flex-column align-start mt-2 text-left"
                      >
                        <h3>Actions:</h3>
                        <p
                          v-if="
                            item.permissions &&
                              item.permissions.owner &&
                              item.budgetBalance > 0 &&
                              expandedBudget &&
                              (expandedBudget.parentBudget ||
                                expandedBudget.childBudgets.length > 0)
                          "
                          class="primary--text my-2 cursor-pointer"
                          @click="
                            fundBudget(
                              item,
                              null,
                              'budget-transfer',
                              false,
                              null,
                              null
                            )
                          "
                        >
                          Transfer funds
                        </p>
                        <p
                          v-if="
                            item.permissions &&
                              (item.permissions.owner ||
                                item.permissions.admin) &&
                              item.parentBudgetId
                          "
                          class="primary--text mb-2 cursor-pointer"
                          @click="
                            fundBudget(
                              null,
                              item,
                              'budget-transfer',
                              true,
                              null,
                              null
                            )
                          "
                        >
                          Request funds from parent budget
                        </p>
                        <p
                          class="primary--text mb-2 cursor-pointer"
                          @click="loadBudgetBuilder(null, false, item)"
                        >
                          Create child budget
                        </p>
                        <p
                          v-if="item.permissions && item.permissions.owner"
                          class="primary--text mb-2 cursor-pointer"
                          @click="loadBudgetBuilder(item, true, null)"
                        >
                          Edit budget
                        </p>
                        <p
                          class="primary--text mb-2 cursor-pointer"
                          @click="loadBudgetActivity(item.budgetId)"
                        >
                          View funding history
                        </p>
                      </div>
                      <div
                        class="d-flex flex-column align-center ml-5"
                        v-if="
                          budgetPieChartOptions.series.length > 0 &&
                            budgetPieChartOptions.series[0] == 0 &&
                            budgetPieChartOptions.series[1] == 0 &&
                            budgetPieChartOptions.series[2] == 0
                        "
                      >
                        <p
                          class="font-weight-bold mb-1"
                          id="pie-chart-placeholder-text"
                        >
                          Load funds into your budget to view a breakdown of
                          budget activity!
                        </p>
                        <Robin
                          :showText="false"
                          :width="110"
                          :height="100"
                          animation="train"
                          :loop="false"
                        />
                      </div>
                      <apexchart
                        v-else
                        class="ml-5 text-left"
                        type="pie"
                        width="320"
                        :options="budgetPieChartOptions.options"
                        :series="budgetPieChartOptions.series"
                      ></apexchart>
                      <!-- <apexchart
                        class="ml-5 text-left"
                        type="donut"
                        width="320"
                        :options="budgetDonutChartOptions.options"
                        :series="budgetDonutChartOptions.series"
                      ></apexchart> -->

                      <div
                        class="d-flex flex-column text-left ml-4 mt-2"
                        id="child-budget-list-container"
                      >
                        <p class="font-weight-bold mb-1">
                          Parent Budget:
                        </p>
                        <p v-if="expandedBudget && expandedBudget.parentBudget">
                          {{ expandedBudget.parentBudget.budgetDisplayName }}
                        </p>
                        <p v-else>None</p>
                        <p
                          class="font-weight-bold mb-1"
                          v-if="expandedBudget && expandedBudget.childBudgets"
                        >
                          Child Budgets:
                        </p>

                        <p
                          v-if="
                            expandedBudget &&
                              expandedBudget.childBudgets.length == 0
                          "
                        >
                          None
                        </p>
                        <p
                          v-for="child in expandedBudget
                            ? expandedBudget.childBudgets
                            : []"
                          :key="child.budgetId"
                          class="mb-1"
                        >
                          {{ child.budgetDisplayName }}
                        </p>
                      </div>
                    </div>
                  </td>
                </template>
                <template v-slot:[`item.displayName`]="{ item }">
                  <span
                    :class="{
                      'selectable-blue-text':
                        item.permissions && item.permissions.owner
                    }"
                    >{{ item.displayName }}</span
                  >
                </template>
                <template v-slot:[`item.funded`]="{ item }">
                  <div>
                    <!-- <v-btn
                      color="error"
                      small
                      v-if="item.sourceFundRequestCount > 0"
                      @click.native.stop="
                        loadBudgetActivityRequests(item.budgetId)
                      "
                      >{{ item.sourceFundRequestCount }}
                      Funding
                      {{
                        item.sourceFundRequestCount == 1
                          ? "Request"
                          : "Requests"
                      }}</v-btn
                    > -->
                    <v-btn
                      v-if="
                        (item.permissions && item.permissions.owner) ||
                          (permissions.includes('wallets:manualfund:budget') &&
                            userProfile.clientId == 1)
                      "
                      color="primary"
                      depressed
                      small
                      :class="{ cashBudget: item.awardId == 1 }"
                      :loading="budgetFundingLoading === item.budgetId"
                      @click.native.stop="
                        fundBudget(null, item, null, false, null, null)
                      "
                      >Add Funds</v-btn
                    >
                  </div>
                </template>
                <!--  // item.formattedBudgetBalance !== undefined &&
                      // item.formattedBudgetBalance !== null
                      //   ? (item.awardId == 1 ? "$ " : "") +
                      //     formatNum(
                      //       parseFloat(item.formattedBudgetBalance).toFixed(2)
                      //     ) +
                      //     (item.awardId == 1
                      //       ? ""
                      //       : item.budgetBalance == 1
                      //       ? " " + item.Award.unitSingular
                      //       : " " + item.Award.unitPlural)
                      //   : -->
                <template v-slot:[`item.budgetBalance`]="{ item }">
                  <div class="d-flex justify-left align-center">
                    <span class="d-flex justify-left">{{
                      formatCurrency(item.budgetBalance, item.awardId == 1) +
                        (item.awardId == 1
                          ? ""
                          : item.budgetBalance == 1
                          ? " " + item.Award.unitSingular
                          : " " + item.Award.unitPlural)
                    }}</span>
                    <v-btn
                      v-if="permissions.includes('budgets:create:refresh')"
                      icon
                      color="brandCyan"
                      :loading="!!loading.balances.includes(item.budgetId)"
                      @click.native.stop="refreshBudgetBalance(item.budgetId)"
                      ><v-icon>mdi-refresh</v-icon></v-btn
                    >
                  </div>
                </template>
                <template v-slot:[`item.totalAccountBalance`]="{ item }">
                  <div>
                    <span class="d-flex justify-left">{{
                      (item.awardId == 1 ? "$ " : "") +
                        formatNum(
                          parseFloat(item.totalAccountBalance).toFixed(
                            item.awardId == 1 ? 2 : 2
                          )
                        )
                    }}</span>
                  </div>
                </template>
                <template v-slot:[`item.icons`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="
                          item.SourceBudgetActivities.filter(
                            x => x.displayInFundingRequests
                          ).length > 0
                        "
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="loadBudgetActivityRequests(item.budgetId)"
                      >
                        <v-icon color="error">mdi-flag</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >This budget has
                      {{
                        item.SourceBudgetActivities.filter(
                          x => x.displayInFundingRequests
                        ).length
                      }}
                      pending
                      {{
                        item.SourceBudgetActivities.filter(
                          x => x.displayInFundingRequests
                        ).length == 1
                          ? "request"
                          : "requests"
                      }}
                      for funds</span
                    >
                  </v-tooltip>
                </template>
              </v-data-table>
            </div>
          </div>
          <div class="d-flex flex-wrap" v-else-if="viewBudgetActivity">
            <BudgetActivityManager
              v-if="viewBudgetActivity"
              @close="viewBudgetActivity = false"
              :clientId="clientId"
              :budgets="budgets"
              :budgetId="filteredBudgetActivity"
            ></BudgetActivityManager>
          </div>
          <div class="d-flex flex-wrap" v-else-if="viewBudgetActivityRequests">
            <BudgetActivityRequests
              v-if="viewBudgetActivityRequests"
              @close="
                viewBudgetActivityRequests = false;
                filteredBudgetRequest = null;
              "
              @load-budget-transfer="
                fundBudget(
                  $event.source,
                  $event.destination,
                  'budget-transfer',
                  false,
                  $event.amount,
                  $event.budgetActivityId
                )
              "
              :clientId="clientId"
              :budgets="budgets"
              :budgetId="filteredBudgetRequest"
            ></BudgetActivityRequests>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-navigation-drawer
      v-model="showBudgetDialog"
      temporary
      fixed
      stateless
      right
      width="500"
    >
      <BudgetCreatorWidget
        v-if="showBudgetDialog"
        ref="budgetCreator"
        @close="closeBudgetCreator"
        @get-budgets="getBudgets"
        @getPartners="getAwardFulfillmentPartners"
        :showBudgetWarning="false"
        :editingBudget="budgetForm.editing"
        :editBudgetObject="budget"
        :preloadParentBudget="preloadParentBudget"
        :preloadPanel="preloadBudgetPanel"
        :budgets="budgets"
        :clientId="clientId"
        source="BUDGETS"
      />
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="dialogPaymentMethods"
      temporary
      fixed
      right
      width="500"
      disable-route-watcher
    >
      <StripePaymentMethodsManager
        v-if="dialogPaymentMethods"
        @reset="dialogPaymentMethods = false"
        @get-budgets="getBudgets"
        @get-funding-limit="getBudgetFundingStatuses"
        @load-budget-activity="loadBudgetActivity(null)"
        :destinationBudget="destinationBudgetForFunding"
        :sourceBudget="sourceBudgetForFunding"
        :budgets="budgets"
        :clientId="clientId"
        :preloadMenu="preloadPaymentMenu"
        :requestFunds="budgetFundingRequest"
        :preloadAmount="preloadFundingAmount"
        :preloadBudgetActivityId="preloadBudgetActivityId"
        :fundingLimit="clientFundingStatus"
        source="BUDGETS"
      />
    </v-navigation-drawer>
    <Plaid
      v-if="loadPlaid"
      :hideButton="true"
      :preloadMenu="preloadPaymentMenu"
    />
    <v-dialog v-model="unsavedChangesDialog" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <!-- Error message to block a user from deleting themselves -->

          <v-card-title class="word-break align-text-left exit-warning-text">
            Are you sure you want to exit? You will lose any unsaved changes.
          </v-card-title>
        </div>

        <v-card-actions class="mx-12">
          <v-btn
            color="primary"
            @click="unsavedChangesDialog = false"
            outlined
            width="140"
            >Go back
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            @click="closeBudgetCreator"
            width="140"
            >Exit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </div>
</template>

<script>
import BudgetService from "../services/BudgetService.js";
import LoadingDialog from "@/components/loadingDialog.vue";
import BudgetCreatorWidget from "@/components/BudgetCreatorWidget.vue";
import StripePaymentMethodsManager from "@/components/stripe/StripePaymentMethodsManager.vue";
import BudgetActivityManager from "@/components/BudgetActivityManager.vue";
import BudgetActivityRequests from "@/components/BudgetActivityRequests.vue";
import Plaid from "@/components/Plaid";
import Robin from "@/components/Robin";
import moment from "moment";
import currency from "currency.js";
import { mapState } from "vuex";
// import WalletService from "../services/WalletService.js";
import { numberWithCommas, truncateNumber } from "../shared_data/functions.js";
import WalletService from "../services/WalletService.js";

export default {
  name: "Budgets",
  title: "Whistle | Budgets",
  components: {
    LoadingDialog,
    BudgetCreatorWidget,
    StripePaymentMethodsManager,
    Plaid,
    Robin,
    BudgetActivityManager,
    BudgetActivityRequests
  },
  props: {
    type: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      // clientId: null,
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      loadingBudgetTable: true,
      budgetTableSearch: null,
      budgetFundingLoading: false,
      selected: [],
      budgets: [],
      awards: [],
      fundingArray: [],
      awardFulfillmentPartners: [],
      showBudgetDialog: false,
      budgetForm: {
        menuStartDate: false,
        menuEndDate: false,
        endDateDisabled: false,
        endDateCheckBox: false,
        valid: false,
        forceValidForm: false,
        editing: false
      },
      budget: {
        clientId: null,
        budgetId: null,
        awardId: null,
        budgetName: null,
        budgetDisplayName: null,
        funded: null,
        sourceBudgetId: null,
        startDate: null,
        endDate: null,
        purchaseOrderNum: null
      },
      dialogPaymentMethods: false,
      viewBudgetActivity: false,
      viewBudgetActivityRequests: false,
      preloadPaymentMenu: null,
      loadPlaid: false,
      destinationBudgetForFunding: null,
      expandedBudgetTable: [],
      preloadParentBudget: null,
      preloadBudgetPanel: null,
      filteredBudgetActivity: null,
      filteredBudgetRequest: null,
      sortBy: null,
      sortDesc: null,
      budgetFilterMenu: false,
      budgetFilterValue: "children",
      budgetFundingRequest: false,
      preloadFundingAmount: null,
      preloadBudgetActivityId: null,
      unsavedChangesDialog: false,
      loading: {
        balances: []
      }
    };
  },

  created() {
    // this.clientId = this.userProfile.clientId;
    if (this.$auth) {
      this.getBudgets();

      // this.getAwards();
      let query = Object.assign({}, this.$route.query);
      console.log(query);
      console.log(this.type);
      if (
        this.type &&
        this.type.toUpperCase() == "ACH" &&
        query.oauth_state_id
      ) {
        this.loadPlaid = true;
        // this.preloadPaymentMenu = "ACH";
      } else if (this.type && this.type.toUpperCase() == "ACH") {
        // this.dialogPaymentMethods = true;
        // this.preloadPaymentMenu = "ACH";
      }
    }

    // this.unwatch = this.$store.watch(
    //   (state) => {
    //     return state.loadItemFromSearch; // could also put a Getter here
    //   },
    //   (budgetId) => {
    //     console.log(`User clicked item in search bar`);
    //     console.log(budgetId);
    //     if (
    //       budgetId !== null &&
    //       this.permissions.includes("budgets:create:budget")
    //     ) {
    //       this.loadEditBudgetForm(budgetId);
    //       //We wipe the ID storede in the store after we load it up
    //       this.$store.dispatch("setLoadItemFromSearch", null);
    //     }
    //   },
    //   //Optional Deep if you need it
    //   {
    //     deep: true,
    //   }
    // );
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  beforeDestroy() {
    //Clears the search in case they go to a page without search
    // this.$store.dispatch("setSearchArray", []);
    // this.unwatch();
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {
    formatCurrency(amt, cash = true) {
      return currency(amt).format({ symbol: cash ? "$" : "" });
    },
    formatNum(x) {
      return numberWithCommas(x);
    },
    truncateNum(x, i) {
      return truncateNumber(x, i);
    },
    getBudgets() {
      this.loadingBudgetTable = true;
      BudgetService.getBudgetsAdminView({ clientId: this.clientId, lite: 0 })
        .then(response => {
          console.log("Budget response ", response);
          response = response.filter(x => x.status != "Deleted");
          response.forEach(function(budget) {
            budget.id = budget.budgetId;
            budget.displayName =
              budget.budgetDisplayName !== null
                ? budget.budgetDisplayName
                : budget.budgetName;
            budget.name =
              budget.budgetDisplayName !== null
                ? budget.budgetDisplayName
                : budget.budgetName;
            if (budget.startDate) {
              budget.formattedStartDate = moment(
                budget.startDate,
                "YYYY-MM-DD hh:mm:ss"
              )
                .local()
                .utc()
                .format("MMM DD, YYYY");
            }
            if (budget.endDate) {
              budget.formattedEndDate = moment(
                budget.endDate,
                "YYYY-MM-DD hh:mm:ss"
              )
                .local()
                .utc()
                .format("MMM DD, YYYY");
            }
            budget.formattedBudgetBalance = truncateNumber(
              budget.budgetBalance,
              2
            );

            budget.displayNameWithBalance =
              (budget.budgetDisplayName || budget.budgetName) +
              (budget.awardId == 1 ? " - $" : " - ") +
              numberWithCommas(budget.formattedBudgetBalance);
          });
          // Let's filter any budgets that have a 0 budget or have expired
          // response = response.filter(
          //   (x) => x.status != "Expired"
          //   // (x.endDate === null ||
          //   //   !x.funded ||
          //   //   moment(x.endDate)
          //   //     .utc()
          //   //     .isAfter(moment().utc())) &&
          //   // (x.budgetBalance > 0 || !x.funded)
          // );

          // response = response.sort((a, b) => a.budgetId - b.budgetId);
          this.budgets = response;
          // this.$store.dispatch("setSearchArray", response);
          this.loadingBudgetTable = false;

          this.getBudgetFundingStatuses();
        })
        .catch(error => {
          console.log("Error!" + error);
          this.loadingBudgetTable = false;
        });
    },
    getBudgetFundingStatuses() {
      // this is used to get the client's balance on how much they have funded if they're using Whistle's KYB status
      // So we can cap them at 1,000
      BudgetService.getBudgetFundingStatuses()
        .then(response => {
          console.log("KYB funding response ", response);

          this.fundingArray = response.map(x => {
            if (x.cooldownExpiration)
              x.cooldownExpirationLocal = moment(x.cooldownExpiration)
                .local()
                .format("MMM Do h:mm a");
            return x;
          });
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    // getAwards() {
    //   BudgetService.getAwards()
    //     .then((response) => {
    //       console.log(response);
    //       var activeAwards = [];
    //       response.forEach(function(award) {
    //         if (award.status !== "Deleted") {
    //           activeAwards.push(award);
    //         }
    //       });
    //       this.awards = activeAwards;
    //     })
    //     .catch((error) => {
    //       console.log("Error!" + error);
    //     });
    // },
    getAwardFulfillmentPartners() {
      console.log("Getting new award fulfillment partners");
      BudgetService.getAwardFulfillmentPartners()
        .then(response => {
          console.log("Award Fulfillment Partners: ", response);
          var partners = response.filter(
            x => x.status != "Deleted" && x.clientId == this.clientId
          );
          // We use $set because Vue wasn't updating this array that we passed as a prop to the Award Creator component
          this.$set(this, "awardFulfillmentPartners", partners);
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    loadEditBudgetForm(budget) {
      if (!this.permissions.includes("budgets:create:budget")) {
        return;
      }
      console.log("Editing budget " + budget);
      // if (typeof budgetId == "object") {
      if (budget.permissions && !budget.permissions.owner) {
        return;
      }
      // budgetId = budgetId.budgetId;
      // }
      var editObj = JSON.parse(JSON.stringify(budget));
      this.budgetForm.editing = true;
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Loading the selected budget";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";
      // BudgetService.getBudget(budgetId)
      //   .then((budget) => {
      //     console.log(budget);
      //     var editObj = JSON.parse(JSON.stringify(budget));
      var formattedStartDate = null;
      if (budget.startDate) {
        formattedStartDate = moment(budget.startDate)
          .local()
          .utc()
          .format("YYYY-MM-DD");
      }
      var formattedEndDate = null;
      if (budget.endDate !== null) {
        formattedEndDate = moment(budget.endDate)
          .local()
          .utc()
          .format("YYYY-MM-DD");
      } else {
        this.budgetForm.endDateDisabled = true;
        this.budgetForm.endDateCheckBox = true;
        this.budgetForm.forceValidForm = true;
      }
      editObj.startDate = formattedStartDate;
      editObj.endDate = formattedEndDate;

      // this.showBudgetDialog = true;
      this.showLoadingDialog = false;
      this.budgetForm.valid = true;
      console.log(JSON.parse(JSON.stringify(editObj)));
      this.loadBudgetBuilder(editObj, true);
      // })
      // .catch((error) => {
      //   this.showLoadingDialog = false;
      //   // this.resetBudgetForm();
      //   console.log("Error fetching budget! ", error);
      // });
    },
    fundBudget(
      source,
      destination,
      preloadMenu,
      requestFunds = false,
      amount = null,
      budgetActivityId = null
    ) {
      console.log(source);
      console.log(destination);
      console.log(preloadMenu);
      console.log(requestFunds);
      console.log(amount);
      this.dialogPaymentMethods = true;
      this.sourceBudgetForFunding = source;
      this.destinationBudgetForFunding = destination;
      this.preloadPaymentMenu = preloadMenu;
      this.budgetFundingRequest = requestFunds;
      this.preloadFundingAmount = amount;
      this.preloadBudgetActivityId = budgetActivityId;

      // this.budgetFundingLoading = budget.budgetId;
      // WalletService.fundBudget(budget.budgetId)
      //   .then((budget) => {
      //     console.log(budget);
      //     this.budgetFundingLoading = false;
      //     this.getBudgets();
      //   })
      //   .catch((error) => {
      //     this.showLoadingDialog = false;
      //     this.resetBudgetForm();
      //     console.log("Error fetching budget! ", error);
      //   });
    },
    openFAQ() {
      // Opens up FreskDesk in a new tab
      window.open("https://whistle.freshdesk.com/support/home", "_blank");
    },
    loadBudgetBuilder(budget, editing, parentBudget = null, preloadPanel) {
      this.showBudgetDialog = true;
      this.budget = budget;
      this.budgetForm.editing = editing;
      this.preloadParentBudget = parentBudget;
      this.preloadBudgetPanel = preloadPanel;
      document.addEventListener("mouseup", this.showExitWarning);
    },
    closeBudgetCreator() {
      this.showBudgetDialog = false;
      this.unsavedChangesDialog = false;
      document.removeEventListener("mouseup", this.showExitWarning);
    },
    refreshClientId() {
      this.selected = [];
      this.getBudgets();
      // this.users = this.allUsers.filter((x) => x.clientId == this.clientId);
      // this.groups = this.allGroups.filter((x) => x.clientId == this.clientId);
      // this.listGroups = this.groups.filter(
      //   (x) => x.groupType == "List" && x.status == "Active"
      // );
    },
    loadBudgetActivity(budgetId = null) {
      // (viewBudgetActivity = true),
      //             (viewBudgetActivityRequests = false)
      this.viewBudgetActivity = true;
      this.viewBudgetActivityRequests = false;
      this.dialogPaymentMethods = false;
      if (budgetId) this.filteredBudgetActivity = budgetId;
      else this.filteredBudgetActivity = null;
    },
    loadBudgetActivityRequests(budgetId = null) {
      this.viewBudgetActivityRequests = true;
      this.dialogPaymentMethods = false;
      this.viewBudgetActivity = false;
      if (budgetId) this.filteredBudgetRequest = budgetId;
      else this.filteredBudgetRequest = null;
    },
    editBudgetClass(item) {
      if (item.permissions && item.permissions.owner) {
        return "cursor-pointer"; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
    updateSort(byDesc, event) {
      console.log("UPDATE SORT", byDesc);
      console.log(event);
      if (byDesc == "by") {
        this.sortBy = event;
      } else if (byDesc == "desc") {
        this.sortDesc = event;
      }

      if (this.sortBy || this.sortDesc) {
        this.budgetFilterValue = null;
      }
    },
    updateFilter(newVal) {
      console.log("NEW FILTER VALUE", newVal);

      this.budgetFilterValue = newVal;
      if (newVal == "children") {
        this.sortBy = null;
        this.sortDesc = null;
      }
    },
    loadKYB() {
      if (this.canCompleteKYB)
        this.$router.push({
          name: "paymentadmin",
          params: {
            preload: "kyb",
            previousRoute: this.$route.name
          }
        });
    },
    showExitWarning(e) {
      // If we're waiting on an API call, then we do nothing
      if (
        this.$refs.budgetCreator &&
        this.$refs.budgetCreator.showLoadingDialog
      )
        return;
      // (e) => {
      // console.log(e);
      // console.log(e.path);
      // console.log(e.composed);
      // console.log(
      //   "Budget click event",
      //   (e.path || e.composedPath()).map(x => x && x.className)
      // );
      if (e.path || e.composed)
        var evtInDrawer = (e.path || e.composedPath()).find(
          x =>
            x &&
            x.className &&
            typeof x.className == "string" &&
            (x.className.includes("navigation-drawer") ||
              x.className.includes("v-date-picker") ||
              x.className.includes("v-list-item") ||
              x.className.includes("ignore-click"))
        );
      // console.log("Event inside drawer ? ", evtInDrawer);
      if (!evtInDrawer) {
        // we want to show an exit warning dialog
        if (
          // this.budget.budgetDisplayName ||
          // this.budget.awardId ||
          this.budgetForm.editing ||
          (this.$refs.budgetCreator &&
            (this.$refs.budgetCreator.budget.budgetDisplayName ||
              this.$refs.budgetCreator.budget.awardId))
        ) {
          console.log("Need to warn about unsaved changes");
          this.unsavedChangesDialog = true;
        } else this.closeBudgetCreator();
      }
      // }
    },
    async refreshBudgetBalance(budgetId) {
      try {
        this.loading.balances.push(budgetId);
        const response = await WalletService.refreshBudgetBalance(budgetId);
        console.log(response);
        let budget = this.budgets.find(x => x.budgetId == budgetId);
        if (budget && response.processed && response.processed[0]) {
          budget.budgetBalance = response.processed[0].balance;
        }
      } catch (e) {
        console.log("Error refreshing budget balance", e);
      } finally {
        this.loading.balances = this.loading.balances.filter(
          x => x != budgetId
        );
      }
    }
  },
  computed: {
    ...mapState([
      "clients",
      "userProfile",
      "postItArray",
      "permissions",
      "globalClientId",
      "marqeta"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    visibleBudgets() {
      if (this.clientId !== null) {
        var temp = this.budgets.filter(
          x =>
            x.clientId == this.clientId &&
            // !x.masterBudget &&
            x.status !== "Expired"
        );

        if (this.budgetFilterValue == "parent") {
          temp = temp.filter(x => x.childDepth == 0);
        }

        temp.forEach(x => {
          if (x.awardId != 1) {
            x.childDepth--;
          }

          if (
            !this.sortBy &&
            !this.sortDesc &&
            this.budgetFilterValue == "children"
          ) {
            var str = "";
            for (var i = -1; i < x.childDepth; i++) {
              str += "- ";
            }
            x.displayName = str + x.budgetDisplayName;
          } else {
            x.displayName = x.budgetDisplayName;
          }

          // if (x.masterBudget) {
          //   x.displayName = "General Funds";
          // }
        });

        return temp;
      }
      return [];
    },
    budgetColumns() {
      var array = [
        { text: "", value: "data-table-expand", width: "1%" },
        { text: "Name", value: "displayName", width: "30%" },
        // { text: "Total Balance", value: "totalAccountBalance", width: "25%" },
        { text: "Avail Balance", value: "budgetBalance", width: "25%" }
      ];
      if (this.userProfile.clientId == 1)
        array.splice(1, 0, {
          text: "Id",
          value: "budgetId",
          width: "10%",
          align: "start"
        });

      if (
        this.visibleBudgets.find(x => x.permissions && x.permissions.owner) ||
        (this.permissions.includes("wallets:manualfund:budget") &&
          this.userProfile.clientId == 1)
      ) {
        var balanceIndex = array.findIndex(x => x.value == "budgetBalance");
        array.splice(balanceIndex + 1, 0, {
          text: "",
          value: "funded",
          width: "15%",
          sortable: false
        });
      }
      return array;
    },
    computedPageHeader() {
      var title = "";
      if (this.permissions.includes("vue:read:budgets")) {
        title = title.concat("Budgets, ");
      }
      if (this.permissions.includes("vue:read:awards")) {
        title = title.concat("Awards, ");
      }
      if (this.permissions.includes("vue:read:badges")) {
        title = title.concat("Badges, ");
      }
      if (title.endsWith(", ")) {
        title = title.substring(0, title.length - 2);
      }
      return title;
    },
    accountBalance() {
      if (this.clientId !== null) {
        var master = this.budgets.find(
          x => x.clientId == this.clientId && x.masterBudget
        );
        if (master) {
          return master;
        }
      }
      return null;
    },
    pendingTransfersTotal() {
      var sum = 0;
      this.budgets
        .filter(x => x.clientId == this.clientId)
        .forEach(budget => {
          budget.BudgetActivities.filter(
            x => x.stripeStatus == "pending" && x.status == "Active"
          ).forEach(x => {
            sum += x.amount;
          });
        });
      return sum;
    },
    expandedBudget() {
      if (this.expandedBudgetTable.length > 0) {
        var budget = JSON.parse(JSON.stringify(this.expandedBudgetTable[0]));
        if (budget.parentBudgetId) {
          var parent = this.budgets.find(
            x => x.budgetId == budget.parentBudgetId
          );
          if (parent) {
            budget.parentBudget = parent;
          } else {
            budget.parentBudget = {
              budgetDisplayName: "Unknown parent"
            };
          }
        }

        var children = [];
        budget.directChildren.forEach(y => {
          var child = this.budgets.find(x => x.budgetId == y);
          if (child) children.push(child);
        });
        budget.childBudgets = children;
        return budget;
      }
      return null;
    },
    budgetPieChartOptions() {
      // console.log(this.expandedBudgetTable);
      var obj = {
        options: {
          chart: {
            width: 320,
            type: "pie"
          },
          plotOptions: {
            pie: {
              expandOnClick: false
            }
          },
          labels: ["Remaining", "Allocated", "Used"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        },
        series: []
      };
      if (this.expandedBudget) {
        obj.series = [
          parseFloat(this.expandedBudget.budgetBalance),
          parseFloat(this.expandedBudget.allocatedBalance),
          parseFloat(this.expandedBudget.usedBalance)
        ];
      }
      return obj;
    },
    budgetDonutChartOptions() {
      // console.log(this.expandedBudget);
      var obj = {
        options: {
          chart: {
            type: "donut"
          },
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 90
            }
          },
          labels: ["Used", "Remaining"],
          // dataLabels: {
          //   enabled: true,
          //   formatter: function(val) {
          //     return "$" + val;
          //   },
          // },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        },
        series: []
      };
      if (this.expandedBudget) {
        obj.series = [
          parseFloat(this.expandedBudget.usedBalance),
          parseFloat(this.expandedBudget.budgetBalance)
        ];
      }
      return obj;
    },
    totalFundingRequests() {
      var count = 0;
      this.visibleBudgets.forEach(x => {
        count += x.fundRequestCount;
      });
      return count;
    },
    clientFundingStatus() {
      if (this.clientId && !this.budgetFundingLoading) {
        var status = this.fundingArray.find(x => x.clientId == this.clientId);
        if (!status)
          return {
            passedKYB: false,
            canFundMore: true,
            showLimitWarning: false,
            amountFunded: 0,
            cooldownActive: false,
            cooldownHoursRemaining: null,
            cooldownExpiration: null,
            cooldownExpirationLocal: null,
            allowedToFund: 10000
          };
        return status;
      }
      return null;
    },
    canCompleteKYB() {
      return (
        this.permissions.includes("vue:read:paymentadmin") &&
        this.permissions.includes("marqeta:create:business") &&
        !this.marqeta.processingKYB &&
        !this.marqeta.completedKYB
      );
    }
  },
  watch: {
    dialogPaymentMethods: function(newVal) {
      if (newVal === false) {
        // side drawer just closed so we refresh budgets in case they have funded
        console.log("Refreshing budgets");
        this.getBudgets();
      }
    }
  }
};
</script>

<style scoped>
.tabs {
  margin-top: 12px;
}

.tabs .theme--dark.v-btn--active::before {
  opacity: 0;
}

.v-data-table-header {
  background-color: var(--v-lightGrey-base);
}
#template-download-button {
  margin-right: 3%;
  /* margin-bottom: 3%; */
}
.v-stepper__step__step.primary {
  background-color: red !important;
  border-color: red !important;
}

.award-button .v-btn--active.no-active::before {
  opacity: 0 !important;
}
.brand-background {
  background-color: var(--v-brand-base);
}
.border-radius {
  border-radius: 5px;
}

.active-background {
  /* background-color: var(--v-brandPaleBlue-base); */
}

.budget-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.budget-table >>> .v-data-table-header {
  background-color: white;
}

.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}

#pie-chart-placeholder-text {
  max-width: 200px;
}
#child-budget-list-container {
  width: 200px;
}
</style>
